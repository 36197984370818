import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import { roles } from '@Commercial/roles'
import { RootState } from '@Core/Communication'
import { usePermissions } from '@Core/Hooks/usePermissions'
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useSelector } from 'react-redux'

interface CommercialContextProps {
    selectedUser: string[]
    setSelectedUser: React.Dispatch<React.SetStateAction<string[]>>
    allProposals: DBCommercialProposal[]
    setAllProposals: React.Dispatch<
        React.SetStateAction<DBCommercialProposal[]>
    >
    getProposal: () => void
}

export const CommercialContext = createContext<CommercialContextProps | null>(
    null
)

export const CommercialContextProvider: React.FC = ({ children }) => {
    const { user } = useSelector((state: RootState) => state.user)
    const [selectedUser, setSelectedUser] = useState<string[]>(['all'])
    const [list, setList] = useState<DBCommercialProposal[]>([] as any)
    const [allProposals, setAllProposals] = useState(
        [] as DBCommercialProposal[]
    )

    const { hasPermission } = usePermissions()
    const viewAll = hasPermission(
        roles.proposal.permissions.read.permissions.all.key
    )

    const proposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetProposals
    )
    const getProposal = useCallback(async () => {
        const proposalList = await proposalContainer.execute()
        setList(proposalList)
    }, [viewAll])

    useEffect(() => {
        const userProposal = list.filter((item) => {
            return item?.user?.mid === (user?.id as string)
        })
        const listToRender = viewAll ? list : userProposal
        setAllProposals(listToRender)
    }, [list, viewAll])

    useEffect(() => {
        getProposal()
    }, [])

    useEffect(() => {
        if (!viewAll) {
            setSelectedUser([user?.id as string])
        }
    }, [viewAll])

    return (
        <CommercialContext.Provider
            value={{
                selectedUser,
                setSelectedUser,
                allProposals,
                setAllProposals,
                getProposal,
            }}
        >
            {children}
        </CommercialContext.Provider>
    )
}

export const useCommercialContext = (): CommercialContextProps => {
    const context = useContext(CommercialContext)

    if (!context) {
        throw new Error(
            'useCommercialContext must be used within a CommercialContextProvider'
        )
    }

    return context
}
