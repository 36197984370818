export const moduleKey = 'inventory'
export const roles = {
    product: {
        name: 'Produtos',
        key: `${moduleKey}:product`,
        permissions: {
            summary: {
                name: 'Resumo',
                description: 'Permite visualizar resumo de produtos',
                key: `${moduleKey}:product:summary`,
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar produtos',
                key: `${moduleKey}:product:read`,
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar produtos',
                key: `${moduleKey}:product:export`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar produtos',
                key: `${moduleKey}:product:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar produtos',
                key: `${moduleKey}:product:update`,
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar produtos',
                key: `${moduleKey}:product:delete`,
            },
        },
    },
    exit: {
        name: 'Saídas',
        key: `${moduleKey}:exit`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar saídas',
                key: `${moduleKey}:exit:read`,
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar saídas',
                key: `${moduleKey}:exit:export`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar saídas',
                key: `${moduleKey}:exit:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar saídas',
                key: `${moduleKey}:exit:update`,
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar saídas',
                key: `${moduleKey}:exit:delete`,
            },
        },
    },
    entries: {
        name: 'Entradas',
        key: `${moduleKey}:entries`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar entradas',
                key: `${moduleKey}:entries:read`,
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar entradas',
                key: `${moduleKey}:entries:export`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar entradas',
                key: `${moduleKey}:entries:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar entradas',
                key: `${moduleKey}:entries:update`,
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar entradas',
                key: `${moduleKey}:entries:delete`,
            },
        },
    },
    suppliers: {
        name: 'Fornecedores',
        key: `${moduleKey}:suppliers`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar fornecedores',
                key: `${moduleKey}:suppliers:read`,
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar fornecedores',
                key: `${moduleKey}:suppliers:export`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar fornecedores',
                key: `${moduleKey}:suppliers:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar fornecedores',
                key: `${moduleKey}:suppliers:update`,
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar fornecedores',
                key: `${moduleKey}:suppliers:delete`,
            },
        },
    },
    destinations: {
        name: 'Destinos',
        key: `${moduleKey}:destinations`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar destinos',
                key: `${moduleKey}:destinations:read`,
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar destinos',
                key: `${moduleKey}:destinations:export`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar destinos',
                key: `${moduleKey}:destinations:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar destinos',
                key: `${moduleKey}:destinations:update`,
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar destinos',
                key: `${moduleKey}:destinations:delete`,
            },
        },
    },
}
