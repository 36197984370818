import { RootState } from '@Core/Communication'
import { CookiesStorage } from '@Core/Communication/GlobalStore/CookiesStorage/CookiesStorage'
import { useAuth } from '@Core/Hooks/useAuth'
import { LoginCard, Logo } from '@Entry/Login/Login.styles'
import { LoginForm } from '@Entry/Login/LoginForm'
import logo from '@assets/logo.png'
import { Backdrop } from '@material-ui/core'
import { OAuthProvider } from 'firebase/auth'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
const storage = new CookiesStorage()

export const SessionExpired = () => {
    const { isSessionExpired } = useSelector(
        (state: RootState) => state.authentication
    )
    const { uiAuthenticate, loading, step, user } = useAuth(
        storage,
        new OAuthProvider('microsoft.com'),
        true
    )

    useEffect(() => {
        if (!isSessionExpired && !storage.getItem('@ofc:accessToken')) {
            toast.error(
                'Ops, sua sessão expirou. Por favor, faça login novamente.'
            )
        }
    }, [isSessionExpired])

    if (!isSessionExpired) return null

    return (
        <div
            style={{
                zIndex: 10,
                display: 'flex',
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <Backdrop open />
            <LoginCard>
                <Logo src={logo}></Logo>
                <h4>Ops, sua sessão expirou</h4>
                <LoginForm
                    uiAuthenticate={uiAuthenticate}
                    step={step}
                    user={user}
                    loading={loading}
                />
            </LoginCard>
        </div>
    )
}
