import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import {
    InventoryProductContainer,
    InventoryProductRegister,
} from '@InventoryControl/Products/Data/DataSource/Container'
import { useState } from 'react'

export const useInventoryProducts = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<DBInventoryProduct[]>([])
    const getProductsUseCase = InventoryProductContainer.get(
        InventoryProductRegister.UseCaseGetProducts
    )

    const formattedAutocomplete = (products: DBInventoryProduct[]) => {
        return products.map((product) => ({
            value: product,
            label: product.name,
        }))
    }

    const getProducts = async () => {
        setLoading(true)
        try {
            const products = await getProductsUseCase.execute()
            setProducts(products)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return { products, getProducts, loading, formattedAutocomplete }
}
