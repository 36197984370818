import { RootState } from '@Core/Communication'
import { CookiesStorage } from '@Core/Communication/GlobalStore/CookiesStorage/CookiesStorage'
import {
    logoutAction,
    setIsAuthenticationFinishedAction,
    setIsLoggedAction,
} from '@Core/Communication/GlobalStore/Reducers/authentication/reducer'
import {
    setUserAction,
    setUserPhotoAction,
} from '@Core/Communication/GlobalStore/Reducers/user/reducer'
import { User } from '@Core/Entities/User'
import { RoutesPaths } from '@Entry/Common/RoutesPaths'
import { MAPI } from '@Services/microsoft'
import { UserContainer, UserRegister } from '@User/Data/DataSource/Container'
import { isTokenExpired } from '@Utils/Support/expiredJWT'
import * as Sentry from '@sentry/react'
import { getAuth } from 'firebase/auth'
import LogRocket from 'logrocket'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const storage = new CookiesStorage()

export const useUser = () => {
    const { user } = useSelector((state: RootState) => state.user)
    const userContainer = UserContainer.get(UserRegister.UseCaseGetUser)
    const dispatch = useDispatch()
    const auth = getAuth()
    const navigate = useNavigate()

    const getPhoto = async () => {
        try {
            const o = await MAPI.get('/me/photo/$value', {
                responseType: 'blob',
            })
            const url = window.URL || window.webkitURL
            const blobUrl = url.createObjectURL(o.data)
            dispatch(setUserPhotoAction(blobUrl))
        } catch (e) {
            dispatch(setUserPhotoAction(''))
        }
    }

    const getUser = useCallback(async () => {
        if (import.meta.env.VITE_MOCK_AUTH) {
            dispatch(
                setUserAction({
                    givenName: 'Buzato',
                    surname: 'DEV',
                    mail: 'igor@buzato.dev.br',
                    id: '123',
                } as User)
            )
            return
        }
        if (isTokenExpired(storage.getItem('@ofc:accessToken'))) {
            dispatch(logoutAction())
            setTimeout(() => {
                navigate(RoutesPaths.Login)
            }, 350)
            return
        }
        if (auth.currentUser === null) return
        const { reloadUserInfo } = auth.currentUser
        const user = await userContainer.execute(reloadUserInfo.email)
        const { localId, displayName, email } = reloadUserInfo as {
            [key: string]: string
        }
        const { name } = user
        dispatch(
            setUserAction({
                ...user,
                id: localId,
                uid: localId,
                displayName,
                mail: email,
                givenName: name?.split(' ')[0],
                surname: name?.split(' ')[1] || '',
            } as unknown as User)
        )
        dispatch(setIsAuthenticationFinishedAction(true))
        dispatch(setIsLoggedAction(true))
        Sentry.setUser({
            id: reloadUserInfo.localId,
            email: reloadUserInfo.email,
            name: name,
        })
        LogRocket.identify(reloadUserInfo.localId, {
            name: String(name),
        })
    }, [auth, dispatch, userContainer])

    useEffect(() => {
        getUser()
        auth.onAuthStateChanged((user) => {
            if (user) {
                getUser()
            }
        })
    }, [])

    return {
        user,
        photo: user?.photo || '',
    }
}
