import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'
import { API } from '@Services/api'
import { useProductManager } from '../../Hooks/useProductManager'
import { NewProductContent } from './NewProductModal.styles'
import { NewProductModalProps } from './NewProductModal.types'

export const NewProductModal = ({
    isOpen,
    setIsOpen,
    callback,
}: NewProductModalProps) => {
    const { handleAddingProduct } = useProductManager(API)
    const { handleChange, handleSubmit } = handleAddingProduct(callback)

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
            <h2>Novo Produto</h2>
            <NewProductContent>
                <Input
                    onChange={handleChange}
                    name="name"
                    required
                    label="Nome"
                />
            </NewProductContent>
            <ModalFooter>
                <Button onClick={toggleModal} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant={ButtonVariant.primary}>
                    Cadastrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
