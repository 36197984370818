// @ts-nocheck
import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
export const exportInventoryDictionary = (item: any) =>
    ({
        _id: 'ID',
        name: 'Nome',
        __v: '',
    }[item] || item)

export const exportInventoryValueDictionary = (
    key: DBInventoryDestination,
    item: DBInventoryDestination[key]
) => {
    return (
        {
            _id: { value: item, type: 'string' },
            name: { value: item, type: 'string' },
        }[key] || { value: item, type: 'string' }
    )
}

export const exportInventoryDestinationValueDictionary = (
    key: DBInventoryDestination,
    item: DBInventoryDestination[key]
) => item

export const exportDestinationData = (data: DBInventoryDestination[]) => {
    const columns = ['_id', 'name']
    const rows = data.map((item: DBInventoryDestination) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportInventoryValueDictionary(
                column,
                item[column],
                item
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportInventoryData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
    }))
