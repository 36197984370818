// @ts-nocheck
import { Clients } from '@Balance/Clients/Domain/Model/Clients'
import { useClients } from '@Balance/Clients/Hooks/useClients'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { useInventoryProducts } from '@InventoryControl/Products/Hooks/useProducts/useInventoryProducts'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import { useInventorySuppliers } from '@InventoryControl/Supplier/Hooks/useInventorySupplier/useInventorySupplier'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { EntryForm } from '../Common/Components/EntryForm/EntryForm'
import { useInventoryCreateEntry } from '../Hooks/useInventoryCreateEntry/useInventoryCreateEntry'
import { entrySchema } from '../entities/Entry'

export const CreateEntry = () => {
    const {
        register,
        watch,
        formState: { errors },
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(entrySchema),
    })
    const {
        handleChange,
        handleSubmit,
        state,
        created,
        failed,
        handleAddingEntry,
        handleRemoveEntry,
        handleCalculate,
    } = useInventoryCreateEntry({ setFormValue: setValue })
    const { products, getProducts } = useInventoryProducts()
    const { suppliers, getSuppliers } = useInventorySuppliers()
    const { clients } = useClients()
    const navigate = useNavigate()
    const { state: locationState } = useLocation()

    const goBack = () => {
        navigate(RoutesPaths.InventoryEntries)
    }

    const handleDateChange = (name: string) => (date: Date) => {
        handleChange({ target: { name: name, value: date } })
    }

    const handleAutoCompleteChange =
        (id: string) =>
        (name: string) =>
        (_: any, { value }: any) => {
            setValue(name, value)
            if (name.includes('product')) {
                setValue(`unitCost-${id}`, value?.unitCost)
                if (state?.[`quantity-${id}`]) {
                    setValue(
                        `totalPurchaseValue-${id}`,
                        state?.[`quantity-${id}`] * value?.unitCost
                    )
                }
            }
        }

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            const [_, ...idRest] = name?.split('-') || ['', '']
            return handleChange({
                target: { name, value: value[name], id: idRest.join('-') },
            })
        })
        return () => subscription.unsubscribe()
    }, [watch, state])

    useEffect(() => {
        if (locationState?.product) {
            handleAutoCompleteChange(state[0].id)(`product-${state[0].id}`)(
                null,
                {
                    value: {
                        value: locationState?.product?._id,
                        label: locationState?.product?.name,
                        ...locationState?.product,
                    },
                }
            )
        }
    }, [locationState])

    useEffect(() => {
        getProducts()
        getSuppliers()
    }, [])

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <Grid container spacing={1}>
                    <Grid item lg={6} xs={6} container>
                        <h1 className="text-2xl font-bold">Nova Entrada</h1>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        lg={6}
                        spacing={1}
                        container
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Button
                            type="button"
                            onClick={() => handleSubmit(goBack)}
                            variant={ButtonVariant.primary}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </ContainerHeader>
            <form>
                <Grid item lg={12} container spacing={1}>
                    {state.map((item) => (
                        <Grid key={item?.id} item lg={12} xs={12}>
                            <EntryForm
                                control={control}
                                register={register}
                                suppliers={formattedAutocomplete<DBInventorySupplier>(
                                    suppliers,
                                    'name'
                                )}
                                state={item}
                                isFailed={failed.includes(item?.id)}
                                isCreated={created.includes(item?.id)}
                                errors={errors}
                                onRemove={handleRemoveEntry}
                                handleChange={handleChange}
                                handleCalculate={handleCalculate(item?.id)}
                                handleAutoCompleteChange={handleAutoCompleteChange(
                                    item?.id
                                )}
                                products={formattedAutocomplete<DBInventoryProduct>(
                                    products,
                                    'name'
                                )}
                                clients={formattedAutocomplete<Clients>(
                                    clients,
                                    'corporateName'
                                )}
                                handleDateChange={handleDateChange}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={1}>
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        container
                        justifyContent="center"
                    >
                        <Button
                            type="button"
                            size="large"
                            onClick={handleAddingEntry}
                            variant={ButtonVariant.secondary}
                        >
                            Adicionar Entrada
                        </Button>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        spacing={1}
                        container
                        justifyContent="flex-end"
                    >
                        <Button
                            type="button"
                            onClick={() => handleSubmit(goBack)}
                            variant={ButtonVariant.primary}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}
