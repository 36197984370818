export const createHoverColor = (hex: string) => {
    hex = hex.replace('#', '')
    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)
    r = Math.round(r * 0.8)
    g = Math.round(g * 0.8)
    b = Math.round(b * 0.8)

    return `rgba(${r}, ${g}, ${b}, 1)`
}
