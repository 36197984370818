// @ts-nocheck
import {
    InventoryExitsContainer,
    InventoryExitsRegister,
} from '@InventoryControl/Exits/Data/DataSource/Container'
import { Exits, exitSchema } from '@InventoryControl/Exits/entities/Exits'
import { generateRandomId } from '@Utils/Mocks/generateRandomId'
import LogRocket from 'logrocket'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

export const useInventoryCreateExit = ({ setFormValue }) => {
    const [created, setCreated] = useState<string[]>([])
    const [failed, setFailed] = useState<string[]>([])
    const [state, setState] = useState<ExitsStack[]>([
        {
            id: generateRandomId(),
            created_at: new Date(),
        },
    ] as ExitsStack[])

    const [loading, setLoading] = useState(false)
    const createExitUseCase = InventoryExitsContainer.get(
        InventoryExitsRegister.UseCaseCreateExit
    )

    const handleAddingExit = () => {
        setState((prev) => [
            ...prev,
            {
                id: generateRandomId(),
                created_at: new Date(),
            },
        ])
    }

    const handleChange = useCallback(
        (e: any) => {
            const { value, name, id } = e.target
            const index = state.findIndex((item) => item.id === id)
            if (index === -1) return
            setState((prev) => {
                const newState = [...prev]
                newState[index] = {
                    ...newState[index],
                    [name]: value,
                }
                return newState
            })
        },
        [state]
    )

    const removeId = (name: string) => name?.split('-').shift()

    const clearFields = (item: ExitsStack): Exits => {
        const fields = Object.keys(item)
        const newFields = fields.reduce((prev, curr) => {
            if (curr === 'id') {
                return prev
            }
            return {
                ...prev,
                [removeId(curr)]: item[curr],
            }
        })
        return newFields as Exits
    }

    const handleRemoveExit = (id: string) => {
        setState((prev) => prev.filter((item) => item.id !== id))
    }

    const createExit = useCallback(async (item: ExitsStack) => {
        try {
            await exitSchema.validate(clearFields(item))
            await createExitUseCase.execute(clearFields(item))
            setCreated((prev) => [...prev, item.id])
            return true
        } catch (e) {
            setFailed((prev) => [...prev, item.id])
            LogRocket.captureException(e)
            console.log(e)
            throw e
        }
    }, [])

    const handleSubmit = useCallback(
        async (callBack: () => void) => {
            setLoading(true)
            setFailed([])
            try {
                await Promise.all(
                    state.map(async (item: ExitsStack) => {
                        if (created.includes(item.id)) return
                        return await createExit(item)
                    })
                )
                toast.success('Saída criada com sucesso')
                setState([] as ExitsStack[])
                callBack()
            } catch (e) {
                toast.error('Erro ao criar saída')
                LogRocket.captureException(e)
                console.log(e)
            }
            setLoading(false)
        },
        [state]
    )

    const handleCalculate = useCallback(
        (id) => () => {
            const index = state.findIndex((item) => item.id === id)
            if (index === -1) return
            const unitCost =
                state[index]?.[`unitCost-${id}`] ||
                state[index]?.[`product-${id}`]?.unitCost
            setState((prev) => {
                const newState = [...prev]
                const newTotal =
                    unitCost * (newState[index]?.[`quantity-${id}`] ?? 0)
                newState[index] = {
                    ...newState[index],
                    [`totalSalesValue-${id}`]: newTotal,
                }
                setFormValue(`totalSalesValue-${id}`, newTotal)
                return newState
            })
        },
        [state]
    )

    return {
        state,
        created,
        failed,
        handleChange,
        handleSubmit,
        loading,
        handleAddingExit,
        handleRemoveExit,
        handleCalculate,
    }
}
