import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface DeleteProductProposals {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteProductProposalsUseCase implements DeleteProductProposals {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(id: string) {
        return this.repository.deleteProduct(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
