import { useState } from 'react'

export const usePaginate = ({ entities }: { entities: any[] }) => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const getPaginate = (page: number, limit: number) => {
        return entities.slice((page - 1) * limit, page * limit)
    }

    return {
        entities,
        getPaginate,
        page,
        limit,
        setPage,
        setLimit,
    }
}
