import { CoreRepository } from '@Core/Data/Repository/CoreRepository'
import { SendStock, SendStockUseCase } from '@Core/Domain/UseCases'
import { Container } from 'inversify'
import { CoreAPI } from '../API/CoreAPI'
import { Register } from './CoreRegister'

const container = new Container()
container
    .bind<SendStock>(Register.UseCaseSendStock)
    .toDynamicValue(
        () => new SendStockUseCase(new CoreRepository(new CoreAPI()))
    )

export default container
