import { InventorySuppliersRepository } from '@InventoryControl/Supplier/Data/Repository/SupplierRepository'
import { DBInventorySupplier } from '../../Data/DataSource/API/Entity/Supplier'

export interface UpdateSupplier {
    execute(entity: DBInventorySupplier): Promise<DBInventorySupplier>
    getSource(): string
}

export class UpdateSupplierUseCase implements UpdateSupplier {
    constructor(private repository: InventorySuppliersRepository) {}
    async execute(entity: DBInventorySupplier): Promise<DBInventorySupplier> {
        return this.repository.update(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
