import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { roles } from '@InventoryControl/roles'
import { InputAdornment } from '@material-ui/core'
import { Form } from '@unform/web'
import { useInventoryProductManager } from '../Hooks/useInventoryProductManager/useInventoryProductManager'

export const ProductDetails = () => {
    const { handleEditProduct, state, loading } = useInventoryProductManager()
    const { handleChange, handleSubmit, handleInative } = handleEditProduct()

    if (loading && !state?._id) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{state?.name}</h1>
                <div>
                    <Grid flex flexEnd>
                        <PermissionWrapper
                            permission={roles.product.permissions.update.key}
                        >
                            {!state.isInactive && (
                                <Button
                                    onClick={handleInative}
                                    variant={ButtonVariant.secondary}
                                >
                                    Inativar
                                </Button>
                            )}
                            {state.isInactive && (
                                <Button
                                    onClick={handleInative}
                                    variant={ButtonVariant.secondary}
                                >
                                    Ativar
                                </Button>
                            )}
                            <Button
                                onClick={handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </Grid>
                </div>
            </ContainerHeader>

            <Form onSubmit={() => {}} className="mt-2">
                <Grid container item lg={12}>
                    <Grid item lg={12}>
                        <Input
                            name={'name'}
                            onChange={handleChange}
                            label="Nome"
                            value={state?.name}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            name={'unitMeasure'}
                            onChange={handleChange}
                            label="Unidade de Medida"
                            value={state?.unitMeasure}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            name={'minimumStock'}
                            type="number"
                            onChange={handleChange}
                            label="Estoque Mínimo"
                            value={state?.minimumStock}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {state?.unitMeasure}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            name={'unitCost'}
                            type="number"
                            onChange={handleChange}
                            label="Custo Unitário"
                            value={state?.unitCost}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
