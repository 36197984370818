import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'

import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { useInventoryCreateDestination } from '@InventoryControl/Destinations/Hooks/useInventoryCreateDestination/useInventoryCreateDestination'
import { Form } from '@unform/web'
import { NewDestinationContent } from './NewDestinationModal.styles'
import { NewDestinationModalProps } from './NewDestinationModal.types'

export const NewDestinationModal = ({
    isOpen,
    setIsOpen,
    callback,
}: NewDestinationModalProps) => {
    const { formRef, handleCreateDestination, handleChange, state, loading } =
        useInventoryCreateDestination()

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    const handleSubmit = async () => {
        handleCreateDestination(() => {
            toggleModal()
            callback?.()
        })
    }

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h2>Novo Destino</h2>
                <NewDestinationContent>
                    {loading && <Loader />}
                    {!loading && (
                        <Grid container>
                            <Grid item lg={12}>
                                <Input
                                    name={'name'}
                                    label="Nome"
                                    value={state?.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    )}
                </NewDestinationContent>
                <ModalFooter>
                    <Button
                        onClick={toggleModal}
                        type="reset"
                        variant={ButtonVariant.secondary}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type="submit"
                        disabled={loading}
                        variant={ButtonVariant.primary}
                    >
                        Cadastrar
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
