import { Product } from '@InventoryControl/Products/entities/Product'
import { Api } from '@Services/api'
import ProductsDataSource from '../ProductsDataSource'
import { DBInventoryProduct } from './Entity/Product'

export class ProductAPI implements ProductsDataSource {
    axios = new Api()

    async getProductById(id: string): Promise<DBInventoryProduct> {
        const { data } = await this.axios.get(`/inventory/products/${id}`)
        return data
    }

    async getProducts(): Promise<DBInventoryProduct[]> {
        const { data } = await this.axios.get('/inventory/products')
        return data
    }

    async search(query: string): Promise<DBInventoryProduct[]> {
        const { data } = await this.axios.get(
            `/inventory/products/search/${query}`
        )
        return data
    }

    async createProduct(product: Product): Promise<DBInventoryProduct> {
        const { data } = await this.axios.post('/inventory/products', product)
        return data
    }

    async updateProduct(
        product: DBInventoryProduct
    ): Promise<DBInventoryProduct> {
        const { data } = await this.axios.put(
            `/inventory/products/${product._id}`,
            product
        )
        return data
    }

    async deleteProduct(id: string): Promise<void> {
        await this.axios.delete(`/inventory/products/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
