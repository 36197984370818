import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Entry } from '@InventoryControl/Entries/entities/Entry'
import { DBInventoryEntry } from '../DataSource/API/Entries/Entry'
import EntryDataSource from '../DataSource/EntryDataSource'

export class InventoryEntryRepository
    implements BaseRepository<DBInventoryEntry, Entry>
{
    dataSource: EntryDataSource
    constructor(_dataSource: EntryDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBInventoryEntry[]> {
        return this.dataSource.getEntries()
    }
    async findById(id: string): Promise<DBInventoryEntry> {
        return this.dataSource.getEntryById(id)
    }
    async search(query: string): Promise<DBInventoryEntry[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Entry): Promise<DBInventoryEntry> {
        return this.dataSource.createEntry(entity)
    }
    async update(entity: DBInventoryEntry): Promise<DBInventoryEntry> {
        return this.dataSource.updateEntry(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteEntry(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
