import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'

export interface GetPreMovements {
    execute(): Promise<DBMovement[]>
    getSource(): string
}

export class GetPreMovementsUseCase implements GetPreMovements {
    constructor(private repository: MovementRepository) {}
    async execute(): Promise<DBMovement[]> {
        return this.repository.preMovements()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
