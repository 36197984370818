import { InferType, date, number, object, string } from 'yup'

export const exitSchema = object().shape({
    observation: string(),
    quantity: number().required('Quantidade é obrigatório'),
    quantityReturned: number(),
    returnDate: date(),
    soldAmount: number(),
    accumulatedStock: number(),
    unitCost: number().required('Custo unitário obrigatório'),
    totalSalesValue: number(),
    created_at: date(),
})

export interface Exits extends InferType<typeof exitSchema> {}
