import { roles } from '@Balance/roles'
import { RoutesPaths } from '@Balance/RoutesPaths'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { MovementsTable } from '@Core/Components/MovementsTable/MovementsTable'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { useMovements } from '@Movements/Hooks/useMovements'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const MovementsList = () => {
    const navigate = useNavigate()
    const {
        movements,
        getMovements,
        getAllMovements,
        allMovements,
        options,
        loading,
    } = useMovements()

    const openNewMovement = () => {
        navigate(RoutesPaths.NewMovement)
    }

    useEffect(() => {
        getMovements()
        getAllMovements()
    }, [])
    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Movimentações</h1>
                <div>
                    <PermissionWrapper
                        permission={roles.movements.permissions.create.key}
                    >
                        <Button
                            variant={ButtonVariant.primary}
                            onClick={openNewMovement}
                        >
                            Nova Movimentação
                        </Button>
                    </PermissionWrapper>
                </div>
            </ContainerHeader>
            <MovementsTable
                loading={loading}
                movements={movements}
                options={options}
                allMovements={allMovements}
                getMovements={getMovements}
                hasPagination
            />
        </Container>
    )
}
