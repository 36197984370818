import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { priceTableDefault } from './useGift.constants'

export const useGift = ({ product, refresh, onChange }) => {
    const handleAddNewPriceTable = useCallback(() => {
        const newItem = {
            ...(product?.priceTable?.length
                ? product?.priceTable[product?.priceTable.length - 1]
                : priceTableDefault()),
        }
        const priceTables = [
            ...product?.priceTable,
            {
                ...newItem,
                isNew: true,
                name: '',
                _id: crypto.randomUUID(),
                productId: product?._id,
            },
        ]
        onChange({
            target: {
                name: 'priceTable',
                value: priceTables,
            },
        })
    }, [product])

    const removePriceTable = useCallback(
        (index: number) => {
            const priceTables = [...product?.priceTable]
            priceTables.splice(index, 1)
            onChange({
                target: {
                    name: 'priceTable',
                    value: priceTables,
                },
            })
            toast.info('Especificação removida')
            refresh?.()
        },
        [product]
    )

    return {
        handleAddNewPriceTable,
        removePriceTable,
    }
}
