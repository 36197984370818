import { RootState } from '@Core/Communication'
import { useSelector } from 'react-redux'

export const usePermissions = () => {
    const { user } = useSelector((state: RootState) => state.user)
    const { isAuthenticationFinished, isLogged } = useSelector(
        (state: RootState) => state.authentication
    )

    const loading = !isAuthenticationFinished || !user

    const hasPermission = (permission: string) => {
        if (import.meta.env.VITE_MOCK_AUTH) {
            return true
        }
        if (!isLogged) {
            return false
        }
        return user?.roles?.some((role) => role === permission) || false
    }
    const hasModulePermission = (module: string) => {
        if (import.meta.env.VITE_MOCK_AUTH) {
            return true
        }
        if (!isLogged) {
            return false
        }
        return user?.roles?.some((role) => role.startsWith(module)) || false
    }

    return {
        loading,
        hasPermission,
        hasModulePermission,
    }
}
