import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import { Card } from '@material-ui/core'
import styled from 'styled-components'

export const ProductProposalContainer = styled(Card)`
    padding: 0;
    margin-bottom: 10px;
    ${({ hasError, theme }) =>
        hasError && `border: 1px solid ${theme.colors.secondary}`};

    ${({ inative, theme }) =>
        inative &&
        `border: 1px solid ${theme.colors.input.supportMessage.warning}`};

    .highlight {
        color: white;
        max-height: 56px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        * {
            font-weight: bold;
        }
    }
`

export const InventoryProductStock = styled.div`
    ${({ out, theme }) =>
        `background-color: ${convertHexToRGBA(
            out ? theme.colors.table.dangerRow : theme.colors.table.successRow,
            0.2
        )};`};
`
