import { PipeDeal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface GetProposalPipe {
    execute(pipe: string): Promise<PipeDeal>
    getSource(): string
}

export class GetProposalPipeUseCase implements GetProposalPipe {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(pipe: string): Promise<PipeDeal> {
        return this.repository.getPipeProposal(pipe)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
