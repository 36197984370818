import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface GetProposals {
    execute(): Promise<DBCommercialProposal[]>
    getSource(): string
}

export class GetProposalsUseCase implements GetProposals {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(): Promise<DBCommercialProposal[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
