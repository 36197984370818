import { ProductDetails } from '@Commercial/Products/ProductDetails/ProductDetails'
import { ProductsList } from '@Commercial/Products/ProductsList/ProductsList'
import { NewProposal } from '@Commercial/Proposal/NewProposal/NewProposal'
import { ProposalDetails } from '@Commercial/Proposal/ProposalDetails/ProposalDetails'
import { ProposalList } from '@Commercial/Proposal/ProposalList/ProposalList'
import { CommercialControlRoot } from '@Commercial/Providers/CommercialControlRoot/CommercialControlRoot'
import { RoutesPaths } from '@Commercial/RoutesPaths'
import { VariablesDetails } from '@Commercial/Variables/VariablesDetails/VariablesDetails'
import { roles } from '@Commercial/roles'
import { PrivacyRoute } from '@Core/Routes/PrivacyRoute'

import { Route } from 'react-router-dom'
const router = [
    <Route
        key={'CommercialControlStack'}
        path="/"
        element={<CommercialControlRoot />}
    >
        <Route
            element={
                <PrivacyRoute permission={roles.product.permissions.read.key} />
            }
        >
            <Route
                path={RoutesPaths.CommercialProducts}
                element={<ProductsList />}
            />
            <Route
                path={RoutesPaths.CommercialProduct}
                element={<ProductDetails />}
            />
            <Route
                path={RoutesPaths.CommercialProposals}
                element={<ProposalList />}
            />
            <Route
                path={RoutesPaths.CommercialProposal}
                element={<ProposalDetails />}
            />
            <Route
                path={RoutesPaths.CommercialCreateProposal}
                element={<NewProposal />}
            />
            <Route
                path={RoutesPaths.CommercialVariables}
                element={<VariablesDetails />}
            />
        </Route>
    </Route>,
]
export default router
