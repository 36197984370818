import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'

import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { useInventoryCreateProduct } from '@InventoryControl/Products/Hooks/useInventoryCreateProduct/useInventoryCreateProduct'
import { InputAdornment } from '@material-ui/core'
import { Form } from '@unform/web'
import { NewProductContent } from './NewProductModal.styles'
import { NewProductModalProps } from './NewProductModal.types'

export const NewProductModal = ({
    isOpen,
    setIsOpen,
    callback,
}: NewProductModalProps) => {
    const { formRef, handleCreateProduct, handleChange, state, loading } =
        useInventoryCreateProduct()

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    const handleSubmit = async () => {
        handleCreateProduct(() => {
            toggleModal()
            callback?.()
        })
    }

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h2>Novo Produto</h2>
                <NewProductContent>
                    {loading && <Loader />}
                    {!loading && (
                        <Grid container>
                            <Grid item lg={12}>
                                <Input
                                    name={'name'}
                                    label="Nome"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <Input
                                    name={'unitMeasure'}
                                    label="Unidade de Medida"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <Input
                                    name={'minimumStock'}
                                    type="number"
                                    label="Estoque Mínimo"
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {state?.unitMeasure}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <Input
                                    name={'unitCost'}
                                    label="Custo Unitário"
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </NewProductContent>
                <ModalFooter>
                    <Button
                        onClick={toggleModal}
                        variant={ButtonVariant.secondary}
                        type="reset"
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" variant={ButtonVariant.primary}>
                        Cadastrar
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
