import { InventoryDestinationsRepository } from '@InventoryControl/Destinations/Data/Repository/DestinationRepository'
import { DBInventoryDestination } from '../../Data/DataSource/API/Entity/Destination'

export interface UpdateDestination {
    execute(entity: DBInventoryDestination): Promise<DBInventoryDestination>
    getSource(): string
}

export class UpdateDestinationUseCase implements UpdateDestination {
    constructor(private repository: InventoryDestinationsRepository) {}
    async execute(
        entity: DBInventoryDestination
    ): Promise<DBInventoryDestination> {
        return this.repository.update(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
