import { createContext, useContext, useState } from 'react'
import { ISummaryContext, StockByClient } from './SummaryContext.types'

export const SummaryContext = createContext<ISummaryContext>({
    stocks: {},
    setStocks: () => {},
})

export const SummaryProvider = ({ children }: { children: any }) => {
    const [stocks, setStocks] = useState<StockByClient>({})

    return (
        <SummaryContext.Provider
            value={{
                stocks,
                // @ts-ignore
                setStocks,
            }}
        >
            {children}
        </SummaryContext.Provider>
    )
}

export const useSummaryContext = () => useContext(SummaryContext)
