// @ts-nocheck
export const formattedAutocomplete = <T>(entities: T[], labelKey: string) => {
    return entities.reduce((acc, entity) => {
        if (entity.hasOwnProperty('isInactive') && entity.isInactive) {
            return acc
        }
    acc.push({
        value: entity,
    // @ts-ignore
    label: entity?.[labelKey],
        })
    return acc
    }, [])
}

    export const formatAutoCompleteValue = <T>(entity: T, labelKey: string) => {
    return {
            value: entity,
        // @ts-ignore
        label: entity?.[labelKey],
    }
}
