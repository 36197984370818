import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
    DBCommercialPriceRange,
    DBCommercialProduct,
} from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { useGift } from '@Commercial/Products/Hooks/useGift/useGift'
import { Button } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Accessories } from '../Accessories/Accessories'
import { CordMarkup } from '../CordMarkup/CordMarkup'
import { PriceRange } from '../PriceRange/PriceRange'

export const Gift = ({
    state,
    onChange,
    refresh: _refresh,
}: {
    state: DBCommercialProduct
    onChange: () => void
    refresh: () => Promise<void>
}) => {
    const { handleAddNewPriceTable, removePriceTable } = useGift({
        product: state,
        refresh: _refresh,
        onChange,
    })
    return (
        <div className="grid">
            <div className="grid grid-cols-4 gap-4">
                <div>
                    <Input
                        name="minQuantity"
                        label="Quantidade Mínima"
                        onChange={onChange}
                        value={state?.minQuantity}
                        type="number"
                    />
                </div>
            </div>
            <div className="mt-4">
                <Tabs defaultValue="priceRange">
                    <TabsList className="grid w-full grid-cols-3">
                        <TabsTrigger value="priceRange">
                            Especificações
                        </TabsTrigger>
                        <TabsTrigger value="accessories">
                            Acessórios
                        </TabsTrigger>
                        <TabsTrigger value="markups">Markups</TabsTrigger>
                    </TabsList>
                    <TabsContent value="priceRange">
                        <div className="grid grid-cols-1 gap-4">
                            {state?.priceTable?.map(
                                (
                                    item: DBCommercialPriceRange,
                                    index: number
                                ) => (
                                    <PriceRange
                                        handleRemove={() =>
                                            removePriceTable(index)
                                        }
                                        priceTable={item}
                                        refresh={_refresh}
                                    />
                                )
                            )}
                            <Button onClick={handleAddNewPriceTable}>
                                Nova Especificação
                            </Button>
                        </div>
                    </TabsContent>
                    <TabsContent value="accessories">
                        <Accessories
                            accessories={state?.accessories}
                            refresh={_refresh}
                            productId={state?._id}
                        />
                    </TabsContent>
                    <TabsContent value="markups">
                        <CordMarkup
                            markup={state?.markups?.[0]}
                            refresh={_refresh}
                        />
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    )
}
