import { Clients } from '@Clients/Domain/Model/Clients'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { AutocompleteEntity } from '@Core/Components/InputAutoComplete/InputAutoComplete.types'
import { Form } from '@unform/web'
import { useState } from 'react'

export const ClientFilter = ({
    clients,
    setSelectedClient,
}: {
    clients: Clients[]
    setSelectedClient: (client: string) => void
}) => {
    const [search, setSearch] = useState('')

    const filteredClients = clients
        ?.filter((client) =>
            client?.corporateName
                ?.toLowerCase()
                ?.includes((search || '')?.toLowerCase() || '')
        )
        .map((client) => ({
            label: client.corporateName,
            value: client._id,
            ...client,
        }))

    const handleClientSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event?.target?.value)
    }

    const handleClientSelected = (
        _: any,
        value: AutocompleteEntity<Clients>
    ) => {
        setSelectedClient(value?.label ?? '')
    }

    return (
        <Form onSubmit={() => {}}>
            <InputAutoComplete
                label="Clientes"
                onChange={handleClientSelected}
                size="small"
                inputProps={{
                    name: `client`,
                }}
                results={filteredClients}
                name="client"
                // @ts-ignore
                onInputChange={handleClientSearch}
            />
        </Form>
    )
}
