export const moduleKey = 'balance'
export const roles = {
    summary: {
        name: 'Resumo',
        key: 'balance:summary',
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar resumo',
                key: 'balance:summary:read',
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar resumo',
                key: 'balance:summary:export',
            },
        },
    },
    movements: {
        name: 'Movimentações',
        key: 'balance:movements',
        permissions: {
            create: {
                name: 'Criar',
                description: 'Permite criar movimentações',
                key: 'balance:movements:create',
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar movimentações',
                key: 'balance:movements:read',
            },
            update: {
                name: 'Editar',
                description: 'Permite editar movimentações',
                key: 'balance:movements:update',
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar movimentações',
                key: 'balance:movements:delete',
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar movimentações',
                key: 'balance:movements:export',
            },
        },
    },
    preMovements: {
        name: 'Pre-Movimentações',
        key: 'balance:pre-movements',
        permissions: {
            create: {
                name: 'Conferir',
                description: 'Permite conferir pre-movimentações',
                key: 'balance:pre-movements:create',
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar pre-movimentações',
                key: 'balance:pre-movements:read',
            },
            delete: {
                name: 'Ignorar',
                description: 'Permite ignorar pre-movimentações',
                key: 'balance:pre-movements:delete',
            },
            notify: {
                name: 'Notificações (beta)',
                description:
                    'Permite receber notificações de pre-movimentações',
                key: 'balance:pre-movements:notify',
            },
        },
    },
    clients: {
        name: 'Clientes',
        key: 'balance:clients',
        permissions: {
            create: {
                name: 'Criar',
                description: 'Permite criar clientes',
                key: 'balance:clients:create',
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar clientes',
                key: 'balance:clients:read',
            },
            update: {
                name: 'Editar',
                description: 'Permite editar clientes',
                key: 'balance:clients:update',
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar clientes',
                key: 'balance:clients:delete',
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar clientes',
                key: 'balance:clients:export',
            },
        },
    },
    suppliers: {
        name: 'Fornecedores',
        key: 'balance:suppliers',
        permissions: {
            create: {
                name: 'Criar',
                description: 'Permite criar fornecedores',
                key: 'balance:suppliers:create',
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar fornecedores',
                key: 'balance:suppliers:read',
            },
            update: {
                name: 'Editar',
                description: 'Permite editar fornecedores',
                key: 'balance:suppliers:update',
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar fornecedores',
                key: 'balance:suppliers:delete',
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar fornecedores',
                key: 'balance:suppliers:export',
            },
        },
    },
    products: {
        name: 'Produtos',
        key: 'balance:products',
        permissions: {
            create: {
                name: 'Criar',
                description: 'Permite criar produtos',
                key: 'balance:products:create',
            },
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar produtos',
                key: 'balance:products:read',
            },
            update: {
                name: 'Editar',
                description: 'Permite editar produtos',
                key: 'balance:products:update',
            },
            delete: {
                name: 'Deletar',
                description: 'Permite deletar produtos',
                key: 'balance:products:delete',
            },
            export: {
                name: 'Exportar',
                description: 'Permite exportar produtos',
                key: 'balance:products:export',
            },
        },
    },
}
