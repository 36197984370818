import { Clients } from '@Balance/Clients/Domain/Model/Clients'
import { useClients } from '@Balance/Clients/Hooks/useClients'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import { useInventoryDestinations } from '@InventoryControl/Destinations/Hooks/useInventoryDestination/useInventoryDestination'
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { useInventoryProducts } from '@InventoryControl/Products/Hooks/useProducts/useInventoryProducts'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { ExitForm } from '../Common/Components/ExitForm/ExitForm'
import { useInventoryCreateExit } from '../Hooks/useInventoryCreateExit/useInventoryCreateExit'
import { exitSchema } from '../entities/Exits'

export const CreateExit = () => {
    const {
        register,
        watch,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(exitSchema),
    })
    const {
        handleChange,
        handleSubmit,
        state,
        created,
        failed,
        handleAddingExit,
        handleRemoveExit,
        handleCalculate,
    } = useInventoryCreateExit({ setFormValue: setValue })
    const { products, getProducts } = useInventoryProducts()
    const { destinations, getDestinations } = useInventoryDestinations()
    const { clients } = useClients()
    const navigate = useNavigate()

    const { state: locationState } = useLocation()

    const goBack = () => {
        navigate(RoutesPaths.InventoryExits)
    }

    const handleDateChange = (name: string) => (date: Date) => {
        handleChange({ target: { name: name, value: date } })
    }

    const handleAutoCompleteChange =
        (id: string) =>
        (name: string) =>
        (_: any, { value }: any) => {
            setValue(name, value)
            if (name.includes('product')) {
                setValue(`unitCost-${id}`, value?.unitCost)
                // @ts-ignore
                if (state?.[`quantity-${id}`]) {
                    setValue(
                        // @ts-ignore
                        `totalSalesValue-${id}`,
                        // @ts-ignore
                        state?.[`quantity-${id}`] * value?.unitCost
                    )
                }
            }
        }

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            const [fieldName, ...idRest] = name?.split('-') || ['', '']
            if (fieldName === 'unitCost' || fieldName === 'quantity') {
                setTimeout(handleCalculate(idRest.join('-')), 1)
            }
            return handleChange({
                // @ts-ignore
                target: { name, value: value[name], id: idRest.join('-') },
            })
        })
        return () => subscription.unsubscribe()
    }, [watch, state])

    useEffect(() => {
        if (locationState?.product) {
            handleAutoCompleteChange(state[0].id)(`product-${state[0].id}`)(
                null,
                {
                    value: {
                        value: locationState?.product?._id,
                        label: locationState?.product?.name,
                        ...locationState?.product,
                    },
                }
            )
        }
    }, [locationState])

    useEffect(() => {
        getProducts()
        getDestinations()
    }, [])

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <Grid container spacing={1}>
                    <Grid item lg={6} xs={6} container>
                        <h1 className="text-2xl font-bold">Nova Saída</h1>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        lg={6}
                        spacing={1}
                        container
                        alignContent="center"
                        justifyContent="flex-end"
                    >
                        <Button
                            type="button"
                            onClick={() => handleSubmit(goBack)}
                            variant={ButtonVariant.primary}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </ContainerHeader>
            <form>
                <Grid item lg={12} container spacing={1}>
                    {state.map((item) => (
                        <Grid key={item?.id} item lg={12} xs={12}>
                            <ExitForm
                                register={register}
                                destinations={formattedAutocomplete<DBInventoryDestination>(
                                    destinations,
                                    'name'
                                )}
                                state={item}
                                isFailed={failed.includes(item?.id)}
                                isCreated={created.includes(item?.id)}
                                errors={errors}
                                onRemove={handleRemoveExit}
                                handleChange={handleChange}
                                handleCalculate={handleCalculate(item?.id)}
                                handleAutoCompleteChange={handleAutoCompleteChange(
                                    item?.id
                                )}
                                products={formattedAutocomplete<DBInventoryProduct>(
                                    products,
                                    'name'
                                )}
                                clients={formattedAutocomplete<Clients>(
                                    clients,
                                    'corporateName'
                                )}
                                handleDateChange={handleDateChange}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={1}>
                    <Grid
                        item
                        lg={12}
                        xs={12}
                        container
                        justifyContent="center"
                    >
                        <Button
                            type="button"
                            size="large"
                            onClick={handleAddingExit}
                            variant={ButtonVariant.secondary}
                        >
                            Adicionar Saída
                        </Button>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        spacing={1}
                        container
                        justifyContent="flex-end"
                    >
                        <Button
                            type="button"
                            onClick={() => handleSubmit(goBack)}
                            variant={ButtonVariant.primary}
                        >
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}
