import { isValidEmail } from '@brazilian-utils/brazilian-utils'
import { setSupplierAction } from '@Core/Communication/GlobalStore/Reducers/supplier/reducer'
import { Supplier } from '@Core/Entities/Supplier'
import { ApiType } from '@Services/api'
import LogRocket from 'logrocket'
import { ChangeEvent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

export const useSupplierManager = (api: ApiType) => {
    const [state, setState] = useState<Partial<Supplier>>()
    const dispatch = useDispatch()

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = target

        if (name.includes('additionalEmails')) {
            const index = Number(
                name
                    .split('additionalEmails')[1]
                    .replace('[', '')
                    .replace(']', '')
            )
            const additionalEmails = state?.additionalEmails || []
            additionalEmails[index] = value
            setState({ ...state, additionalEmails })
            return
        }

        setState({ ...state, [name]: value })
    }

    const handleAddSupplier = useCallback(
        ({ callback }: { callback?: () => void }) => {
            const handleSubmit = async () => {
                if (!state?.corporateName)
                    return toast.error('Nome do fornecedor é obrigatório')
                if (!state?.mail)
                    return toast.error('E-mail do fornecedor é obrigatório')
                if (!isValidEmail(state.mail))
                    return toast.error('E-mail inválido')
                for (let i in state.additionalEmails) {
                    if (!isValidEmail(state.additionalEmails[Number(i)])) {
                        toast.error('E-mail adicional inválido')
                        return
                    }
                }

                try {
                    const { data } = await api.post('/supplier', state)
                    toast.success('Fornecedor adicionado com sucesso!')
                    setState({})
                    callback?.()
                    dispatch(
                        setSupplierAction({
                            source: api.getSource(),
                            data: [data],
                            incremental: true,
                        })
                    )
                } catch (e) {
                    toast.error('Erro ao adicionar fornecedor')
                }
            }
            const handleAddingAdditionalEmail = () => {
                if (!state?.additionalEmails) {
                    setState({ ...state, additionalEmails: [''] })
                    return
                }
                setState({
                    ...state,
                    additionalEmails: [...state.additionalEmails, ''],
                })
            }
            const handleRemoveAdditionalMail = ({
                index,
            }: {
                index: number
            }) => {
                const additionalEmails = state?.additionalEmails || []
                additionalEmails.splice(index, 1)
                setState({ ...state, additionalEmails })
            }
            return {
                state,
                handleChange,
                handleSubmit,
                handleAddingAdditionalEmail,
                handleRemoveAdditionalMail,
            }
        },
        [state]
    )

    const handleDeleteSupplier = (id: number | string) => async () => {
        await api.delete(`/supplier/${id}`)
    }

    const handleEditSupplier = useCallback(
        (id: number | string) => {
            const handleSubmit = async () => {
                try {
                    await api.put(`/supplier/${id}`, state)
                    getSupplierByID(id)
                    toast.success('Fornecedor atualizado com sucesso!')
                } catch (e) {
                    toast.error('Erro ao atualizar fornecedor')
                    LogRocket.captureException(e as Error)
                    throw e
                }
            }
            const handleNewAdditionalEmail = () => {
                if (!state?.additionalEmails) {
                    setState({ ...state, additionalEmails: [''] })
                    return
                }
                setState({
                    ...state,
                    additionalEmails: [...state.additionalEmails, ''],
                })
            }
            const handleRemoveAdditionalMail = ({
                index,
            }: {
                index: number
            }) => {
                const additionalEmails = state?.additionalEmails || []
                additionalEmails.splice(index, 1)
                setState({ ...state, additionalEmails })
            }
            return {
                handleChange,
                handleSubmit,
                handleNewAdditionalEmail,
                handleRemoveAdditionalMail,
            }
        },
        [state]
    )

    const getSupplierByID = async (id: number | string) => {
        const { data } = await api.get(`/supplier/${id}`)
        setState({ ...data[0] })
        return { ...data[0] }
    }

    return {
        state,
        handleAddSupplier,
        handleEditSupplier,
        handleDeleteSupplier,
        getSupplierByID,
    }
}
