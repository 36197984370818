import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'

export interface DeleteClients {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteClientsUseCase implements DeleteClients {
    constructor(private repository: ClientsRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
