import { Supplier } from '@Core/Entities/Supplier'
import { createSlice } from '@reduxjs/toolkit'
import { ReducersName } from '../ReducersName'
import { SetSupplierAction } from './reducer.type'

const initialState: { supplier: { [key: string]: Supplier[] } } = {
    supplier: {},
}

const supplierReducer = createSlice({
    initialState,
    name: ReducersName.supplier,
    reducers: {
        setSupplierAction(state, { payload }: SetSupplierAction) {
            if (payload.incremental) {
                state.supplier = {
                    ...state.supplier,
                    [payload.source]: [
                        ...state.supplier[payload.source],
                        ...payload.data,
                    ],
                }
                return
            }
            state.supplier = {
                ...state.supplier,
                [payload.source]: payload.data,
            }
        },
    },
})
const { actions } = supplierReducer

export const { setSupplierAction } = actions

export default supplierReducer.reducer
