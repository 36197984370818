import {
    DeleteProductsUseCase,
    GetProductsUseCase,
    GetProductUseCase,
} from '@Commercial/Products/Domain/UseCases'
import { CreateAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/CreateAccessories'
import { CreateCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/CreateCordType'
import { CreateProductUseCase } from '@Commercial/Products/Domain/UseCases/CreateProduct'
import { DeleteAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/DeleteAccessories'
import { DeleteCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/DeleteCordType'
import { DeletePriceRangeUseCase } from '@Commercial/Products/Domain/UseCases/DeletePriceRange'
import { UpdateAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/UpdateAccessories'
import { UpdateCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/UpdateCordType'
import { UpdateMarkupUseCase } from '@Commercial/Products/Domain/UseCases/UpdateMarkup'
import { UpdatePriceRangeUseCase } from '@Commercial/Products/Domain/UseCases/UpdatePriceRange'
import { Container } from 'inversify'
import { UpdateProductUseCase } from '../../../Domain/UseCases/UpdateProduct'
import { CommercialProductsRepository } from '../../Repository/ProductRepository'
import { ProductAPI } from '../API/ProductAPI'
import { Register } from './ProductRegister'

const container = new Container()
container
    .bind<GetProductUseCase>(Register.UseCaseGetProduct)
    .toDynamicValue(
        () =>
            new GetProductUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind<GetProductsUseCase>(Register.UseCaseGetProducts)
    .toDynamicValue(
        () =>
            new GetProductsUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind<CreateProductUseCase>(Register.UseCaseCreateProduct)
    .toDynamicValue(
        () =>
            new CreateProductUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind<UpdateProductUseCase>(Register.UseCaseUpdateProduct)
    .toDynamicValue(
        () =>
            new UpdateProductUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteProduct)
    .toDynamicValue(
        () =>
            new DeleteProductsUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind(Register.UseCaseUpdateCordType)
    .toDynamicValue(
        () =>
            new UpdateCordTypeUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind(Register.UseCaseCreateCordType)
    .toDynamicValue(
        () =>
            new CreateCordTypeUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteCordType)
    .toDynamicValue(
        () =>
            new DeleteCordTypeUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseCreateAccessories)
    .toDynamicValue(
        () =>
            new CreateAccessoriesUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseUpdateAccessories)
    .toDynamicValue(
        () =>
            new UpdateAccessoriesUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseDeleteAccessories)
    .toDynamicValue(
        () =>
            new DeleteAccessoriesUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseUpdateMarkup)
    .toDynamicValue(
        () =>
            new UpdateMarkupUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseUpdatePriceRange)
    .toDynamicValue(
        () =>
            new UpdatePriceRangeUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

container
    .bind(Register.UseCaseDeletePriceRange)
    .toDynamicValue(
        () =>
            new DeletePriceRangeUseCase(
                new CommercialProductsRepository(new ProductAPI())
            )
    )

export default container
