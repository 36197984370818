import { usePermissions } from '@Core/Hooks/usePermissions'

export const PermissionWrapper = ({
    permission,
    children,
}: {
    permission: string
    children: any
}) => {
    const { hasPermission, loading } = usePermissions()

    if (loading) return <>Carregando...</>

    if (!hasPermission(permission)) {
        return <></>
    }

    return children
}
