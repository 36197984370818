import zipcelx from 'zipcelx'

const blackList = ['_id', '__v']
const blackListFilter = (item: string) => !blackList.includes(item)

export const exportXlsx = (data: any, fileName: string, options: any) => {
    if (options?.loading) {
        options?.loading?.(true)
    }
    const collumns = Object.keys(data[0])
        .filter(blackListFilter)
        .map((item: any) => ({
            value: options.columnsDict(item),
            type: 'string',
        }))
    let values: any = []
    data.forEach((item: any) => {
        let valuesItem: any = []
        Object.keys(item).forEach((key: any) => {
            if (blackList.includes(key)) return
            const val = item[key]
            valuesItem.push(options.valuesDict(key, val))
        })
        values.push(valuesItem)
    })

    const config = {
        filename: fileName,
        sheet: {
            data: [collumns, ...values],
        },
    }

    zipcelx(config)
    if (options?.loading) {
        options?.loading?.(false)
    }
}
