import { NegativeClientStock } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks.types'
import { Clients } from '@Clients/Domain/Model/Clients'
import { Api } from '@Services/api'
import ClientsDataSource from '../ClientsDataSource'
import { Client } from './Entity/Client'

export class ClientAPI implements ClientsDataSource {
    axios = new Api()

    async getClientById(id: string): Promise<Clients> {
        const { data } = await this.axios.get(`/clients/${id}`)
        return data[0]
    }

    async getClients(): Promise<Clients[]> {
        const { data } = await this.axios.get('/clients')
        return data
    }

    async search(query: string): Promise<Clients[]> {
        const { data } = await this.axios.get(`/clients/search/${query}`)
        return data
    }

    async createClient(client: Client): Promise<Clients> {
        const { data } = await this.axios.post('/clients', client)
        return data
    }

    async updateClient(client: Clients): Promise<Clients> {
        const { data } = await this.axios.put(`/clients/${client._id}`, client)
        return data
    }

    async deleteClient(id: string): Promise<void> {
        await this.axios.delete(`/clients/${id}`)
    }

    async getNegative(): Promise<NegativeClientStock[]> {
        const { data } = await this.axios.get('/clients/negative')
        return data
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
