import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'
import { CreateMovementModel } from '../Model/CreateMovement'

export interface CreateMovement {
    execute(movement: CreateMovementModel): Promise<DBMovement>
    getSource(): string
}

export class CreateMovementUseCase implements CreateMovement {
    constructor(private repository: MovementRepository) {}
    async execute(movement: CreateMovementModel): Promise<DBMovement> {
        return this.repository.create(movement)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
