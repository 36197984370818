import {
    DeleteSupplierUseCase,
    GetSupplierUseCase,
    GetSuppliersUseCase,
    UpdateSupplierUseCase,
} from '@InventoryControl/Supplier/Domain/UseCases'
import { CreateSupplierUseCase } from '@InventoryControl/Supplier/Domain/UseCases/CreateSupplier'

export const Register = {
    UseCaseGetSuppliers: GetSuppliersUseCase,
    UseCaseGetSupplier: GetSupplierUseCase,
    UseCaseCreateSupplier: CreateSupplierUseCase,
    UseCaseUpdateSupplier: UpdateSupplierUseCase,
    UseCaseDeleteSupplier: DeleteSupplierUseCase,
}
