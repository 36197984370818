import { BalanceRoles } from '@Balance/index'
import { Button, Container, ContainerHeader } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { Divider } from '@material-ui/core'
import { Form } from '@unform/web'
import { PermissionsCard } from '@User/Common/PermissionsCard/PermissionsCard'

import { CommercialRoles } from '@Commercial/index'
import { Grid } from '@Core/Components/Grid/Grid'
import { EntryRoles } from '@Entry/index'
import { InventoryRoles } from '@InventoryControl/index'
import { useUserManager } from '@User/Hooks/useUserManager/useUserManager'
import { UserRoles } from '..'
export const UserDetails = () => {
    const {
        user,
        loading,
        handleChangePermission,
        handleSave,
        handleChange,
        handleDisable,
        handleEnable,
    } = useUserManager()

    if (loading) return <Loader />

    return (
        <Container>
            <Form onSubmit={() => {}}>
                <ContainerHeader className="m-2">
                    <h1 className="text-2xl font-bold">{user?.name}</h1>
                    <RightContent>
                        <Button onClick={handleSave}>Salvar</Button>
                        {user?.disabled && (
                            <Button onClick={handleEnable} variant="secondary">
                                Ativar
                            </Button>
                        )}
                        {!user?.disabled && (
                            <Button onClick={handleDisable} variant="secondary">
                                Desativar
                            </Button>
                        )}
                    </RightContent>
                </ContainerHeader>
                <Grid container lg={12} xs={12} spacing={1}>
                    <Grid item lg={6} xs={12}>
                        <Input
                            name="name"
                            label="Nome"
                            onChange={handleChange}
                            value={user?.name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <Input
                            name="mail"
                            label="E-mail"
                            value={user?.mail}
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <h2>Permissões</h2>
                    </Grid>
                    <Grid container spacing={1} lg={12} xs={12}>
                        <Grid item lg={12} xs={12}>
                            <PermissionsCard
                                title="Controle de saldo"
                                roleItem={BalanceRoles}
                                userPermissions={user?.roles}
                                onChange={handleChangePermission}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <PermissionsCard
                                title="Controle de Estoque"
                                roleItem={InventoryRoles}
                                userPermissions={user?.roles}
                                onChange={handleChangePermission}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <PermissionsCard
                                title="Comercial"
                                roleItem={CommercialRoles}
                                userPermissions={user?.roles}
                                onChange={handleChangePermission}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <PermissionsCard
                                title="Gestão de usuários"
                                roleItem={UserRoles}
                                userPermissions={user?.roles}
                                onChange={handleChangePermission}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <PermissionsCard
                                title="Core"
                                roleItem={EntryRoles}
                                userPermissions={user?.roles}
                                onChange={handleChangePermission}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
