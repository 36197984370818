import { SidebarItem } from '@Entry/Common/Sidebar/Sidebar.styles'
import { uniqueId } from 'lodash'
import { useLocation } from 'react-router-dom'
import { PermissionWrapper } from '../PermissionWrapper/PermissionWrapper'
import { AutoMenuProps } from './AutoMenu.types'

export const AutoMenu = ({ menu }: AutoMenuProps) => {
    const { pathname } = useLocation()

    return (
        <>
            {menu.map((item) => (
                <PermissionWrapper
                    key={uniqueId()}
                    permission={item.permission}
                >
                    <SidebarItem
                        to={item.to}
                        active={pathname === item.to}
                        className="sidebar-item"
                        title={item.title}
                        activeClassName="active"
                    >
                        {item.icon}
                        <span className="sidebar-text">{item.title}</span>
                    </SidebarItem>
                </PermissionWrapper>
            ))}
        </>
    )
}
