import { DBCommercialCordType } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'

export interface CreateCordType {
    execute(client: DBCommercialCordType): Promise<DBCommercialCordType>
    getSource(): string
}

export class CreateCordTypeUseCase implements CreateCordType {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(entity: DBCommercialCordType): Promise<DBCommercialCordType> {
        return this.repository.createCordType(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
