import { useNegativeStocks } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks'
import { NegativeClientStock } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks.types'
import { RoutesPaths } from '@Balance/RoutesPaths'
import { Container, ContainerHeader, Table } from '@Core/Components'
import { Input, InputSize } from '@Core/Components/Input/Input'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import {
    Card,
    CardHeader,
    FormControlLabel,
    Grid,
    Switch,
    TablePagination,
} from '@material-ui/core'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const NegativeBalance = () => {
    const { negativeStocks } = useNegativeStocks()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [column, setColumn] = useState(false)
    const [searchText, setSearchText] = useState('')
    const navigate = useNavigate()

    const formatArray = ({ client, product, _id }: NegativeClientStock) => {
        return {
            _id: _id[0],
            client: client[0],
            product: product.map(({ name: [name], _id: [_id], amount }) => ({
                name,
                _id,
                amount,
            })),
        }
    }

    const filtered: NegativeClientStock[] = negativeStocks.filter((entity) => {
        const { client, product } = formatArray(entity)
        return (
            client?.corporateName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            product?.some(({ name }) =>
                String(name)?.toLowerCase().includes(searchText.toLowerCase())
            )
        )
    })

    const getPaginate = (page: number, limit: number) => {
        const start = (page - 1) * limit
        return filtered.splice(start, limit)
    }

    const goTo = (id: string) => {
        navigate(formatRouteId(RoutesPaths.ClientDetail, id))
    }

    return (
        <Container>
            <ContainerHeader>
                <h2>Clientes com saldo negativo</h2>
                <RightContent>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={() => setColumn(!column)}
                                checked={column}
                            />
                        }
                        label="Colunas"
                    />
                    <Input
                        size={InputSize.Small}
                        useUnform={false}
                        value={searchText}
                        placeholder="Buscar"
                        onChange={(e: any) => setSearchText(e.target.value)}
                        name={'search'}
                    />
                </RightContent>
            </ContainerHeader>
            <Grid container spacing={1}>
                {getPaginate(page, limit).map((negativeStock) => (
                    <Grid
                        item
                        md={column ? 4 : 12}
                        lg={column ? 4 : 12}
                        xs={column ? 4 : 12}
                        key={negativeStock._id[0]}
                    >
                        <Card
                            onClick={() => goTo(negativeStock._id[0])}
                            className="clickable"
                        >
                            <CardHeader
                                title={
                                    negativeStock.client?.[0]?.corporateName ??
                                    'N/A'
                                }
                            />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Produto</th>
                                        <th>Saldo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {negativeStock.product?.map((product) => (
                                        <tr key={product._id}>
                                            <td>{product.name ?? 'N/A'}</td>
                                            <td>{product.amount ?? 0}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    </Grid>
                ))}
                <Grid item lg={12} xs={12}>
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100, 10000, 100000]}
                        component="div"
                        count={negativeStocks.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
