/**
 * Replaces the ':id' placeholder in a given route string with a stringified version of the provided id.
 *
 * @param {string} route - The route string containing the ':id' placeholder.
 * @param {string|number} id - The value to replace the ':id' placeholder with. Will be stringified if it is a number.
 * @return {string} The formatted route string with the ':id' placeholder replaced with the provided id.
 */
export const formatRouteId = (route: string, id: string | number) => {
    return route.replace(':id', id.toString())
}
