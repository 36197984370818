export function formatCurrency(
    value: number = 0,
    noPrefix = false
): string | number {
    const currencyFractionDigits = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).resolvedOptions().maximumFractionDigits

    const withPrefix = noPrefix
        ? {}
        : {
              style: 'currency',
              currency: 'BRL',
          }
    const valueString = value.toLocaleString('pt-BR', {
        ...withPrefix,
        maximumFractionDigits: currencyFractionDigits,
    })

    if (noPrefix) {
        return parseFloat(Number(String(value)).toFixed(2))
    }

    return valueString
}
