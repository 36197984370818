import { Product } from '@Commercial/Products/entities/Product'
import { Api } from '@Services/api'
import ProductsDataSource from '../ProductsDataSource'
import {
    CommercialAccessories,
    DBCommercialCordType,
    DBCommercialMarkup,
    DBCommercialPriceRange,
    DBCommercialProduct,
} from './Entity/Product'

export class ProductAPI implements ProductsDataSource {
    getAccessories(): Promise<CommercialAccessories[]> {
        throw new Error('Method not implemented.')
    }
    axios = new Api()

    async getProductById(id: string): Promise<DBCommercialProduct> {
        const { data } = await this.axios.get(`/commercial/products/${id}`)
        return data
    }

    async getProducts(): Promise<DBCommercialProduct[]> {
        const { data } = await this.axios.get('/commercial/products')
        return data
    }

    async search(query: string): Promise<DBCommercialProduct[]> {
        const { data } = await this.axios.get(
            `/commercial/products/search/${query}`
        )
        return data
    }

    async createProduct(product: Product): Promise<DBCommercialProduct> {
        const { data } = await this.axios.post('/commercial/products', product)
        return data
    }

    async createCordType(
        entity: DBCommercialCordType
    ): Promise<DBCommercialCordType> {
        const { data } = await this.axios.post(
            '/commercial/products/cord-type',
            entity
        )
        return data
    }

    async createAccessories(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        const { data } = await this.axios.post(
            '/commercial/products/accessories',
            entity
        )
        return data
    }

    async updateProduct(
        product: DBCommercialProduct
    ): Promise<DBCommercialProduct> {
        const { data } = await this.axios.put(
            `/commercial/products/${product._id}`,
            product
        )
        return data
    }

    async updateCordType(
        entity: DBCommercialCordType
    ): Promise<DBCommercialCordType> {
        const { data } = await this.axios.put(
            `/commercial/products/cord-type/${entity._id}`,
            entity
        )
        return data
    }

    async updateAccessories(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        const { data } = await this.axios.put(
            `/commercial/products/accessories/${entity._id}`,
            entity
        )
        return data
    }

    async updateMarkup(
        entity: DBCommercialMarkup
    ): Promise<DBCommercialMarkup> {
        const { data } = await this.axios.put(
            `/commercial/products/markup/${entity._id}`,
            entity
        )
        return data
    }

    async updatePriceRange(
        entity: DBCommercialPriceRange
    ): Promise<DBCommercialPriceRange> {
        const { data } = await this.axios.put(
            `/commercial/products/priceTable/${entity._id}`,
            entity
        )
        return data
    }
    async deleteProduct(id: string): Promise<void> {
        await this.axios.delete(`/commercial/products/${id}`)
    }

    async deleteCordType(id: string): Promise<void> {
        await this.axios.delete(`/commercial/products/cord-type/${id}`)
    }

    async deleteAccessories(id: string): Promise<void> {
        await this.axios.delete(`/commercial/products/accessories/${id}`)
    }

    async deletePriceRange(id: string): Promise<void> {
        await this.axios.delete(`/commercial/products/prices/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
