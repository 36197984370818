// @ts-nocheck
import { Stock } from '@Core/Entities/Stock'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { dateFormatter } from '@Movements/Utils/Movements.utils'

export const filterByProduct =
    (selectedProduct: string) => (movement: DBMovement) => {
        if (!movement?.product.length || !selectedProduct.length) {
            return true
        }
        return movement.product?.[0]?.name === selectedProduct
    }
export const filterByClient =
    (selectedClient: string) => (movement: DBMovement) => {
        if (!movement?.client.length || !selectedClient.length) {
            return true
        }
        return movement.client?.[0]?.corporateName === selectedClient
    }

export const filterByDate =
    (selectedRange: string[]) => (movement: DBMovement) => {
        if (selectedRange[0] === '' || !selectedRange[0]) {
            return true
        }
        const [startDate, endDate] = selectedRange
        const movementDate = new Date(movement.date)
        if (!endDate) {
            return movementDate >= new Date(startDate)
        }
        const start = new Date(startDate)
        start.setHours(0, 0, 0, 0)
        const end = new Date(endDate)
        end.setHours(23, 59, 59, 999)
        return movementDate >= start && movementDate <= end
    }

export const hasFilter = (
    selectedRange: string[],
    selectedProduct: string,
    selectedClient: string
) =>
    selectedProduct.length ||
    (selectedRange[0] && selectedRange[0] !== '') ||
    selectedClient !== ''

export const exportMovementData = (data: any) => {
    const columns = [
        'client',
        'supplier',
        'product',
        'amount',
        'type',
        'date',
        'user',
    ]
    const rows = data.map((item: any) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportMovementValueDictionary(
                column,
                item[column]
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportMovementValueDictionary = (key: Stock, item: Stock[key]) =>
    ({
        _id: { value: item, type: 'string' },
        client: { value: item.corporateName, type: 'string' },
        supplier: { value: item[0]?.corporateName, type: 'string' },
        product: { value: item[0]?.name, type: 'string' },
        amount: { value: item, type: 'number' },
        type: { value: item === 2 ? 'Saída' : 'Entrada', type: 'string' },
        date: { value: dateFormatter(new Date(item)), type: 'string' },
        user: { value: item.name, type: 'string' },
        __v: { value: item, type: 'number' },
    }[key] || { value: item, type: 'string' })
