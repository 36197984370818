import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
import { Product } from '@InventoryControl/Products/entities/Product'

export interface CreateProduct {
    execute(client: Product): Promise<DBCommercialProduct>
    getSource(): string
}

export class CreateProductUseCase implements CreateProduct {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(entity: Product): Promise<DBCommercialProduct> {
        return this.repository.create(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
