import styled from 'styled-components'

export const Container = styled.div`
    width: calc(100% - 40px);
    background-color: ${({ theme }) => theme.colors.container.background};
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    position: relative;
`

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
`
