import {
    CreateExitsUseCase,
    DeleteExitsUseCase,
    GetExitUseCase,
    GetExitsUseCase,
    UpdateExitUseCase,
} from '@InventoryControl/Exits/Domain/UseCases'

export const Register = {
    UseCaseGetExits: GetExitsUseCase,
    UseCaseGetExit: GetExitUseCase,
    UseCaseCreateExit: CreateExitsUseCase,
    UseCaseUpdateExit: UpdateExitUseCase,
    UseCaseDeleteExit: DeleteExitsUseCase,
}
