import { ApiType } from '@Services/api'
import { useState } from 'react'
import { DBProduct } from '../entities/Product'

export const useProducts = (api: ApiType) => {
    const [products, setProducts] = useState<DBProduct[]>([])
    const [localProducts, setLocalProducts] = useState<DBProduct[]>([])
    const [loading, setLoading] = useState(false)

    const getProducts = async () => {
        setLoading(true)
        const { data } = await api.get('/products')
        setProducts(data)
        setLocalProducts(data)
        setLoading(false)
    }

    const localSearch = (search: string) => {
        const filteredProducts = products.filter((product) =>
            product.name.toLowerCase().includes(search.toLowerCase())
        )
        setLocalProducts(filteredProducts)
    }

    return {
        products,
        localProducts,
        getProducts,
        localSearch,
        loading,
    }
}
