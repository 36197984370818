export const moduleKey = 'commercial'
export const roles = {
    product: {
        name: 'Produtos',
        key: `${moduleKey}:product`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar produtos',
                key: `${moduleKey}:product:read`,
            },
            create: {
                name: 'Criar',
                description: 'Permite criar produtos',
                key: `${moduleKey}:product:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar produtos',
                key: `${moduleKey}:product:update`,
                permissions: {
                    cost: {
                        name: 'Editar Custo',
                        description: 'Permite gerenciar valores',
                        key: `${moduleKey}:product:updateCost`,
                    },
                    markup: {
                        name: 'Editar Markup',
                        description: 'Permite gerenciar valores',
                        key: `${moduleKey}:product:updateMarkup`,
                    },
                    cord: {
                        name: 'Editar Cordão',
                        description: 'Permite gerenciar cordão',
                        key: `${moduleKey}:product:updateCord`,
                    },
                },
            },
            delete: {
                name: 'Desativar',
                description: 'Permite desativar produtos',
                key: `${moduleKey}:product:delete`,
            },
        },
    },
    proposal: {
        name: 'Propostas',
        key: `${moduleKey}:proposal`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar propostas',
                key: `${moduleKey}:proposal:read`,
                permissions: {
                    all: {
                        name: 'Visualizar Todas',
                        description: 'Permite visualizar todas as propostas',
                        key: `${moduleKey}:proposal:readAll`,
                    },
                    readCost: {
                        name: 'Visualizar Custo',
                        description: 'Permite visualizar valores',
                        key: `${moduleKey}:proposal:readCost`,
                    },
                    readFooter: {
                        name: 'Visualizar Rodapé Completo',
                        description: 'Permite visualizar valores',
                        key: `${moduleKey}:proposal:readFooter`,
                    },
                    readSimpleFooter: {
                        name: 'Visualizar Rodapé Simples',
                        description: 'Permite visualizar valores',
                        key: `${moduleKey}:proposal:readSimpleFooter`,
                    },
                },
            },
            create: {
                name: 'Criar',
                description: 'Permite criar propostas',
                key: `${moduleKey}:proposal:create`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar propostas',
                key: `${moduleKey}:proposal:update`,
                permissions: {
                    noMarkupLimit: {
                        name: 'Sem limite de markup',
                        description: 'Permite editar propostas',
                        key: `${moduleKey}:proposal:updateNoMarkupLimit`,
                    },
                },
            },
            delete: {
                name: 'Deletar',
                description: 'Permite editar propostas',
                key: `${moduleKey}:proposal:delete`,
            },
            joker: {
                name: 'Produto Coringa',
                description: 'Permite editar propostas',
                key: `${moduleKey}:proposal:joker`,
            },
        },
    },
    variables: {
        name: 'Variáveis',
        key: `${moduleKey}:variables`,
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar variáveis',
                key: `${moduleKey}:variables:read`,
            },
            update: {
                name: 'Editar',
                description: 'Permite editar variáveis',
                key: `${moduleKey}:variables:update`,
            },
        },
    },
}
