import { RoutesPaths } from '@Balance/RoutesPaths'
import { roles } from '@Balance/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { API } from '@Services/api'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { Form } from '@unform/web'
import { Key, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NewProductModal } from '../../Common/NewProductModal/NewProductModal'
import { useProducts } from '../../Hooks/useProducts'

export const ProductsList = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { products, getProducts, loading } = useProducts(API)

    const openModal = () => {
        setIsModalOpen(true)
    }

    const handleSubmitting = () => {
        getProducts()
        setIsModalOpen(false)
    }

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <Container>
            <Form onSubmit={() => {}}>
                <ContainerHeader>
                    <h1 className="text-2xl font-bold">Produtos</h1>
                    <PermissionWrapper
                        permission={roles.products.permissions.create.key}
                    >
                        <Button
                            onClick={openModal}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Novo Produto
                        </Button>
                        <NewProductModal
                            callback={handleSubmitting}
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                        />
                    </PermissionWrapper>
                </ContainerHeader>
            </Form>
            <Grid container>
                <Grid item lg={12}>
                    {loading && <Loader />}
                    {!loading && products && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product) => (
                                    <tr
                                        key={product._id as Key}
                                        data-clickable
                                        onClick={() => {
                                            navigate(
                                                formatRouteId(
                                                    RoutesPaths.ProductDetail,
                                                    product._id
                                                )
                                            )
                                        }}
                                    >
                                        <td>{product.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
