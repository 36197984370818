import { LocalStorage } from '@Core/Communication/GlobalStore/LocalStorage/LocalStorage'
import { createSlice } from '@reduxjs/toolkit'
import { ReducersName } from '../ReducersName'
import { SetSidebarCollapsed } from './reducer.type'

const storage = new LocalStorage()
const initialState = {
    sidebarCollapsed: window.innerWidth <= 1000,
    theme: storage.getItem('theme') ?? 'light',
    featuresFlags: {},
    isMobile: window.innerWidth <= 768,
}

const appConfigReducer = createSlice({
    initialState,
    name: ReducersName.appConfig,
    reducers: {
        setSidebarCollapsedAction(
            state,
            { payload: sidebarCollapsed }: SetSidebarCollapsed
        ) {
            state.sidebarCollapsed = sidebarCollapsed
        },
        toggleSidebarCollapsedAction(state) {
            state.sidebarCollapsed = !state.sidebarCollapsed
        },
        toggleThemeAction(state) {
            state.theme = state.theme === 'light' ? 'dark' : 'light'
            storage.setItem('theme', state.theme)
        },
        setIsMobileAction(state, { payload: isMobile }) {
            state.isMobile = isMobile
        },
        setFeaturesFlagsAction(state, { payload: featuresFlags }) {
            state.featuresFlags = featuresFlags
        },
    },
})
const { actions } = appConfigReducer

export const {
    setSidebarCollapsedAction,
    toggleSidebarCollapsedAction,
    toggleThemeAction,
    setFeaturesFlagsAction,
    setIsMobileAction,
} = actions

export default appConfigReducer.reducer
