import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'

export interface DeleteAccessories {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteAccessoriesUseCase implements DeleteAccessories {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(id: string) {
        return this.repository.deleteAccessories(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
