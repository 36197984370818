import { Chip } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const OnlineTag = styled(Chip)`
    ${({ isOnline }) =>
        isOnline &&
        css`
            background-color: #4caf50;
            color: #fff;
        `}
`
