// @ts-nocheck
import { dateFormatter } from '@Movements/Utils/Movements.utils'
import { formatCurrency } from '@Utils/Formatters/currency'
import { DBInventoryEntry } from '../Data/DataSource/API/Entries/Entry'

export const exportInventoryDictionary = (item: any) =>
    ({
        _id: 'ID',
        supplier: 'Fornecedor',
        product: 'Produto',
        quantity: 'Quantidade',
        unitCost: 'Custo Unitário',
        totalPurchaseValue: 'Valor de Compra Total',
        created_at: 'Data',
        __v: '',
    }[item] || item)

export const exportInventoryValueDictionary = (
    key: DBInventoryEntry,
    item: DBInventoryEntry[key]
) => {
    return (
        {
            _id: { value: item, type: 'string' },
            supplier: { value: item?.name, type: 'string' },
            product: { value: item?.name, type: 'string' },
            quantity: { value: item, type: 'number' },
            unitCost: {
                value: formatCurrency(item) ?? 'N/D',
                type: 'string',
            },
            totalPurchaseValue: {
                value: formatCurrency(Number(item || 0)) ?? 'N/D',
                type: 'string',
            },
            created_at: {
                value: dateFormatter(new Date(item)),
                type: 'string',
            },
            __v: { value: item, type: 'number' },
        }[key] || { value: item, type: 'string' }
    )
}

export const exportInventoryEntryValueDictionary = (
    key: DBInventoryEntry,
    item: DBInventoryEntry[key]
) => item

export const exportEntryData = (data: DBInventoryEntry[]) => {
    const columns = [
        '_id',
        'supplier',
        'product',
        'quantity',
        'unitCost',
        'totalPurchaseValue',
        'created_at',
    ]
    const rows = data.map((item: DBInventoryEntry) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportInventoryValueDictionary(
                column,
                item[column],
                item
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportInventoryData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
    }))
