import { IgnorePreMovementUseCase } from '@Balance/Movements/Domain/UseCases/IgnorePreMovement'
import {
    CreateMovementUseCase,
    DeleteMovementUseCase,
    GetMovementsUseCase,
    GetMovementUseCase,
    GetPreMovementsUseCase,
    UpdateMovementUseCase,
} from '@Movements/Domain/UseCases'

export const Register = {
    UseCaseCreateMovement: CreateMovementUseCase,
    UseCaseGetMovement: GetMovementUseCase,
    UseCaseGetMovements: GetMovementsUseCase,
    UseCaseUpdateMovement: UpdateMovementUseCase,
    UseCaseDeleteMovement: DeleteMovementUseCase,
    UseCaseGetPreMovements: GetPreMovementsUseCase,
    UseCaseIgnorePreMovement: IgnorePreMovementUseCase,
}
