import { Clients } from '@Balance/Clients/Domain/Model/Clients'
import { useClientStock } from '@Balance/Clients/Hooks/useClientStock'
import { useSummaryContext } from '@Balance/Summary/Context/SummaryContext'
import { Button, ButtonVariant } from '@Core/Components'
import { Loader } from '@Core/Components/Loader/Loader'
import { StockTable } from '@Core/Components/StockTable/StockTable'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { useEffect } from 'react'
import { AiOutlineClose } from 'react-icons/ai'

export const BalanceClientStock = ({
    client,
    onRemove,
}: {
    client: Clients
    onRemove: (client: Clients) => void
}) => {
    const { _id } = client
    const { stock, loading } = useClientStock(_id)
    const { setStocks } = useSummaryContext()

    const injectClient = stock?.map((item) => ({
        ...item,
        client: [client],
    }))

    useEffect(() => {
        setStocks(prev => ({...prev, [client._id]: injectClient}))
    }, [stock])

    return (
        <Card style={{width: '100%'}}>
            <CardHeader
                title={client.corporateName}
                action={
                    <Button
                        variant={ButtonVariant.tertiary}
                        onClick={() => onRemove?.(client)}
                    >
                        <AiOutlineClose />
                    </Button>
                }
            />
            <CardContent>
                {Boolean(loading) && <Loader />}
                {!Boolean(loading) && (
                    <StockTable
                        stocks={injectClient || []}
                    />
                )}
            </CardContent>
        </Card>
    )
}
