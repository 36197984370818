import styled, { css } from 'styled-components'
import { getStatusTagColor } from './StatusTag.utils'

export const StatusTag = styled.div<{ status: string; size?: 'large' }>`
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    background: ${({ status }) => getStatusTagColor(status)};
    color: ${({ theme }) => theme.text};
    ${({ size }) =>
        size === 'large' &&
        css`
            height: 100%;
            font-size: 14px;
            padding: 6px 12px;
        `}
`
