import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'

export interface DeleteProducts {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteProductsUseCase implements DeleteProducts {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
