import { CoreRepository } from '../../Data/Repository/CoreRepository'

export interface SendStock {
    execute(id: string): Promise<void>
    getSource(): string
}

export class SendStockUseCase implements SendStock {
    constructor(private repository: CoreRepository) {}
    async execute(id: string): Promise<void> {
        return this.repository.sendStock(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
