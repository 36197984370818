import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { Proposal } from '@Commercial/Proposal/entities/Proposal'
import { CommercialProposalRepository } from './../../Data/Repository/ProposalRepository'

export interface CreateProposal {
    execute(entity: Proposal): Promise<DBCommercialProposal>
    getSource(): string
}

export class CreateProposalUseCase implements CreateProposal {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(entity: Proposal): Promise<DBCommercialProposal> {
        return this.repository.create(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
