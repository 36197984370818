import {
    InventorySupplierContainer,
    InventorySupplierRegister,
} from '@InventoryControl/Supplier/Data/DataSource/Container'
import {
    Supplier,
    supplierSchema,
} from '@InventoryControl/Supplier/entities/Supplier'
import getValidationErrors from '@Utils/Support/getValidationErrors'
import { FormHandles } from '@unform/core'
import { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

export const useInventoryCreateSupplier = () => {
    const formRef = useRef<FormHandles>(null)
    const createSupplierUseCase = InventorySupplierContainer.get(
        InventorySupplierRegister.UseCaseCreateSupplier
    )
    const [state, setState] = useState<Supplier>({} as Supplier)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }))
    }

    const handleCreateSupplier = useCallback(
        async (callBack: () => void) => {
            try {
                if (
                    await supplierSchema.validate(state, {
                        abortEarly: false,
                    })
                ) {
                    await createSupplierUseCase.execute(state)
                    toast.success('Fornecedor criado com sucesso')
                    formRef.current?.reset()
                    setState({} as Supplier)
                    callBack?.()
                }
            } catch (error) {
                console.log(error)
                const errors = getValidationErrors(error as any)
                formRef.current?.setErrors(errors)
            }
        },
        [state]
    )

    return {
        handleCreateSupplier,
        handleChange,
        formRef,
        state,
    }
}
