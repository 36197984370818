import {
    CommercialAccessories,
    DBCommercialProduct,
} from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { CommercialCordType } from '@Commercial/Products/Data/DataSource/Container/ProductRegister'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Modal } from '@Core/Components/Modal/Modal'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { Grid } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'

export const CordType = ({
    isOpen,
    onClose,
    control,
    setValue,
    state,
    prefixName,
    disabled = false
}: {
    isOpen: boolean
    onClose: () => void
    control: any
    setValue: any
    disabled?: boolean
}) => {
    const getProductContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseGetProduct
    )
    const [cordType, setCordType] = useState<CommercialCordType[]>([])
    const [cordAccessories, setCordAccessories] = useState<
        CommercialAccessories[]
    >([])

    const getUpdatedPrice = useCallback(async (): Promise<void> => {
        if(disabled) return
        if(!cordType?.length || !state.cordType) return
        const newType = cordType.find((type) => type._id === state.cordType._id)
        if(!newType) return
        setTimeout(() => {
            setValue(`${prefixName}.cordType`, {...newType})
        }, 1000)
    }, [state, cordType])

    const getSizes = useCallback(() => {
        if (!state.cordType) return []
        const { table } = state.cordType || { table: {} }
        const { rows } = table || { rows: [] }
        let size = []
        for (const element of rows) {
            size.push(element[0])
        }
        return size
    }, [state.cordType])

    const getCord = useCallback(async () => {
        let product = state
        if (state?.productID !== undefined) {
            product = (await getProductContainer.execute(
                state?.productID
            )) as unknown as DBCommercialProduct[]
        }
        if(product?.cordType?.length) {
            const types = [...product?.cordType]
            const accessories = product?.accessories
            setCordType(types)

            setCordAccessories(accessories)
            getMarkups()
        }
    }, [state])

    const getMarkups = useCallback(async () => {
        const markups = state.markups
        setValue(prefixName + '.markupTable', markups[0])
    }, [])

    const getCordAccessories = useCallback(
        (size = '') => {
            const filteredItems = cordAccessories.filter((item) => {
                const lowerCaseName = String(item.name).toLowerCase()
                return (
                    lowerCaseName.includes('mm') && lowerCaseName.includes(size)
                )
            })
            const withoutSize = cordAccessories.filter(
                (item) => !String(item.name).toLowerCase().includes('mm')
            )
            return filteredItems
                .concat(withoutSize)
                .sort((a, b) => a.name.localeCompare(b.name))
        },
        [cordAccessories, state.size]
    )

    useEffect(() => {
        getCord()
        if (!state.cordAccessories) {
            setValue(`${prefixName}.cordAccessories`, [])
        }
    }, [])

    useEffect(() => {
        getSizes()
    }, [state.cordType])

    useEffect(() => {
        getUpdatedPrice()
    }, [cordType, state])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="grid grid-cols-1 gap-4">
                <h2>{state.name}</h2>
                <Grid container spacing={2}>
                    <Grid item xs={6} lg={6}>
                        {Boolean(cordType.length) && (
                            <InputAutoComplete
                                control={control}
                                useUnform={false}
                                results={formattedAutocomplete<CommercialCordType>(
                                    cordType || [],
                                    'name'
                                )}
                                label={'Tipo'}
                                labelKey={'name'}
                                onChange={getSizes}
                                name={`${prefixName}.cordType`}
                            />
                        )}
                    </Grid>
                    {Boolean(getSizes().length) && (
                        <>
                            <Grid item xs={6} lg={6}>
                                <InputAutoComplete
                                    onChange={() => {}}
                                    control={control}
                                    useUnform={false}
                                    defaultValue={[]}
                                    results={formattedAutocomplete<CommercialAccessories>(
                                        getSizes(),
                                        'value'
                                    )}
                                    label={'Especificação'}
                                    labelKey={'value'}
                                    name={`${prefixName}.size`}
                                />
                            </Grid>
                        </>
                    )}
                    {state.cordAccessories && cordAccessories && (
                        <Grid item xs={12} lg={12}>
                            <InputAutoComplete
                                control={control}
                                useUnform={false}
                                results={formattedAutocomplete<CommercialAccessories>(
                                    getCordAccessories(state?.size?.value),
                                    'name'
                                )}
                                multiple
                                filterSelectedOptions
                                label={'Acessórios'}
                                labelKey={'name'}
                                onChange={setValue}
                                name={`${prefixName}.cordAccessories`}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </Modal>
    )
}
