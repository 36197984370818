import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { roles } from '@Commercial/roles'
import { Button, ButtonVariant, Container } from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { Grid, InputAdornment } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { ProductProposalForm } from '../Components/ProductProposalForm/ProductProposalForm'
import { ProposalFooter } from '../Components/ProposalFooter/ProposalFooter'
import { DBCommercialProposal } from '../Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '../Data/DataSource/Container'
import { useNewProposal } from '../Hooks/useNewProposal/useNewProposal'
import { sumActiveProducts, sumQuantities } from '../Utils/counts'

export const NewProposal = () => {
    const {
        loading,
        state,
        formState,
        handleSubmit,
        register,
        handleAddingNewProduct,
        methods,
    } = useNewProposal()
    const { control, setValue } = methods
    const { errors } = formState
    const [creditCardTax, setCreditCardTax] = useState([])
    const handleDelete = (index: number) => {
        setValue(
            `products`,
            state.products
                .slice(0, index)
                .concat(state.products.slice(index + 1))
        )
    }
    const variablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )

    const getCreditCard = async () => {
        const variables = await variablesContainer.execute()
        const { variableValue } = variables.find(
            (item) => item.variableType === 'creditCard'
        ) || {
            variableValue: '[]',
        }
        setCreditCardTax(
            JSON.parse(variableValue as string).sort((a, b) => a.name - b.name)
        )
    }

    const handleDuplicateProposal = (index: number) => () => {
        let duplicateProposal = [
            ...state?.products,
        ] as unknown as DBCommercialProposal & { _id: string }[]
        const { productID, ...data } = state?.products[
            index
        ] as unknown as DBCommercialProposal & { productID: string }
        duplicateProposal.push({ ...data, _id: String(productID || data._id) })
        methods.setValue('products', duplicateProposal as any)
    }

    useEffect(() => {
        getCreditCard()
    }, [])

    const FixedTextInfo = () => (
        <>
            <span className="flex flex-col  min-w-fit">
                <div className="flex text-base gap-2 justify-around">
                    <span>Parcelas</span> - <span>Taxa</span>
                </div>
                {!!creditCardTax &&
                    creditCardTax?.map(({ name, value, id }) => (
                        <div
                            key={id}
                            className="flex text-sm justify-around text-center"
                        >
                            <span className="text-center p-x-2">{name}</span> -{' '}
                            <span>{value}</span>
                        </div>
                    ))}
            </span>
        </>
    )

    return (
        <>
            <Container>
                <BackButton />
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-12">
                            <div className="flex gap-2 w-full">
                                <div className="flex gap-2 col-span-4 w-full">
                                    <Input
                                        size="small"
                                        fullWidth
                                        className="font-bold-minput"
                                        name={'name'}
                                        inputProps={{ placeholder: 'ODC1234' }}
                                        useUnform={false}
                                        register={register('name')}
                                        error={Boolean(errors?.name)}
                                        helperText={errors?.name?.message}
                                        label="Pipe"
                                        required
                                    />

                                    <Input
                                        size="small"
                                        fullWidth
                                        name={'clientName'}
                                        useUnform={false}
                                        control={control}
                                        label="Cliente"
                                        disabled
                                        helperText={
                                            'Preenchido automaticamente'
                                        }
                                    />
                                </div>
                                <div className="min-w-fit">
                                    <RightContent>
                                        <PermissionWrapper
                                            permission={
                                                roles.proposal.permissions
                                                    .create.key
                                            }
                                        >
                                            <Button
                                                disabled={loading}
                                                variant={ButtonVariant.primary}
                                                type="submit"
                                                color="primary"
                                            >
                                                {loading
                                                    ? 'Carregando...'
                                                    : 'Criar Proposta'}
                                            </Button>
                                        </PermissionWrapper>
                                    </RightContent>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 w-full">
                            <Grid container spacing={2}>
                                {Boolean(state?.products.length) &&
                                    state?.products?.map(
                                        (item: any, index: number) => (
                                            <Grid item xs={12} key={item._id}>
                                                <ProductProposalForm
                                                    duplicateProposal={handleDuplicateProposal(
                                                        index
                                                    )}
                                                    isUnique={
                                                        state?.products
                                                            .length === 1
                                                    }
                                                    onDelete={() =>
                                                        handleDelete(index)
                                                    }
                                                    controller={control}
                                                    state={state}
                                                    error={
                                                        !state?.products?.[
                                                            index
                                                        ]?.unitCost &&
                                                        state?.products[index]
                                                            .name !== 'Outros'
                                                    }
                                                    key={item._id}
                                                    index={index}
                                                    register={register}
                                                    setValue={setValue}
                                                />
                                            </Grid>
                                        )
                                    )}
                            </Grid>
                        </div>
                        <div className="col-span-12 grid grid-cols-12 w-full py-2">
                            <div className="col-span-5">
                                <Button
                                    type="button"
                                    onClick={handleAddingNewProduct}
                                    variant={ButtonVariant.primary}
                                >
                                    Adicionar Produto
                                </Button>
                            </div>
                            <div className="col-span-7 flex w-full justify-end gap-1 items-center">
                                <div>
                                    Produtos:{' '}
                                    <span title="Produtos ativos">
                                        {sumActiveProducts(state)}
                                    </span>{' '}
                                    /{' '}
                                    <span title="Produtos">
                                        {state?.products.length}
                                    </span>
                                </div>
                                - <div>Quantidade: {sumQuantities(state)}</div>
                            </div>
                        </div>
                        <div className="col-span-12 grid grid-cols-12 w-full gap-2">
                            <div className="col-span-7 grid grid-cols-12 gap-2 w-full">
                                <div className="col-span-3 w-full">
                                    <Input
                                        size="small"
                                        fullWidth
                                        name={'bvPercentage'}
                                        min={0}
                                        max={100}
                                        type="number"
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            step: 0.01,
                                        }}
                                        step={0.01}
                                        useUnform={false}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0 },
                                            endAdornment: (
                                                <HoverCard>
                                                    <HoverCardTrigger
                                                        asChild
                                                        className="cursor-help"
                                                    >
                                                        <InputAdornment position="end">
                                                            <AiOutlineInfoCircle />
                                                        </InputAdornment>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="grid gap-2 w-fit max-w-lg">
                                                        <FixedTextInfo />
                                                    </HoverCardContent>
                                                </HoverCard>
                                            ),
                                        }}
                                        register={register('bvPercentage')}
                                        label="BV / Cartão %"
                                    />
                                </div>
                                <div className="col-span-3 w-full">
                                    <Input
                                        size="small"
                                        fullWidth
                                        disabled
                                        control={control}
                                        name={'bvAmountToShow'}
                                        useUnform={false}
                                        defaultValue={0}
                                        label="Valor total do BV / Cartão"
                                    />
                                </div>
                            </div>
                            <div className="col-span-7 grid grid-cols-12 gap-2 w-full">
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        size="small"
                                        name={'discount'}
                                        min={0}
                                        max={100}
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        useUnform={false}
                                        register={register('discount')}
                                        label="Desconto %"
                                    />
                                </div>
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        size="small"
                                        disabled
                                        control={control}
                                        name={'totalDiscount'}
                                        defaultValue={0}
                                        useUnform={false}
                                        label="Valor total do Desconto"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <TextArea
                                register={register('observations')}
                                control={control}
                                useUnform={false}
                                name={'observations'}
                                label="Observações"
                                fullWidth
                                rows={5}
                            />
                        </div>
                    </div>
                </form>
            </Container>
            <ProposalFooter state={state} setFormValue={setValue} />
        </>
    )
}
