import { CommercialVariables } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export const VariableTableItem = ({
    variable,
    onDelete,
    handleSave,
}: {
    variable: CommercialVariables
    refresh: () => void
    onDelete: () => void
    handleSave: (entity: CommercialVariables) => void
}) => {
    const [editMode, setEditMode] = useState(false)
    const { control, handleSubmit } = useForm({
        defaultValues: variable,
    })

    const handleSaveVariable = (data: CommercialVariables) => {
        handleSave(data)
        setEditMode(false)
    }

    const renderButtons = () => {
        if (editMode) {
            return (
                <>
                    <Button
                        type="button"
                        variant={ButtonVariant.secondary}
                        onClick={() => onDelete()}
                    >
                        Deletar
                    </Button>
                    <Button
                        type="reset"
                        variant={ButtonVariant.secondary}
                        onClick={() => setEditMode(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant={ButtonVariant.primary}
                        onClick={handleSubmit(handleSaveVariable)}
                    >
                        Salvar
                    </Button>
                </>
            )
        }
        return (
            <Button
                type="button"
                variant={ButtonVariant.primary}
                onClick={() => setEditMode(true)}
            >
                Editar
            </Button>
        )
    }

    return (
        <tr>
            <td>
                {editMode && (
                    <Input name="name" control={control} useUnform={false} />
                )}
                {!editMode && variable.name}
            </td>
            <td>
                {editMode && (
                    <Input name="value" control={control} useUnform={false} />
                )}
                {!editMode && variable.value}
            </td>
            <td>{renderButtons()}</td>
        </tr>
    )
}
