import { generateInputRandomName } from '@Utils/Mocks/generateInputRandomName'
import { TextField } from '@material-ui/core'
import { useField } from '@unform/core'
import { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { InputProps } from './Input.types'
export * from './Input.types'

export const Input = ({
    label,
    supportMessages,
    name = generateInputRandomName(),
    useUnform = true,
    register,
    control,
    errors = {},
    formatter,
    ...props
}: InputProps) => {
    if (!useUnform) {
        if (!control) {
            return (
                <TextField
                    {...props}
                    {...register}
                    name={name}
                    variant="outlined"
                    label={label}
                    helperText={errors?.[name]?.message || props.helperText}
                />
            )
        }
        return (
            <Controller
                control={control}
                render={({ field }) => (
                    <TextField
                        {...field}
                        {...props}
                        variant="outlined"
                        label={label}
                        helperText={errors?.[name]?.message || props.helperText}
                    />
                )}
                name={name}
            />
        )
    }
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: (ref) => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: (ref) => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])
    return (
        <TextField
            ref={inputRef}
            defaultValue={defaultValue}
            name={name}
            variant="outlined"
            label={label}
            error={!!error}
            helperText={supportMessages?.[0]?.message || error}
            {...props}
        />
    )
}
