import { Button } from '@material-ui/core'
import { BsChevronLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
export const BackButton = () => {
    const navigate = useNavigate()
    return (
        <Button
            onClick={() => navigate(-1)}
            variant="text"
            startIcon={<BsChevronLeft />}
        >
            Voltar
        </Button>
    )
}
