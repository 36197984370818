import { UsersRepository } from '@User/Data/Repository/UsersRepository'

export interface EnableUser {
    execute(id: string): Promise<void>
    getSource(): string
}

export class EnableUserUseCase implements EnableUser {
    constructor(private repository: UsersRepository) {}
    async execute(id: string): Promise<void> {
        return this.repository.enable(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
