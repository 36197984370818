import { CommercialAccessories } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'

export interface CreateAccessories {
    execute(client: CommercialAccessories): Promise<CommercialAccessories>
    getSource(): string
}

export class CreateAccessoriesUseCase implements CreateAccessories {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        return this.repository.createAccessories(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
