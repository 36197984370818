import { Clients } from '@Clients/Domain/Model/Clients'
import { createSlice } from '@reduxjs/toolkit'
import { ReducersName } from '../ReducersName'
import { SetClientsAction } from './reducer.type'

const initialState: { clients: { [key: string]: Clients[] } } = {
    clients: {},
}

const clientsReducer = createSlice({
    initialState,
    name: ReducersName.clients,
    reducers: {
        setClientsAction(state, { payload }: SetClientsAction) {
            if (payload.incremental) {
                state.clients = {
                    ...state.clients,
                    [payload.source as string]: [
                        ...state.clients[payload.source as string],
                        ...payload.data,
                    ],
                }
                return
            }
            state.clients = {
                ...state.clients,
                [payload.source as string]: payload.data,
            }
        },
    },
})
const { actions } = clientsReducer

export const { setClientsAction } = actions

export default clientsReducer.reducer
