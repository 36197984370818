import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
import { DBCommercialMarkup } from '../../Data/DataSource/API/Entity/Product'

export interface UpdateMarkup {
    execute(entity: DBCommercialMarkup): Promise<DBCommercialMarkup>
    getSource(): string
}

export class UpdateMarkupUseCase implements UpdateMarkup {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(entity: DBCommercialMarkup): Promise<DBCommercialMarkup> {
        return this.repository.updateMarkup(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
