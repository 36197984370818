import { isValidEmail } from '@brazilian-utils/brazilian-utils'
import { Client } from '@Clients/Data/DataSource/API/Entity/Client'
import { Clients } from '@Clients/Domain/Model/Clients'

export const validateClient = (client: Client, clients: Clients[]) => {
    const name = _validadeClientCorporateName(client, clients)
    const mail = _validadeClientMail(client)

    return name && mail
}

const _validadeClientCorporateName = (client: Client, clients: Clients[]) => {
    if (!client.corporateName) throw new Error('Nome do cliente é obrigatório')
    if (clients.some((c) => c.corporateName === client.corporateName))
        throw new Error('Cliente já cadastrado')
    return true
}
const _validadeClientMail = (client: Client) => {
    if (!client.mail) throw new Error('E-mail do cliente é obrigatório')
    if (!isValidEmail(client.mail)) throw new Error('E-mail inválido')
    return true
}
