import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
import { CommercialAccessories } from '../../Data/DataSource/API/Entity/Product'

export interface UpdateAccessories {
    execute(entity: CommercialAccessories): Promise<CommercialAccessories>
    getSource(): string
}

export class UpdateAccessoriesUseCase implements UpdateAccessories {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        return this.repository.updateAccessories(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
