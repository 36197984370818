import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
export interface GetProduct {
    execute(id: string): Promise<DBCommercialProduct>
    getSource(): string
}

export class GetProductUseCase implements GetProduct {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(id: string): Promise<DBCommercialProduct> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
