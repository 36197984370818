import { Button, ButtonVariant, Table } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { getProductStatus } from '@InventoryControl/Products/Utils/productsTools'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { formatCurrency } from '@Utils/Formatters/currency'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { Chip, TablePagination } from '@material-ui/core'
import { Key, MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductsListProps } from './ProductsLis.types'

export const ProductsListComponent = ({
    summary = false,
    products,
    filteredProducts,
    loading,
    getPaginate,
    page,
    limit,
    setLimit,
    setPage,
}: ProductsListProps) => {
    const navigate = useNavigate()

    const newEntry =
        (product: DBInventoryProduct) =>
        (e: MouseEventHandler<HTMLButtonElement>) => {
            //@ts-ignore
            e?.stopPropagation()
            navigate(RoutesPaths.InventoryCreateEntry, { state: { product } })
        }

    const newExit =
        (product: DBInventoryProduct) =>
        (e: MouseEventHandler<HTMLButtonElement>) => {
            //@ts-ignore
            e?.stopPropagation()
            navigate(RoutesPaths.InventoryCreateExit, { state: { product } })
        }

    return (
        <Grid container item lg={12}>
            <Grid item lg={12}>
                {loading && <Loader />}
                {!loading && products && (
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Unidade de Medida</th>
                                {!summary && <th>Estoque Mínimo</th>}
                                <th>Estoque Atual</th>
                                {!summary && (
                                    <>
                                        <th>Custo Unitário</th>
                                        <th>Ações</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {getPaginate(page, limit).map((product) => (
                                <tr
                                    key={product._id as Key}
                                    data-inactive={product.isInactive}
                                    data-clickable={!summary}
                                    onClick={() => {
                                        if (summary) return
                                        navigate(
                                            formatRouteId(
                                                RoutesPaths.InventoryProduct,
                                                product._id
                                            )
                                        )
                                    }}
                                >
                                    <td>{product.name}</td>
                                    <td>{product.unitMeasure}</td>
                                    {!summary && (
                                        <td>{product.minimumStock}</td>
                                    )}
                                    <td
                                        data-status={
                                            !summary &&
                                            getProductStatus(product)
                                        }
                                    >
                                        {product.stock || 0}
                                    </td>
                                    {!summary && (
                                        <>
                                            <td>
                                                {formatCurrency(
                                                    product.unitCost || 0
                                                )}
                                            </td>
                                            <td>
                                                {product.isInactive ? (
                                                    <Chip
                                                        label="Inativo"
                                                        color="secondary"
                                                    />
                                                ) : (
                                                    <>
                                                        <Button
                                                            //@ts-ignore
                                                            onClick={newEntry(
                                                                product
                                                            )}
                                                            variant={
                                                                ButtonVariant.primary
                                                            }
                                                        >
                                                            Entrada
                                                        </Button>
                                                        <Button
                                                            //@ts-ignore
                                                            onClick={newExit(
                                                                product
                                                            )}
                                                            variant={
                                                                ButtonVariant.secondary
                                                            }
                                                        >
                                                            Saída
                                                        </Button>
                                                    </>
                                                )}
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                {Number(filteredProducts?.length) > 10 && (
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100, 10000, 100000]}
                        component="div"
                        count={filteredProducts.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}
