import {
    CreateProductUseCase,
    DeleteProductsUseCase,
    GetProductUseCase,
    GetProductsUseCase,
    UpdateProductUseCase,
} from '@Commercial/Products/Domain/UseCases'
import { CreateAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/CreateAccessories'
import { CreateCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/CreateCordType'
import { DeleteAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/DeleteAccessories'
import { DeleteCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/DeleteCordType'
import { DeletePriceRangeUseCase } from '@Commercial/Products/Domain/UseCases/DeletePriceRange'
import { UpdateAccessoriesUseCase } from '@Commercial/Products/Domain/UseCases/UpdateAccessories'
import { UpdateCordTypeUseCase } from '@Commercial/Products/Domain/UseCases/UpdateCordType'
import { UpdateMarkupUseCase } from '@Commercial/Products/Domain/UseCases/UpdateMarkup'
import { UpdatePriceRangeUseCase } from '@Commercial/Products/Domain/UseCases/UpdatePriceRange'

export const Register = {
    UseCaseCreateCordType: CreateCordTypeUseCase,
    UseCaseCreateProduct: CreateProductUseCase,
    UseCaseDeleteCordType: DeleteCordTypeUseCase,
    UseCaseDeleteProduct: DeleteProductsUseCase,
    UseCaseGetProduct: GetProductUseCase,
    UseCaseGetProducts: GetProductsUseCase,
    UseCaseUpdateCordType: UpdateCordTypeUseCase,
    UseCaseUpdateProduct: UpdateProductUseCase,
    UseCaseCreateAccessories: CreateAccessoriesUseCase,
    UseCaseUpdateAccessories: UpdateAccessoriesUseCase,
    UseCaseDeleteAccessories: DeleteAccessoriesUseCase,
    UseCaseUpdateMarkup: UpdateMarkupUseCase,
    UseCaseUpdatePriceRange: UpdatePriceRangeUseCase,
    UseCaseDeletePriceRange: DeletePriceRangeUseCase,
}

export enum CommercialCordType {
    type = 'cord-type',
    accessories = 'accessories',
    markup = 'markup',
}
