import { Loader } from '@Core/Components/Loader/Loader'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { Navigate, Outlet } from 'react-router-dom'

export const PrivacyRoute = ({ permission }: { permission: string }) => {
    const { hasPermission, loading } = usePermissions()
    if (loading) return <Loader />
    if (!hasPermission(permission)) {
        return <Navigate to="/permission-denied" />
    }
    return <Outlet />
}
