import {
    InventoryDestinationContainer,
    InventoryDestinationRegister,
} from '@InventoryControl/Destinations/Data/DataSource/Container'
import {
    Destination,
    destinationSchema,
} from '@InventoryControl/Destinations/entities/Destination'
import getValidationErrors from '@Utils/Support/getValidationErrors'
import { FormHandles } from '@unform/core'
import { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

export const useInventoryCreateDestination = () => {
    const [loading, setLoading] = useState(false)
    const formRef = useRef<FormHandles>(null)
    const createDestinationUseCase = InventoryDestinationContainer.get(
        InventoryDestinationRegister.UseCaseCreateDestination
    )
    const [state, setState] = useState<Destination>({} as Destination)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }))
    }

    const handleCreateDestination = useCallback(
        async (callBack: () => void) => {
            setLoading(true)
            try {
                if (
                    await destinationSchema.validate(state, {
                        abortEarly: false,
                    })
                ) {
                    await createDestinationUseCase.execute(state)
                    toast.success('Destino criado com sucesso')
                    formRef.current?.reset()
                    handleChange({ target: { name: 'name', value: '' } } as any)
                    setState({} as Destination)
                    callBack?.()
                }
            } catch (error) {
                const errors = getValidationErrors(error as any)
                formRef.current?.setErrors(errors)
            } finally {
                setLoading(false)
            }
        },
        [state]
    )

    return {
        handleCreateDestination,
        handleChange,
        formRef,
        state,
        loading,
    }
}
