import { Button, ButtonVariant } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { IconButton } from '@Core/Components/IconButton/IconButton'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'
import { ModalProps } from '@Core/Components/Modal/Modal.types'
import { API } from '@Services/api'
import { handleEnter } from '@Utils/Support/keydown'
import DeleteIcon from '@assets/delete.png'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import { useEffect, useRef } from 'react'
import { useSupplierManager } from '../../Hooks/useSupplierManager'

export const NewSupplierModal = ({
    isOpen,
    onClose,
}: Omit<ModalProps, 'children'>) => {
    const { handleAddSupplier, state } = useSupplierManager(API)
    const formRef = useRef<FormHandles>(null)
    const _onClose = () => {
        formRef.current?.reset()
        onClose?.()
    }
    const {
        handleChange,
        handleAddingAdditionalEmail,
        handleSubmit,
        handleRemoveAdditionalMail,
    } = handleAddSupplier({
        callback: _onClose,
    })
    useEffect(() => {
        window.addEventListener('keydown', handleEnter)
        return () => window.removeEventListener('keydown', handleEnter)
    }, [])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Novo Fornecedor</h2>
            <Form ref={formRef} onSubmit={() => {}}>
                <Grid container lg={12}>
                    <Grid item lg={6}>
                        <Input
                            onChange={handleChange}
                            name="corporateName"
                            value={state?.corporateName}
                            required
                            label="Nome"
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            onChange={handleChange}
                            value={state?.mail}
                            required
                            name="mail"
                            label="E-mail"
                            type="mail"
                        />
                    </Grid>

                    {state?.additionalEmails &&
                        state.additionalEmails.map((mail, index) => (
                            <Grid item lg={6} flex key={mail}>
                                <Grid item xs={10}>
                                    <Input
                                        onChange={handleChange}
                                        required
                                        name={`additionalEmails[${index}]`}
                                        label="E-mail"
                                        type="mail"
                                    />
                                </Grid>
                                <IconButton
                                    size="medium"
                                    onClick={() =>
                                        handleRemoveAdditionalMail({ index })
                                    }
                                >
                                    <img
                                        src={DeleteIcon}
                                        alt="Remover e-mail"
                                    />
                                </IconButton>
                            </Grid>
                        ))}
                    <Grid item lg={3}>
                        <Button onClick={handleAddingAdditionalEmail}>
                            Adicionar outro e-mail
                        </Button>
                    </Grid>
                </Grid>
            </Form>
            <ModalFooter>
                <Button onClick={onClose} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant={ButtonVariant.primary}>
                    Cadastrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
