import styled from "styled-components"

export const IndicatorContainer = styled.div<{ offsetTop: number }>`
  height: 44px;
  width: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  left: -1px;
  border-radius: 0px 10px 10px 0px;
  transform: translateY(${(props) => props.offsetTop}px);
  transition: transform 0.3s;
  z-index: 1;
`