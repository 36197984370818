import { AiOutlineClose } from 'react-icons/ai'
import { Backdrop, Close, ModalContainer } from './Modal.styles'
import { ModalProps, ModalSize } from './Modal.types'
export { ModalFooter } from './Modal.styles'
export { ModalSize } from './Modal.types'

export const Modal = ({
    children,
    isOpen,
    onClose,
    size = ModalSize.Medium,
}: ModalProps) => {
    return (
        <>
            <Backdrop isOpen={isOpen} onClick={onClose} />
            <ModalContainer size={size} isOpen={isOpen}>
                <Close type="button" onClick={onClose}>
                    <AiOutlineClose />
                </Close>
                {children}
            </ModalContainer>
        </>
    )
}
