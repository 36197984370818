import { format, subDays } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import * as React from 'react'
import { DateRange } from 'react-day-picker'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

export function DatePickerWithRange({
    className,
    date,
    setDate,
}: {
    className?: string
    date?: DateRange
    setDate: React.Dispatch<React.SetStateAction<DateRange | undefined>>
}) {
    const handleChange = (date: DateRange | undefined) => {
        setDate(date)
    }

    return (
        <div className={cn('grid gap-2', className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={'outline'}
                        className={cn(
                            'w-[300px] justify-start text-left font-normal',
                            !date && 'text-muted-foreground'
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, 'dd LLL, y')} -{' '}
                                    {format(date.to, 'dd LLL, y')}
                                </>
                            ) : (
                                format(date.from, 'dd LLL, y')
                            )
                        ) : (
                            <span>Selecione uma Data</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <div className="p-2">
                        <Select
                            onValueChange={(value) =>
                                handleChange({
                                    to: new Date(),
                                    from: subDays(new Date(), Number(value)),
                                })
                            }
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Predefinidos" />
                            </SelectTrigger>
                            <SelectContent position="popper">
                                <SelectItem value="0">Hoje</SelectItem>
                                <SelectItem value="3">
                                    Últimos 3 Dias
                                </SelectItem>
                                <SelectItem value="7">Última Semana</SelectItem>
                                <SelectItem value="30">Último Mês</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={handleChange}
                        numberOfMonths={2}
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}
