import { db, storage } from '@/utils/firebase'
import { Container, ContainerHeader } from '@Core/Components'
import { FileUpload } from '@Core/Components/FileInput/FileInput'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { formatFileSize } from '@Utils/Support/fileSize'
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    TextField,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadString } from 'firebase/storage'
import { useCallback, useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import { toast } from 'react-toastify'

export const Settings = () => {
    const [salutation, setSalutation] = useState('')
    const [description, setDescription] = useState('')
    const [showPicker, setShowPicker] = useState(false)

    const [file, setFile] = useState(null)
    const [dimension, setDimension] = useState({
        width: 0,
        height: 0,
        warning: false,
    })
    const [wallpaper, setWallpaper] = useState('')
    const [color, setColor] = useState('')
    const [loading, setLoading] = useState(false)

    const getText = async () => {
        const saluRef = doc(db, 'settings', 'home')
        const saluSnap = await getDoc(saluRef)

        if (saluSnap.exists()) {
            setSalutation(saluSnap.data().salutation)
            setDescription(saluSnap.data().description)
            setColor(saluSnap.data().color)
        }
    }

    const saveText = useCallback(async () => {
        setLoading(true)
        await setDoc(doc(db, 'settings', 'home'), {
            salutation,
            description,
            color,
        })
        toast.success('Texto alterado com sucesso')
        setLoading(false)
    }, [salutation, description, color])

    const onChange = (file) => {
        setFile(file)
        dimensionsVerify(file)
    }

    const dimensionsVerify = (i: { file: File }) => {
        const img = new Image()
        img.src = URL.createObjectURL(i.file)

        img.onload = async () => {
            const dimensions = img
            const maxWidth = 1920
            const maxHeight = 1080
            setDimension({
                width: dimensions.width,
                height: dimensions.height,
                warning:
                    dimensions.width > maxWidth ||
                    dimensions.height > maxHeight,
            })
        }
    }

    const fileSizeWarning = file?.file?.size > 1000000

    const handleSubmit = useCallback(async () => {
        if (!file) {
            toast.error('Selecione uma imagem')
            return
        }
        setLoading(true)
        try {
            const storageRef = ref(storage, 'home-wallpaper.jpg')
            uploadString(storageRef, file.base64.split(',')[1], 'base64')
                .then(() => {
                    toast.success('Imagem alterada com sucesso')
                    setLoading(false)
                    setFile(null)
                    getCurrentImage()
                })
                .catch((error) => {
                    setLoading(false)
                    toast.error('Erro ao alterar imagem')
                })
        } catch (e) {
            toast.error('Erro ao alterar imagem')
            console.log(e)
        }
    }, [file])

    const getCurrentImage = () => {
        const storageRef = ref(storage, 'home-wallpaper.jpg')
        getDownloadURL(storageRef).then((url) => {
            setWallpaper(url)
        })
    }

    useEffect(() => {
        getCurrentImage()
        getText()
    }, [])

    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Configurações</h1>
            </ContainerHeader>
            <Grid container lg={12} xs={12} spacing={1}>
                <Grid item lg={12}>
                    <h2>Home</h2>
                </Grid>
                <Grid item lg={file ? 12 : 6} md={12} xs={12}>
                    <Card>
                        <CardHeader title="Background" />
                        {file && (
                            <CardContent>
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        lg={12}
                                        xs={12}
                                        container
                                        spacing={1}
                                    >
                                        <Grid item lg={12} xs={12}>
                                            {dimension.warning && (
                                                <Alert severity="warning">
                                                    <AlertTitle>
                                                        Atenção
                                                    </AlertTitle>
                                                    A Imagem seleciona é maior
                                                    que o recomendado
                                                    <br />
                                                    Recomendo: 1920x1080
                                                    <br />
                                                    Imagem: {dimension.width}x
                                                    {dimension.height}
                                                </Alert>
                                            )}
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            {fileSizeWarning && (
                                                <Alert severity="warning">
                                                    <AlertTitle>
                                                        Atenção
                                                    </AlertTitle>
                                                    O tamanho da imagem é muito
                                                    grande, podendo causar
                                                    lentidão ao ser exibida
                                                    <br />
                                                    Recomendado: Até 1MB
                                                    <br />
                                                    Imagem:{' '}
                                                    {formatFileSize(
                                                        file?.file?.size
                                                    )}
                                                </Alert>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {file && (
                                        <Grid
                                            spacing={2}
                                            lg={12}
                                            container
                                            justifyContent="space-around"
                                        >
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                xs={12}
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    item
                                                    xs={12}
                                                >
                                                    <h3>Imagem nova</h3>
                                                </Grid>
                                                <img
                                                    width={'100%'}
                                                    src={file?.base64}
                                                    style={{
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                xs={12}
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    item
                                                    xs={12}
                                                >
                                                    <h3>Imagem atual</h3>
                                                </Grid>
                                                <img
                                                    width={'100%'}
                                                    src={wallpaper}
                                                    style={{
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        )}
                        <CardActions disableSpacing>
                            <Grid container lg={12}>
                                <Grid item lg={6} xs={12}>
                                    <FileUpload onDone={onChange}>
                                        {file
                                            ? 'Alterar imagem'
                                            : 'Selecionar imagem'}
                                    </FileUpload>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    xs={12}
                                    container
                                    justifyContent="flex-end"
                                >
                                    {file && (
                                        <RightContent>
                                            <Button
                                                disabled={loading}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >
                                                {loading
                                                    ? 'Salvando...'
                                                    : 'Salvar'}
                                            </Button>
                                        </RightContent>
                                    )}
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item lg={6} md={12} xs={12}>
                    <Card>
                        <CardHeader title="Saudação e Descrição" />
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Saudação"
                                        value={salutation}
                                        onChange={({ target }) =>
                                            setSalutation(target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={2}
                                        variant="outlined"
                                        label="Descrição"
                                        value={description}
                                        onChange={({ target }) =>
                                            setDescription(target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item lg={12} xs={12}>
                                    <Grid
                                        container
                                        onClick={() =>
                                            setShowPicker(!showPicker)
                                        }
                                    >
                                        <Button variant="outlined">
                                            Cor do texto: {color}
                                        </Button>
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                width: 40,
                                                height: 40,
                                                background: color,
                                                borderRadius: 5,
                                                marginLeft: 4,
                                            }}
                                        />
                                    </Grid>
                                    {showPicker && (
                                        <ChromePicker
                                            color={color}
                                            onChangeComplete={({ hex }) =>
                                                setColor(hex)
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button
                                disabled={loading}
                                variant="contained"
                                color="primary"
                                onClick={saveText}
                            >
                                {loading ? 'Salvando...' : 'Salvar'}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}
