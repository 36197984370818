import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import { InventoryExitsRepository } from '@InventoryControl/Exits/Data/Repository/ExitsRepository'
import { Exits } from '@InventoryControl/Exits/entities/Exits'

export interface CreateExits {
    execute(exit: Exits): Promise<DBInventoryExits>
    getSource(): string
}

export class CreateExitsUseCase implements CreateExits {
    constructor(private repository: InventoryExitsRepository) {}
    async execute(exit: Exits): Promise<DBInventoryExits> {
        return this.repository.create(exit)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
