import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useInventoryExitManager } from '../Hooks/useInventoryExitManager/useInventoryExitManager'
import { DeleteExitModal } from './Components/DeleteExitModal/DeleteExitModal'
import { ExitDetailsForm } from './Components/ExitDetailsForm/ExitDetailsForm'

export const ExitDetails = () => {
    const { id } = useParams<{ id: string }>()
    const { getExit, state, loading, handleChange, handleSave, deleteExit } =
        useInventoryExitManager()

    const [editMode, setEditMode] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const handleEdit = () => {
        setEditMode(!editMode)
        getExit(id || '')
    }

    const handleDelete = () => {
        setShowDeleteModal(true)
    }

    const onSaved = () => {
        setEditMode(false)
        getExit(id || '')
        toast.success('Saída salva com sucesso!')
    }

    useEffect(() => {
        if (id) {
            getExit(id)
        }
    }, [id])

    if (!state?._id && loading) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Detalhes da Saída</h1>
                <RightContent>
                    {!editMode && (
                        <>
                            <Button
                                onClick={handleEdit}
                                variant={ButtonVariant.primary}
                            >
                                Editar
                            </Button>
                            <Button
                                onClick={handleDelete}
                                variant={ButtonVariant.secondary}
                            >
                                Deletar
                            </Button>
                            <DeleteExitModal
                                isOpen={showDeleteModal}
                                setIsOpen={setShowDeleteModal}
                                callback={deleteExit}
                            />
                        </>
                    )}
                    {editMode && (
                        <>
                            <Button
                                onClick={handleEdit}
                                variant={ButtonVariant.secondary}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => handleSave(onSaved)}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </>
                    )}
                </RightContent>
            </ContainerHeader>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <ExitDetailsForm
                        state={state}
                        editMode={editMode}
                        handleChange={handleChange}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
