import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { NegativeClientStock } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks.types'
import { Clients } from '@Clients/Domain/Model/Clients'
import { Client } from '../DataSource/API/Entity/Client'
import ClientsDataSource from '../DataSource/ClientsDataSource'

export class ClientsRepository implements BaseRepository<Clients, Client> {
    dataSource: ClientsDataSource
    constructor(_dataSource: ClientsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<Clients[]> {
        return this.dataSource.getClients()
    }
    async findById(id: string): Promise<Clients> {
        return this.dataSource.getClientById(id)
    }
    async search(query: string): Promise<Clients[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Client): Promise<Clients> {
        return this.dataSource.createClient(entity)
    }
    async update(entity: Clients): Promise<Clients> {
        return this.dataSource.updateClient(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteClient(id)
    }

    async getNegative(): Promise<NegativeClientStock[]> {
        return this.dataSource.getNegative()
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
