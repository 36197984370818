import { Destination } from '@InventoryControl/Destinations/entities/Destination'
import { Api } from '@Services/api'
import DestinationsDataSource from '../DestinationsDataSource'
import { DBInventoryDestination } from './Entity/Destination'

export class DestinationAPI implements DestinationsDataSource {
    axios = new Api()

    async getDestinationById(id: string): Promise<DBInventoryDestination> {
        const { data } = await this.axios.get(`/inventory/destinations/${id}`)
        return data
    }

    async getDestinations(): Promise<DBInventoryDestination[]> {
        const { data } = await this.axios.get('/inventory/destinations')
        return data
    }

    async search(query: string): Promise<DBInventoryDestination[]> {
        const { data } = await this.axios.get(
            `/inventory/destinations/search/${query}`
        )
        return data
    }

    async createDestination(
        destination: Destination
    ): Promise<DBInventoryDestination> {
        const { data } = await this.axios.post(
            '/inventory/destinations',
            destination
        )
        return data
    }

    async updateDestination(
        destination: DBInventoryDestination
    ): Promise<DBInventoryDestination> {
        const { data } = await this.axios.put(
            `/inventory/destinations/${destination._id}`,
            destination
        )
        return data
    }

    async deleteDestination(id: string): Promise<void> {
        await this.axios.delete(`/inventory/destinations/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
