import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'

export interface DeleteCordType {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteCordTypeUseCase implements DeleteCordType {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(id: string) {
        return this.repository.deleteCordType(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
