import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { CookiesStorage } from '../../CookiesStorage/CookiesStorage'
import { ReducersName } from '../ReducersName'
import { SetIsAuthenticationFinished, SetIsLoggedPayload } from './reducer.type'
const storage = new CookiesStorage()

const initialState = {
    isAuthenticationFinished: false,
    isLogged: false,
    isSessionExpired: true,
    isMS: false,
}

const authenticationReducer = createSlice({
    initialState,
    name: ReducersName.authentication,
    reducers: {
        setIsAuthenticationFinishedAction(
            state,
            { payload: isAuthenticationFinished }: SetIsAuthenticationFinished
        ) {
            state.isAuthenticationFinished = isAuthenticationFinished
        },
        setIsLoggedAction(state, { payload: isLogged }: SetIsLoggedPayload) {
            state.isLogged = isLogged
        },
        setIsSessionExpiredAction(
            state,
            { payload: isLogged }: SetIsLoggedPayload
        ) {
            state.isSessionExpired = isLogged
        },
        setIsMSAction(state, { payload: isLogged }: SetIsLoggedPayload) {
            state.isMS = isLogged
            storage.setItem('@ofc:isMS', isLogged)
        },
        logoutAction() {
            storage.removeItem('@ofc:accessToken')
            Sentry.setUser(null)
            return initialState
        },
    },
})
const { actions } = authenticationReducer

export const {
    setIsLoggedAction,
    setIsSessionExpiredAction,
    setIsAuthenticationFinishedAction,
    logoutAction,
    setIsMSAction,
} = actions

export default authenticationReducer.reducer
