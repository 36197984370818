export const moduleKey = 'core'
export const roles = {
    settings: {
        name: 'Configurações',
        key: `${moduleKey}:settings`,
        permissions: {
            read: {
                name: 'Visualizar / Editar',
                description: 'Permite visualizar as configurações',
                key: `${moduleKey}:settings:read`,
            },
        },
    },
}
