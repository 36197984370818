export enum RoutesPaths {
    Client = '/balance/clients/',
    ClientDetail = '/balance/clients/:id',
    ClientsList = '/balance/clients/list',
    PreMovements = '/balance/pre-movements',
    PreMovement = '/balance/pre-movements/:id',
    Movement = '/balance/movements',
    Movements = '/balance/movements/list',
    NewMovement = '/balance/movements/new',
    MovementDetails = '/balance/movements/:id',
    ProductDetail = '/balance/products/:id',
    ProductList = '/balance/products/list',
    Supplier = '/balance/supplier',
    SupplierDetail = '/balance/supplier/:id',
    SupplierList = '/balance/supplier/list',
    ViewerPDF = '/balance/viewer-pdf',
    SupplierMovement = '/balance/supplier/new-movement/:id',
    BalanceSummary = '/balance/summary',
}
