import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'
import { Form } from '@unform/web'
import { handleEnter } from '@Utils/Support/keydown'
import { useEffect } from 'react'
import { useClientManager } from '../../Hooks/useClientManager'
import { NewClientContent } from './NewClienteModal.styles'
import { NewClientModalProps } from './NewClientModal.types'

export const NewClientModal = ({ isOpen, onClose }: NewClientModalProps) => {
    const { handleAddClient, state, formRef } = useClientManager()
    const _onClose = () => {
        onClose?.()
    }
    const { handleSubmit, handleChange, handleAddingAdditionalEmail } =
        handleAddClient(_onClose)

    useEffect(() => {
        window.addEventListener('keydown', handleEnter)
        return () => window.removeEventListener('keydown', handleEnter)
    }, [])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Novo Cliente</h2>
            <Form ref={formRef} onSubmit={() => {}}>
                <NewClientContent>
                    <Input
                        onChange={handleChange}
                        name="corporateName"
                        required
                        label="Nome"
                    />
                    <Input
                        onChange={handleChange}
                        required
                        name="mail"
                        label="E-mail"
                        type="mail"
                    />
                    {state?.additionalEmails &&
                        state.additionalEmails.map((_, index) => (
                            <Input
                                key={'additionalEmails_' + index}
                                onChange={handleChange}
                                required
                                name={`additionalEmails[${index}]`}
                                label="E-mail"
                                type="mail"
                            />
                        ))}
                    <Button onClick={handleAddingAdditionalEmail}>
                        Adicionar outro e-mail
                    </Button>
                </NewClientContent>
            </Form>
            <ModalFooter>
                <Button onClick={onClose} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} variant={ButtonVariant.primary}>
                    Cadastrar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
