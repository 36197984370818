import {
    exportClientStockDictionary,
    exportClientStockValueDictionary,
} from '@Balance/Clients/ClientDetails/ClientDetails.utils'
import { useClients } from '@Balance/Clients/Hooks/useClients'
import { useSummary } from '@Balance/Hooks/useSummary/useSummary'
import { StockDocument } from '@Balance/Reports/Pdf/Pdf'
import { useSummaryContext } from '@Balance/Summary/Context/SummaryContext'
import { Container, ContainerHeader } from '@Core/Components'
import { MenuOptions } from '@Core/Components/MenuOptions/MenuOptions'
import { IMenuOption } from '@Core/Components/MenuOptions/MenuOptions.types'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import { usePDF } from '@react-pdf/renderer'
import { useEffect } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { exportStockByClientData } from './BalanceCard.utils'
import { BalanceClientStock } from './components/BalanceClientStock/BalanceClientStock'

export const BalanceCard = () => {
    const { clients } = useClients()
    const {
        selectedClients,
        selectedClientsName,
        handleRemoveClient,
        handleSelectClient,
    } = useSummary({ clients })
    const { stocks } = useSummaryContext()
    const stockDocument = StockDocument({
        title: `Resumo de Saldo`,
        // @ts-ignore
        stocks: exportStockByClientData(stocks),
        withClient: true,
    })
    const [instance, update] = usePDF({
        document: stockDocument
    })

    const exportOptions = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(exportStockByClientData(stocks), `Resumo de Saldo`, {
                    columnsDict: exportClientStockDictionary,
                    valuesDict: exportClientStockValueDictionary,
                    sheetName: 'Saldo',
                })
            },
        },
        {
            label: 'Exportar para PDF',
            href: instance.url,
            attrs: {
                download: `Resumo de Saldo.pdf`,
                target: '_blank',
            },
        },
    ]

    useEffect(() => {
        update(stockDocument)
    }, [stocks])

    return (
        <Container>
            <ContainerHeader>
                <h2>Resumo</h2>
                <RightContent>
                    {exportOptions && (
                        <MenuOptions
                            data-testid="stock-share"
                            menuList={exportOptions as IMenuOption[]}
                        >
                            <TfiExport />
                        </MenuOptions>
                    )}
                </RightContent>
            </ContainerHeader>
            <Grid container spacing={1}>
                <Grid item lg={12}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                        <Select
                            data-testid="client-select"
                            fullWidth
                            inputProps={{
                                fullWidth: true,
                                name: 'client',
                                id: 'demo-mutiple-name',
                            }}
                            value={selectedClientsName}
                            onChange={handleSelectClient}
                            multiple
                            displayEmpty
                            renderValue={(selected) => {
                                // @ts-ignore
                                if (selected.length === 0) {
                                    return (
                                        <em>Selecione um ou mais clientes</em>
                                    )
                                }
                                // @ts-ignore
                                return selected.join(', ')
                            }}
                        >
                            {clients.map((client, index) => (
                                <MenuItem
                                    data-testid={`client-select-option-${index}`}
                                    key={client._id}
                                    value={client.corporateName}
                                >
                                    {client.corporateName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {selectedClients.map((client) => (
                    <Grid container item lg={12} key={client?._id}>
                        <BalanceClientStock
                            client={client}
                            onRemove={handleRemoveClient}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
