import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import {
    InventoryExitsContainer,
    InventoryExitsRegister,
} from '@InventoryControl/Exits/Data/DataSource/Container'
import { useState } from 'react'

export const useInventoryExits = () => {
    const getExitsUseCase = InventoryExitsContainer.get(
        InventoryExitsRegister.UseCaseGetExits
    )
    const [exits, setExits] = useState<DBInventoryExits[]>([])
    const [loading, setLoading] = useState(false)

    const getExits = async () => {
        setLoading(true)
        try {
            const exits = await getExitsUseCase.execute()
            setExits(exits)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }
    return { exits, loading, getExits }
}
