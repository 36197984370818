import {
    logoutAction,
    setIsAuthenticationFinishedAction,
    setIsLoggedAction,
    setIsMSAction,
    setIsSessionExpiredAction,
} from '@Core/Communication/GlobalStore/Reducers/authentication/reducer'
import { isTokenExpired } from '@Utils/Support/expiredJWT'
import {
    OAuthProvider,
    getAuth,
    signInWithEmailAndPassword,
    signInWithPopup,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
export const useAuth = (
    storage: Storage,
    provider: any,
    sessionRenew: boolean = false
) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(1)
    const [user, setUser] = useState<any>({})
    provider.setCustomParameters({
        tenant: import.meta.env.VITE_AUTH_DOMAIN_TENANT,
        prompt: 'consent',
    })

    const authenticate = () => {
        setLoading(true)
        const auth = getAuth()
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const credential = OAuthProvider.credentialFromResult(result)
                const accessToken = credential?.accessToken || ''
                dispatch(setIsMSAction(true))
                handleSetToken(accessToken)
                getIsAuthenticated()
            })
            .catch((error) => {
                console.error(error)
                toast.error(
                    'Ops, não foi possível realizar seu login, tente novamente.'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleSetToken = (accessToken: string) => {
        storage.setItem('@ofc:accessToken', accessToken)
    }

    const getIsAuthenticated = async () => {
        if (!storage.getItem('@ofc:accessToken')) return
        if (isTokenExpired(String(storage.getItem('@ofc:accessToken')))) {
            dispatch(logoutAction())
            return
        }
        dispatch(setIsAuthenticationFinishedAction(true))
        dispatch(setIsLoggedAction(true))
        dispatch(setIsSessionExpiredAction(false))
        if (!sessionRenew) {
            navigate('/')
        }
    }

    const uiAuthenticate = (username: string, password: string) => {
        setLoading(true)
        const auth = getAuth()
        signInWithEmailAndPassword(auth, username, password)
            .then((result) => {
                storage.setItem('@ofc:isMS', 'false')
                dispatch(setIsMSAction(false))
                setUser(auth.currentUser)
                if (!sessionRenew) {
                    navigate('/')
                }
                const credential = auth.currentUser
                const accessToken = credential?.accessToken || ''
                handleSetToken(accessToken)
                dispatch(setIsLoggedAction(true))
                dispatch(setIsSessionExpiredAction(false))
            })
            .catch((error) => {
                console.error(error)
                toast.error(
                    'Ops, não foi possível realizar seu login, tente novamente.'
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getIsAuthenticated()
    }, [])

    return {
        authenticate,
        uiAuthenticate,
        loading,
        step,
        user,
    }
}
