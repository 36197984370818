import { Button, ButtonVariant } from '@Core/Components'
import { Input, SupportMessages } from '@Core/Components/Input/Input'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'

import { useCommercialCreateProduct } from '@Commercial/Products/Hooks/useCommercialCreateProduct/useCommercialCreateProduct'
import { roles } from '@Commercial/roles'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { Select } from '@Core/Components/Select/Select'
import { InputAdornment } from '@material-ui/core'
import { Form } from '@unform/web'
import { NewProductContent } from './NewProductModal.styles'
import { NewProductModalProps } from './NewProductModal.types'

export const typeOptions = [
    {
        label: 'Fixo',
        value: 'default',
    },
    {
        label: 'Dinâmico',
        value: 'dynamic',
    },
    {
        label: 'RollerClip',
        value: 'rollerClip',
    },
    {
        label: 'Brinde',
        value: 'gift',
    },
]

export const NewProductModal = ({
    isOpen,
    setIsOpen,
    callback,
}: NewProductModalProps) => {
    const { formRef, handleCreateProduct, handleChange, state, loading } =
        useCommercialCreateProduct()

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    const handleSubmit = async () => {
        handleCreateProduct(() => {
            toggleModal()
            callback?.()
        })
    }

    const markupError =
        state?.markup < Number(state?.minMarkup) ||
        state?.markup > Number(state?.maxMarkup)

    const isDefault = state?.productType === 'default'

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h2>Novo Produto</h2>
                <NewProductContent>
                    {loading && <Loader />}
                    {!loading && (
                        <Grid container>
                            <Grid item lg={12}>
                                <Input
                                    name={'name'}
                                    label="Nome"
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <Select
                                    label="Tipo"
                                    required
                                    options={typeOptions}
                                    onChange={handleChange}
                                    value={state?.productType}
                                    name={`productType`}
                                />
                            </Grid>
                            {isDefault && (
                                <>
                                    <PermissionWrapper
                                        permission={
                                            roles.product.permissions.update
                                                .permissions.cost.key
                                        }
                                    >
                                        <Grid item lg={12}>
                                            <Input
                                                name={'unitCost'}
                                                label="Custo Unitário"
                                                onChange={handleChange}
                                                type="number"
                                                step={0.01}
                                                InputProps={{
                                                    inputProps: {
                                                        step: 0.01,
                                                    },
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            R$
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </PermissionWrapper>
                                    <PermissionWrapper
                                        permission={
                                            roles.product.permissions.update
                                                .permissions.markup.key
                                        }
                                    >
                                        <Grid item lg={12}>
                                            <fieldset>
                                                <legend>Markup Mínimo</legend>
                                                <Grid container lg={12}>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'minMarkup'}
                                                            label="Markup"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="number"
                                                            InputProps={{
                                                                min: 0,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={
                                                                'minUnitPrice'
                                                            }
                                                            label="Venda unitária"
                                                            disabled
                                                            value={
                                                                state.minUnitPrice
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'minProfit'}
                                                            label="Lucro"
                                                            disabled
                                                            value={
                                                                state.minProfit
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={
                                                                'minMarkupFinal'
                                                            }
                                                            label="Markup Final"
                                                            disabled
                                                            value={
                                                                state.minMarkupFinal
                                                            }
                                                            type="number"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <fieldset>
                                                <legend>Markup Ideal</legend>
                                                <Grid container lg={12}>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'markup'}
                                                            label="Markup"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="number"
                                                            error={markupError}
                                                            supportMessages={[
                                                                {
                                                                    message:
                                                                        markupError
                                                                            ? `O Valor deve ser maior que ${Number(
                                                                                  state?.minMarkup
                                                                              )} e menor que ${Number(
                                                                                  state?.maxMarkup
                                                                              )}`
                                                                            : '',
                                                                } as SupportMessages,
                                                            ]}
                                                            InputProps={{
                                                                min: state.minMarkup,
                                                                max: state.maxMarkup,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'unitPrice'}
                                                            label="Venda unitária"
                                                            disabled
                                                            value={
                                                                state.unitPrice
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'profit'}
                                                            label="Lucro"
                                                            disabled
                                                            value={state.profit}
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'markupFinal'}
                                                            label="Markup Final"
                                                            disabled
                                                            value={
                                                                state.markupFinal
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <fieldset>
                                                <legend>Markup Máximo</legend>
                                                <Grid container lg={12}>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'maxMarkup'}
                                                            label="Markup"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            type="number"
                                                            InputProps={{
                                                                min: 0,
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={
                                                                'maxUnitPrice'
                                                            }
                                                            label="Venda unitária"
                                                            disabled
                                                            value={
                                                                state.maxUnitPrice
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={'maxProfit'}
                                                            label="Lucro"
                                                            disabled
                                                            value={
                                                                state.maxProfit
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    (
                                                                        <InputAdornment position="start">
                                                                            R$
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={3}>
                                                        <Input
                                                            name={
                                                                'maxMarkupFinal'
                                                            }
                                                            label="Markup Final"
                                                            disabled
                                                            value={
                                                                state.maxMarkupFinal
                                                            }
                                                            type="number"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </fieldset>
                                        </Grid>
                                    </PermissionWrapper>
                                </>
                            )}
                        </Grid>
                    )}
                </NewProductContent>
                <ModalFooter>
                    <Button
                        onClick={toggleModal}
                        variant={ButtonVariant.secondary}
                        type="reset"
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" variant={ButtonVariant.primary}>
                        Cadastrar
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
