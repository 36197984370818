import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { roles } from '@InventoryControl/roles'
import { Form } from '@unform/web'
import { useInventoryDestinationManager } from '../Hooks/useInventoryDestinationManager/useInventoryDestinationManager'

export const DestinationDetails = () => {
    const { handleEditDestination, state, loading } =
        useInventoryDestinationManager()
    const { handleChange, handleSubmit } = handleEditDestination()

    if (loading && state?._id) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{state?.name}</h1>
                <div>
                    <Grid flex flexEnd>
                        <PermissionWrapper
                            permission={
                                roles.destinations.permissions.update.key
                            }
                        >
                            <Button
                                onClick={handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </Grid>
                </div>
            </ContainerHeader>

            <Form onSubmit={() => {}} className="mt-2">
                <Grid container item lg={12}>
                    <Grid item lg={12}>
                        {state?.name && (
                            <Input
                                name={'name'}
                                onChange={handleChange}
                                label="Nome"
                                value={state?.name}
                            />
                        )}
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
