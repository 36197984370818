import { Clients } from '@Balance/Clients/Domain/Model/Clients'
import { useSummaryContext } from '@Balance/Summary/Context/SummaryContext'
import { useState } from 'react'

export const useSummary = ({ clients }: { clients: Clients[] }) => {
    const [selectedClients, setSelectedClients] = useState<Clients[]>([])
    const [selectedClientsName, setSelectedClientsName] = useState<string[]>([])
    const { setStocks } = useSummaryContext()

    const handleSelectClient = (e: any) => {
        const { value } = e?.target
        if (value.length === 0) {
            setSelectedClients([])
            setSelectedClientsName([])
            return
        }
        let clientNames: string[] = []
        const clientSelected = value.map((client: string | Clients) => {
            if (typeof client == 'string') {
                clientNames.push(client)
                return clients?.find(
                    (c: Clients) => c?.corporateName === client
                ) as Clients
            }
            clientNames.push(client.corporateName)
            return clients?.find(
                (c: Clients) => c?.corporateName === client.corporateName
            ) as Clients
        })
        setSelectedClientsName([...clientNames])
        setSelectedClients([...clientSelected])
    }

    const handleRemoveClient = (client: Clients) => {
        const newClients = selectedClients.filter((c) => c._id !== client._id)
        const newClientsName = selectedClientsName.filter(
            (c) => c !== client.corporateName
        )
        setStocks((prev) => {
            // @ts-ignore
            delete prev?.[client._id]
            return prev
        })
        setSelectedClients([...newClients])
        setSelectedClientsName([...newClientsName])
    }

    return {
        handleSelectClient,
        handleRemoveClient,
        selectedClients,
        selectedClientsName,
    }
}
