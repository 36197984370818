import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { InventoryProductsRepository } from '@InventoryControl/Products/Data/Repository/ProductRepository'

export interface GetProducts {
    execute(): Promise<DBInventoryProduct[]>
    getSource(): string
}

export class GetProductsUseCase implements GetProducts {
    constructor(private repository: InventoryProductsRepository) {}
    async execute(): Promise<DBInventoryProduct[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
