/**
 * Removes leading zeros from a numeric string and formats it with a decimal separator.
 * 
 * @param inputString - The numeric string to be formatted.
 * @returns The formatted string without leading zeros.
 */
export function removeLeadingZeros(inputString: string): string {
  if (!inputString) {
    return '';
  }
  const formattedString: string = String(inputString).replace(',', '.');
  const hasDecimal: boolean = formattedString.includes('.');

  const [integerPart, decimalPart]: string[] = formattedString.split('.');

  const trimmedIntegerPart: string = String(Number(integerPart));
  const result: string = hasDecimal
    ? `${trimmedIntegerPart}.${decimalPart}`
    : trimmedIntegerPart;

  return result;
}