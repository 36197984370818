import { CommercialContextProvider } from '@Commercial/Context/CommercialContextProvider'
import { CookiesStorage } from '@Core/Communication/GlobalStore/CookiesStorage/CookiesStorage'
import { useSession } from '@Core/Hooks/useSession'
import { useUser } from '@Core/Hooks/useUser'
import { Header } from '@Entry/Common/Header/Header'
import { RoutesPaths } from '@Entry/Common/RoutesPaths'
import { Sidebar } from '@Entry/Common/Sidebar/Sidebar'
import { SessionExpired } from '@Entry/SessionExpired/SessionExpired'
import { Outlet, useLocation } from 'react-router-dom'
import { EntryLayout, Main } from './EntryRoot.styles'

const NoPadding = [RoutesPaths.Home]

export const EntryRoot = () => {
    const { user, photo } = useUser()
    const { pathname } = useLocation()
    useSession(new CookiesStorage())

    const noPadding = NoPadding.indexOf(pathname) > -1

    return (
        <CommercialContextProvider>
            <EntryLayout>
                <SessionExpired />
                <Header user={{ ...user, photo }} />
                <Sidebar />
                <Main noPadding={noPadding}>
                    <Outlet
                        context={[
                            {
                                user,
                                photo,
                            },
                        ]}
                    />
                </Main>
            </EntryLayout>
        </CommercialContextProvider>
    )
}
