import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const CordTypeEmpty = {
    _id: crypto.randomUUID(),
    name: '',
    isNew: true,
    table: {
        columns: [
            {
                key: crypto.randomUUID(),
                value: 'Especificação',
            },
        ],
        rows: [
            [
                {
                    key: crypto.randomUUID(),
                    value: '',
                },
            ],
        ],
    },
}

export const useCordTypes = ({ product, refresh }) => {
    const [cordTypes, setCordTypes] = useState<(typeof CordTypeEmpty)[]>([])

    const deleteCordTypeContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseDeleteCordType
    )
    const handleAddCordType = () => {
        setCordTypes((prev) => [...prev, { ...CordTypeEmpty }])
    }

    const handleRemoveCordType = useCallback(
        (index: number) => async () => {
            const element = cordTypes[index]
            if (!element?.isNew) {
                setCordTypes((prev) => [
                    ...prev.slice(0, index),
                    ...prev.slice(index + 1),
                ])
                await deleteCordTypeContainer.execute(element._id)
                await refresh()
                toast.error('Tipo deletado')
                getCords()
            }
        },
        [product]
    )

    const getCords = useCallback(async () => {
        setCordTypes(product.cordType)
    }, [product])

    useEffect(() => {
        getCords()
    }, [product])

    return {
        cordTypes,
        handleAddCordType,
        handleRemoveCordType,
        refresh: getCords,
    }
}
