import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { SidebarItemProps, SidebarProps } from './Sidebar.types'

export const SidebarContainer = styled.div<SidebarProps>`
    position: relative;
    grid-area: sidebar;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    background-color: ${({ theme }) => theme.sidebarColor};
    border: 1px solid ${({ theme }) => theme.sidebarColor};
    color: ${({ theme }) => theme.text};
    max-width: 250px;
    transition: max-width 0.3s;
    > * .sidebar-text {
        overflow: hidden;
        min-width: 150px;
        text-overflow: ellipsis;
    }
    ${({ collapsed }) =>
        collapsed &&
        css`
            max-width: 0px;
            min-width: 60px;
            border: 'none';
            overflow: 'hidden';
            > * .sidebar-text {
                display: none;
            }
            > * .sidebar-item {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        `}
    @media (max-width: 768px) {
        max-width: 100vw;
        ${({ collapsed }) =>
            collapsed && {
                maxWidth: 0,
                minWidth: 0,
                border: 'none',
                overflow: 'hidden',
            }}
    }
`
export const SidebarContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 20px;
`

export const SidebarItem = styled(NavLink)<SidebarItemProps>`
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    min-height: 24px;
    transition: all 0.3s;
    background-color: ${({ active }) =>
        (active && `rgba(0, 0, 0, 0.1)`) || `transparent`};
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    list-style: none;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .rotate-180 {
        transform: rotate(180deg);
    }
`
