// @ts-nocheck
import { formatCurrency } from '@Utils/Formatters/currency'
import { DBInventorySupplier } from '../Data/DataSource/API/Entity/Supplier'

export const exportInventoryDictionary = (item: any) =>
    ({
        _id: 'ID',
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        contact: 'Contato',
        totalPurchases: 'Total Comprado',
        __v: '',
    }[item] || item)

export const exportInventoryValueDictionary = (
    key: DBInventorySupplier,
    item: DBInventorySupplier[key]
) => {
    return (
        {
            _id: { value: item, type: 'string' },
            name: { value: item, type: 'string' },
            phone: { value: item, type: 'string' },
            email: { value: item, type: 'number' },
            totalPurchases: {
                value: formatCurrency(item) ?? 'N/D',
                type: 'string',
            },
            contact: {
                value: item,
                type: 'string',
            },
            __v: { value: item, type: 'number' },
        }[key] || { value: item, type: 'string' }
    )
}

export const exportInventorySupplierValueDictionary = (
    key: DBInventorySupplier,
    item: DBInventorySupplier[key]
) => item

export const exportSupplierData = (data: DBInventorySupplier[]) => {
    const columns = [
        '_id',
        'name',
        'phone',
        'email',
        'contact',
        'totalPurchases',
    ]
    const rows = data.map((item: DBInventorySupplier) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportInventoryValueDictionary(
                column,
                item[column],
                item
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportInventoryData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
    }))
