import {
    CreateEntryUseCase,
    DeleteEntryUseCase,
    GetEntriesUseCase,
    GetEntryUseCase,
    UpdateEntryUseCase,
} from '@InventoryControl/Entries/Domain/UseCases'
import { Container } from 'inversify'
import { InventoryEntryRepository } from '../../Repository/EntryRepository'
import { EntryAPI } from '../API/EntryAPI'
import { Register } from './EntryRegister'

const container = new Container()
container
    .bind<GetEntryUseCase>(Register.UseCaseGetEntry)
    .toDynamicValue(
        () => new GetEntryUseCase(new InventoryEntryRepository(new EntryAPI()))
    )
container
    .bind<GetEntriesUseCase>(Register.UseCaseGetEntries)
    .toDynamicValue(
        () =>
            new GetEntriesUseCase(new InventoryEntryRepository(new EntryAPI()))
    )

container
    .bind<CreateEntryUseCase>(Register.UseCaseCreateEntry)
    .toDynamicValue(
        () =>
            new CreateEntryUseCase(new InventoryEntryRepository(new EntryAPI()))
    )
container
    .bind<UpdateEntryUseCase>(Register.UseCaseUpdateEntry)
    .toDynamicValue(
        () =>
            new UpdateEntryUseCase(new InventoryEntryRepository(new EntryAPI()))
    )
container
    .bind(Register.UseCaseDeleteEntry)
    .toDynamicValue(
        () =>
            new DeleteEntryUseCase(new InventoryEntryRepository(new EntryAPI()))
    )

export default container
