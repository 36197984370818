import styled from 'styled-components'
export const EntryLayout = styled.div`
    display: grid;
    grid-template-columns: fit-content(250px) 4fr;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        'header header'
        'sidebar main';
    height: 100vh;
`
export const Main = styled.div<{noPadding: boolean}>`
    ${( { noPadding } ) => !noPadding && 'padding: 0 15px;'};
    grid-area: main;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
`
