import { RootState } from '@Core/Communication'
import { Loader } from '@Core/Components/Loader/Loader'
import { LoaderFullScreen } from '@Core/Components/Loader/Loader.styles'
import Routes from '@Core/Routes/Routes'
import { darkTheme } from '@Core/Styles/Themes/Dark'
import { lightTheme } from '@Core/Styles/Themes/Light'
import { materialTheme } from '@Core/Styles/Themes/material.theme'
import '@knocklabs/react-notification-feed/dist/index.css'
import {
    ThemeProvider as MuiThemeProvider,
    StylesProvider,
    createTheme,
} from '@material-ui/core/styles'
import { setDefaultOptions } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { fetchAndActivate } from 'firebase/remote-config'
import LogRocket from 'logrocket'
import { useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './GlobalStyle'
import { ThemeProvider as ShadchThemeProvider } from './components/theme-provider'
import './utils/firebase'
import { remoteConfig } from './utils/remoteConfig'

setDefaultOptions({
    locale: ptBR,
})

function App() {
    const { theme } = useSelector((state: RootState) => state.appConfig)
    const [pathname, setPathname] = useState(window.location.pathname)
    const [loading, setLoading] = useState(true)

    const isDarkMode = useMemo(
        () => theme === 'dark' && pathname !== '/login',
        [theme, pathname]
    )
    const muiTheme = useMemo(
        () =>
            createTheme({
                ...materialTheme[isDarkMode ? 'dark' : 'light'],
            }),
        [isDarkMode]
    )

    const initRemoteConfig = async () => {
        fetchAndActivate(remoteConfig).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        if (process.env.NODE_ENV !== 'development') {
            LogRocket.init('buzato/odhub')
        }
        initRemoteConfig()
        history.pushState = ((f) =>
            function pushState() {
                var ret = f.apply(this, arguments)
                setPathname(arguments[2])
                return ret
            })(history.pushState)
    }, [])

    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={!isDarkMode ? lightTheme : darkTheme}>
                    <ShadchThemeProvider
                        defaultTheme="light"
                        storageKey="vite-ui-theme"
                    >
                        <GlobalStyles />
                        <div className="App">
                            {loading && (
                                <LoaderFullScreen>
                                    <Loader />
                                </LoaderFullScreen>
                            )}
                            {!loading && <Routes />}
                        </div>
                        <ToastContainer theme={isDarkMode ? 'dark' : 'light'} />
                    </ShadchThemeProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    )
}

export default App
