import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import {
    InventoryDestinationContainer,
    InventoryDestinationRegister,
} from '@InventoryControl/Destinations/Data/DataSource/Container'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useInventoryDestinationManager = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { id } = useParams<{ id: string }>()
    const [state, setState] = useState<DBInventoryDestination>(
        {} as DBInventoryDestination
    )
    const getDestinationUseCase = InventoryDestinationContainer.get(
        InventoryDestinationRegister.UseCaseGetDestination
    )
    const updateDestinationUseCase = InventoryDestinationContainer.get(
        InventoryDestinationRegister.UseCaseUpdateDestination
    )

    const handleEditDestination = () => {
        const handleChange = (e: any) => {
            const { name, value } = e.target
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        const handleSubmit = async () => {
            setLoading(true)
            try {
                await updateDestinationUseCase.execute(state)
                toast.success('Destino atualizado com sucesso')
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar destino')
            }
            setLoading(false)
        }
        return { handleChange, handleSubmit }
    }

    const getDestination = async () => {
        setLoading(true)
        try {
            const destination = await getDestinationUseCase.execute(String(id))
            setState(destination)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!id) return
        getDestination()
    }, [id])

    return { state, getDestination, loading, handleEditDestination }
}
