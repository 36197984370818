import styled from 'styled-components'

export const UserPicture = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 10px;
`

export const UserProfileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-weight: 500;
    :hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
    }
`

export const ProfileMenuContainer = styled.div`
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #fff;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 120px;
    border-radius: 0px 0 5px 5px;
    p {
        margin: 5px 0;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: 0.3s;
        :hover {
            cursor: pointer;
            color: #007bff;
        }
    }
`

export const UserName = styled.div`
    color: ${({ theme }) => theme.text};
    @media (max-width: 768px) {
        display: none;
    }
`

export const UpdateContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: fixed;
    top: 65px;
    right: 10px;
    background-color: #fff;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 2;
    .close {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`
