import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Destination } from '@InventoryControl/Destinations/entities/Destination'
import { DBInventoryDestination } from '../DataSource/API/Entity/Destination'
import DestinationsDataSource from '../DataSource/DestinationsDataSource'

export class InventoryDestinationsRepository
    implements BaseRepository<DBInventoryDestination, Destination>
{
    dataSource: DestinationsDataSource
    constructor(_dataSource: DestinationsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBInventoryDestination[]> {
        return this.dataSource.getDestinations()
    }
    async findById(id: string): Promise<DBInventoryDestination> {
        return this.dataSource.getDestinationById(id)
    }
    async search(query: string): Promise<DBInventoryDestination[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Destination): Promise<DBInventoryDestination> {
        return this.dataSource.createDestination(entity)
    }
    async update(
        entity: DBInventoryDestination
    ): Promise<DBInventoryDestination> {
        return this.dataSource.updateDestination(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteDestination(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
