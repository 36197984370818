import styled from 'styled-components'

export const HeaderContainer = styled.div`
    position: relative;
    grid-area: header;
    display: flex;
    width: 100vw;
    background-color: ${({ theme }) => theme.headerColor};
    height: 60px;
    align-items: center;
    color: #111;
    justify-content: space-between;
    z-index: 10;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
`

export const Logo = styled.img`
    height: 80%;
    max-height: 40px;
    position: relative;
`

export const HamburgerMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
`

export const LeftContent = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

export const RightContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

export const ThemeSwitcher = styled.div`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0 10px;
    @media (max-width: 768px) {
        margin: 0 5px;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            90deg,
            rgba(37, 37, 17, 1) 0%,
            rgba(17, 17, 217, 1) 100%
        );
        -webkit-transition: 0.4s;
        transition: all 0.4s;
    }
    .slider:before {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input + .slider:before {
        background-color: #fbc22c;
        box-shadow: 5px 0px 0px -1px rgba(251, 194, 44, 0.62);
    }
    input:checked + .slider:before {
        background-color: #f5f5f5;
        box-shadow: -5px 0px 0px -1px rgba(255, 255, 255, 0.62);
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #111;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
`

const Divider = styled.div`
    width: 1px;
    height: 40px;
    background-color: ${({ theme }) =>
        theme.isDarkMode ? '#fff' : 'rgba(0,0,0,0.3)'};
`

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const VerticalDivider = {
    Divider,
    Container,
}
