import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Product } from '@Commercial/Products/entities/Product'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useCommercialProductManager = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [taxValue, setTaxValue] = useState(10)
    const { id } = useParams<{ id: string }>()
    const [state, setState] = useState<DBCommercialProduct>({
        unitCost: 0,
    } as DBCommercialProduct)
    const getVariablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )
    const getProductUseCase = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseGetProduct
    )
    const updateProductUseCase = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseUpdateProduct
    )

    const getTax = async () => {
        const variables = await getVariablesContainer.execute()
        const { variableValue } = variables.find(
            (item) => item.variableType === 'tax'
        ) || {
            variableValue: 0,
        }
        setTaxValue(Number(variableValue))
    }

    const handleEditProduct = () => {
        const handleInative = async () => {
            const newState = { ...state }
            newState.isInactive = !newState.isInactive
            setState(newState)
            setLoading(true)
            try {
                await updateProductUseCase.execute(newState)
                const msm = newState.isInactive ? 'Desativado' : 'Ativado'
                toast.success(`Produto ${msm} com sucesso`)
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar produto')
            }
            setLoading(false)
        }
        const handleChange = (e: any) => {
            const { name, value } = e.target
            setState((prev) => {
                const newState = {
                    ...prev,
                    [name]: value,
                }
                return calculateMarkups(newState)
            })
        }

        const handleSubmit = async () => {
            setLoading(true)
            const newState = {
                ...state,
            }
            if (newState?.markup > newState?.maxMarkup) {
                newState.markup = newState.maxMarkup
            }
            try {
                await updateProductUseCase.execute(newState)
                toast.success('Produto atualizado com sucesso')
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar produto')
            }
            setLoading(false)
        }
        return { handleChange, handleSubmit, handleInative }
    }

    const getProduct = async () => {
        setLoading(true)
        try {
            const product = await getProductUseCase.execute(String(id))
            const { inventoryProduct } = product
            if (inventoryProduct.length > 0) {
                product.inventoryProduct =
                    inventoryProduct[0] as DBInventoryProduct
            } else {
                product.inventoryProduct = {} as DBInventoryProduct
            }
            const productCalculated = calculateMarkups(
                product as unknown as Product
            )
            setState(productCalculated as unknown as DBCommercialProduct)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const calculateMarkups = (state: Product) => {
        const calculateUnitPrice = (markup: number, unitCost: number) => {
            return ((Number(markup) / 100 + 1) * unitCost * 100) / 100
        }
        const calculateProfit = (unitPrice: number, unitCost: number) => {
            const tax = ((unitPrice || 0) * (taxValue || 0)) / 100
            return Number(unitPrice) - Number(unitCost) - tax
        }
        const calculateMarkup = (profit: number, unitCost: number) => {
            return (profit / unitCost) * 100
        }
        const minUnitPrice = calculateUnitPrice(
            Number(state.minMarkup),
            Number(state.unitCost)
        )
        const unitPrice = calculateUnitPrice(
            Number(state.markup),
            Number(state.unitCost)
        )
        const maxUnitPrice = calculateUnitPrice(
            Number(state.maxMarkup),
            Number(state.unitCost)
        )

        const minProfit = calculateProfit(
            Number(minUnitPrice),
            Number(state.unitCost)
        )
        const profit = calculateProfit(
            Number(unitPrice),
            Number(state.unitCost)
        )
        const maxProfit = calculateProfit(
            Number(maxUnitPrice),
            Number(state.unitCost)
        )

        const minMarkupFinal = calculateMarkup(
            Number(minProfit),
            Number(state.unitCost)
        )
        const markupFinal = calculateMarkup(
            Number(profit),
            Number(state.unitCost)
        )
        const maxMarkupFinal = calculateMarkup(
            Number(maxProfit),
            Number(state.unitCost)
        )
        return {
            ...state,
            unitPrice: formatCurrency(unitPrice),
            profit: formatCurrency(profit),
            markupFinal,
            minProfit: formatCurrency(minProfit),
            maxProfit: formatCurrency(maxProfit),
            minMarkupFinal: Number(minMarkupFinal).toFixed(0),
            maxMarkupFinal: Number(maxMarkupFinal).toFixed(0),
            minUnitPrice: formatCurrency(minUnitPrice),
            maxUnitPrice: formatCurrency(maxUnitPrice),
        }
    }

    useEffect(() => {
        getTax()
    }, [])

    useEffect(() => {
        if (!id) return
        getProduct()
    }, [id])

    return { state, getProduct, loading, handleEditProduct }
}
