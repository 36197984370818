import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { roles } from '@InventoryControl/roles'
import { InputAdornment } from '@material-ui/core'
import { Form } from '@unform/web'
import { useInventorySupplierManager } from '../Hooks/useInventorySupplierManager/useInventorySupplierManager'

export const SupplierDetails = () => {
    const { handleEditSupplier, state, loading } = useInventorySupplierManager()
    const { handleChange, handleSubmit } = handleEditSupplier()

    if (loading && !state?._id) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{state?.name}</h1>
                <div>
                    <Grid flex flexEnd>
                        <PermissionWrapper
                            permission={roles.suppliers.permissions.update.key}
                        >
                            <Button
                                onClick={handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </Grid>
                </div>
            </ContainerHeader>

            <Form onSubmit={() => {}} className="mt-2">
                <Grid container item lg={12}>
                    <Grid item lg={12}>
                        <Input
                            name={'name'}
                            onChange={handleChange}
                            label="Nome"
                            value={state?.name}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            name={'contact'}
                            onChange={handleChange}
                            label="Contato"
                            value={state?.contact}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <Input
                            name={'phone'}
                            onChange={handleChange}
                            type="phone"
                            label="Telefone"
                            value={state?.phone}
                        />
                    </Grid>
                    <Grid item lg={12}>
                        <Input
                            name={'email'}
                            onChange={handleChange}
                            type="email"
                            label="E-mail"
                            value={state?.email}
                        />
                    </Grid>
                    <Grid item lg={12}>
                        <Input
                            name={'totalPurchases'}
                            onChange={handleChange}
                            type="totalPurchases"
                            label="Total Comprado"
                            value={state?.totalPurchases}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
