import styled from 'styled-components'

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    tr.head {
        background-color: ${({ theme }) => theme.colors.table.hoverRow};
    }
    th {
        padding: 10px;
        & > * {
            margin: 0 5px;
        }
    }
    tfoot {
        padding: 10px;
        tr {
            td {
                padding: 10px;
                text-align: center;
                font-weight: 500;
                gap: 10px;
                div {
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                }
                ul {
                    display: flex;
                    list-style: none;
                    gap: 10px;
                    margin: 0;
                    padding: 0;
                }
                li {
                    display: flex;
                    gap: 10px;
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
    tbody {
        tr {
            &:nth-child(odd) {
                background-color: ${({ theme }) => theme.colors.table.oddRow};
            }
            &:hover {
                background-color: ${({ theme }) => theme.colors.table.hoverRow};
            }
            td {
                padding: 10px;
                text-align: center;
                font-weight: 500;
                gap: 10px;
                & > * {
                    margin: 0 5px;
                }
                a {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: ${({ theme }) => theme.colors.primary};
                    i {
                        justify-content: flex-end;
                        align-items: center;
                        margin-left: 5px;
                    }
                }
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        td[data-first-col='true'] {
            border-left: 1px solid rgba(0, 0, 0, 0.5);
        }
        td[data-first-row='true'] {
            border-top: 1px solid rgba(0, 0, 0, 0.5);
        }
        tr[data-clickable] {
            cursor: pointer;
        }
        tr[data-inactive='true'],
        tr[data-danger='true'] {
            background-color: ${({ theme }) => theme.colors.table.inactiveRow};
        }
        td[data-status='warning'] {
            background-color: ${({ theme }) => theme.colors.table.warningRow};
        }
        td[data-status='danger'] {
            background-color: ${({ theme }) => theme.colors.table.dangerRow};
        }
        td[data-status='success'] {
            background-color: ${({ theme }) => theme.colors.table.successRow};
        }
        td[data-status='warning'].opacity {
            background-color: rgba(220, 200, 0, 0.1);
        }
        td[data-status='danger'].opacity {
            background-color: rgba(255, 0, 50, 0.1);
        }
        td[data-status='success'].opacity {
            background-color: rgba(0, 255, 0, 0.1);
        }
    }
`
