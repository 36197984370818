import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'
import { useDeleteMovement } from '@Movements/Hooks/useDeleteMovement'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const DeleteModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean
    onClose?: () => void
}) => {
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const {
        state: { redirectPath },
    } = useLocation()
    const { deleteMovement, loading } = useDeleteMovement({
        id: String(id ?? ''),
        onCompleted: () => {
            onClose?.()
            toast.success('Movimentação deletada com sucesso!')
            navigate(redirectPath)
        },
    })

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2>Você tem certeza que gostaria de deletar esta movimentação?</h2>
            <ul>
                <li>Ao deletar o saldo do cliente será revertido</li>
                <li>Está ação não pode ser desfeita.</li>
            </ul>
            <ModalFooter>
                <Button onClick={onClose} variant={ButtonVariant.primary}>
                    Cancelar
                </Button>
                <Button
                    onClick={() => deleteMovement()}
                    variant={ButtonVariant.secondary}
                    disabled={loading}
                >
                    {loading ? 'Aguarde...' : 'Deletar'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
