import { Client } from '@Clients/Data/DataSource/API/Entity/Client'
import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'
import { Clients } from '../Model/Clients'

export interface CreateClient {
    execute(client: Client): Promise<Clients>
    getSource(): string
}

export class CreateClientUseCase implements CreateClient {
    constructor(private repository: ClientsRepository) {}
    async execute(client: Client): Promise<Clients> {
        return this.repository.create(client)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
