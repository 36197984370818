import { RightContent } from '@Entry/Common/Header/Header.styles'
import { dateFormatter } from '@Movements/Utils/Movements.utils'
import { Key } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { Grid } from '../Grid/Grid'
import { MenuOptions } from '../MenuOptions/MenuOptions'
import { Table } from '../Table/Table'
import { StockTableProps } from './StockTable.types'

export const StockTable = ({
    stocks,
    exportOptions,
    showColumns: showColumns = [],
}: StockTableProps) => (
    <>
        <Grid container item lg={12}>
            <Grid item lg={8}>
                <h3 className="font-bold">Saldo</h3>
            </Grid>
            <Grid flex flexEnd item lg={4}>
                <RightContent>
                    {Boolean(stocks?.length) && exportOptions && (
                        <MenuOptions
                            data-testid="stock-share"
                            menuList={exportOptions}
                        >
                            <TfiExport />
                        </MenuOptions>
                    )}
                </RightContent>
            </Grid>
        </Grid>
        <Grid item lg={12}>
            <Table>
                <thead>
                    <tr>
                        {showColumns.includes('client') && <th>Cliente</th>}
                        <th>Produto</th>
                        <th>Total Entrada</th>
                        <th>Total Saída</th>
                        <th>Saldo</th>
                        <th>Última Entrada</th>
                        <th>Última Saída</th>
                    </tr>
                </thead>
                <tbody>
                    {stocks &&
                        stocks.map((stock) => (
                            <tr
                                key={stock.product?.[0]?._id as Key}
                                data-danger={stock.amount < 0}
                            >
                                {showColumns.includes('client') && (
                                    <td>{stock?.client?.[0]?.corporateName}</td>
                                )}
                                <td>{stock.product?.[0]?.name}</td>
                                <td>
                                    {stock.product?.[0].inputTotal?.[0]
                                        ?.total || 0}
                                </td>
                                <td>
                                    {stock.product?.[0].outTotal?.[0]?.total ||
                                        0}
                                </td>
                                <td>{stock.amount}</td>
                                <td>
                                    {(stock.product?.[0].lastInput?.[0]?.date &&
                                        dateFormatter(
                                            new Date(
                                                stock.product?.[0].lastInput?.[0]?.date
                                            )
                                        )) ||
                                        'N/A'}
                                </td>
                                <td>
                                    {(stock.product?.[0].lastOut?.[0]?.date &&
                                        dateFormatter(
                                            new Date(
                                                stock.product?.[0].lastOut?.[0]?.date
                                            )
                                        )) ||
                                        'N/A'}
                                </td>
                            </tr>
                        ))}
                    {!stocks?.length && (
                        <tr>
                            <td colSpan={6}>Nenhum produto com saldo</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Grid>
    </>
)
