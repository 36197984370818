import { SummaryProvider } from '@Balance/Summary/Context/SummaryContext'
import { Outlet } from 'react-router-dom'

export const BalanceRoot = () => {
    return (
        <SummaryProvider>
            <Outlet />
        </SummaryProvider>
    )
}
