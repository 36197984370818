import {
    CreateExitsUseCase,
    DeleteExitsUseCase,
    GetExitsUseCase,
    GetExitUseCase,
} from '@InventoryControl/Exits/Domain/UseCases'
import { Container } from 'inversify'
import { UpdateExitUseCase } from '../../../Domain/UseCases/UpdateExits'
import { InventoryExitsRepository } from '../../Repository/ExitsRepository'
import { ExitAPI } from '../API/ExitsAPI'
import { Register } from './ExitsRegister'

const container = new Container()
container
    .bind<GetExitUseCase>(Register.UseCaseGetExit)
    .toDynamicValue(
        () => new GetExitUseCase(new InventoryExitsRepository(new ExitAPI()))
    )
container
    .bind<GetExitsUseCase>(Register.UseCaseGetExits)
    .toDynamicValue(
        () => new GetExitsUseCase(new InventoryExitsRepository(new ExitAPI()))
    )

container
    .bind<CreateExitsUseCase>(Register.UseCaseCreateExit)
    .toDynamicValue(
        () =>
            new CreateExitsUseCase(new InventoryExitsRepository(new ExitAPI()))
    )
container
    .bind<UpdateExitUseCase>(Register.UseCaseUpdateExit)
    .toDynamicValue(
        () => new UpdateExitUseCase(new InventoryExitsRepository(new ExitAPI()))
    )
container
    .bind(Register.UseCaseDeleteExit)
    .toDynamicValue(
        () =>
            new DeleteExitsUseCase(new InventoryExitsRepository(new ExitAPI()))
    )

export default container
