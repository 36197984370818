import {
    CreateEntryUseCase,
    DeleteEntryUseCase,
    GetEntriesUseCase,
    GetEntryUseCase,
    UpdateEntryUseCase,
} from '@InventoryControl/Entries/Domain/UseCases'

export const Register = {
    UseCaseGetEntries: GetEntriesUseCase,
    UseCaseGetEntry: GetEntryUseCase,
    UseCaseCreateEntry: CreateEntryUseCase,
    UseCaseUpdateEntry: UpdateEntryUseCase,
    UseCaseDeleteEntry: DeleteEntryUseCase,
}
