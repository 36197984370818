import {
    InventoryEntriesContainer,
    InventoryEntriesRegister,
} from '@InventoryControl/Entries/Data/DataSource/Container'
import { Entry, entrySchema } from '@InventoryControl/Entries/entities/Entry'
import { generateRandomId } from '@Utils/Mocks/generateRandomId'
import LogRocket from 'logrocket'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { EntriesStack } from './useInventoryCreateEntry.types'
// @ts-ignore
export const useInventoryCreateEntry = ({ setFormValue }) => {
    const [created, setCreated] = useState<string[]>([])
    const [failed, setFailed] = useState<string[]>([])
    const [state, setState] = useState<EntriesStack[]>([
        {
            id: generateRandomId(),
            created_at: new Date(),
        },
    ] as EntriesStack[])

    const [loading, setLoading] = useState(false)
    const createEntryUseCase = InventoryEntriesContainer.get(
        InventoryEntriesRegister.UseCaseCreateEntry
    )

    const handleAddingEntry = () => {
        setState(
            (prev) =>
                [
                    ...prev,
                    {
                        id: generateRandomId(),
                        created_at: new Date(),
                    } as unknown as Entry,
                ] as EntriesStack[]
        )
    }

    const handleChange = useCallback(
        (e: any) => {
            const { value, name, id } = e.target
            const index = state.findIndex((item) => item.id === id)
            if (index === -1) return
            setState((prev) => {
                const newState = [...prev]
                newState[index] = {
                    ...newState[index],
                    [name]: value,
                }
                return newState
            })
            if (name.includes('quantity') || name.includes('unitCost')) {
                handleCalculate(id)()
            }
        },
        [state]
    )

    const removeId = (name: string) => name?.split('-').shift()

    const clearFields = (item: EntriesStack): Entry => {
        const fields = Object.keys(item)
        const newFields = fields.reduce((prev, curr) => {
            if (curr === 'id') {
                return prev
            }
            return {
                // @ts-ignore
                ...prev,
                // @ts-ignore
                [removeId(curr)]: item[curr],
            }
        })
        return newFields as unknown as Entry
    }

    const handleRemoveEntry = (id: string) => {
        setState((prev) => prev.filter((item) => item.id !== id))
    }

    const createEntry = useCallback(async (item: EntriesStack) => {
        try {
            await entrySchema.validate(clearFields(item))
            await createEntryUseCase.execute(clearFields(item))
            setCreated((prev) => [...prev, item.id])
            return true
        } catch (e) {
            setFailed((prev) => [...prev, item.id])
            LogRocket.captureException(e as unknown as Error)
            console.log(e)
            throw e
        }
    }, [])

    const handleSubmit = useCallback(
        async (callBack: () => void) => {
            setLoading(true)
            setFailed([])
            try {
                await Promise.all(
                    state.map(async (item: EntriesStack) => {
                        if (created.includes(item.id)) return
                        return await createEntry(item)
                    })
                )
                toast.success('Entrada criada com sucesso')
                setState([] as EntriesStack[])
                callBack()
            } catch (e) {
                toast.error('Erro ao criar entrada')
                LogRocket.captureException(e as unknown as Error)
                console.log(e)
            }
            setLoading(false)
        },
        [state]
    )

    const handleCalculate = useCallback(
        (id: string) => () => {
            const index = state.findIndex((item) => item.id === id)
            if (index === -1) return
            const unitCost =
                // @ts-ignore
                state[index]?.[`unitCost-${id}`] ||
                // @ts-ignore
                state[index]?.[`product-${id}`]?.unitCost
            setState((prev) => {
                const newState = [...prev]
                const newTotal =
                    // @ts-ignore
                    isNaN(unitCost * (newState[index]?.[`quantity-${id}`] ?? 0))
                        ? 0
                        : unitCost * newState[index][`quantity-${id}`]
                newState[index] = {
                    ...newState[index],
                    [`totalPurchaseValue-${id}`]: newTotal,
                }
                setFormValue(`totalPurchaseValue-${id}`, newTotal)
                return newState
            })
        },
        [state]
    )

    return {
        state,
        created,
        failed,
        handleChange,
        handleSubmit,
        loading,
        handleAddingEntry,
        handleRemoveEntry,
        handleCalculate,
    }
}
