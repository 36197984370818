import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface UpdateTaxValueProposalsUseCase {
    execute(user: string): Promise<void>
    getSource(): string
}

export class UpdateTaxValueProposalsUseCase
    implements UpdateTaxValueProposalsUseCase
{
    constructor(private repository: CommercialProposalRepository) {}
    async execute(user: string): Promise<void> {
        return this.repository.updateProposalTaxValues(user)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
