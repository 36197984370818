import { AutoMenu } from '@Core/Components/AutoMenu/AutoMenu'
import { IMenu } from '@Core/Components/AutoMenu/AutoMenu.types'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { SidebarFolder } from '@Entry/Common/Sidebar/components/SidebarFolder/SidebarFolder'
import { useMemo } from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { BsCardChecklist } from 'react-icons/bs'
import { GiCheckboxTree } from 'react-icons/gi'
import { IoIdCardOutline } from 'react-icons/io5'
import { MdOutlineInventory2 } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import { RoutesPaths } from './RoutesPaths'
import { moduleKey, roles } from './roles'

const MenuItems: IMenu[] = [{
    title: 'Resumo',
    to: RoutesPaths.InventoryProductsSummary,
    icon: <BsCardChecklist />,
    permission: roles.product.permissions.summary.key
},
{
    title: 'Produtos',
    to: RoutesPaths.InventoryProducts,
    icon: <IoIdCardOutline />,
    permission: roles.product.permissions.read.key
},
{
    title: 'Saídas',
    to: RoutesPaths.InventoryExits,
    icon: <BsCardChecklist />,
    permission: roles.exit.permissions.read.key
},
{
    title: 'Entradas',
    to: RoutesPaths.InventoryEntries,
    icon: <BsCardChecklist />,
    permission: roles.entries.permissions.read.key
},
{
    title: 'Fornecedores',
    to: RoutesPaths.InventorySuppliers,
    icon: <AiOutlineShoppingCart />,
    permission: roles.suppliers.permissions.read.key
},{
    title: 'Destinos',
    to: RoutesPaths.InventoryDestinations,
    icon: <GiCheckboxTree />,
    permission: roles.destinations.permissions.read.key
}]


export const Menu = () => {
    const { pathname } = useLocation()
    const { hasModulePermission } = usePermissions()
    const hasSubMenuActive = useMemo(
        () => Boolean(MenuItems.find((item) => item.to === pathname)),
        [pathname]
    )

    if (!hasModulePermission(moduleKey)) return <></>

    return (
        <SidebarFolder
            name="Controle de Estoque"
            icon={<MdOutlineInventory2 />}
            active={hasSubMenuActive}
        >
            <AutoMenu menu={MenuItems} />
        </SidebarFolder>
    )
}
