import { Api } from '@Services/api'
import CoreDataSource from '../CoreDataSource'

export class CoreAPI implements CoreDataSource {
    axios = new Api()

    async sendStock(id: string): Promise<void> {
        const { data } = await this.axios.post(`/clients/send-stock/${id}`)
        return data
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
