import { CommercialVariables } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface GetVariables {
    execute(): Promise<CommercialVariables[]>
    getSource(): string
}

export class GetVariablesUseCase implements GetVariables {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(): Promise<CommercialVariables[]> {
        return this.repository.getVariables()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
