export enum RoutesPaths {
    CommercialProducts = '/commercial/products',
    CommercialProductsSummary = '/commercial/products/summary',
    CommercialProduct = '/commercial/product/:id',
    CommercialProposals = '/commercial/proposals',
    CommercialProposal = '/commercial/proposal/:id',
    CommercialCreateProposal = '/commercial/proposal/create',
    CommercialVariables = '/commercial/variables',
    CommercialVariable = '/commercial/variable/:id',
    CommercialCreateVariable = '/commercial/variable/create',
}
