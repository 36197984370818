import { Entry } from '@InventoryControl/Entries/entities/Entry'
import { Api } from '@Services/api'
import EntryDataSource from '../EntryDataSource'
import { DBInventoryEntry } from './Entries/Entry'

export class EntryAPI implements EntryDataSource {
    search(query: string): Promise<DBInventoryEntry[]> {
        throw new Error('Method not implemented.')
    }
    axios = new Api()

    async getEntryById(id: string): Promise<DBInventoryEntry> {
        const { data } = await this.axios.get(`/inventory/entries/${id}`)
        return data
    }

    async getEntries(): Promise<DBInventoryEntry[]> {
        const { data } = await this.axios.get('/inventory/entries')
        return data
    }

    async createEntry(entity: Entry): Promise<DBInventoryEntry> {
        const { data } = await this.axios.post('/inventory/entries', entity)
        return data
    }

    async updateEntry(entity: DBInventoryEntry): Promise<DBInventoryEntry> {
        const { data } = await this.axios.put(
            `/inventory/entries/${entity._id}`,
            entity
        )
        return data
    }

    async deleteEntry(id: string): Promise<void> {
        await this.axios.delete(`/inventory/entries/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
