import { NegativeClientStock } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks.types'
import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'

export interface NegativeStockClients {
    execute(): Promise<NegativeClientStock[]>
    getSource(): string
}

export class NegativeStockClientsUseCase implements NegativeStockClients {
    constructor(private repository: ClientsRepository) {}
    async execute(): Promise<NegativeClientStock[]> {
        return this.repository.getNegative()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
