import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { DBUser } from '../../Domain/Model/User'
import { User } from '../DataSource/API/Entity/User'
import UserDataSource from '../DataSource/UsersDataSource'

export class UsersRepository implements BaseRepository<DBUser, User> {
    dataSource: UserDataSource
    constructor(_dataSource: UserDataSource) {
        this.dataSource = _dataSource
    }
    create(entity: User): Promise<DBUser> {
        return this.dataSource.createUser(entity)
    }
    delete(id: string): Promise<void> {
        throw new Error('Method not supported.')
    }

    async findAll(): Promise<DBUser[]> {
        return this.dataSource.getUsers()
    }
    async findById(id: string): Promise<DBUser> {
        return this.dataSource.getUserById(id)
    }

    async update(entity: DBUser): Promise<DBUser> {
        return this.dataSource.updateUser(entity)
    }

    async disable(id: string): Promise<void> {
        return this.dataSource.disableUser(id)
    }

    async enable(id: string): Promise<void> {
        return this.dataSource.enableUser(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
