import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import { InventoryEntryRepository } from '@InventoryControl/Entries/Data/Repository/EntryRepository'
import { Entry } from '@InventoryControl/Entries/entities/Entry'

export interface CreateEntry {
    execute(entity: Entry): Promise<DBInventoryEntry>
    getSource(): string
}

export class CreateEntryUseCase implements CreateEntry {
    constructor(private repository: InventoryEntryRepository) {}
    async execute(entity: Entry): Promise<DBInventoryEntry> {
        return this.repository.create(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
