import {
    DeleteSupplierUseCase,
    GetSupplierUseCase,
    GetSuppliersUseCase,
    UpdateSupplierUseCase,
} from '@InventoryControl/Supplier/Domain/UseCases'
import { CreateSupplierUseCase } from '@InventoryControl/Supplier/Domain/UseCases/CreateSupplier'
import { Container } from 'inversify'
import { InventorySuppliersRepository } from '../../Repository/SupplierRepository'
import { SupplierAPI } from '../API/SupplierAPI'
import { Register } from './SupplierRegister'

const container = new Container()
container
    .bind<GetSupplierUseCase>(Register.UseCaseGetSupplier)
    .toDynamicValue(
        () =>
            new GetSupplierUseCase(
                new InventorySuppliersRepository(new SupplierAPI())
            )
    )
container
    .bind<GetSuppliersUseCase>(Register.UseCaseGetSuppliers)
    .toDynamicValue(
        () =>
            new GetSuppliersUseCase(
                new InventorySuppliersRepository(new SupplierAPI())
            )
    )

container
    .bind<CreateSupplierUseCase>(Register.UseCaseCreateSupplier)
    .toDynamicValue(
        () =>
            new CreateSupplierUseCase(
                new InventorySuppliersRepository(new SupplierAPI())
            )
    )
container
    .bind<UpdateSupplierUseCase>(Register.UseCaseUpdateSupplier)
    .toDynamicValue(
        () =>
            new UpdateSupplierUseCase(
                new InventorySuppliersRepository(new SupplierAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteSupplier)
    .toDynamicValue(
        () =>
            new DeleteSupplierUseCase(
                new InventorySuppliersRepository(new SupplierAPI())
            )
    )

export default container
