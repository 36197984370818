import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import {
    InventoryEntriesContainer,
    InventoryEntriesRegister,
} from '@InventoryControl/Entries/Data/DataSource/Container'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useInventoryEntriesManager = () => {
    const getEntryUseCase = InventoryEntriesContainer.get(
        InventoryEntriesRegister.UseCaseGetEntry
    )
    const saveEntryUseCase = InventoryEntriesContainer.get(
        InventoryEntriesRegister.UseCaseUpdateEntry
    )
    const deleteEntryUseCase = InventoryEntriesContainer.get(
        InventoryEntriesRegister.UseCaseDeleteEntry
    )

    const [state, setState] = useState<DBInventoryEntry>({} as DBInventoryEntry)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleChange = ({ target }: { target: any }) => {
        const { name, value } = target
        setState((prev) => ({ ...prev, [name]: value }))
    }

    const handleSave = async (callback: () => void) => {
        setLoading(true)
        try {
            await saveEntryUseCase.execute(state)
            callback?.()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao salvar')
            Sentry.captureException(e)
        }
        setLoading(false)
    }

    const getEntry = async (id: string) => {
        setLoading(true)
        try {
            const entry = await getEntryUseCase.execute(id)
            setState(entry)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const deleteEntry = async () => {
        setLoading(true)
        try {
            await deleteEntryUseCase.execute(state._id)
            toast.success('Entrada excluída com sucesso')
            navigate(RoutesPaths.InventoryEntries)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return {
        state,
        loading,
        handleChange,
        handleSave,
        getEntry,
        deleteEntry,
    }
}
