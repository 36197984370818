import { AutoForm } from '@Core/Components/AutoForm/AutoForm'
import { Loader } from '@Core/Components/Loader/Loader'
import { entrySchema } from '@InventoryControl/Entries/entities/Entry'
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { useInventoryProducts } from '@InventoryControl/Products/Hooks/useProducts/useInventoryProducts'
import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import { useInventorySuppliers } from '@InventoryControl/Supplier/Hooks/useInventorySupplier/useInventorySupplier'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

export const EntryDetailsForm = ({
    state,
    editMode,
    handleChange,
    loading,
}: any) => {
    const { products, getProducts } = useInventoryProducts()
    const { suppliers, getSuppliers } = useInventorySuppliers()

    const {
        register,
        watch,
        formState: { errors },
        setValue,
        control,
    } = useForm({
        resolver: yupResolver(entrySchema),
        defaultValues: {
            ...state,
        },
    })

    const handleAutoCompleteChange =
        (name: string) =>
        (_: any, { value }: any) => {
            setValue(name, value)
        }

    const handleDateChange = (name: string) => (value: any) => {
        setValue(name, value)
    }

    const fillForm = useCallback(() => {
        Object.entries(state || {}).forEach(([key, value]) => {
            setValue(key, value)
            return true
        })
    }, [state])

    const inputs = useMemo(
        () => [
            {
                grid: 12,
                name: 'supplier',
                label: 'Fornecedor',
                type: 'autocomplete',
                key: 'supplier',
                labelKey: 'name',
                disabled: !editMode,
                value: state?.supplier,
                register: register('supplier'),
                onChange: handleAutoCompleteChange('supplier'),
                results: formattedAutocomplete<DBInventorySupplier>(
                    suppliers,
                    'name'
                ),
            },
            {
                grid: 8,
                name: 'product',
                label: 'Produto',
                type: 'autocomplete',
                key: 'product',
                labelKey: 'name',
                value: state.product,
                register: register('product'),
                disabled: !editMode,
                onChange: handleAutoCompleteChange('product'),
                results: formattedAutocomplete<DBInventoryProduct>(
                    products,
                    'name'
                ),
            },
            {
                grid: 4,
                name: 'unitCost',
                control: control,
                register: register('unitCost'),
                label: 'Custo Unitário',
                type: 'input',
                value: state?.unitCost,
                key: 'unitCost',
                disabled: !editMode,
            },
            {
                grid: 4,
                name: 'quantity',
                register: register('quantity'),
                label: 'Quantidade',
                control: control,
                type: 'input',
                key: 'quantity',
                value: state?.quantity,
                disabled: !editMode,
            },
            {
                grid: 4,
                name: 'totalPurchaseValue',
                register: register('totalPurchaseValue'),
                label: 'Valor Total',
                control: control,
                type: 'input',
                value: state?.totalPurchaseValue,
                key: 'totalPurchaseValue',
                disabled: !editMode,
            },
            {
                grid: 4,
                name: 'created_at',
                register: register('created_at'),
                label: 'Data da Entrada',
                type: 'date',
                onChange: handleDateChange('created_at'),
                key: 'created_at',
                value: watch('created_at'),
                disabled: !editMode,
            },
            {
                grid: 12,
                name: 'observation',
                control: control,
                register: register('observation'),
                label: 'Observações',
                type: 'text',
                key: 'observation',
                value: state?.observation,
                disabled: !editMode,
                rows: 5,
            },
        ],
        [state]
    )

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            return handleChange({
                // @ts-ignore
                target: { name, value: value[name] },
            })
        })
        return () => subscription.unsubscribe()
    }, [watch, state])

    useEffect(() => {
        getProducts()
        getSuppliers()
        fillForm()
    }, [])

    if (!state || loading) return <Loader />

    return (
        <div className="mt-2">
            <AutoForm inputs={inputs} />
        </div>
    )
}
