import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'
import { Clients } from '../Model/Clients'

export interface UpdateClient {
    execute(client: Clients): Promise<Clients>
    getSource(): string
}

export class UpdateClientUseCase implements UpdateClient {
    constructor(private repository: ClientsRepository) {}
    async execute(client: Clients): Promise<Clients> {
        return this.repository.update(client)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
