import { BaseModal } from '@Core/Entities/BaseModal'
export interface ModalProps extends BaseModal {
    children: React.ReactNode
    size?: ModalSize
}

export enum ModalSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}
