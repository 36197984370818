import { RoutesPaths } from '@Balance/RoutesPaths'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { ModalFooter } from '@Core/Components/Modal/Modal.styles'
import { Supplier } from '@Core/Entities/Supplier'
import { IMovementStack } from '@Movements/Domain/Model/CreateMovement'
import { useMovements } from '@Movements/Hooks/useMovements'
import { useMovementsManager } from '@Movements/Hooks/useMovementsManager'
import { MovementStack } from '@Movements/NewMovement/components/MovementStack'
import { API } from '@Services/api'
import { Form } from '@unform/web'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSupplierManager } from '../../Hooks/useSupplierManager'

export const NewMovementSupplier = () => {
    const navigate = useNavigate()
    const { getMovements } = useMovements()
    const { id } = useParams()
    const { state: locationState } = useLocation()
    const [localSupplier, setLocalSupplier] = useState<Supplier>()
    const { supplier } = locationState || {}
    const { handleAddingMovements, state, formRef, loading } =
        useMovementsManager()
    const { getSupplierByID } = useSupplierManager(API)

    const goBack = () => {
        navigate(`${RoutesPaths.Supplier}/${id}`)
    }

    const {
        handleChange,
        handleSubmit,
        handleAddingNewStackMovement,
        handleRemoveStackMovement,
    } = handleAddingMovements({
        callback: goBack,
        onlySupplier: true,
        supplier: localSupplier,
    })

    useEffect(() => {
        getMovements()
        if (!Boolean(supplier)) {
            const getSupplier = async () => {
                const data = await getSupplierByID(id || '')
                setLocalSupplier(data)
            }
            getSupplier()
        } else {
            setLocalSupplier(supplier)
        }
    }, [id, supplier])

    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">
                    Nova movimentação para {localSupplier?.corporateName}
                </h1>
            </ContainerHeader>
            {loading && <Loader />}
            {!loading && (
                <Form ref={formRef} onSubmit={() => {}}>
                    <Grid container>
                        <Grid item lg={12}>
                            {Boolean(state?.movements?.length) &&
                                state?.movements?.map(
                                    (item: IMovementStack, index: number) => (
                                        <MovementStack
                                            formRef={formRef}
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            onlyInput
                                            onChange={handleChange}
                                            onRemove={handleRemoveStackMovement}
                                        />
                                    )
                                )}
                        </Grid>
                        <Grid item lg={12}>
                            <Button
                                onClick={handleAddingNewStackMovement}
                                variant={ButtonVariant.primary}
                            >
                                Adicionar Produto
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
            <ModalFooter>
                <Button onClick={goBack} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    variant={ButtonVariant.primary}
                >
                    {loading ? 'Aguarde...' : 'Salvar'}
                </Button>
            </ModalFooter>
        </Container>
    )
}
