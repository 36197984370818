import { RootState } from '@Core/Communication'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { IndicatorContainer } from './Indicator.styles'

export const Indicator = () => {
    const location = useLocation()
    const { sidebarCollapsed } = useSelector(
        (state: RootState) => state.appConfig
    )
    const [indicatorOffsetTop, setIndicatorOffsetTop] = useState(0)

    const getActiveElement = useCallback(() => {
        const activeMenuItem = document.querySelector('.active') as HTMLElement
        const folderActive = document.querySelector('.activeFolder') as HTMLElement
        if (activeMenuItem) {
            return activeMenuItem?.offsetTop
        }
        if (folderActive) {
            return folderActive?.offsetTop
        }
        return false
    }, [document, sidebarCollapsed])

    const handleUpdateIndicator = useCallback(
        (event: Event) => {
            const { detail } = event as CustomEvent<{ offsetTop: number }>
            const { offsetTop } = detail
            setTimeout(() => {
                const ElementOffsetTop = getActiveElement()
                if(!ElementOffsetTop) return
                setIndicatorOffsetTop(Number(ElementOffsetTop ?? offsetTop))
            }, 0)
        },
        [document]
    )

    useEffect(() => {
      setTimeout(() => {
        const offsetTop = getActiveElement()
        if(!offsetTop) return
        setIndicatorOffsetTop((prev) => Number(offsetTop ?? prev))
      }, 0)
    }, [location, sidebarCollapsed, document])

    useEffect(() => {
        window.addEventListener('update-indicator', handleUpdateIndicator)

        return () => {
            window.removeEventListener(
                'update-indicator',
                handleUpdateIndicator
            )
        }
    }, [])

    return <IndicatorContainer offsetTop={indicatorOffsetTop} />
}
