import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
export interface GetProducts {
    execute(): Promise<DBCommercialProduct[]>
    getSource(): string
}

export class GetProductsUseCase implements GetProducts {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(): Promise<DBCommercialProduct[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
