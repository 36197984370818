import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'

export interface DeleteMovement {
    execute(id: DBMovement['_id']): Promise<void>
    getSource(): string
}

export class DeleteMovementUseCase implements DeleteMovement {
    constructor(private repository: MovementRepository) {}
    async execute(id: DBMovement['_id']): Promise<void> {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
