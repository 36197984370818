import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
import { DBCommercialPriceRange } from '../../Data/DataSource/API/Entity/Product'

export interface UpdatePriceRange {
    execute(entity: DBCommercialPriceRange): Promise<DBCommercialPriceRange>
    getSource(): string
}

export class UpdatePriceRangeUseCase implements UpdatePriceRange {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(
        entity: DBCommercialPriceRange
    ): Promise<DBCommercialPriceRange> {
        return this.repository.updatePriceRange(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
