import { AutoMenu } from '@Core/Components/AutoMenu/AutoMenu'
import { IMenu } from '@Core/Components/AutoMenu/AutoMenu.types'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { SidebarItem } from '@Entry/Common/Sidebar/Sidebar.styles'
import { SidebarFolder } from '@Entry/Common/Sidebar/components/SidebarFolder/SidebarFolder'
import { useMemo } from 'react'
import { BiCog, BiDetail } from 'react-icons/bi'
import { FiTrendingUp } from 'react-icons/fi'
import { IoIdCardOutline } from 'react-icons/io5'
import { SlDocs } from 'react-icons/sl'
import { useLocation } from 'react-router-dom'
import { RoutesPaths } from './RoutesPaths'
import { moduleKey, roles } from './roles'

const MenuItems: IMenu[] = [
    {
        title: 'Propostas',
        to: RoutesPaths.CommercialProposals,
        icon: <BiDetail />,
        permission: roles.proposal.permissions.read.key,
    },
    {
        title: 'Produtos',
        to: RoutesPaths.CommercialProducts,
        icon: <IoIdCardOutline />,
        permission: roles.product.permissions.read.key,
    },
    {
        title: 'Variáveis',
        to: RoutesPaths.CommercialVariables,
        icon: <BiCog />,
        permission: roles.variables.permissions.read.key,
    },
]

export const Menu = () => {
    const { pathname } = useLocation()
    const { hasModulePermission } = usePermissions()
    const hasSubMenuActive = useMemo(
        () => Boolean(MenuItems.find((item) => item.to === pathname)),
        [pathname]
    )

    if (!hasModulePermission(moduleKey)) return <></>

    return (
        <SidebarFolder
            name="Comercial"
            icon={<FiTrendingUp />}
            active={hasSubMenuActive}
        >
            <>
                <AutoMenu menu={MenuItems} />
                <SidebarItem
                    target="_blank"
                    rel="noopener noreferrer"
                    to={'https://central.tiflux.com.br/v/knowledge_folders'}
                    active={false}
                    className="sidebar-item"
                    title={'Material de apoio'}
                >
                    <SlDocs />
                    <span className="sidebar-text">Material de apoio</span>
                </SidebarItem>
            </>
        </SidebarFolder>
    )
}
