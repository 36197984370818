import {
    CreateDestinationUseCase,
    DeleteDestinationsUseCase,
    GetDestinationUseCase,
    GetDestinationsUseCase,
    UpdateDestinationUseCase,
} from '@InventoryControl/Destinations/Domain/UseCases'

export const Register = {
    UseCaseGetDestinations: GetDestinationsUseCase,
    UseCaseGetDestination: GetDestinationUseCase,
    UseCaseCreateDestination: CreateDestinationUseCase,
    UseCaseUpdateDestination: UpdateDestinationUseCase,
    UseCaseDeleteDestination: DeleteDestinationsUseCase,
}
