import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import { InventoryExitsRepository } from '@InventoryControl/Exits/Data/Repository/ExitsRepository'

export interface GetExits {
    execute(): Promise<DBInventoryExits[]>
    getSource(): string
}

export class GetExitsUseCase implements GetExits {
    constructor(private repository: InventoryExitsRepository) {}
    async execute(): Promise<DBInventoryExits[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
