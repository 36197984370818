import { UsersRepository } from '@User/Data/Repository/UsersRepository'
import { DBUser } from '../Model/User'

export interface GetUsers {
    execute(): Promise<DBUser[]>
    getSource(): string
}

export class GetUsersUseCase implements GetUsers {
    constructor(private repository: UsersRepository) {}
    async execute(): Promise<DBUser[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
