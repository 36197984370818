import { Api } from '@Services/api'
import { DBUser } from '@User/Domain/Model/User'
import UsersDataSource from '../UsersDataSource'

export class UserAPI implements UsersDataSource {
    axios = new Api()

    async createUser(user: DBUser): Promise<DBUser> {
        const { data } = await this.axios.post('/user', user)
        return data
    }

    async getUserById(id: string): Promise<DBUser> {
        const { data } = await this.axios.get(`/user/${id}`)
        return data
    }

    async getUsers(): Promise<DBUser[]> {
        const { data } = await this.axios.get('/user')
        // if (import.meta.env.DEV) {
        //     return data
        // }
        return data.filter(({ mail }: DBUser) => 
            mail?.includes(import.meta.env.VITE_AUTH_DOMAIN_TENANT)
        )
    }

    async updateUser(user: DBUser): Promise<DBUser> {
        const { data } = await this.axios.put(`/user/${user.uid}`, user)
        return data
    }

    async disableUser(id: string): Promise<void> {
        await this.axios.delete(`/user/${id}`)
    }

    async enableUser(id: string): Promise<void> {
        await this.axios.put(`/user/${id}/enable`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
