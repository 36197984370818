import styled from 'styled-components'
import { ButtonProps, ButtonVariant } from './Button.type'
import { getSize, getVariant } from './Button.utils'
export { ButtonVariant } from './Button.type'

export const Button = styled.button<ButtonProps>`
    ${({ variant = ButtonVariant.primary, theme }) =>
        getVariant(variant, theme)};
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    ${({ size = 'medium' }) => getSize(size)};
    ${({ disabled }) =>
        disabled &&
        `   
        color: #333;
        background-color: #eee;
        cursor: not-allowed;
    :hover{
        background-color: #eee;
        
    }
            `}
`
