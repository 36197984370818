export class LocalStorage {
    length: number = 0
    key: any = ''
    clear() {
        localStorage.clear()
    }

    getItem(key: string) {
        return localStorage.getItem(key)
    }

    setItem(key: string, value: any) {
        localStorage.setItem(key, value)
    }

    removeItem(key: string) {
        localStorage.removeItem(key)
    }
}
