import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Product } from '@InventoryControl/Products/entities/Product'
import { DBInventoryProduct } from '../DataSource/API/Entity/Product'
import ProductsDataSource from '../DataSource/ProductsDataSource'

export class InventoryProductsRepository
    implements BaseRepository<DBInventoryProduct, Product>
{
    dataSource: ProductsDataSource
    constructor(_dataSource: ProductsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBInventoryProduct[]> {
        return this.dataSource.getProducts()
    }
    async findById(id: string): Promise<DBInventoryProduct> {
        return this.dataSource.getProductById(id)
    }
    async search(query: string): Promise<DBInventoryProduct[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Product): Promise<DBInventoryProduct> {
        return this.dataSource.createProduct(entity)
    }
    async update(entity: DBInventoryProduct): Promise<DBInventoryProduct> {
        return this.dataSource.updateProduct(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteProduct(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
