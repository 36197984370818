import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    Paper,
    Switch,
} from '@material-ui/core'
import { useState } from 'react'
import { BsChevronUp } from 'react-icons/bs'
import { PermissionsCardProps } from './PermissionsCard.types'

const Chevron = (collapsed: boolean) => {
    return (
        <BsChevronUp
            style={{
                transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 0.3s ease-in-out',
            }}
        />
    )
}

export const PermissionsCard = ({
    roleItem,
    title,
    userPermissions,
    onChange,
}: PermissionsCardProps) => {
    const [collapsed, setCollapsed] = useState(true)
    const hasPermission = (permission: string) =>
        userPermissions?.includes(permission)
    return (
        <Paper>
            <CardHeader
                style={{ cursor: 'pointer' }}
                onClick={() => setCollapsed(!collapsed)}
                title={title}
                subheader={`${Object.values(roleItem).length} Sub módulos`}
                action={Chevron(collapsed)}
            />
            <CardContent
                style={{
                    maxHeight: collapsed ? '0px' : '1000px',
                    height: collapsed ? '0px' : '100%',
                    transition: 'all 0.3s ease-in-out',
                }}
            >
                <Card
                    style={{
                        maxHeight: collapsed ? '0px' : '1000px',
                        opacity: collapsed ? 0 : 1,
                        transition: 'all 0.3s ease-in-out',
                    }}
                >
                    <CardContent>
                        {Object.values(roleItem).map((role) => (
                            <Grid
                                key={role.name}
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item lg={2}>
                                    <h3>{role.name}</h3>
                                </Grid>

                                {Object.values(role.permissions).map(
                                    (permission) => (
                                        <>
                                            <Grid item key={permission.key}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            name={
                                                                permission.key
                                                            }
                                                            onChange={onChange}
                                                            checked={hasPermission(
                                                                permission.key
                                                            )}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={permission.name}
                                                />
                                            </Grid>
                                            {Object.values(
                                                permission?.permissions || []
                                            )?.length > 0 &&
                                                Object.values(
                                                    permission?.permissions
                                                )?.map((subPermission: any) => (
                                                    <Grid
                                                        item
                                                        key={subPermission.key}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    name={
                                                                        subPermission.key
                                                                    }
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                    checked={hasPermission(
                                                                        subPermission.key
                                                                    )}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={
                                                                subPermission.name
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                        </>
                                    )
                                )}
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
            </CardContent>
        </Paper>
    )
}
