import { CreateMovementModel } from '@Movements/Domain/Model/CreateMovement'
import { FormHandles } from '@unform/core'
import { RefObject } from 'react'
import { toast } from 'react-toastify'

export const validateCreateMovement = (
    movement: CreateMovementModel,
    formRef: RefObject<FormHandles>
) => {
    const { movements, client, onlySupplier } = movement
    let hasError = false
    if (!client && !onlySupplier) {
        formRef?.current?.setFieldError('client', 'Selecione um cliente')
        hasError = true
    }
    if (!movements || movements.length === 0) {
        toast.error('Adicione pelo menos um movimento')
        hasError = true
    }
    for (let index = 0; index < movements.length; index++) {
        const element = movements[index]
        if (!element.product) {
            formRef?.current?.setFieldError(
                `movements[${index}].product`,
                'Selecione um produto'
            )
            hasError = true
        }
        if (!element.amount) {
            formRef?.current?.setFieldError(
                `movements[${index}].amount`,
                'Informe a quantidade'
            )
            hasError = true
        }
        if (!element.type) {
            formRef?.current?.setFieldError(
                `movements[${index}].type`,
                'Selecione um tipo'
            )
            hasError = true
        }
        if (element.amount <= 0) {
            formRef?.current?.setFieldError(
                `movements[${index}].amount`,
                'Informe uma quantidade válida'
            )
            hasError = true
        }
        if (Number(element.type) === 2) {
            if (element.inSupplier && !element.supplier) {
                formRef?.current?.setFieldError(
                    `movements[${index}].supplier`,
                    'Selecione um fornecedor'
                )
                hasError = true
            }
            if (!element.ticketNumber) {
                formRef?.current?.setFieldError(
                    `movements[${index}].ticketNumber`,
                    'Informe o número do ticket'
                )
                hasError = true
            }
        }
        if (!onlySupplier && Number(element.type) === 1 && !element.caSales) {
            formRef?.current?.setFieldError(
                `movements[${index}].caSales`,
                'Campo Obrigatório'
            )
            hasError = true
        }
    }
    return !hasError
}
