import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import {
    CommercialCell,
    CommercialPriceRangeCell,
    DBCommercialPriceRange,
} from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Button, ButtonVariant, Table } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { Card, Grid, IconButton } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    AiFillCloseCircle,
    AiOutlineInfoCircle,
    AiOutlinePlusCircle,
} from 'react-icons/ai'
import { toast } from 'react-toastify'

export const PriceRange = ({ priceTable, refresh, handleRemove }) => {
    const [editMode, setEditMode] = useState(false || priceTable?.isNew)
    const [state, setState] = useState<DBCommercialPriceRange>(priceTable)

    const { control, setValue, watch } = useForm({
        defaultValues: state,
    })

    const updatePriceContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseUpdatePriceRange
    )

    const deletePriceContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseDeletePriceRange
    )

    const handleCheckIsFixed = useCallback(
        (position: number) => {
            setState((prev) => {
                const newRows = [...prev.rows]
                newRows[0][position] = {
                    ...newRows[0][position],
                    isFixed: !newRows[0][position].isFixed,
                }
                setValue('rows', newRows)
                return {
                    ...prev,
                    rows: newRows,
                }
            })
        },
        [setValue, setState]
    )

    const addColumn = useCallback(() => {
        setState((prev) => {
            const newColumns = [...prev.columns, '']
            setValue('columns', newColumns as CommercialCell[])
            const newRows = [...prev.rows]
            for (const element of newRows) {
                element.push('' as unknown as CommercialPriceRangeCell)
            }
            setValue('rows', newRows as [CommercialPriceRangeCell[]])
            return {
                ...prev,
                columns: newColumns,
            }
        })
    }, [setState])

    const removeColumn = useCallback(
        (index: number) => {
            setState((prev) => {
                const newColumns = [...prev.columns]
                newColumns.splice(index, 1)
                setValue('columns', newColumns)
                const newRows = [...prev.rows]
                for (const element of newRows) {
                    element.splice(index, 1)
                }
                setValue('rows', newRows as [CommercialPriceRangeCell[]])
                return {
                    ...prev,
                    columns: newColumns,
                }
            })
        },
        [setState]
    )

    const handleSave = async () => {
        setEditMode(false)
        const data = await updatePriceContainer.execute(state)
        toast.success(`Especificação ${state.name} atualizado com sucesso`)
        refresh()
    }

    const handleDelete = async () => {
        if (state?.isNew) {
            handleRemove()
            return
        }
        await deletePriceContainer.execute(state._id)
        toast.info(`Especificação ${state.name} deletado com sucesso`)
        refresh()
    }

    useEffect(() => {
        watch((value) => {
            setState(value as DBCommercialPriceRange)
        })
    }, [])

    const FixedTextInfo = () => (
        <>
            <span className="text-sm">
                Quando a opção de valor fixo estiver ativada, o custo será
                constante, independentemente da quantidade adquirida, dentro do
                intervalo especificado. Por exemplo, em um intervalo de 1 a 10
                unidades, cada unidade custará 10 reais, seja a compra de 1
                unidade ou 10 unidades.
            </span>
            <span>
                Quando a opção de valor fixo estiver desativada, o custo será
                calculado com base na quantidade adquirida, dentro do intervalo
                especificado. Por exemplo, em um intervalo de 1 a 10 unidades,
                cada unidade custará 10 reais. Assim, a compra de 10 unidades
                resultará em um custo total de 100 reais.
            </span>
        </>
    )

    if (!state) {
        return <Loader />
    }

    return (
        <Card>
            <form>
                <div className="grid grid-cols-2 p-4 items-center">
                    <div>
                        {editMode ? (
                            <Input
                                name={'name'}
                                label="Nome"
                                control={control}
                                fullWidth
                                useUnform={false}
                            />
                        ) : (
                            <span className="font-bold text-lg">
                                {state?.name}
                            </span>
                        )}
                    </div>
                    <div className="flex justify-end gap-4">
                        {editMode ? (
                            <div className="flex gap-4">
                                <Button type="button" onClick={handleSave}>
                                    Salvar
                                </Button>
                                <Button
                                    type="button"
                                    variant={ButtonVariant.secondary}
                                    onClick={() => {
                                        setEditMode(false)
                                        refresh()
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        ) : (
                            <Button
                                type="button"
                                variant={ButtonVariant.secondary}
                                onClick={() => setEditMode(true)}
                            >
                                Editar
                            </Button>
                        )}
                        <div
                            className="flex p-4 cursor-pointer"
                            onClick={handleDelete}
                        >
                            <AiFillCloseCircle size={24} />
                        </div>
                    </div>
                </div>
                <Table key={state._id}>
                    <thead>
                        <th colSpan={state.columns.length}>
                            Intervalo de Valor por Quantidade
                        </th>
                    </thead>
                    <tbody>
                        {editMode && (
                            <tr className="head">
                                <th></th>
                                {[...new Array(state.columns.length)].map(
                                    (_, i) => (
                                        <th key={_}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => removeColumn(i)}
                                            >
                                                <AiFillCloseCircle />
                                            </IconButton>
                                        </th>
                                    )
                                )}
                            </tr>
                        )}
                        <tr className="head">
                            <th>Quantidade</th>
                            {state.columns.map(({ key, value }, i) => (
                                <>
                                    <th key={key}>
                                        {editMode && (
                                            <Input
                                                name={
                                                    'columns[' + i + '].value'
                                                }
                                                control={control}
                                                fullWidth
                                                useUnform={false}
                                            />
                                        )}
                                        {!editMode && value}
                                    </th>
                                </>
                            ))}
                            {editMode && (
                                <td
                                    rowSpan={state.rows.length + 1}
                                    className="head"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={addColumn}
                                    >
                                        <AiOutlinePlusCircle />
                                    </IconButton>
                                </td>
                            )}
                        </tr>
                        {state.rows.map((row, i) => (
                            <tr key={row[0]}>
                                <th rowSpan={2}>Valor</th>
                                {row.map(({ key, value, isFixed }, j) => (
                                    <td key={key}>
                                        {editMode && (
                                            <Grid container spacing={1}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    className="grid grid-cols-2 gap-2 h-12"
                                                >
                                                    <div className="flex items-center justify-end">
                                                        <Switch
                                                            id={`isFixed-${j}`}
                                                            checked={isFixed}
                                                            onCheckedChange={() =>
                                                                handleCheckIsFixed(
                                                                    j
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex items-center">
                                                        <HoverCard>
                                                            <HoverCardTrigger
                                                                asChild
                                                            >
                                                                <Label
                                                                    htmlFor={`isFixed-${j}`}
                                                                    className="flex items-center gap-1"
                                                                >
                                                                    Valor Fixo
                                                                    <AiOutlineInfoCircle />
                                                                </Label>
                                                            </HoverCardTrigger>
                                                            <HoverCardContent className="grid gap-2 w-fit max-w-lg">
                                                                <FixedTextInfo />
                                                            </HoverCardContent>
                                                        </HoverCard>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={12}>
                                                    <Input
                                                        name={
                                                            'rows[' +
                                                            i +
                                                            '][' +
                                                            j +
                                                            '].value'
                                                        }
                                                        label="Valor"
                                                        control={control}
                                                        fullWidth
                                                        useUnform={false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!editMode && (
                                            <div className="grid">
                                                <HoverCard>
                                                    <HoverCardTrigger asChild>
                                                        <span className="flex justify-center items-center gap-1">
                                                            {isFixed
                                                                ? 'Valor Fixo'
                                                                : 'Valor unitário'}
                                                            <AiOutlineInfoCircle />
                                                        </span>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="grid gap-2 w-fit max-w-lg">
                                                        <FixedTextInfo />
                                                    </HoverCardContent>
                                                </HoverCard>
                                                <span>{value}</span>
                                            </div>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </form>
        </Card>
    )
}
