import { UsersRepository } from '@User/Data/Repository/UsersRepository'
import { DBUser } from '../Model/User'

export interface GetUser {
    execute(id: string): Promise<DBUser>
    getSource(): string
}

export class GetUserUseCase implements GetUser {
    constructor(private repository: UsersRepository) {}
    async execute(id: string): Promise<DBUser> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
