import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface DeleteProposals {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteProposalsUseCase implements DeleteProposals {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
