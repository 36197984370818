import { ThemeOptions } from '@material-ui/core'
import { lightTheme } from './Light'

export const materialTheme = {
    dark: {
        palette: {
            type: 'dark',
            primary: {
                main: lightTheme.colors.primary,
            },
            secondary: {
                main: lightTheme.colors.secondary,
            },
        },
    } as ThemeOptions,
    light: {
        palette: {
            type: 'light',
            primary: {
                main: lightTheme.colors.primary,
            },
            secondary: {
                main: lightTheme.colors.secondary,
            },
        },
    } as ThemeOptions,
}
