import { generateInputRandomName } from '@Utils/Mocks/generateInputRandomName'
import { Input } from '../Input/Input'
import { TextAreaProps } from './TextArea.types'

export const TextArea = ({
    label,
    hasError = false,
    name = generateInputRandomName(),
    ...props
}: TextAreaProps) => {
    return <Input multiline name={name} {...props} label={label} />
}
