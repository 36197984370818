import { TextField } from '@material-ui/core'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'

// @ts-ignore
const ExampleCustomInput = forwardRef<any>(({ value, onClick }, ref) => (
    <TextField
        variant="outlined"
        label="Data"
        onClick={onClick}
        size="small"
        fullWidth
        ref={ref}
        value={formattedValue(value)}
    />
))

export const formattedValue = (value: string) => {
    const [start, end] = value.split(' - ')
    if (start && end) {
        return `${format(new Date(start), 'dd/MM/yyyy')} - ${format(
            new Date(end),
            'dd/MM/yyyy'
        )}`
    }
    if (start) {
        return `${format(new Date(start), 'dd/MM/yyyy')} - `
    }
    return ''
}

export const DateFilter = ({
    onChange,
    range = [new Date(), new Date()],
}: {
    onChange: (date: Date[]) => void
    range: [Date, Date]
}) => {
    const [startDate, setStartDate] = useState(range[0])
    const [endDate, setEndDate] = useState(null)
    const _onChange = (dates: any) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
        onChange([start, end])
    }
    return (
        <DatePicker
            selected={startDate}
            onChange={_onChange}
            startDate={startDate}
            endDate={endDate}
            locale={ptBR}
            isClearable
            customInput={<ExampleCustomInput />}
            selectsRange
        />
    )
}
