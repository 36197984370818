import { PrivacyRoute } from '@Core/Routes/PrivacyRoute'
import { RoutesPaths } from '@User/RoutesPaths'
import { UserDetails } from '@User/UserDetails/UserDetails'

import { UserList } from '@User/UserList/UserList'
import { roles } from '@User/roles'
import { Route } from 'react-router-dom'
const router = [
    <Route key={'UserStack'} path="/">
        <Route
            element={
                <PrivacyRoute permission={roles.user.permissions.read.key} />
            }
        >
            <Route path={RoutesPaths.UserList} element={<UserList />} />
            <Route path={RoutesPaths.UserDetail} element={<UserDetails />} />
        </Route>
    </Route>,
]
export default router
