import {
    MovementContainer,
    MovementRegister,
} from '@Movements/Data/DataSource/Container'
import { useState } from 'react'

export const useDeleteMovement = ({
    id,
    onCompleted,
}: {
    id: string
    onCompleted: () => void
}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>('')
    const movementContainer = MovementContainer.get(
        MovementRegister.UseCaseDeleteMovement
    )
    const deleteMovement = async () => {
        setLoading(true)
        try {
            setError('')
            await movementContainer.execute(id)
            onCompleted()
        } catch (e) {
            setError(e as string)
        }
        setLoading(false)
    }

    return {
        deleteMovement,
        loading,
        error,
    }
}
