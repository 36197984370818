import { PrivacyRoute } from '@Core/Routes/PrivacyRoute'
import { DestinationDetails } from '@InventoryControl/Destinations/DestinationDetails/DestinationDetails'
import { DestinationsList } from '@InventoryControl/Destinations/DestinationsList/DestinationsList'
import { CreateEntry } from '@InventoryControl/Entries/CreateEntries/CreateEntry'
import { EntriesDetails } from '@InventoryControl/Entries/EntriesDetails/EntriesDetails'
import { EntriesList } from '@InventoryControl/Entries/EntriesList/EntriesList'
import { CreateExit } from '@InventoryControl/Exits/CreateExit/CreateExit'
import { ExitDetails } from '@InventoryControl/Exits/ExitDetails/ExitDetails'
import { ExitsList } from '@InventoryControl/Exits/ExitsList/ExitsList'
import { ProductDetails } from '@InventoryControl/Products/ProductDetails/ProductDetails'
import { ProductsList } from '@InventoryControl/Products/ProductsList/ProductsList'
import { ProductsSummary } from '@InventoryControl/Products/ProductsSummary/ProductsSummary'
import { InventoryControlRoot } from '@InventoryControl/Providers/InventoryControlRoot/InventoryControlRoot'
import { roles } from '@InventoryControl/roles'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { SupplierDetails } from '@InventoryControl/Supplier/SupplierDetails/SupplierDetails'
import { SuppliersList } from '@InventoryControl/Supplier/SuppliersList/SuppliersList'

import { Route } from 'react-router-dom'
const router = [
    <Route
        key={'InventoryControlStack'}
        path="/"
        element={<InventoryControlRoot />}
    >
        <Route
            element={
                <PrivacyRoute
                    permission={roles.product.permissions.summary.key}
                />
            }
        >
            <Route
                path={RoutesPaths.InventoryProductsSummary}
                element={<ProductsSummary />}
            />
        </Route>
        <Route
            element={
                <PrivacyRoute permission={roles.product.permissions.read.key} />
            }
        >
            <Route
                path={RoutesPaths.InventoryProducts}
                element={<ProductsList />}
            />
            <Route
                path={RoutesPaths.InventoryProduct}
                element={<ProductDetails />}
            />
            <Route path={RoutesPaths.InventoryExits} element={<ExitsList />} />
            <Route path={RoutesPaths.InventoryExit} element={<ExitDetails />} />
            <Route
                path={RoutesPaths.InventoryCreateExit}
                element={<CreateExit />}
            />
            <Route
                path={RoutesPaths.InventoryEntries}
                element={<EntriesList />}
            />
            <Route
                path={RoutesPaths.InventoryEntry}
                element={<EntriesDetails />}
            />
            <Route
                path={RoutesPaths.InventoryCreateEntry}
                element={<CreateEntry />}
            />

            <Route
                path={RoutesPaths.InventoryDestinations}
                element={<DestinationsList />}
            />
            <Route
                path={RoutesPaths.InventoryDestination}
                element={<DestinationDetails />}
            />
            <Route
                path={RoutesPaths.InventorySuppliers}
                element={<SuppliersList />}
            />
            <Route
                path={RoutesPaths.InventorySupplier}
                element={<SupplierDetails />}
            />
        </Route>
    </Route>,
]
export default router
