export function calculateSaleValue(
    netValue: number,
    bvPercentage: number
): number {
    return netValue / (1 - bvPercentage / 100)
}

export function calculateBV(saleValue: number, bvPercentage: number): number {
    const bv = saleValue * bvPercentage
    return bv
}
