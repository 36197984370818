import {
    ClientContainer,
    ClientRegister,
} from '@Clients/Data/DataSource/Container'
import { Clients } from '@Clients/Domain/Model/Clients'
import { RootState } from '@Core/Communication'
import { setClientsAction } from '@Core/Communication/GlobalStore/Reducers/clients/reducer'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetClients } from '../Domain/UseCases/GetClients'
import { SearchClient } from './../Domain/UseCases/SearchClient'

export const useClients = () => {
    const clientContainer = ClientContainer.get<GetClients>(
        ClientRegister.UseCaseGetClients
    )
    const { clients } = useSelector((state: RootState) => state.clients)
    const [localClients, setLocalClients] = useState<Clients[]>([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const getAllClients = async () => {
        setLoading(true)
        const data = await clientContainer.execute()
        dispatch(
            setClientsAction({ data, source: clientContainer.getSource() })
        )
        setLoading(false)
        setLocalClients(data)
    }

    const search = async (query: string) => {
        if (!query) return await getAllClients()
        const searchClientContainer = ClientContainer.get<SearchClient>(
            ClientRegister.UseCaseSearchClient
        )
        const data = await searchClientContainer.execute(query)
        dispatch(
            setClientsAction({
                data,
                source: searchClientContainer.getSource(),
            })
        )
    }

    const localSearch = (query: string) => {
        const filteredClients = clients?.[clientContainer?.getSource()]?.filter(
            (client) =>
                client.corporateName.toLowerCase().includes(query.toLowerCase())
        )
        setLocalClients(filteredClients)
    }

    useEffect(() => {
        getAllClients()
    }, [])

    return {
        clients: clients[clientContainer?.getSource()] || [],
        localClients,
        loading,
        search,
        localSearch,
    }
}
