import { Button } from '@/components/ui/button'
import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@/components/ui/command'
import { RoutesPaths as BalanceRoutes } from '@Balance/RoutesPaths'
import { roles as BalanceRoles } from '@Balance/roles'
import { useCommercialContext } from '@Commercial/Context/CommercialContextProvider'
import { StatusTag } from '@Commercial/Proposal/Components/StatusTag/StatusTag'
import { STATUS } from '@Commercial/Proposal/ProposalList/ProposalList.contants'
import { RoutesPaths as CommercialRoutes } from '@Commercial/RoutesPaths'
import { roles as CommercialRoles } from '@Commercial/roles'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { RoutesPaths as InventoryRoutes } from '@InventoryControl/RoutesPaths'
import { roles as InventoryRoles } from '@InventoryControl/roles'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { CommandLoading, useCommandState } from 'cmdk'
import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

export function CommandMenu() {
    const { hasPermission } = usePermissions()
    const { allProposals } = useCommercialContext()
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()
    const isWindows = String(navigator?.userAgentData?.platform).includes(
        'Windows'
    )

    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                setOpen((open) => !open)
            }
        }
        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    const navigateAndClose = (to: string) => {
        setOpen(false)
        navigate(to, { relative: 'route' })
    }

    const SubItem = ({ permission, ...props }: any) => {
        const search = useCommandState((state) => state.search)
        if (permission && !hasPermission(permission)) return null
        if (!search) return null
        return <CommandItem {...props} />
    }

    const ProposalItem = ({ permission, ...props }: any) => {
        const search = useCommandState((state) => state.search)
        if (permission && !hasPermission(permission)) return null
        return (
            <>
                {allProposals.length === 0 && (
                    <CommandLoading>Carregando...</CommandLoading>
                )}
                {search.length > 3 &&
                    allProposals?.map((p) => (
                        <SubItem
                            value={p.name}
                            permission={permission}
                            onSelect={() =>
                                navigateAndClose(
                                    formatRouteId(
                                        CommercialRoutes.CommercialProposal,
                                        p._id
                                    )
                                )
                            }
                            key={`word-${p.name}`}
                        >
                            <div className="flex w-full justify-between">
                                <div className="flex flex-col p-1 w-8/12">
                                    <span className="font-semibold">
                                        {p.name}
                                    </span>
                                    <span className="text-xs">
                                        Cliente: {p.clientName}
                                    </span>
                                </div>
                                <div className="flex items-center justify-between">
                                    {p?.status && STATUS[p?.status] ? (
                                        <StatusTag
                                            title="Atualizado automaticamente"
                                            status={p.status}
                                        >
                                            {STATUS[p?.status]}
                                        </StatusTag>
                                    ) : (
                                        <StatusTag
                                            title="Aguardando atualização"
                                            status="default"
                                        >
                                            N/D
                                        </StatusTag>
                                    )}
                                </div>
                            </div>
                        </SubItem>
                    ))}
            </>
        )
    }
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                className="inline-flex items-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground h-9 px-4 py-2 relative w-full justify-start text-sm text-muted-foreground sm:pr-12 md:w-40 lg:w-64"
                variant="outline"
            >
                Buscar...
                <kbd className="pointer-events-none absolute right-1.5 top-1.5 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex text-white bg-od-primary-500">
                    <span className="text-xs">{isWindows ? 'Ctrl' : '⌘'}</span>K
                </kbd>
            </Button>
            <CommandDialog open={open} onOpenChange={setOpen}>
                <CommandInput placeholder="Digite um comando ou uma pesquisa" />
                <CommandList>
                    <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>
                    <CommandGroup heading="Sugestões">
                        <CommandItem onSelect={() => navigateAndClose('/')}>
                            <div className="flex items-center">
                                <AiOutlineHome />
                                <span className="ml-2">Home</span>
                            </div>
                        </CommandItem>
                        {hasPermission(
                            BalanceRoles.movements.permissions.read.key
                        ) && (
                            <CommandItem
                                onSelect={() =>
                                    navigateAndClose(BalanceRoutes.Movements)
                                }
                                value={'movimentações'}
                            >
                                <div className="flex flex-col">
                                    <span>Movimentações</span>
                                    <span className="text-xs">
                                        Controle de saldo
                                    </span>
                                </div>
                            </CommandItem>
                        )}
                        {hasPermission(
                            InventoryRoles.entries.permissions.read.key
                        ) && (
                            <CommandItem
                                onSelect={() =>
                                    navigateAndClose(
                                        InventoryRoutes.InventoryEntries
                                    )
                                }
                                value={'entradas'}
                            >
                                <div className="flex flex-col">
                                    <span>Entradas</span>
                                    <span className="text-xs">
                                        Controle de estoque
                                    </span>
                                </div>
                            </CommandItem>
                        )}
                        {hasPermission(
                            CommercialRoles.proposal.permissions.read.key
                        ) && (
                            <CommandItem
                                onSelect={() =>
                                    navigateAndClose(
                                        CommercialRoutes.CommercialProposals
                                    )
                                }
                                value={'propostas'}
                            >
                                <div className="flex flex-col">
                                    <span>Propostas</span>
                                    <span className="text-xs">Comercial</span>
                                </div>
                            </CommandItem>
                        )}
                    </CommandGroup>
                    <ProposalItem
                        permission={
                            CommercialRoles.proposal.permissions.read.key
                        }
                    />
                </CommandList>
            </CommandDialog>
        </>
    )
}
