export const WhatsIsPreMovementsBottomSheet = () => {
    return (
        <>
            <h3>Entendendo as pré-movimentações</h3>
            <h4>- O que são e como funcionam</h4>
            <p>
                O nosso sistema é capaz de gerar automaticamente{' '}
                <strong>"pré-movimentações"</strong> para os tickets{' '}
                <strong>fechados</strong> da mesa de{' '}
                <strong>🔖Cartão (CAR)</strong> no Tiflux, verificando se não há
                registro de movimentações para esses tickets dentro do nosso
                sistema.
            </p>
            <p>
                Caso ainda não tenham sido registradas quaisquer movimentações,
                o sistema cria as <strong>"pré-movimentações"</strong>{' '}
                correspondentes.
            </p>
            <p>
                É fundamental verificar cuidadosamente todas as informações
                geradas nas <strong>"pré-movimentações"</strong> para evitar
                possíveis <strong>duplicações</strong> ou{' '}
                <strong>movimentações incorretas</strong>. É importante
                ressaltar a importância desse cuidado ao analisar as{' '}
                <strong>pré-movimentações</strong>.
            </p>
            <h4>
                - Horário de coleta de informações e geração de
                pré-movimentações
            </h4>
            <p>
                A coleta de informações e a geração das{' '}
                <strong>pré-movimentações</strong> são realizadas diariamente no
                nosso sistema. Esse processo ocorre às{' '}
                <strong>5 horas da manhã</strong> e leva em consideração as
                atividades do <strong>dia anterior</strong>.
            </p>
        </>
    )
}
