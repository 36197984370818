import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Proposal } from '@Commercial/Proposal/entities/Proposal'
import {
    CommercialVariables,
    DBCommercialProposal,
    PipeDeal,
} from '../DataSource/API/Entity/Proposal'
import ProductsDataSource from '../DataSource/ProposalDataSource'

export class CommercialProposalRepository
    implements BaseRepository<DBCommercialProposal, Proposal>
{
    dataSource: ProductsDataSource
    constructor(_dataSource: ProductsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBCommercialProposal[]> {
        return this.dataSource.getProposal()
    }

    async duplicate(id: string): Promise<DBCommercialProposal> {
        return this.dataSource.duplicate(id)
    }
    async findById(id: string): Promise<DBCommercialProposal> {
        return this.dataSource.getProposalById(id)
    }

    async getVariables(): Promise<CommercialVariables[]> {
        return this.dataSource.getVariables()
    }
    async search(query: string): Promise<DBCommercialProposal[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Proposal): Promise<DBCommercialProposal> {
        return this.dataSource.createProposal(entity)
    }
    async update(entity: DBCommercialProposal): Promise<DBCommercialProposal> {
        return this.dataSource.updateProposal(entity)
    }

    async getPipeProposal(pipe: string): Promise<PipeDeal> {
        return this.dataSource.getPipeProposal(pipe)
    }

    async updateProposalTaxValues(user: string): Promise<void> {
        return this.dataSource.updateProposalTaxValues(user)
    }

    async updateVariables(
        entity: CommercialVariables
    ): Promise<CommercialVariables> {
        return this.dataSource.updateVariables(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteProposal(id)
    }
    async deleteProduct(id: string): Promise<void> {
        return this.dataSource.deleteProductProposal(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
