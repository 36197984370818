import {
    DeleteDestinationsUseCase,
    GetDestinationsUseCase,
    GetDestinationUseCase,
} from '@InventoryControl/Destinations/Domain/UseCases'
import { CreateDestinationUseCase } from '@InventoryControl/Destinations/Domain/UseCases/CreateDestination'
import { Container } from 'inversify'
import { UpdateDestinationUseCase } from '../../../Domain/UseCases/UpdateDestination'
import { InventoryDestinationsRepository } from '../../Repository/DestinationRepository'
import { DestinationAPI } from '../API/DestinationAPI'
import { Register } from './DestinationRegister'

const container = new Container()
container
    .bind<GetDestinationUseCase>(Register.UseCaseGetDestination)
    .toDynamicValue(
        () =>
            new GetDestinationUseCase(
                new InventoryDestinationsRepository(new DestinationAPI())
            )
    )
container
    .bind<GetDestinationsUseCase>(Register.UseCaseGetDestinations)
    .toDynamicValue(
        () =>
            new GetDestinationsUseCase(
                new InventoryDestinationsRepository(new DestinationAPI())
            )
    )

container
    .bind<CreateDestinationUseCase>(Register.UseCaseCreateDestination)
    .toDynamicValue(
        () =>
            new CreateDestinationUseCase(
                new InventoryDestinationsRepository(new DestinationAPI())
            )
    )
container
    .bind<UpdateDestinationUseCase>(Register.UseCaseUpdateDestination)
    .toDynamicValue(
        () =>
            new UpdateDestinationUseCase(
                new InventoryDestinationsRepository(new DestinationAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteDestination)
    .toDynamicValue(
        () =>
            new DeleteDestinationsUseCase(
                new InventoryDestinationsRepository(new DestinationAPI())
            )
    )

export default container
