import { User } from '@Core/Entities/User'
import { createSlice } from '@reduxjs/toolkit'
import { ReducersName } from '../ReducersName'
import {
    SetUserAction,
    SetUserFeaturesFlagsAction,
    SetUserPhotoAction,
} from './reducer.type'

const initialState: { user: User | undefined } = {
    user: undefined,
}

const userReducer = createSlice({
    initialState,
    name: ReducersName.user,
    reducers: {
        setUserAction(state, { payload: userPayload }: SetUserAction) {
            state.user = userPayload
        },
        setUserFeaturesFlagsAction(
            state,
            {
                payload: { data: userFeaturesFlagsPayload },
            }: SetUserFeaturesFlagsAction
        ) {
            state.user = {
                ...state.user,
                featuresFlags: userFeaturesFlagsPayload,
            } as User
        },
        setUserPhotoAction(
            state,
            { payload: userPhotoPayload }: SetUserPhotoAction
        ) {
            state.user = {
                ...state.user,
                photo: userPhotoPayload,
            } as User
        },
    },
})
const { actions } = userReducer

export const { setUserAction, setUserPhotoAction, setUserFeaturesFlagsAction } =
    actions

export default userReducer.reducer
