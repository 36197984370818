import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'
import { CommercialVariables } from '../../Data/DataSource/API/Entity/Proposal'

export interface UpdateVariablesProposals {
    execute(entity: CommercialVariables): Promise<CommercialVariables>
    getSource(): string
}

export class UpdateVariablesProposalsUseCase
    implements UpdateVariablesProposals
{
    constructor(private repository: CommercialProposalRepository) {}
    async execute(entity: CommercialVariables): Promise<CommercialVariables> {
        return this.repository.updateVariables(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
