export const typeOptions = [
    {
        label: 'Entrada',
        value: '1',
    },
    {
        label: 'Saída',
        value: '2',
    },
]
