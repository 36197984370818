import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import { InventorySuppliersRepository } from '@InventoryControl/Supplier/Data/Repository/SupplierRepository'

export interface GetSuppliers {
    execute(): Promise<DBInventorySupplier[]>
    getSource(): string
}

export class GetSuppliersUseCase implements GetSuppliers {
    constructor(private repository: InventorySuppliersRepository) {}
    async execute(): Promise<DBInventorySupplier[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
