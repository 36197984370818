import { uniqueId } from 'lodash'

export const priceTableDefault = () => ({
    name: '',
    isNew: true,
    columns: [
        {
            key: uniqueId(),
            value: '1 a 99',
        },
        {
            key: uniqueId(),
            value: '100+',
        },
    ],
    rows: [
        [
            {
                key: uniqueId(),
                isFixed: true,
                value: '50',
            },
            {
                key: uniqueId(),
                isFixed: false,
                value: '0,5',
            },
        ],
    ],
})
