import { NegativeClientStock } from '@Balance/Hooks/useNegativeStocks/useNegativeStocks.types'
import { StockByClient } from '@Balance/Summary/Context/SummaryContext.types'
import { Stock } from '@Core/Entities/Stock'

export const exportStockByClientData = (
    stocksByClient: StockByClient
): Stock[] => {
    if (!stocksByClient) return []
    const stockArray = Object.values(stocksByClient)
        .filter((clientStocks) => clientStocks?.length > 0)
        .flatMap((clientStocks) =>
            clientStocks.map((stock) => ({
                ...stock,
                client: stock.client[0],
                lastInput: stock.product[0].lastInput,
                lastOut: stock.product[0].lastOut,
            }))
        )
    // @ts-ignore
    return stockArray
}

export const exportNegativeStockByClientData = (
    stocksByClient: NegativeClientStock[]
): Stock[] => {
    if (!stocksByClient) return []
    const stockArray = Object.values(stocksByClient).map((stock) => ({
        ...stock,
        client: stock.client[0],
    }))
    // @ts-ignore
    return stockArray
}
