const getScreenSize = () => {
    if (!window) return { width: 0, height: 0 }
    const { height, width } = window.screen
    return { width, height }
}
const GUIDELINE_WIDTH = 1920
const GUIDELINE_HEIGHT = 1080

const REFERENCE_VERTICAL = 'vertical'
const REFERENCE_HORIZONTAL = 'horizontal'

export const horizontalScale = (size: number) =>
    (getScreenSize().width / GUIDELINE_WIDTH) * size

export const verticalScale = (size: number) =>
    (getScreenSize().height / GUIDELINE_HEIGHT) * size

export const roundToNearestPixel = (layoutSize: number): number => {
    const ratio = window?.devicePixelRatio || 1
    return Math.round(layoutSize * ratio) / ratio
}

export const getResponsiveSize = (
    size: string | number,
    suffix: string = 'px',
    reference = REFERENCE_HORIZONTAL
) => {
    if (typeof size === 'string') {
        size = parseInt(size, 10)
    }

    let result = 0

    if (reference === REFERENCE_HORIZONTAL) {
        result = horizontalScale(size)
    }

    if (reference === REFERENCE_VERTICAL) {
        result = verticalScale(size)
    }

    result = Math.round(roundToNearestPixel(result))

    return result.toString() + suffix
}
