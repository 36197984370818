import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { RoutesPaths } from '@Commercial/RoutesPaths'
import { roles } from '@Commercial/roles'
import { LocalStorage } from '@Core/Communication/GlobalStore/LocalStorage/LocalStorage'
import { Button, ButtonVariant, Container } from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { memorizeResult } from '@Utils/Support/memorize'
import { Grid, InputAdornment, Switch } from '@material-ui/core'
import { format } from 'date-fns'
import update from 'immutability-helper'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ProductProposalForm } from '../Components/ProductProposalForm/ProductProposalForm'
import { ProposalFooter } from '../Components/ProposalFooter/ProposalFooter'
import { StatusTag } from '../Components/StatusTag/StatusTag'
import { DBCommercialProposal } from '../Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '../Data/DataSource/Container'
import { DeleteProposal } from '../DeleteProposal/DeleteProposal'
import { useManagerProposal } from '../Hooks/useManagerProposal/useManagerProposal'
import { sumActiveProducts, sumQuantities } from '../Utils/counts'
import { DnD } from './components/DnD/DnD'

const STATUS = {
    won: 'GANHO',
    lost: 'PERDIDO',
    open: 'ABERTO',
}

export const ProposalDetails = () => {
    const [loading, setLoading] = useState(true)
    const [deleteModal, setDeleteModal] = useState(false)
    const [creditCardTax, setCreditCardTax] = useState([])
    const storage = new LocalStorage()
    const [moveDisabled, setMoveDisabled] = useState(
        Boolean(storage.getItem('moveDisabled') === 'true')
    )
    const { id } = useParams<{ id: string }>()
    const navigate = useNavigate()
    const { state: locationState } = useLocation()
    const { duplicate } = locationState || { duplicate: false }
    const variablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )

    const handleGetProposal = useCallback(async () => {
        const [getProposal] = (await getProposalContainer.execute(
            id || ''
        )) || [{}]

        getProposal.name = String(getProposal.name).toUpperCase()
        if (duplicate) {
            getProposal.name = ''
        }
        getProposal.products = getProposal.products
            .sort((a, b) => a?.position - b?.position)
            .sort((a, b) => moveDisabled && b.isActive - a.isActive)

        if (!getProposal.clientName || getProposal.clientName === 'N/D') {
            getClient(getProposal.name).then((client) => {
                getProposal.clientName = client
                fillForm(getProposal)
                setLoading(false)
            })
        } else {
            fillForm(getProposal)
            setLoading(false)
        }
    }, [duplicate, moveDisabled])

    useEffect(() => {
        const products = [...state?.products]
        const newProducts = products
            .sort((a, b) => a?.position - b?.position)
            .sort((a, b) => moveDisabled && b.isActive - a.isActive)
        setValue('products', newProducts)
    }, [moveDisabled])

    const {
        loading: _loading,
        state,
        handleSubmit,
        register,
        handleAddingNewProduct,
        methods,
    } = useManagerProposal({ callback: handleGetProposal })
    const { control, setValue, reset } = methods
    const disabled = useMemo(
        () => state.status === 'won' || state.status === 'lost',
        [state.status]
    )
    const getProposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetProposal
    )

    const deleteProductProposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseDeleteProductProposal
    )

    const deleteProposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseDeleteProposal
    )

    const pipeContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetProposalPipe
    )

    const deleteProposal = useCallback(async () => {
        setLoading(true)
        await deleteProposalContainer.execute(id || '')
        toast.info('Proposta deletada com sucesso!')
        navigate(RoutesPaths.CommercialProposals)
    }, [id])

    const fillForm = (data: DBCommercialProposal) => {
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'discount') {
                setValue(key, value || 0)
                return
            }
            if (key === 'products') {
                value.forEach((item: any, index: number) => {
                    const { product, ...rest } = item
                    if (product === null) {
                        return
                    }
                    const { _id, ...productRest } = product
                    setValue(`products[${index}]`, {
                        ...productRest,
                        productID: _id,
                    })
                    Object.entries(rest).forEach(
                        ([productKey, productValue]) => {
                            setValue(
                                `products[${index}].${productKey}`,
                                productValue
                            )
                        }
                    )
                })
                return
            }
            setValue(key, value)
        })
    }

    const handleDeleteProduct = useCallback(
        async (id: string, index: number) => {
            try {
                setValue(
                    'products',
                    state?.products
                        .slice(0, index)
                        .concat(state.products.slice(index + 1))
                )
                await deleteProductProposalContainer.execute(id)
                setTimeout(handleSubmit, 5)
            } catch (error) {
                console.log(error)
            }
        },
        [state]
    )

    const getClient = useCallback(
        memorizeResult(async (pipe: string) => {
            if (!pipe) return 'N/D'
            try {
                const pipeItem = await pipeContainer.execute(pipe)
                return pipeItem.organization.name || 'N/D'
            } catch (e) {
                return 'N/D'
            }
        }),
        []
    )

    const getCreditCard = async () => {
        const variables = await variablesContainer.execute()
        const { variableValue } = variables.find(
            (item) => item.variableType === 'creditCard'
        ) || {
            variableValue: '[]',
        }
        setCreditCardTax(
            JSON.parse(variableValue as string).sort((a, b) => a.name - b.name)
        )
    }

    useEffect(() => {
        getCreditCard()
    }, [])

    useEffect(() => {
        storage.setItem('moveDisabled', moveDisabled)
    }, [moveDisabled])

    useEffect(() => {
        setLoading(true)
        reset({
            products: [
                {
                    isActive: true,
                    markup: 100,
                },
            ],
            bvPercentage: 0,
            bvAmount: 0,
        })
        handleGetProposal()
    }, [id])

    useEffect(() => {
        navigate(formatRouteId(RoutesPaths.CommercialProposal, id || ''), {
            state: undefined,
            replace: true,
        })
    }, [_loading])

    const handleDuplicateProposal = (index: number) => () => {
        let duplicateProposal = [
            ...state?.products,
        ] as unknown as DBCommercialProposal & { _id: string }[]
        const { productID, ...data } = state?.products[
            index
        ] as unknown as DBCommercialProposal & { productID: string }
        duplicateProposal.push({ ...data, _id: String(productID || data._id) })
        methods.setValue('products', duplicateProposal as any)
    }

    const FixedTextInfo = () => (
        <>
            <span className="flex flex-col  min-w-fit">
                <div className="flex text-base gap-2 justify-around">
                    <span>Parcelas</span> - <span>Taxa</span>
                </div>
                {!!creditCardTax &&
                    creditCardTax?.map(({ name, value, id }) => (
                        <div
                            key={id}
                            className="flex text-sm justify-around text-center"
                        >
                            <span className="text-center p-x-2">{name}</span> -{' '}
                            <span>{value}</span>
                        </div>
                    ))}
            </span>
        </>
    )

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            setValue(
                'products',
                update(state.products, {
                    $splice: [
                        [dragIndex, 1],
                        [
                            hoverIndex,
                            0,
                            {
                                ...state.products[dragIndex],
                                position: hoverIndex,
                            } as any,
                        ],
                    ],
                })
            )
            setTimeout(handleSubmit, 5)
        },
        [state]
    )

    const renderDnDCard = useCallback(
        (item, index: number) => {
            return (
                <Grid item xs={12} key={item._id}>
                    <DnD
                        key={item._id}
                        id={item._id}
                        index={index}
                        moveCard={moveCard}
                        text={item?.name}
                    >
                        <ProductProposalForm
                            disabled={disabled}
                            hasDragAndDrop={state?.products.length > 1}
                            error={false}
                            isUnique={state?.products.length === 1}
                            onDelete={() =>
                                handleDeleteProduct(item._id, index)
                            }
                            controller={control}
                            state={state}
                            key={item._id}
                            index={index}
                            register={register}
                            setValue={setValue}
                            duplicateProposal={handleDuplicateProposal(index)}
                        />
                    </DnD>
                </Grid>
            )
        },
        [
            register,
            setValue,
            handleDuplicateProposal,
            handleDeleteProduct,
            state,
            control,
        ]
    )

    if (loading) {
        return <Loader />
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <DeleteProposal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                callback={deleteProposal}
            />
            <Container>
                <Grid container>
                    <Grid item lg={6} md={6} xs={6}>
                        <BackButton />
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-12">
                            <div className="grid grid-cols-7 gap-1 w-full">
                                <div className="col-span-1">
                                    <Input
                                        size="small"
                                        className="font-bold-minput"
                                        fullWidth
                                        name={'name'}
                                        disabled={disabled}
                                        inputProps={{ placeholder: 'ODC1234' }}
                                        useUnform={false}
                                        register={register('name')}
                                        label="Pipe"
                                    />
                                </div>
                                <div className="col-span-2">
                                    <Input
                                        fullWidth
                                        size="small"
                                        name={'clientName'}
                                        disabled
                                        useUnform={false}
                                        register={register('clientName')}
                                        label="Cliente"
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Input
                                        fullWidth
                                        size="small"
                                        name={'createdAt'}
                                        disabled
                                        useUnform={false}
                                        register={register('createdAt')}
                                        value={format(
                                            new Date(state?.createdAt),
                                            'dd/MM/yyyy'
                                        )}
                                        label="Data"
                                    />
                                </div>
                                <div className="flex gap-2 pr-2 col-span-3">
                                    <PermissionWrapper
                                        permission={
                                            roles.proposal.permissions.delete
                                                .key
                                        }
                                    >
                                        <Button
                                            className="w-full"
                                            type="button"
                                            onClick={() => setDeleteModal(true)}
                                            disabled={_loading || disabled}
                                            title={
                                                disabled
                                                    ? 'Não é possível deletar uma proposta que foi concluída'
                                                    : ''
                                            }
                                            variant={ButtonVariant.secondary}
                                        >
                                            Deletar proposta
                                        </Button>
                                    </PermissionWrapper>
                                    <PermissionWrapper
                                        permission={
                                            roles.proposal.permissions.update
                                                .key
                                        }
                                    >
                                        <Button
                                            className="w-full"
                                            variant={ButtonVariant.primary}
                                            color="primary"
                                            title={
                                                disabled
                                                    ? 'Não é possível editar uma proposta que foi concluída'
                                                    : ''
                                            }
                                            disabled={_loading || disabled}
                                        >
                                            {_loading
                                                ? 'Carregando...'
                                                : 'Salvar Proposta'}
                                        </Button>
                                    </PermissionWrapper>

                                    {state?.status && STATUS[state?.status] ? (
                                        <StatusTag
                                            className="!h-[40px]"
                                            size="large"
                                            status={state.status}
                                        >
                                            {STATUS[state?.status]}
                                        </StatusTag>
                                    ) : (
                                        <StatusTag
                                            className="!h-[40px]"
                                            size="large"
                                            title="Aguardando atualização"
                                            status="default"
                                        >
                                            N/D
                                        </StatusTag>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 w-full">
                            <div className="flex justify-between gap-2">
                                <span className="text-xl font-bold"></span>
                                <div className="flex flex-row justify-center items-center pr-2">
                                    <Switch
                                        size="small"
                                        alt="Mover desativados para baixo automaticamente"
                                        checked={moveDisabled}
                                        disabled={disabled}
                                        onChange={() => {
                                            setMoveDisabled(!moveDisabled)
                                        }}
                                    />
                                    <span className="text-xs">
                                        Mover desativados para baixo
                                        automaticamente
                                    </span>
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                {Boolean(state?.products.length) &&
                                    state?.products?.map(
                                        (item: any, index: number) =>
                                            renderDnDCard(item, index)
                                    )}
                            </Grid>
                        </div>
                        <div className="grid col-span-12 grid-cols-12 w-full px-1 py-2">
                            <div className="col-span-5">
                                {Boolean(!disabled) && (
                                    <Button
                                        type="button"
                                        onClick={handleAddingNewProduct}
                                        variant={ButtonVariant.primary}
                                    >
                                        Adicionar Produto
                                    </Button>
                                )}
                            </div>
                            <div className="col-span-7 flex w-full justify-end gap-1 items-center">
                                <div>
                                    Produtos:{' '}
                                    <span title="Produtos ativos">
                                        {sumActiveProducts(state)}
                                    </span>{' '}
                                    /{' '}
                                    <span title="Produtos">
                                        {state?.products.length}
                                    </span>
                                </div>
                                - <div>Quantidade: {sumQuantities(state)}</div>
                            </div>
                        </div>

                        <div className="col-span-12 grid grid-cols-12 w-full gap-2">
                            <div className="col-span-7 md:col-span-10 grid grid-cols-12 gap-2 w-full">
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        name={'bvPercentage'}
                                        min="0"
                                        max="100"
                                        size="small"
                                        type="number"
                                        disabled={disabled}
                                        useUnform={false}
                                        inputProps={{
                                            min: 0,
                                            max: 100,
                                            step: 0.01,
                                        }}
                                        register={register('bvPercentage')}
                                        InputProps={{
                                            inputProps: {
                                                step: 0.01,
                                                min: 0,
                                            },
                                            endAdornment: (
                                                <HoverCard>
                                                    <HoverCardTrigger
                                                        asChild
                                                        className="cursor-help"
                                                    >
                                                        <InputAdornment position="end">
                                                            <AiOutlineInfoCircle />
                                                        </InputAdornment>
                                                    </HoverCardTrigger>
                                                    <HoverCardContent className="grid gap-2 w-fit max-w-lg">
                                                        <FixedTextInfo />
                                                    </HoverCardContent>
                                                </HoverCard>
                                            ),
                                        }}
                                        label="BV / Cartão %"
                                    />
                                </div>
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        disabled
                                        size="small"
                                        control={control}
                                        name={'bvAmountToShow'}
                                        register={register('bvAmountToShow')}
                                        defaultValue={0}
                                        useUnform={false}
                                        label="Valor total do BV / Cartão"
                                    />
                                </div>
                            </div>
                            <div className="col-span-7 md:col-span-10 grid grid-cols-12 gap-2 w-full">
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        size="small"
                                        name={'discount'}
                                        min={0}
                                        max={100}
                                        type="number"
                                        disabled={disabled}
                                        inputProps={{ min: 0, max: 100 }}
                                        useUnform={false}
                                        register={register('discount')}
                                        label="Desconto %"
                                    />
                                </div>
                                <div className="col-span-3 w-full">
                                    <Input
                                        fullWidth
                                        disabled
                                        size="small"
                                        control={control}
                                        name={'totalDiscount'}
                                        defaultValue={0}
                                        useUnform={false}
                                        label="Valor total do Desconto"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <TextArea
                                register={register('observations')}
                                control={control}
                                useUnform={false}
                                disabled={disabled}
                                name={'observations'}
                                label="Observações"
                                fullWidth
                                rows={5}
                            />
                        </div>
                    </div>
                </form>
            </Container>
            <ProposalFooter state={state} setFormValue={setValue} />
        </DndProvider>
    )
}
