import { Button } from '@Core/Components'
import { useNavigate } from 'react-router-dom'
import { PermissionContainer } from './PermissionDenied.styles'

export const PermissionDenied = () => {
    const navigate = useNavigate()

    return (
        <PermissionContainer>
            <h1 className="text-2xl font-bold">Permissão Negada</h1>
            <span>
                Desculpe, você não tem permissão para acessar esta
                funcionalidade.
            </span>
            <Button onClick={() => navigate('/')}>Voltar para Home</Button>
        </PermissionContainer>
    )
}
