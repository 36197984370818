import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import {
    InventoryEntriesContainer,
    InventoryEntriesRegister,
} from '@InventoryControl/Entries/Data/DataSource/Container'
import { useState } from 'react'

export const useInventoryEntries = () => {
    const getEntriesUseCase = InventoryEntriesContainer.get(
        InventoryEntriesRegister.UseCaseGetEntries
    )
    const [entries, setEntries] = useState<DBInventoryEntry[]>([])
    const [loading, setLoading] = useState(false)

    const getEntries = async () => {
        setLoading(true)
        try {
            const entries = await getEntriesUseCase.execute()
            setEntries(entries)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }
    return { entries, loading, getEntries }
}
