import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import { InventorySuppliersRepository } from '@InventoryControl/Supplier/Data/Repository/SupplierRepository'
import { Supplier } from '@InventoryControl/Supplier/entities/Supplier'

export interface CreateSupplier {
    execute(client: Supplier): Promise<DBInventorySupplier>
    getSource(): string
}

export class CreateSupplierUseCase implements CreateSupplier {
    constructor(private repository: InventorySuppliersRepository) {}
    async execute(client: Supplier): Promise<DBInventorySupplier> {
        return this.repository.create(client)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
