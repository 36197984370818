import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import { InventoryDestinationsRepository } from '@InventoryControl/Destinations/Data/Repository/DestinationRepository'
import { Destination } from '@InventoryControl/Destinations/entities/Destination'

export interface CreateDestination {
    execute(client: Destination): Promise<DBInventoryDestination>
    getSource(): string
}

export class CreateDestinationUseCase implements CreateDestination {
    constructor(private repository: InventoryDestinationsRepository) {}
    async execute(client: Destination): Promise<DBInventoryDestination> {
        return this.repository.create(client)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
