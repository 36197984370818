// @ts-ignore
export interface InputProps {
    label?: string
    supportMessages?: SupportMessages[]
    hasError?: boolean
    name: string
    required?: boolean
    [key: string]: any
}

export interface SupportMessages {
    message: string
    variant: 'error' | 'warning' | 'info' | 'success'
}

export interface InputSupportMessagesProps {
    variant: 'error' | 'warning' | 'info' | 'success'
}

export interface SInputProps {
    hasError: boolean
    focused: boolean
    [key: string]: any
}

export interface InputLabelProps {
    focused: boolean
    hasError: boolean
    active: boolean
    _onFocus?: (e: any) => void
    children?: React.ReactNode
    size?: InputSize
}

export enum InputSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}
