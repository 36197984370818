import { Button } from '@material-ui/core'
import React from 'react'

export const FileUpload = ({
    multiple = false,
    onDone,
    children,
}: {
    multiple?: boolean
    onDone: (files: File | File[]) => void
    children: React.ReactNode
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (!files) return
        let allFiles: any[] = []
        for (const element of files) {
            let file = element
            let reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                }
                allFiles.push(fileInfo)
                if (allFiles.length == files.length) {
                    if (multiple) {
                        onDone(allFiles)
                    } else {
                        onDone(allFiles[0])
                    }
                }
            }
        }
    }

    return (
        <div>
            <input
                color="primary"
                accept="image/*"
                type="file"
                onChange={handleChange}
                id="icon-button-file"
                style={{ display: 'none' }}
                multiple={multiple}
            />
            <label htmlFor="icon-button-file">
                <Button
                    variant="contained"
                    component="span"
                    color="primary"
                >
                    {children}
                </Button>
            </label>
        </div>
    )
}
