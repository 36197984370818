import { getContrastColor } from '@Utils/Formatters/constrastColor'
import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import styled from 'styled-components'

export const Status = styled.div<{ error?: boolean; success?: boolean }>`
    border-color: ${({ error, success }) =>
        error ? '#f44336' : success ? '#4caf50' : 'transparent'};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    position: relative;

    .success {
        display: ${({ success }) => (success ? 'flex' : 'none')};
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        justify-content: center;
        align-items: center;
        background-color: ${convertHexToRGBA('#4caf50', 0.1)};
        z-index: 10;
        span {
            font-size: 4rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.input.supportMessage.success};
            // text-shadow: 0px 0px 4px ${({ theme }) =>
                getContrastColor(theme.text)};
            text-shadow: ${({ theme }) => getContrastColor(theme.text)} 1px 0px
                    0px,
                ${({ theme }) => getContrastColor(theme.text)} 0.540302px
                    0.841471px 0px,
                ${({ theme }) => getContrastColor(theme.text)} -0.416147px 0.909297px
                    0px,
                ${({ theme }) => getContrastColor(theme.text)} -0.989992px 0.14112px
                    0px,
                ${({ theme }) => getContrastColor(theme.text)} -0.653644px -0.756802px
                    0px,
                ${({ theme }) => getContrastColor(theme.text)} 0.283662px -0.958924px
                    0px,
                ${({ theme }) => getContrastColor(theme.text)} 0.96017px -0.279415px
                    0px;
            text-transform: uppercase;
            transform: rotate(-15deg);
        }
    }
`
