import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'

import { DeleteProposalContent } from './DeleteProposal.styles'
import { DeleteProposalProps } from './DeleteProposal.types'



export const DeleteProposal = ({
    isOpen,
    setIsOpen,
    callback,
}: DeleteProposalProps) => {

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
                <h2>Deletar Proposta</h2>
                <DeleteProposalContent>
                    <p>
                   Tem certeza que deseja deletar essa proposta?
                    </p>
                   <b>Está ação não pode ser desfeita</b>
                </DeleteProposalContent>
                <ModalFooter>
                    <Button
                        onClick={toggleModal}
                        variant={ButtonVariant.secondary}
                    >
                        Cancelar
                    </Button>
                    <Button onClick={callback} variant={ButtonVariant.primary}>
                        Deletar
                    </Button>
                </ModalFooter>
        </Modal>
    )
}
