import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import { InventoryEntryRepository } from '@InventoryControl/Entries/Data/Repository/EntryRepository'

export interface GetEntry {
    execute(id: string): Promise<DBInventoryEntry>
    getSource(): string
}

export class GetEntryUseCase implements GetEntry {
    constructor(private repository: InventoryEntryRepository) {}
    async execute(id: string): Promise<DBInventoryEntry> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
