import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'

export interface GetProposal {
    execute(id: string): Promise<DBCommercialProposal>
    getSource(): string
}

export class GetProposalUseCase implements GetProposal {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(id: string): Promise<DBCommercialProposal> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
