import { Pagination } from '@Core/Data/DataSource/API/Entity/Pagination'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'

export interface GetMovements {
    execute(page: Number, limit: Number): Promise<Pagination<DBMovement>>
    getSource(): string
}

export class GetMovementsUseCase implements GetMovements {
    constructor(private repository: MovementRepository) {}
    async execute(
        page: number,
        limit: number
    ): Promise<Pagination<DBMovement>> {
        return this.repository.findAll(page, limit)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
