import { CoreContainer, CoreRegister } from '@Core/Data/DataSource/Container'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button, ButtonVariant } from '../Button/Button'
import { Modal, ModalFooter } from '../Modal/Modal'
import { SendMailWithStockProps } from './SendMailWithStock.types'

export const SendMailWithStock = ({
    client,
    onClose,
    isOpen,
    onSuccess,
}: SendMailWithStockProps) => {
    const [loading, setLoading] = useState(false)
    const sendStockUseCase = CoreContainer.get(CoreRegister.UseCaseSendStock)
    const sendMail = async () => {
        setLoading(true)
        const { _id } = client
        try {
            await sendStockUseCase.execute(_id)
            toast.success('Atualização de saldo enviada com sucesso')
            onSuccess?.()
            onClose?.()
        } catch (err) {
            toast.error('Erro ao enviar o email')
        }
        setLoading(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <center>
                <h2>
                    Você gostaria de enviar a atualização de saldo para o
                    cliente {client.corporateName}?
                </h2>
            </center>
            <ul>
                <li>Será enviado um email para todos os e-mails do cliente.</li>
                <li>
                    Será enviado o saldo atualizado de todos os produtos do
                    cliente.
                </li>
            </ul>
            <ModalFooter>
                <Button onClick={onClose} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button
                    onClick={() => sendMail()}
                    variant={ButtonVariant.primary}
                    disabled={loading}
                >
                    {loading ? 'Aguarde...' : 'Enviar'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
