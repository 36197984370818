import {
    CreateProductUseCase,
    DeleteProductsUseCase,
    GetProductUseCase,
    GetProductsUseCase,
    UpdateProductUseCase,
} from '@InventoryControl/Products/Domain/UseCases'

export const Register = {
    UseCaseGetProducts: GetProductsUseCase,
    UseCaseGetProduct: GetProductUseCase,
    UseCaseCreateProduct: CreateProductUseCase,
    UseCaseUpdateProduct: UpdateProductUseCase,
    UseCaseDeleteProduct: DeleteProductsUseCase,
}
