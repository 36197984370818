import { InventoryDestinationsRepository } from '@InventoryControl/Destinations/Data/Repository/DestinationRepository'

export interface DeleteDestinations {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteDestinationsUseCase implements DeleteDestinations {
    constructor(private repository: InventoryDestinationsRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
