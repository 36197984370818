import { Exits } from '@InventoryControl/Exits/entities/Exits'
import { Api } from '@Services/api'
import ExitsDataSource from '../ExitsDataSource'
import { DBInventoryExits } from './Entity/Exits'

export class ExitAPI implements ExitsDataSource {
    axios = new Api()

    async getExitById(id: string): Promise<DBInventoryExits> {
        const { data } = await this.axios.get(`/inventory/exits/${id}`)
        return data
    }

    async getExits(): Promise<DBInventoryExits[]> {
        const { data } = await this.axios.get('/inventory/exits')
        return data
    }

    async search(query: string): Promise<DBInventoryExits[]> {
        const { data } = await this.axios.get(
            `/inventory/exits/search/${query}`
        )
        return data
    }

    async createExit(exit: Exits): Promise<DBInventoryExits> {
        const { data } = await this.axios.post('/inventory/exits', exit)
        return data
    }

    async updateExit(exit: DBInventoryExits): Promise<DBInventoryExits> {
        const { data } = await this.axios.put(
            `/inventory/exits/${exit._id}`,
            exit
        )
        return data
    }

    async deleteExit(id: string): Promise<void> {
        await this.axios.delete(`/inventory/exits/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
