import { createGlobalStyle } from 'styled-components'
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif !important;
    transition: all 0.50s linear;
  }
  .clickable, .clickable * > * {
    cursor: pointer;
  }
  .cupertino-pane {
      margin: 20px;
  }
`
