interface JwtPayload {
    exp: number
}

export function isTokenExpired(token: string): boolean {
    if (!token) return true
    const jwtPayload: JwtPayload = JSON.parse(atob(token.split('.')[1]))
    const now: number = Date.now() / 1000 // em segundos
    return jwtPayload.exp < now
}
