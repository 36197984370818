import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import {
    InventoryExitsContainer,
    InventoryExitsRegister,
} from '@InventoryControl/Exits/Data/DataSource/Container'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useInventoryExitManager = () => {
    const getExitUseCase = InventoryExitsContainer.get(
        InventoryExitsRegister.UseCaseGetExit
    )
    const saveExitUseCase = InventoryExitsContainer.get(
        InventoryExitsRegister.UseCaseUpdateExit
    )
    const deleteExitUseCase = InventoryExitsContainer.get(
        InventoryExitsRegister.UseCaseDeleteExit
    )

    const [state, setState] = useState<DBInventoryExits>({} as DBInventoryExits)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleChange = ({ target }: { target: any }) => {
        const { name, value } = target
        setState((prev) => ({ ...prev, [name]: value }))
    }

    const handleSave = async (callback: () => void) => {
        setLoading(true)
        try {
            await saveExitUseCase.execute(state)
            callback?.()
        } catch (e) {
            console.log(e)
            toast.error('Erro ao salvar')
            Sentry.captureException(e)
        }
        setLoading(false)
    }

    const getExit = async (id: string) => {
        setLoading(true)
        try {
            const exit = await getExitUseCase.execute(id)
            setState(exit)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    const deleteExit = async () => {
        setLoading(true)
        try {
            await deleteExitUseCase.execute(state._id)
            toast.success('Saída excluída com sucesso')
            navigate(RoutesPaths.InventoryExits)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return {
        state,
        loading,
        handleChange,
        handleSave,
        getExit,
        deleteExit,
    }
}
