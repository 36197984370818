import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import {
    InventorySupplierContainer,
    InventorySupplierRegister,
} from '@InventoryControl/Supplier/Data/DataSource/Container'
import { useState } from 'react'

export const useInventorySuppliers = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [suppliers, setSuppliers] = useState<DBInventorySupplier[]>([])
    const getSuppliersUseCase = InventorySupplierContainer.get(
        InventorySupplierRegister.UseCaseGetSuppliers
    )

    const formattedAutocomplete = (entity: DBInventorySupplier[]) => {
        return entity.map((destination) => ({
            value: destination,
            label: destination.name,
        }))
    }

    const getSuppliers = async () => {
        setLoading(true)
        try {
            const suppliers = await getSuppliersUseCase.execute()
            setSuppliers(suppliers)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return { suppliers, getSuppliers, loading, formattedAutocomplete }
}
