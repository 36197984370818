export enum RoutesPaths {
    InventoryProducts = '/inventory/products',
    InventoryProductsSummary = '/inventory/products/summary',
    InventoryProduct = '/inventory/product/:id',
    InventoryExits = '/inventory/exits',
    InventoryExit = '/inventory/exit/:id',
    InventoryCreateExit = '/inventory/exits/create',
    InventoryEntries = '/inventory/entries',
    InventoryEntry = '/inventory/entry/:id',
    InventoryCreateEntry = '/inventory/entries/create',
    InventorySummary = '/inventory/summary',
    InventorySuppliers = '/inventory/suppliers',
    InventorySupplier = '/inventory/supplier/:id',
    InventoryDestinations = '/inventory/destinations',
    InventoryDestination = '/inventory/destination/:id',
}
