import { InferType, object, string } from 'yup'

export const supplierSchema = object().shape({
    name: string().required('Nome obrigatório'),
    contact: string(),
    email: string().email('Email inválido'),
    phone: string(),
    totalPurchases: string(),
})

export interface Supplier extends InferType<typeof supplierSchema> {}
