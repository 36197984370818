import { IconButtonContainer } from './IconButton.styles'

export const IconButton = ({
    children,
    onClick,
    size = 'medium',
    justifyContent = 'center',
    alignItems = 'flex-start',
}: {
    size: 'small' | 'large' | 'medium'
    children: React.ReactNode
    onClick: () => void
    justifyContent?: 'center' | 'flex-end' | 'flex-start'
    alignItems?: 'center' | 'flex-end' | 'flex-start'
}) => {
    return (
        <IconButtonContainer
            // @ts-ignore
            size={size}
            justifyContent={justifyContent}
            alignItems={alignItems}
            onClick={onClick}
        >
            {children}
        </IconButtonContainer>
    )
}
