import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import { InventoryDestinationsRepository } from '@InventoryControl/Destinations/Data/Repository/DestinationRepository'

export interface GetDestinations {
    execute(): Promise<DBInventoryDestination[]>
    getSource(): string
}

export class GetDestinationsUseCase implements GetDestinations {
    constructor(private repository: InventoryDestinationsRepository) {}
    async execute(): Promise<DBInventoryDestination[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
