import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'
import { Clients } from '../Model/Clients'

export interface GetClients {
    execute(): Promise<Clients[]>
    getSource(): string
}

export class GetClientsUseCase implements GetClients {
    constructor(private repository: ClientsRepository) {}
    async execute(): Promise<Clients[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
