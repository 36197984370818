import styled from 'styled-components'

export const FooterContainer = styled.div`
    display: flex;
    z-index: 9;
    position: sticky;
    bottom: 0;
    width: calc(100% - 20px);
    border-radius: 8px;
    right: 10px;
    background-color: ${({ theme }) => theme.colors.container.background};
    justify-content: space-between;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .saleValue {
        background-color: rgba(0, 100, 255, 0.1);
    }

    .costTotal {
        background-color: rgba(255, 0, 50, 0.1);
    }

    .tax {
        background-color: rgba(220, 200, 0, 0.1);
    }

    .bv {
        background-color: rgba(220, 100, 0, 0.1);
    }

    .profit {
        background-color: rgba(0, 200, 0, 0.1);
    }

    .markup {
        background-color: rgba(0, 255, 0, 0.1);
    }

    div {
        &:first-child {
            border-radius: 8px 0 0 8px;
        }
        &:last-child {
            border-radius: 0 8px 8px 0;
        }
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 10px;
        min-height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`
