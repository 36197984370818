import {
    CommercialAccessories,
    CommercialCell,
    CommercialPriceRangeCell,
    DBCommercialProduct,
} from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { Dispatch, SetStateAction } from 'react'
import { DBCommercialProposal } from '../Data/DataSource/API/Entity/Proposal'
import { IFormInputs } from '../Hooks/useNewProposal/useNewProposal.types'
import { calculateSaleValue } from './calculateBv'
import { removeLeadingZeros } from './removeLeadingZeros'

export const formCalculate =
    (setState: Dispatch<SetStateAction<IFormInputs>>) =>
    (value: DBCommercialProposal, { name }: { name: any }) => {
        for (let index = 0; index < value.products.length; index++) {
            if (value.products[index].isActive === undefined) {
                value.products[index].isActive = true
            }
            if (value.products[index].unitCost === undefined) {
                value.products[index].unitCost = 0
            }
            if (value.products[index].quantity === undefined) {
                value.products[index].quantity = 0
            }

            value.products[index].unitCost = removeLeadingZeros(
                value.products[index].unitCost
            )
            value.products[index].freight =
                removeLeadingZeros(value.products[index].freight) || 0
            const isCord = value.products[index].productType === 'dynamic'
            const isRoller = value.products[index].productType === 'rollerClip'
            const isGift = value.products[index].productType === 'gift'
            if (isCord || isRoller || isGift) {
                if (isCord) {
                    value.products[index].unitCost = calculateCost(
                        value.products[index].size,
                        value.products[index].quantity,
                        value.products[index].cordType
                    )
                    value.products[index].unitCost =
                        calculateAccessories(value.products[index]) +
                        value.products[index].unitCost
                    value.products[index].unitCost =
                        value.products[index].unitCost.toFixed(2)
                }
                if (
                    (isRoller || isGift) &&
                    !value.products[index].markupTable
                ) {
                    value.products[index].markupTable =
                        value.products[index].markups?.[0]
                }

                const { markup, minMarkup, maxMarkup } = getCordMarkup(
                    value.products[index],
                    value.products[index].quantity
                )

                if (
                    value.products[index].maxMarkup !== maxMarkup ||
                    value.products[index].minMarkup !== minMarkup
                ) {
                    value.products[index].maxMarkup = maxMarkup
                    value.products[index].minMarkup = minMarkup
                    value.products[index].markup = markup
                }

                if (!value?.products[index]?.markup) {
                    value.products[index].markup = markup
                }
            }
            if (value?.bvPercentage) {
                value.products[index].unitCostTotal = calculateSaleValue(
                    Number(value.products[index].unitCostTotal),
                    Number(value.bvPercentage) / 100
                )
            }
            if (value.products?.[index] && !value?.products[index]?.markup) {
                value.products[index].markup = 100
            }

            let unitPageCost = 0
            if (isRoller) {
                const { totalPagesCost, pages } = calculateRollerPageCost(
                    value.products[index]
                )
                value.products[index].pages = pages
                value.products[index].totalPagePrice = totalPagesCost
                unitPageCost =
                    value.products[index].totalPagePrice /
                    Number(value.products[index].quantity)
                value.products[index].unitPageCost = unitPageCost
            }
            if (isGift) {
                const { unitCost: cost } = calculateCostForGift(
                    value.products[index]
                )
                value.products[index].unitCost = cost
            }

            const unitFreight = calculateSaleValue(
                (Number(removeLeadingZeros(value.products?.[index]?.freight)) ||
                    0) /
                    (Number(value.products?.[index]?.quantity) || 0) +
                    Number(
                        String(value?.products[index]?.unitCost).replace(
                            ',',
                            '.'
                        )
                    ) +
                    Number(unitPageCost || 0),
                Number(value.bvPercentage) || 0
            )

            const unitFreightWithoutBV = calculateSaleValue(
                (Number(removeLeadingZeros(value.products?.[index]?.freight)) ||
                    0) /
                    (Number(value.products?.[index]?.quantity) || 0) +
                    Number(
                        String(value?.products[index]?.unitCost).replace(
                            ',',
                            '.'
                        )
                    ) +
                    Number(unitPageCost || 0),
                0
            )

            const unitPrice = (
                Number(
                    (Number(value?.products[index]?.markup) / 100 + 1) *
                        unitFreight *
                        100
                ) / 100
            ).toFixed(2)

            value.products[index].unitCostTotal =
                Number(unitFreight) ||
                Number(
                    String(value?.products[index]?.unitCost).replace(',', '.')
                ) /
                    100 +
                    Number(
                        String(value?.products[index]?.unitCost).replace(
                            ',',
                            '.'
                        )
                    )

            value.products[index].unitCostTotalFull =
                Number(unitFreightWithoutBV) ||
                Number(
                    String(value?.products[index]?.unitCost).replace(',', '.')
                ) /
                    100 +
                    Number(
                        String(value?.products[index]?.unitCost).replace(
                            ',',
                            '.'
                        )
                    )

            const unitDiscount =
                (unitPrice * Number(value?.discount || 0)) / 100
            value.products[index].unitDiscount = unitDiscount
            value.products[index].unitPriceTotal = Number(
                unitPrice - unitDiscount || 0
            )

            value.products[index].totalCost =
                Number(unitPrice - unitDiscount) *
                Number(value?.products[index]?.quantity)
            value.products[index].totalPriceFull =
                Number(unitPrice - unitDiscount) *
                Number(value?.products[index]?.quantity)
            const unitPriceWithoutBV =
                Number(
                    (removeLeadingZeros(value.products?.[index]?.freight) ||
                        0) / (value.products?.[index]?.quantity || 0)
                ) +
                Number(
                    String(value?.products[index]?.unitCost).replace(',', '.')
                ) +
                Number(unitPageCost || 0)

            const unitPriceWithMarkup =
                ((Number(value?.products[index]?.markup) / 100 + 1) *
                    (unitPriceWithoutBV - unitDiscount) *
                    100) /
                100

            value.products[index].totalCost =
                unitPriceWithoutBV * Number(value?.products[index]?.quantity)

            value.products[index].totalPrice =
                unitPriceWithMarkup * Number(value?.products[index]?.quantity)

            if (index === value.products.length - 1) {
                return setState((prev) => {
                    if (name) {
                        prev = value
                    }
                    return prev
                })
            }
        }
    }

const calculateAccessories = (product: any) => {
    const cordAccessoriesUnitCost = product?.cordAccessories?.reduce(
        (acc, { value }) => acc + Number(value?.value),
        0
    )
    return cordAccessoriesUnitCost
}

export const calculateCost = (size: CommercialCell, quantity: number = 1, cordType: any) => {
    try {
        if (size && cordType && cordType.table && cordType.table.rows && cordType.table.columns) {
            let getSizeRow = cordType.table.rows.find(
                (row: CommercialCell[]) => row[0]?.value === size?.value
            )
            const [__, ...columns] = cordType.table.columns

            getSizeRow = getSizeRow || [];

            const [_, ...quantityPrice] = getSizeRow

            const index = columns.findIndex(({ value }: CommercialCell) => {
                const [min, max = Infinity] = value.split('a').map((item) => String(item).replace(/\D/g, '')).map(Number)
                return Number(quantity) >= Number(min) && Number(quantity) <= Number(max)
            })
            const unitCost = Number(String(quantityPrice[index]?.value || quantityPrice[0]?.value || 0).replace(',', '.'))
            return unitCost || Number(String(quantityPrice[0]?.value || 0).replace(',', '.'))
        }
        return 0
    } catch (e) {
        console.log(e)
        return 0
    }
}
export const calculateCostForGift = (product: DBCommercialProduct) => {
    try {
        const unitCostExtra = calculateUnitCostExtra(product)
        const quantityPrice = product?.priceRange?.rows[0]
        const multiplier = product?.multiplier || 1

        if (!quantityPrice) {
            return {
                unitCost: unitCostExtra,
                isFixed: false,
            }
        }

        const quantity = product?.quantity || 1
        const index = findQuantityIndex(product)
        const selectedPriceRange = quantityPrice[index]
        if (index !== -1) {
            const unitCost = calculateUnitCost(selectedPriceRange, quantity)
            return {
                unitCost: unitCost * multiplier + unitCostExtra,
                isFixed: selectedPriceRange?.isFixed,
            }
        }

        return {
            unitCost:
                calculateUnitCost(quantityPrice[0], quantity) * multiplier +
                unitCostExtra,
            isFixed: quantityPrice[0]?.isFixed,
        }
    } catch (e) {
        console.log(e)
        return {
            unitCost: 0,
            isFixed: false,
        }
    }
}

export const calculateUnitCostExtra = (product: DBCommercialProduct) => {
    const additionalProductUnitCost =
        Number(String(product?.additionalProductUnitCost)?.replace(',', '.')) ||
        0
    const accessoriesCost = calculateAccessoriesCost(product?.giftAccessories)

    return additionalProductUnitCost + accessoriesCost
}

export const calculateAccessoriesCost = (
    accessories?: CommercialAccessories[]
) => {
    if (!accessories) {
        return 0
    }

    return accessories.reduce((acc, { value }) => acc + Number(value?.value), 0)
}

export const findQuantityIndex = (product: DBCommercialProduct) => {
    const columns = product?.priceRange?.columns
    const quantity = product?.quantity
    const quantityPrice = product?.priceRange?.rows[0]

    if (!columns || !quantityPrice) {
        return -1
    }

    return columns.findIndex(({ value }) => {
        const range = value
            .split('a')
            .map((item) => String(item).replace(/\D/g, ''))
        const min = Number(range[0])
        const max = Number(range[1]) || Infinity
        return Number(quantity) >= min && Number(quantity) <= max
    })
}

export const calculateUnitCost = (
    priceRange: CommercialPriceRangeCell,
    quantity: number
) => {
    const priceValue = priceRange.value
    const unitCost = Number(String(priceValue).replace(',', '.'))

    if (priceRange.isFixed) {
        return unitCost / quantity
    }

    return unitCost
}
const calculateRollerPageCost = (product: any) => {
    const { quantity, pagePrice, quantityPerPage } = product
    const pages = Math.ceil(quantity / quantityPerPage)
    return { totalPagesCost: pagePrice * pages, pages: pages }
}

const getCordMarkup = (product: any, quantity = 1) => {
    if (product?.markupTable) {
        const { columns, rows } = product?.markupTable || [[], []]
        const index = columns.findIndex(({ value }: CommercialCell) => {
            const range = value
                .split('a')
                .map((item) => String(item).replace(/\D/g, ''))
            const min = Number(range[0])
            const max = Number(range[1]) || Infinity
            const isQuantityInRange =
                Number(quantity) >= min && Number(quantity) <= max
            return isQuantityInRange
        })

        if (index !== -1) {
            return {
                markup: Number(String(rows[0][index]?.markup)),
                maxMarkup: Number(String(rows[0][index]?.maxMarkup)),
                minMarkup: Number(String(rows[0][index]?.minMarkup)),
            }
        } else {
            return {
                markup: Number(String(rows[0][0]?.markup)),
                maxMarkup: Number(String(rows[0][0]?.maxMarkup)),
                minMarkup: Number(String(rows[0][0]?.minMarkup)),
            }
        }
    }
    return {
        markup: product.markup || 0,
        maxMarkup: product.maxMarkup || 0,
        minMarkup: product.minMarkup || 0,
    }
}
