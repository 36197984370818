import { FeatureRoutes, FeatureIcons } from './useUserProfileMenu.type'
import { MdHome, MdLogin, MdLogout, MdSettings } from 'react-icons/md'
import { ImProfile } from 'react-icons/im'

export const FEATURE_ROUTES: FeatureRoutes = {
    home: '/',
    login: '/login',
    logout: '/login',
    profile: '/user-profile',
    settings: '/settings',
}

export const FEATURE_ICONS: FeatureIcons = {
    home: MdHome,
    login: MdLogin,
    logout: MdLogout,
    profile: ImProfile,
    settings: MdSettings,
}
