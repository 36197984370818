import { RootState } from '@Core/Communication'
import { setMovementsAction } from '@Core/Communication/GlobalStore/Reducers/movements/reducer'
import {
    MovementContainer,
    MovementRegister,
} from '@Movements/Data/DataSource/Container'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useMovements = () => {
    const dispatch = useDispatch()
    const { movements } = useSelector((state: RootState) => state.movements)
    const [allMovements, setAllMovements] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const getMovementUseCase = MovementContainer.get(
        MovementRegister.UseCaseGetMovements
    )
    const [options, setOptions] = useState({
        page: 1,
        limit: 10,
        count: 0,
    })

    const getMovements = async (page = 1, limit = 10) => {
        setLoading(true)
        const {
            data,
            page: currentPage,
            limit: currentLimit,
            count,
        } = await getMovementUseCase.execute(page, limit)
        setOptions({ page: currentPage, limit: currentLimit, count })
        dispatch(
            setMovementsAction({ data, source: getMovementUseCase.getSource() })
        )
        setLoading(false)
    }

    const getAllMovements = async () => {
        setLoading(true)
        const { data } = await getMovementUseCase.execute(1, 0)
        setAllMovements(data)
        setLoading(false)
    }

    return {
        movements: movements[getMovementUseCase.getSource()],
        allMovements,
        getMovements,
        getAllMovements,
        options,
        loading,
    }
}
