import { UsersRepository } from '@User/Data/Repository/UsersRepository'
import { DBUser } from '../Model/User'

export interface UpdateUser {
    execute(user: DBUser): Promise<DBUser>
    getSource(): string
}

export class UpdateUserUseCase implements UpdateUser {
    constructor(private repository: UsersRepository) {}
    async execute(user: DBUser): Promise<DBUser> {
        return this.repository.update(user)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
