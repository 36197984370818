// @ts-nocheck
import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { dateFormatter } from '@Movements/Utils/Movements.utils'
import { formatCurrency } from '@Utils/Formatters/currency'

export const exportInventoryDictionary = (item: any) =>
    ({
        _id: 'ID',
        name: 'Nome',
        unitMeasure: 'Unidade de Medida',
        minimumStock: 'Estoque Mínimo',
        unitCost: 'Custo Unitário',
        isInactive: 'Inativo',
        created_at: 'Data',
        __v: '',
    }[item] || item)

export const exportInventoryValueDictionary = (
    key: DBInventoryProduct,
    item: DBInventoryProduct[key]
) => {
    return (
        {
            _id: { value: item, type: 'string' },
            name: { value: item, type: 'string' },
            unitMeasure: { value: item, type: 'string' },
            minimumStock: { value: item, type: 'number' },
            unitCost: {
                value: formatCurrency(item) ?? 'N/D',
                type: 'string',
            },
            isInactive: {
                value: Boolean(item) ? 'Sim' : 'Não',
                type: 'string',
            },
            created_at: {
                value: dateFormatter(new Date(item)),
                type: 'string',
            },
            __v: { value: item, type: 'number' },
        }[key] || { value: item, type: 'string' }
    )
}

export const exportInventoryProductValueDictionary = (
    key: DBInventoryProduct,
    item: DBInventoryProduct[key]
) => item

export const exportProductData = (data: DBInventoryProduct[]) => {
    const columns = [
        '_id',
        'name',
        'unitMeasure',
        'minimumStock',
        'unitCost',
        'isInactive',
        'created_at',
    ]
    const rows = data.map((item: DBInventoryProduct) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportInventoryValueDictionary(
                column,
                item[column],
                item
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportInventoryData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
    }))
