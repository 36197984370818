import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import { InventoryDestinationsRepository } from '@InventoryControl/Destinations/Data/Repository/DestinationRepository'

export interface GetDestination {
    execute(id: string): Promise<DBInventoryDestination>
    getSource(): string
}

export class GetDestinationUseCase implements GetDestination {
    constructor(private repository: InventoryDestinationsRepository) {}
    async execute(id: string): Promise<DBInventoryDestination> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
