import { Container, ContainerHeader } from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { useEffect } from 'react'
import { ProductsListComponent } from '../Common/ProductsList/ProductsList'
import { usePaginate } from '../Hooks/usePaginate/usePaginate'
import { useInventoryProducts } from '../Hooks/useProducts/useInventoryProducts'

export const ProductsSummary = () => {
    const { products, getProducts, loading } = useInventoryProducts()
    const {
        filteredProducts,
        getPaginate,
        handleSearch,
        search,
        page,
        limit,
        setPage,
        setLimit,
    } = usePaginate({
        products,
        activeFilter: ['active'],
    })

    useEffect(() => {
        getProducts()
    }, [])

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Produtos</h1>
                <RightContent>
                    <Input
                        size="small"
                        useUnform={false}
                        label="Buscar"
                        name="search"
                        onChange={handleSearch}
                        value={search}
                    />
                </RightContent>
            </ContainerHeader>
            <ProductsListComponent
                summary
                products={products}
                filteredProducts={filteredProducts}
                loading={loading}
                getPaginate={getPaginate}
                page={page}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
            />
        </Container>
    )
}
