import { Clients } from '@Clients/Domain/Model/Clients'
import { RootState } from '@Core/Communication'
import {
    MovementContainer,
    MovementRegister,
} from '@Movements/Data/DataSource/Container'
import {
    CreateMovementModel,
    IMovementStack,
} from '@Movements/Domain/Model/CreateMovement'
import { DBMovement } from './../Data/DataSource/API/Entity/Movement'

import { Supplier } from '@Core/Entities/Supplier'
import { DBProduct } from '@Products/entities/Product'
import { getUserName } from '@Utils/Formatters/getUserName'
import { FormHandles } from '@unform/core'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { HandleAddingMovementsProps } from './useMovementsManager.types'
import { validateCreateMovement } from './useMovementsManager.utils'

const INITIAL_MOVEMENT: IMovementStack = {
    id: crypto.randomUUID(),
    product: {} as DBProduct,
    amount: 0,
    type: 0,
    client: {} as Clients,
    user: {
        mid: '',
        name: '',
        mail: '',
    },
    supplier: {} as Supplier,
    date: '',
    inSupplier: false,
    caSales: '',
    ticketNumber: '',
    observation: '',
}

export const useMovementsManager = () => {
    const formRef = useRef<FormHandles>(null)
    const [state, setState] = useState<{ [key: string]: any }>({
        movements: [{ ...INITIAL_MOVEMENT, id: crypto.randomUUID() }],
    })
    const { user } = useSelector((state: RootState) => state.user)
    const [loading, setLoading] = useState(false)
    const handleAddingMovements = ({
        toggleModal,
        callback,
        onlySupplier = false,
        supplier = {} as Supplier,
    }: HandleAddingMovementsProps) => {
        const createMovementUseCase = MovementContainer.get(
            MovementRegister.UseCaseCreateMovement
        )
        const handleChange = (e: any) => {
            const { value, name } = e.target
            formRef?.current?.setFieldValue(name, value)
            formRef?.current?.setFieldError(name, '')
            if (name.includes('movements')) {
                const index = Number(name.match(/\[(\d+)\]/)[1])
                const field = name.split('.')[1] as
                    | 'product'
                    | 'amount'
                    | 'type'
                setState((prev: CreateMovementModel) => {
                    const movements = [...prev?.movements]
                    movements[index] = {
                        ...movements[index],
                        [field]: value,
                    }
                    return { ...prev, movements }
                })
                return
            }
            setState({
                ...state,
                [name]: value,
            })
        }

        const removeNotNecessariesData = (entity: any) => {
            if (!entity) return
            const { movements, stocks, ...rest } = entity
            return {
                ...rest,
            }
        }

        const handleSubmit = async () => {
            const allData = formRef?.current?.getData()
            const { client, ...restData } = allData as any
            const newData = {
                ...restData,
                client: removeNotNecessariesData(client),
            }
            const movementData = {
                ...newData,
                onlySupplier,
                supplier: removeNotNecessariesData(supplier),
                movements: allData?.movements?.map(
                    ({ id, _id, ...rest }: { [key: string]: any }) => ({
                        ...rest,
                    })
                ),
                date: new Date(),
                user: {
                    mid: user?.id as string,
                    name: getUserName(user),
                },
            } as CreateMovementModel
            if (!validateCreateMovement(movementData, formRef)) return
            setLoading(true)
            try {
                await createMovementUseCase.execute(movementData)
                toast.success('Movimento cadastrado com sucesso!')
                toggleModal?.()
                callback?.()
            } catch (error) {
                // @ts-ignore
                const { message } = error?.response?.data || { message: '' }
                if (message === 'No suficiente stock') {
                    toast.error(
                        'Não há saldo suficiente para realizar esta operação!'
                    )
                }
            }
            setLoading(false)
        }

        const handleAddingNewStackMovement = async () => {
            const newMovement: IMovementStack = {
                ...INITIAL_MOVEMENT,
                id: crypto.randomUUID(),
            }
            setState((prev: CreateMovementModel) => ({
                ...prev,
                movements: [...(prev?.movements || []), newMovement],
            }))
        }

        const handleRemoveStackMovement = (id: string | number) => {
            setState((prev: CreateMovementModel) => ({
                ...prev,
                movements: (prev?.movements || []).filter(
                    (movement) => movement.id !== id
                ),
            }))
        }
        return {
            handleChange,
            handleSubmit,
            handleAddingNewStackMovement,
            handleRemoveStackMovement,
        }
    }
    const handleEditMovement = (id: string) => {
        const getMovementUseCase = MovementContainer.get(
            MovementRegister.UseCaseGetMovement
        )
        const updateMovementUseCase = MovementContainer.get(
            MovementRegister.UseCaseUpdateMovement
        )
        const getMovement = async () => {
            setLoading(true)
            const movement = await getMovementUseCase.execute(id)
            setState(movement)
            setLoading(false)
            return movement
        }

        const handleChange = (e: any) => {
            const { value, name } = e.target
            formRef?.current?.setFieldValue(name, value)
            setState((prev) => ({
                ...prev,
                [name]: value,
            }))
        }

        const handleSubmit = async () => {
            try {
                const movement = {
                    ...state,
                    client: state?.client?.value,
                    product: state?.product?.value,
                    supplier: state?.supplier?.value,
                }
                await updateMovementUseCase.execute(movement as DBMovement)
                toast.success('Movimento atualizado com sucesso!')
            } catch (error) {
                toast.error('Erro ao atualizar o movimento!')
            }
        }

        return {
            getMovement,
            handleChange,
            handleSubmit,
        }
    }

    return {
        handleEditMovement,
        handleAddingMovements,
        formRef,
        state,
        loading,
    }
}
