import { RoutesPaths } from '@Balance/RoutesPaths'
import { Clients } from '@Clients/Domain/Model/Clients'
import { useClients } from '@Clients/Hooks/useClients'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Loader } from '@Core/Components/Loader/Loader'
import { ModalFooter } from '@Core/Components/Modal/Modal.styles'
import { SendMailWithStock } from '@Core/Components/SendMailWithStock/SendMailWithStock'
import { IMovementStack } from '@Movements/Domain/Model/CreateMovement'
import { useMovements } from '@Movements/Hooks/useMovements'
import { useMovementsManager } from '@Movements/Hooks/useMovementsManager'
import { Form } from '@unform/web'
import {
    ChangeEvent,
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePreMovements } from '../Hooks/usePreMovements'
import { MovementStack } from './components/MovementStack'

export const NewMovement = () => {
    const navigate = useNavigate()
    const { clients } = useClients()
    const { getMovements } = useMovements()
    const { state: locationState } = useLocation()
    const pathName = locationState?.pathname || RoutesPaths.Movements

    const [filteredClients, setFilteredClients] = useState<any[]>([])

    const [clientSelected, setClientSelected] = useState<
        { label: string } | undefined
    >()
    const [isLoading, setIsLoading] = useState(true)
    const [sendStock, setSendStock] = useState(false)
    const [clientToSend, setClientToSend] = useState<Clients>()
    const { ignorePreMovement } = usePreMovements({})
    const { handleAddingMovements, state, formRef, loading } =
        useMovementsManager()

    const goBack = () => {
        navigate(pathName)
    }

    const clear = () => {
        setClientSelected(undefined)
        setTimeout(() => {
            getMovements()
        }, 500)
    }

    const {
        handleChange,
        handleSubmit,
        handleAddingNewStackMovement,
        handleRemoveStackMovement,
    } = handleAddingMovements({
        callback: () => {
            if (locationState?.preMovement?.id) {
                ignorePreMovement(locationState?.preMovement?.id)
            }
            setSendStock(true)
        },
    })

    const handleClientFormatter = (client: Clients) => {
        return {
            label: client.corporateName,
            value: client._id,
            ...client,
        }
    }

    const handleClientSearch = (
        e: ChangeEvent<InputHTMLAttributes<HTMLInputElement>>
    ) => {
        const { target } = e || { target: null }
        if (!target?.value) return
        const { value } = target
        const filtered = clients
            .filter((client: Clients) =>
                client?.corporateName
                    ?.toLowerCase()
                    ?.includes(String(value)?.toLowerCase())
            )
            .map(handleClientFormatter)
        setFilteredClients(filtered)
    }

    // @ts-ignore
    const handleClientSelect = (_, value) => {
        setClientSelected({ label: value?.label })
        setClientToSend(value)
        // @ts-ignore
        formRef?.current?.setFieldValue('client', value)
        formRef?.current?.setFieldError('client', '')
        handleChange({
            target: {
                name: 'client',
                value,
            },
        })
        setIsLoading(false)
    }

    const closeSendStock = () => {
        setSendStock(false)
        goBack()
        clear()
    }

    const mailSuccess = () => {
        clear()
    }

    const handlePreMovement = useCallback(() => {
        const { preMovement } = locationState
        if (preMovement?.client !== '') {
            const preClient = clients.find(
                (client) => client.corporateName === preMovement?.client
            )
            handleClientSelect(null, {
                value: preClient?._id,
                label: preClient?.corporateName,
                ...preClient,
            })
        }
    }, [locationState])

    useEffect(() => {
        getMovements()
    }, [])

    useEffect(() => {
        setFilteredClients(clients.map(handleClientFormatter))
        if (locationState?.client) {
            handleClientSelect(null, {
                value: locationState?.client._id,
                label: locationState?.client?.corporateName,
                ...locationState?.client,
            })
        } else if (locationState?.preMovement) {
            handlePreMovement()
        } else {
            setIsLoading(false)
        }
    }, [clients])

    useEffect(() => {
        if (locationState?.client) {
            handleClientSelect(null, {
                value: locationState?.client._id,
                label: locationState?.client?.corporateName,
                ...locationState?.client,
            })
        } else {
            setIsLoading(false)
        }
    }, [locationState])

    if (isLoading) return <Loader />

    return (
        <Container>
            <SendMailWithStock
                onSuccess={mailSuccess}
                client={clientToSend || ({} as Clients)}
                isOpen={sendStock}
                onClose={closeSendStock}
            />
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Nova movimentação</h1>
            </ContainerHeader>
            {(loading || isLoading) && <Loader />}
            {(!loading || !isLoading) && (
                <Form ref={formRef} onSubmit={() => {}}>
                    <Grid container>
                        <Grid item lg={12}>
                            <InputAutoComplete
                                inputProps={{
                                    name: 'client',
                                    required: true,
                                }}
                                results={filteredClients}
                                name={'client'}
                                // @ts-ignore
                                onInputChange={handleClientSearch}
                                onChange={handleClientSelect}
                                value={clientSelected}
                                label="Cliente"
                            />
                        </Grid>
                        <Grid item lg={12}>
                            {Boolean(state?.movements?.length) &&
                                state?.movements?.map(
                                    (item: IMovementStack, index: number) => (
                                        <MovementStack
                                            formRef={formRef}
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            onChange={handleChange}
                                            onRemove={handleRemoveStackMovement}
                                        />
                                    )
                                )}
                        </Grid>
                        {!locationState?.preMovement && (
                            <Button
                                onClick={handleAddingNewStackMovement}
                                variant={ButtonVariant.primary}
                            >
                                Adicionar Produto
                            </Button>
                        )}
                    </Grid>
                </Form>
            )}
            <ModalFooter>
                <Button onClick={goBack} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    variant={ButtonVariant.primary}
                >
                    {loading ? 'Aguarde...' : 'Salvar'}
                </Button>
            </ModalFooter>
        </Container>
    )
}
