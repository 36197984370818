import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import {
    InventoryProductContainer,
    InventoryProductRegister,
} from '@InventoryControl/Products/Data/DataSource/Container'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useInventoryProductManager = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { id } = useParams<{ id: string }>()
    const [state, setState] = useState<DBInventoryProduct>(
        {} as DBInventoryProduct
    )
    const getProductUseCase = InventoryProductContainer.get(
        InventoryProductRegister.UseCaseGetProduct
    )
    const updateProductUseCase = InventoryProductContainer.get(
        InventoryProductRegister.UseCaseUpdateProduct
    )

    const handleEditProduct = () => {
        const handleInative = async () => {
            const newState = { ...state }
            newState.isInactive = !newState.isInactive
            setState(newState)
            setLoading(true)
            try {
                await updateProductUseCase.execute(newState)
                const msm = newState.isInactive ? 'Desativado' : 'Ativado'
                toast.success(`Produto ${msm} com sucesso`)
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar produto')
            }
            setLoading(false)
        }
        const handleChange = (e: any) => {
            const { name, value } = e.target
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        const handleSubmit = async () => {
            setLoading(true)
            try {
                await updateProductUseCase.execute(state)
                toast.success('Produto atualizado com sucesso')
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar produto')
            }
            setLoading(false)
        }
        return { handleChange, handleSubmit, handleInative }
    }

    const getProduct = async () => {
        setLoading(true)
        try {
            const product = await getProductUseCase.execute(String(id))
            setState(product)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!id) return
        getProduct()
    }, [id])

    return { state, getProduct, loading, handleEditProduct }
}
