import { InferType, boolean, number, object, string } from 'yup'

export const productSchema = object().shape({
    name: string().required('Nome obrigatório'),
    description: string(),
    productType: string().default('default'),
    unitCost: number().default(0),
    isInactive: boolean(),
    minMarkup: number().default(0),
    maxMarkup: number(),
})

export interface Product extends InferType<typeof productSchema> {}
