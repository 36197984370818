import { formatAutoCompleteValue } from '@Utils/Formatters/formattedAutocomplete'
import { generateInputRandomName } from '@Utils/Mocks/generateInputRandomName'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Input } from '../Input/Input'
import { InputAutoCompleteProps } from './InputAutoComplete.types'
export const InputAutoComplete = ({
    results,
    inputProps,
    label,
    onChange,
    control,
    name = generateInputRandomName(),
    labelKey = '',
    ...props
}: InputAutoCompleteProps) => {
    const [inputValue, setInputValue] = useState('')
    if (!inputProps?.useUnform && control) {
        if (props.multiple) {
            return (
                <Controller
                    render={({
                        field: {
                            onChange: _onChange,
                            value,
                            ...controllerProps
                        },
                    }) => (
                        <Autocomplete
                            options={results}
                            getOptionLabel={(option) => option?.label || ""}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            noOptionsText="Nenhum resultado encontrado"
                            filterSelectedOptions
                            multiple
                            {...props}
                            {...controllerProps}
                            value={value}
                            onChange={(_, values) => onChange(name, values)}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...inputProps}
                                    {...params}
                                    label={label}
                                    variant="outlined"
                                />
                            )}
                        />
                    )}
                    // @ts-ignore
                    onChange={([_, data]) => data}
                    name={name}
                    control={control}
                />
            )
        }
        return (
            <Controller
                render={({
                    field: { onChange: _onChange, value, ...controllerProps },
                }) => (
                    <Autocomplete
                        options={results}
                        getOptionLabel={(option) => option?.label || ""}
                        noOptionsText="Nenhum resultado encontrado"
                        {...props}
                        {...controllerProps}
                        value={formatAutoCompleteValue(value, labelKey)}
                        onChange={(_, { value }) => _onChange(value)}
                        disableClearable
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params?.InputProps,
                                    ...inputProps?.InputProps,
                                }}
                                label={label}
                                variant="outlined"
                            />
                        )}
                    />
                )}
                // @ts-ignore
                onChange={([_, data]) => data}
                name={name}
                control={control}
            />
        )
    }
    return (
        <Autocomplete
            options={results}
            getOptionLabel={(option) => option?.label || ""}
            onChange={onChange}
            noOptionsText="Nenhum resultado encontrado"
            {...props}
            renderInput={(params) => (
                <Input {...params} {...inputProps} label={label} />
            )}
        />
    )
}
