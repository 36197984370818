import { PreMovementsList } from '@Balance/Movements/PreMovements/PreMovements'
import { BalanceRoot } from '@Balance/Providers/BalanceRoot/BalanceRoot'
import { roles } from '@Balance/roles'
import { RoutesPaths } from '@Balance/RoutesPaths'
import { Summary } from '@Balance/Summary/Summary'
import { ClientDetails } from '@Clients/ClientDetails/ClientDetails'
import { ClientsList } from '@Clients/ClientsList/ClientsList'
import { PrivacyRoute } from '@Core/Routes/PrivacyRoute'
import { MovementDetails } from '@Movements/MovementDetails/MovementDetails'
import { MovementsList } from '@Movements/MovementsList/MovementsList'
import { NewMovement } from '@Movements/NewMovement/NewMovement'
import { ProductDetails } from '@Products/ProductDetails/ProductDetails'
import { ProductsList } from '@Products/ProductsList/ProductsList/ProductsList'
import { NewMovementSupplier } from '@Supplier/Common/NewSupplierMovement/NewSupplierMovement'
import { SupplierDetails } from '@Supplier/SupplierDetails/SupplierDetails'
import { SupplierList } from '@Supplier/SupplierList/SupplierList'
import { Route } from 'react-router-dom'
const router = [
    <Route key={'BalanceStack'} path="/" element={<BalanceRoot />}>
        <Route
            element={
                <PrivacyRoute
                    permission={roles.movements.permissions.read.key}
                />
            }
        >
            <Route path={RoutesPaths.Movements} element={<MovementsList />} />
            <Route
                path={RoutesPaths.PreMovements}
                element={<PreMovementsList />}
            />
        </Route>
        <Route
            element={
                <PrivacyRoute
                    permission={roles.movements.permissions.create.key}
                />
            }
        >
            <Route path={RoutesPaths.NewMovement} element={<NewMovement />} />
        </Route>
        <Route
            element={
                <PrivacyRoute
                    permission={roles.movements.permissions.read.key}
                />
            }
        >
            <Route
                path={RoutesPaths.MovementDetails}
                element={<MovementDetails />}
            />
        </Route>
        <Route
            element={
                <PrivacyRoute permission={roles.clients.permissions.read.key} />
            }
        >
            <Route path={RoutesPaths.ClientsList} element={<ClientsList />} />
        </Route>
        <Route path={RoutesPaths.ClientDetail} element={<ClientDetails />} />
        <Route path={RoutesPaths.ProductList} element={<ProductsList />} />
        <Route path={RoutesPaths.ProductDetail} element={<ProductDetails />} />
        <Route path={RoutesPaths.SupplierList} element={<SupplierList />} />
        <Route
            path={RoutesPaths.SupplierMovement}
            element={<NewMovementSupplier />}
        />
        <Route
            path={RoutesPaths.SupplierDetail}
            element={<SupplierDetails />}
        />
        <Route path={RoutesPaths.BalanceSummary} element={<Summary />} />
    </Route>,
]
export default router
