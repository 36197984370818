import { roles } from '@Balance/roles'
import { RoutesPaths } from '@Balance/RoutesPaths'
import { useClients } from '@Clients/Hooks/useClients'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Fieldset } from '@Core/Components/Fieldset/Fieldset'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { Select } from '@Core/Components/Select/Select'
import { Switch } from '@Core/Components/Switch/Switch.styles'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { DeleteModal } from '@Movements/Common/DeleteModal/DeleteModal'
import { typeOptions } from '@Movements/constants/Movement.constants'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { useMovementsManager } from '@Movements/Hooks/useMovementsManager'
import { useProducts } from '@Products/Hooks/useProducts'
import { API } from '@Services/api'
import { useSupplier } from '@Supplier/Hooks/useSupplier'
import { Form } from '@unform/web'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { SetStateAction, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useNavigate, useParams } from 'react-router-dom'
export const MovementDetails = () => {
    const [clientSelected, setClientSelected] = useState({
        _id: '',
        value: {},
        label: '',
    })
    const [productSelected, setProductSelected] = useState({
        value: {},
        label: '',
    })
    const [supplierSelected, setSupplierSelected] = useState({
        value: {},
        label: '',
    })

    const [isOpenDate, setIsOpenDate] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const [editMode, setEditMode] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const { handleEditMovement, state, formRef, loading } =
        useMovementsManager()
    const { getMovement, handleChange, handleSubmit } = handleEditMovement(
        id || ''
    )
    const { localClients } = useClients()
    const { localProducts, getProducts } = useProducts(API)
    const { supplier: suppliers } = useSupplier(API)

    const toggleEditMode = () => {
        setEditMode(!editMode)
        setIsOpenDate(false)
    }
    const toggleDataPicker = () => setIsOpenDate(!isOpenDate)
    const openDeleteModal = () => setDeleteModal(true)
    const closeDeleteModal = () => setDeleteModal(false)

    const _handleSubmit = async () => {
        handleSubmit()
        setEditMode(false)
    }

    const handleGetMovement = async () => {
        const data = await getMovement()
        fillForm(data)
    }

    const returnObject = (item: any) => {
        if (item?.length) {
            return item[0]
        }
        return item
    }

    const fillForm = (data: DBMovement) => {
        const client = returnObject(data.client)
        const product = returnObject(data.product)
        const supplier = returnObject(data.supplier)
        formRef.current?.setFieldValue('type', data.type)
        formRef.current?.setFieldValue('observation', data.observation)
        formRef.current?.setFieldValue('amount', data.amount)
        handleChange({
            target: {
                name: 'amount',
                value: data.amount,
            },
        })
        handleClientSelect({}, { value: client, label: client.corporateName })
        handleProductSelected(
            {},
            {
                value: product,
                label: product.name,
            }
        )
        handleSupplierSelected(
            {},
            {
                value: supplier,
                label: supplier?.corporateName,
            }
        )
    }

    const handleClientSelect = (
        _: {},
        value: SetStateAction<{ value: {}; label: string }>
    ) => {
        setClientSelected(value as any)
        // @ts-ignore
        formRef?.current?.setFieldValue('client', value)
        handleChange({
            target: {
                name: 'client',
                value,
            },
        })
    }

    const handleProductSelected = (
        _: {},
        value: SetStateAction<{ value: {}; label: string }>
    ) => {
        setProductSelected(value)
        // @ts-ignore
        formRef?.current?.setFieldValue('product', value)
        handleChange({
            target: {
                name: 'product',
                value,
            },
        })
    }

    const handleSupplierSelected = (
        _: {},
        value: SetStateAction<{ value: {}; label: string }>
    ) => {
        setSupplierSelected(value)
        // @ts-ignore
        formRef?.current?.setFieldValue('supplier', value)
        handleChange({
            target: {
                name: 'supplier',
                value,
            },
        })
    }

    const handleSupplerToggle = () => {
        formRef.current?.setFieldValue('supplier', !state?.inSupplier)
        handleChange({
            target: {
                name: 'inSupplier',
                value: !state?.inSupplier,
            },
        })
    }

    const handleDateChange = (date: any) => {
        formRef.current?.setFieldValue('date', date)
        handleChange({
            target: {
                name: 'date',
                value: date,
            },
        })
    }

    const _handleClickOnClient = () => {
        if (editMode) {
            return
        }
        // @ts-ignore
        navigate(
            // @ts-ignore
            formatRouteId(RoutesPaths.ClientDetail, clientSelected?.value?._id)
        )
    }

    useEffect(() => {
        handleGetMovement()
        getProducts()
    }, [id])

    if (loading) {
        return <Loader />
    }

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Detalhes da movimentação</h1>
                <DeleteModal isOpen={deleteModal} onClose={closeDeleteModal} />
                {!editMode && (
                    <RightContent>
                        <PermissionWrapper
                            permission={roles.movements.permissions.delete.key}
                        >
                            <Button
                                onClick={openDeleteModal}
                                variant={ButtonVariant.secondary}
                            >
                                Deletar
                            </Button>
                        </PermissionWrapper>
                        <PermissionWrapper
                            permission={roles.movements.permissions.update.key}
                        >
                            <Button
                                onClick={toggleEditMode}
                                variant={ButtonVariant.primary}
                            >
                                Editar
                            </Button>
                        </PermissionWrapper>
                    </RightContent>
                )}
                {editMode && (
                    <RightContent>
                        <PermissionWrapper
                            permission={roles.movements.permissions.update.key}
                        >
                            <Button
                                onClick={toggleEditMode}
                                variant={ButtonVariant.secondary}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={_handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </RightContent>
                )}
            </ContainerHeader>
            <Form ref={formRef} onSubmit={() => {}} className="mt-2">
                <Grid container>
                    <Grid item lg={8}>
                        <div
                            className={!editMode ? 'clickable' : ''}
                            onClick={_handleClickOnClient}
                        >
                            <InputAutoComplete
                                inputProps={{
                                    name: 'client',
                                    required: true,
                                }}
                                disabled={!editMode}
                                label="Cliente"
                                name={'client'}
                                value={clientSelected}
                                results={
                                    localClients?.map((client) => ({
                                        label: client.corporateName,
                                        value: client,
                                    })) || []
                                }
                                onChange={handleClientSelect}
                            />
                        </div>
                    </Grid>
                    <Grid item lg={4}>
                        <Select
                            label="Tipo"
                            required
                            disabled={!editMode}
                            value={String(state?.type)}
                            options={typeOptions}
                            onChange={handleChange}
                            name="type"
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <InputAutoComplete
                            inputProps={{
                                name: 'product',
                                required: true,
                            }}
                            disabled={!editMode}
                            label="Produto"
                            name={'product'}
                            value={productSelected}
                            results={localProducts.map((product) => ({
                                label: product.name,
                                value: product,
                            }))}
                            onChange={handleProductSelected}
                        />
                    </Grid>
                    <Grid item lg={4}>
                        {((String(state?.type) === '1' && state?.caSales) ||
                            String(state?.type) === '1') && (
                            <Input
                                label="Venda no CA"
                                disabled={!editMode}
                                onChange={handleChange}
                                required
                                value={state?.caSales}
                                name={`caSales`}
                            />
                        )}
                        {((String(state?.type) === '2' &&
                            state?.ticketNumber) ||
                            String(state?.type) === '2') && (
                            <Input
                                label="Ticket Produção"
                                required
                                value={state?.ticketNumber}
                                disabled={!editMode}
                                onChange={handleChange}
                                name={`ticketNumber`}
                            />
                        )}
                    </Grid>
                    <Grid item lg={4}>
                        <Input
                            label="Quantidade"
                            type="number"
                            required
                            disabled={!editMode}
                            onChange={handleChange}
                            value={Number(state?.amount)}
                            name={`amount`}
                            inputProps={{
                                min: 0,
                            }}
                        />
                    </Grid>
                    {String(state?.type) === '2' && editMode && (
                        <Grid item lg={4}>
                            <Input
                                name="inSupplier"
                                style={{ display: 'none' }}
                            />
                            <Switch onClick={handleSupplerToggle}>
                                <input
                                    type="checkbox"
                                    checked={state?.inSupplier}
                                />
                                <div className="slider round"></div>
                            </Switch>
                            Produção em Terceiro
                        </Grid>
                    )}
                    {state?.inSupplier && (
                        <Grid item lg={8}>
                            <InputAutoComplete
                                inputProps={{
                                    name: 'supplier',
                                    required: true,
                                }}
                                disabled={!editMode}
                                label="Fornecedor"
                                name={'supplier'}
                                value={supplierSelected}
                                results={suppliers.map(
                                    (item: { corporateName: any }) => ({
                                        label: item.corporateName,
                                        value: item,
                                    })
                                )}
                                onChange={handleSupplierSelected}
                            />
                        </Grid>
                    )}
                    <Grid item lg={12}>
                        {(state?.observation || editMode) && (
                            <TextArea
                                value={state?.observation || ''}
                                disabled={!editMode}
                                onChange={handleChange}
                                name={`observation`}
                                label="Observações"
                                rows={5}
                            />
                        )}
                    </Grid>
                    <Grid item lg={6}>
                        <Fieldset>
                            <legend>Data da movimentação</legend>
                            {state?.date && (
                                <Grid item lg={12}>
                                    <Button
                                        disabled={!editMode}
                                        variant={
                                            isOpenDate
                                                ? ButtonVariant.secondary
                                                : ButtonVariant.primary
                                        }
                                        onClick={toggleDataPicker}
                                    >
                                        {isOpenDate
                                            ? 'Data da movimentação'
                                            : format(
                                                  new Date(state?.date),
                                                  'd MMMM, yyyy h:mm aa',
                                                  {
                                                      locale: ptBR,
                                                  }
                                              )}
                                    </Button>
                                    {isOpenDate && editMode && (
                                        <center
                                            style={{
                                                position: 'absolute',
                                                marginTop: 40,
                                            }}
                                        >
                                            <DatePicker
                                                selected={new Date(state?.date)}
                                                onChange={handleDateChange}
                                                showTimeSelect
                                                inline
                                                locale={ptBR}
                                                timeFormat="HH:mm"
                                                dateFormat="d MMMM, yyyy h:mm aa"
                                            />
                                        </center>
                                    )}
                                </Grid>
                            )}
                        </Fieldset>
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
