import { CommercialVariables } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { useCallback, useEffect, useState } from 'react'

export const useVariablesTable = ({
    variables,
    onSave,
}: {
    variables: CommercialVariables
    onSave: (variables: CommercialVariables) => void
}) => {
    const [state, setVariables] = useState<{ [key: string]: string }[]>(
        JSON.parse(variables?.variableValue)
    )

    const handleAddVariable = useCallback(() => {
        setVariables((prev) => [
            ...prev,
            {
                id: crypto.randomUUID(),
                name: '',
                value: '',
            },
        ])
    }, [setVariables])

    const handleRemoveVariable = useCallback(
        (index: number) => async () => {
            setVariables((prev) => [
                ...prev.slice(0, index),
                ...prev.slice(index + 1),
            ])
        },
        []
    )

    const handleSaveVariable = async (entity: CommercialVariables) => {
        const newState = [
            ...state.filter((item) => item.id !== entity.id),
            entity,
        ]
        setVariables([...newState])
        const variable = {
            ...variables,
            variableValue: JSON.stringify(newState),
        }
        onSave(variable)
    }

    useEffect(() => {
        console.log(JSON.parse(variables?.variableValue))
        setVariables(
            JSON.parse(variables?.variableValue).sort((a, b) => a.name - b.name)
        )
    }, [variables])

    return {
        state,
        handleAddVariable,
        handleRemoveVariable,
        handleSaveVariable,
    }
}
