import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { MenuOptions } from '@Core/Components/MenuOptions/MenuOptions'
import { IMenuOption } from '@Core/Components/MenuOptions/MenuOptions.types'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { roles } from '@InventoryControl/roles'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { Chip } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { NewProductModal } from '../Common/NewProductModal/NewProductModal'
import { ProductsListComponent } from '../Common/ProductsList/ProductsList'
import { usePaginate } from '../Hooks/usePaginate/usePaginate'
import { useInventoryProducts } from '../Hooks/useProducts/useInventoryProducts'
import {
    exportInventoryDictionary,
    exportInventoryProductValueDictionary,
    exportProductData,
} from '../Utils/excelExport'

export const ProductsList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { products, getProducts, loading } = useInventoryProducts()
    const [activeFilter, setActiveFilter] = useState(['active'])
    const {
        handleSearch,
        search,
        page,
        limit,
        setPage,
        setLimit,
        filteredProducts,
        getPaginate,
    } = usePaginate({ products, activeFilter })

    const chipVariant = (type: string) => {
        if (activeFilter.includes(type)) {
            return 'default'
        }
        return 'outlined'
    }
    const handleActiveFilter = (type: string) =>
        useCallback(() => {
            setActiveFilter((prev) => {
                const hasType = prev.includes(type)
                const newState = hasType
                    ? prev.filter((item) => item !== type)
                    : [...prev, type]
                return newState
            })
        }, [])

    const openModal = () => {
        setIsModalOpen(true)
    }

    const exportMenuList: IMenuOption[] = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(exportProductData(filteredProducts), `Produtos`, {
                    columnsDict: exportInventoryDictionary,
                    valuesDict: exportInventoryProductValueDictionary,
                    sheetName: 'Produtos',
                })
            },
        },
    ]

    useEffect(() => {
        getProducts()
    }, [])
    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Produtos</h1>
                <RightContent>
                    <Chip
                        label="Ativos"
                        color="primary"
                        onClick={handleActiveFilter('active')}
                        variant={chipVariant('active')}
                    />
                    <Chip
                        label="Inativos"
                        color="secondary"
                        onClick={handleActiveFilter('inactive')}
                        variant={chipVariant('inactive')}
                    />
                    <Input
                        size="small"
                        useUnform={false}
                        label="Buscar"
                        name="search"
                        onChange={handleSearch}
                        value={search}
                    />
                    <PermissionWrapper
                        permission={roles.product.permissions.create.key}
                    >
                        <Button
                            onClick={openModal}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Novo Produto
                        </Button>
                        <NewProductModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                            callback={getProducts}
                        />
                    </PermissionWrapper>
                    <PermissionWrapper
                        permission={roles.product.permissions.export.key}
                    >
                        <MenuOptions menuList={exportMenuList}>
                            <TfiExport />
                        </MenuOptions>
                    </PermissionWrapper>
                </RightContent>
            </ContainerHeader>
            <ProductsListComponent
                products={products}
                filteredProducts={filteredProducts}
                loading={loading}
                getPaginate={getPaginate}
                page={page}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
            />
        </Container>
    )
}
