import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'
import { DeleteAdditionalEmailProps } from './DeleteAdditionalEmailModal.types'

export const DeleteAdditionalEmailModal = ({
    onConfirm,
    email,
    isOpen,
    setIsOpen,
}: DeleteAdditionalEmailProps) => {
    const handleClose = () => {
        setIsOpen?.(false)
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <center>
                <h2>Tem certeza que deseja remover esse e-mail?</h2>
            </center>
            <ModalFooter>
                <Button onClick={handleClose} variant={ButtonVariant.secondary}>
                    Cancelar
                </Button>
                <Button
                    onClick={() => onConfirm?.(email)}
                    variant={ButtonVariant.primary}
                >
                    Excluir
                </Button>
            </ModalFooter>
        </Modal>
    )
}
