import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import { InventorySuppliersRepository } from '@InventoryControl/Supplier/Data/Repository/SupplierRepository'

export interface GetSupplier {
    execute(id: string): Promise<DBInventorySupplier>
    getSource(): string
}

export class GetSupplierUseCase implements GetSupplier {
    constructor(private repository: InventorySuppliersRepository) {}
    async execute(id: string): Promise<DBInventorySupplier> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
