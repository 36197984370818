import { useVariablesTable } from '@Commercial/Products/Hooks/useVariablesTable/useVariablesTable'
import { Button, Table } from '@Core/Components'
import { Grid } from '@material-ui/core'
import { VariableTableItem } from '../VariableTableItem/VariableTableItem'

export const VariableTable = ({ variables, onSave, options }) => {
    const {
        state,
        handleAddVariable,
        handleRemoveVariable,
        handleSaveVariable,
    } = useVariablesTable({ variables, onSave })

    return (
        <div className="w-full items-center justify-center">
            <Grid container spacing={2}>
                <Table>
                    <thead>
                        <tr>
                            <th>{options?.nameLabel || 'Nome'}</th>
                            <th>{options?.valueLabel || 'Valor'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.map((variable, index) => (
                            <VariableTableItem
                                key={variable.id || variable._id}
                                handleSave={handleSaveVariable}
                                variable={variable}
                                onDelete={handleRemoveVariable(index)}
                            />
                        ))}
                        {!state.length && (
                            <tr>
                                <td colSpan={2}>Nenhum item encontrado</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignContent="center"
                    justifyContent="center"
                >
                    <Button type="button" onClick={handleAddVariable}>
                        Adicionar novo
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}
