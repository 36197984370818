import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Exits } from '@InventoryControl/Exits/entities/Exits'
import { DBInventoryExits } from '../DataSource/API/Entity/Exits'
import ExitsDataSource from '../DataSource/ExitsDataSource'

export class InventoryExitsRepository
    implements BaseRepository<DBInventoryExits, Exits>
{
    dataSource: ExitsDataSource
    constructor(_dataSource: ExitsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBInventoryExits[]> {
        return this.dataSource.getExits()
    }
    async findById(id: string): Promise<DBInventoryExits> {
        return this.dataSource.getExitById(id)
    }
    async search(query: string): Promise<DBInventoryExits[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Exits): Promise<DBInventoryExits> {
        return this.dataSource.createExit(entity)
    }
    async update(entity: DBInventoryExits): Promise<DBInventoryExits> {
        return this.dataSource.updateExit(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteExit(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
