import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Supplier } from '@InventoryControl/Supplier/entities/Supplier'
import { DBInventorySupplier } from '../DataSource/API/Entity/Supplier'
import SuppliersDataSource from '../DataSource/SupplierDataSource'

export class InventorySuppliersRepository
    implements BaseRepository<DBInventorySupplier, Supplier>
{
    dataSource: SuppliersDataSource
    constructor(_dataSource: SuppliersDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBInventorySupplier[]> {
        return this.dataSource.getSuppliers()
    }
    async findById(id: string): Promise<DBInventorySupplier> {
        return this.dataSource.getSupplierById(id)
    }
    async search(query: string): Promise<DBInventorySupplier[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Supplier): Promise<DBInventorySupplier> {
        return this.dataSource.createSupplier(entity)
    }
    async update(entity: DBInventorySupplier): Promise<DBInventorySupplier> {
        return this.dataSource.updateSupplier(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteSupplier(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
