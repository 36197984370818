import { Clients } from '@Balance/Clients/Domain/Model/Clients'
import { Stock } from '@Core/Entities/Stock'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { dateFormatter } from '@Movements/Utils/Movements.utils'
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer'

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#E4E4E4',
        paddingTop: 35,
        paddingBottom: 65,
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: 100,
        height: 100,
        objectFit: 'contain',
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        padding: 10,
    },
    section: {
        padding: 10,
        flex: 1,
        flexDirection: 'column',
    },
    textStock: {
        width: '20%',
        padding: 5,
        fontSize: 12,
        textAlign: 'center',
    },
    textStockLarge: {
        width: '40%',
        padding: 5,
        fontSize: 12,
        textAlign: 'center',
    },
    textMovement: {
        width: '10%',
        padding: 5,
        fontSize: 12,
        textAlign: 'center',
    },
    textMovementLarge: {
        width: '26.25%',
        padding: 5,
        fontSize: 12,
        textAlign: 'center',
    },
    item: {
        width: '100%',
        flexDirection: 'row',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
})

interface IStockDocument extends Omit<Stock, 'client'> {
    client: Clients
}

export const StockDocument = ({
    title,
    stocks,
    withClient = false,
}: {
    title: string
    stocks: IStockDocument[]
    withClient: boolean
}) => (
    <Document>
        <Page wrap style={styles.page}>
            <View style={styles.logoContainer}>
                <Image
                    style={styles.logo}
                    src="https://odhub.oficinadocracha.com.br/assets/logo-9444fdd1.png"
                />
            </View>
            <View style={styles.title}>
                <Text>{title}</Text>
            </View>
            <View style={styles.section}>
                <View style={styles.item} fixed>
                    {withClient && (
                        <Text style={styles.textStockLarge}>Cliente</Text>
                    )}
                    <Text style={styles.textStockLarge} fixed>
                        Produto
                    </Text>
                    <Text style={styles.textStock} fixed>
                        Quantidade
                    </Text>
                    <Text style={styles.textStock} fixed>
                        Última Entrada
                    </Text>
                    <Text style={styles.textStock} fixed>
                        Última Saída
                    </Text>
                </View>
                {stocks?.map((item) => (
                    <View key={item._id} style={styles.item}>
                        {withClient && (
                            <Text style={styles.textStockLarge}>
                                {item.client?.corporateName}
                            </Text>
                        )}
                        <Text style={styles.textStockLarge}>
                            {item.product?.[0]?.name}
                        </Text>
                        <Text style={styles.textStock}>{item.amount}</Text>
                        <Text style={styles.textStock}>
                            {dateFormatter(
                                new Date(
                                    item.product?.[0]?.lastInput?.[0]?.date
                                )
                            )}
                        </Text>
                        <Text style={styles.textStock}>
                            {dateFormatter(
                                new Date(item.product?.[0]?.lastOut?.[0]?.date)
                            )}
                        </Text>
                    </View>
                ))}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                }
                fixed
            />
        </Page>
    </Document>
)

export const MovementDocument = ({
    title,
    movements,
}: {
    title: string
    movements: DBMovement[]
}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.logoContainer}>
                <Image
                    style={styles.logo}
                    src="https://odhub.oficinadocracha.com.br/assets/logo-9444fdd1.png"
                />
            </View>
            <View style={styles.title}>
                <Text>{title}</Text>
            </View>
            <View style={styles.section}>
                <View style={styles.item}>
                    <Text style={styles.textMovementLarge}>Cliente</Text>
                    <Text style={styles.textMovementLarge}>Produto</Text>
                    <Text style={styles.textMovement}>Quantidade</Text>
                    <Text style={styles.textMovement}>Tipo</Text>
                    <Text style={styles.textMovement}>Data</Text>
                    <Text style={styles.textMovementLarge}>Responsável</Text>
                </View>
                {movements?.map((item) => (
                    <View key={item._id} style={styles.item}>
                        <Text style={styles.textMovementLarge}>
                            {item.client[0]?.corporateName ?? 'N/A'}
                        </Text>
                        <Text style={styles.textMovementLarge}>
                            {item.product?.[0]?.name}
                        </Text>
                        <Text style={styles.textMovement}>{item.amount}</Text>
                        <Text style={styles.textMovement}>
                            {String(item.type) === '1' ? 'Entrada' : 'Saída'}
                        </Text>
                        <Text style={styles.textMovement}>
                            {dateFormatter(new Date(item?.date))}
                        </Text>
                        <Text style={styles.textMovementLarge}>
                            {item?.user?.name}
                        </Text>
                    </View>
                ))}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                }
                fixed
            />
        </Page>
    </Document>
)
