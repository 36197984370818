import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'

export interface IgnorePreMovement {
    execute(id: string): Promise<DBMovement>
    getSource(): string
}

export class IgnorePreMovementUseCase implements IgnorePreMovement {
    constructor(private repository: MovementRepository) {}
    async execute(id: string): Promise<DBMovement> {
        return this.repository.updatePreMovement({ id, is_ignored: true })
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
