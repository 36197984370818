import { AutoMenu } from '@Core/Components/AutoMenu/AutoMenu'
import { IMenu } from '@Core/Components/AutoMenu/AutoMenu.types'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { SidebarFolder } from '@Entry/Common/Sidebar/components/SidebarFolder/SidebarFolder'
import { useMemo } from 'react'
import { BsBoxSeam, BsCardChecklist } from 'react-icons/bs'
import { FiList, FiPackage, FiUsers } from 'react-icons/fi'
import { IoIdCardOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { RoutesPaths } from './RoutesPaths'
import { moduleKey, roles } from './roles'


const MenuItems: IMenu[] = [{
    title: 'Resumo',
    to: RoutesPaths.BalanceSummary,
    icon: <BsCardChecklist />,
    permission: roles.summary.permissions.read.key
},{
    title: 'Movimentações',
    to: RoutesPaths.Movements,
    icon: <FiList />,
    permission: roles.movements.permissions.read.key
},{
    title: 'Pre-Movimentações',
    to: RoutesPaths.PreMovements,
    icon: <FiList />,
    permission: roles.preMovements.permissions.read.key
}, {
    title: 'Clientes',
    to: RoutesPaths.ClientsList,
    icon: <FiUsers />,
    permission: roles.clients.permissions.read.key
}, {
    title: 'Fornecedor',
    to: RoutesPaths.SupplierList,
    icon: <FiPackage />,
    permission: roles.suppliers.permissions.read.key
},{
    title: 'Produtos',
    to: RoutesPaths.ProductList,
    icon: <IoIdCardOutline />,
    permission: roles.products.permissions.read.key
}] 

export const Menu = () => {
    const { pathname } = useLocation()
    const { hasModulePermission } = usePermissions()
    const hasSubMenuActive = useMemo(
        () => Boolean(MenuItems.find((item) => item.to === pathname)),
        [pathname]
    )

    if (!hasModulePermission(moduleKey)) return <></>

    return (
        <SidebarFolder name="Controle de Saldo" icon={<BsBoxSeam />} active={hasSubMenuActive}>
            <AutoMenu menu={MenuItems} />
        </SidebarFolder>
    )
}
