import { CommercialProductsRepository } from '@Commercial/Products/Data/Repository/ProductRepository'
import { DBCommercialCordType } from '../../Data/DataSource/API/Entity/Product'

export interface UpdateCordType {
    execute(entity: DBCommercialCordType): Promise<DBCommercialCordType>
    getSource(): string
}

export class UpdateCordTypeUseCase implements UpdateCordType {
    constructor(private repository: CommercialProductsRepository) {}
    async execute(entity: DBCommercialCordType): Promise<DBCommercialCordType> {
        return this.repository.updateCordType(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
