import BalanceStack from '@Balance/Navigator/Routes'
import CommercialStack from '@Commercial/Navigator/Routes'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionDenied } from '@Core/ErrorScreen/PermissionDenied/PermissionDenied'
import { RoutesPaths } from '@Entry/Common/RoutesPaths'
import { EntryRoot } from '@Entry/EntryRoot/EntryRoot'
import { Home } from '@Entry/Home/Home'
import { Login } from '@Entry/Login/Login'
import { roles } from '@Entry/roles'
import { Settings } from '@Entry/Settings/Settings'
import InventoryStack from '@InventoryControl/Navigator/Routes'
import UserStack from '@User/Navigator/Routes'
import ErrorPage from '@Utils/Pages/Error/Error'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom'
import { PrivacyRoute } from './PrivacyRoute'
const router = createBrowserRouter(
    createRoutesFromElements([
        <Route
            key={'MainStack'}
            path="/"
            element={<EntryRoot />}
            loader={() => <Loader />}
            errorElement={<ErrorPage />}
        >
            <Route
                path={RoutesPaths.PermissionDenied}
                element={<PermissionDenied />}
            />
            <Route path={RoutesPaths.Home} element={<Home />} />
            <Route
                element={
                    <PrivacyRoute
                        permission={roles.settings.permissions.read.key}
                    />
                }
            >
                <Route path={RoutesPaths.Settings} element={<Settings />} />
            </Route>
            {...BalanceStack}
            {...UserStack}
            {...InventoryStack}
            {...CommercialStack}
        </Route>,
        <Route key={'LoginRoute'} path="/login" element={<Login />} />,
    ])
)

export default function Routes() {
    return <RouterProvider router={router} />
}
