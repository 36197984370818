import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { useState } from 'react'

export const usePaginate = ({
    products,
}: {
    products: DBInventoryProduct[]
}) => {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const filteredProducts = products?.filter(
        (product) =>
            product.name.toLowerCase().includes(search.toLowerCase()) &&
            !product.isInactive
    )
    const getPaginate = (page: number, limit: number) => {
        return filteredProducts.slice((page - 1) * limit, page * limit)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    return {
        filteredProducts,
        getPaginate,
        handleSearch,
        search,
        page,
        limit,
        setPage,
        setLimit,
    }
}
