import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import { InventoryEntryRepository } from '@InventoryControl/Entries/Data/Repository/EntryRepository'

export interface GetEntries {
    execute(): Promise<DBInventoryEntry[]>
    getSource(): string
}

export class GetEntriesUseCase implements GetEntries {
    constructor(private repository: InventoryEntryRepository) {}
    async execute(): Promise<DBInventoryEntry[]> {
        return this.repository.findAll()
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
