import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { AutocompleteEntity } from '@Core/Components/InputAutoComplete/InputAutoComplete.types'
import { DBProduct } from '@Products/entities/Product'
import { Form } from '@unform/web'
import { useState } from 'react'

export const ProductFilter = ({
    products,
    setSelectedProduct,
}: {
    products: DBProduct[]
    setSelectedProduct: (product: string) => void
}) => {
    const [search, setSearch] = useState('')

    const filteredProducts = products
        ?.filter((product) =>
            product?.name
                ?.toLowerCase()
                ?.includes((search || '')?.toLowerCase() || '')
        )
        .map((product) => ({
            label: product.name,
            value: product._id,
            ...product,
        }))

    const handleProductSearch = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearch(event?.target?.value)
    }

    const handleProductSelected = (
        _: any,
        value: AutocompleteEntity<DBProduct>
    ) => {
        setSelectedProduct(value?.label ?? '')
    }

    return (
        <Form onSubmit={() => {}}>
            <InputAutoComplete
                label="Produtos"
                onChange={handleProductSelected}
                size="small"
                inputProps={{
                    name: `product`,
                }}
                results={filteredProducts}
                name="product"
                // @ts-ignore
                onInputChange={handleProductSearch}
            />
        </Form>
    )
}
