import styled from 'styled-components'
import { ModalSize } from './Modal.types'
import { getModalSize } from './Modal.utils'

export const ModalContainer = styled.div<{ isOpen: boolean; size: ModalSize }>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: ${({ theme }) => theme.background};
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    ${({ size }) => getModalSize(size)}
    // max-width: 80vw;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
`

export const Backdrop = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
    transition: all 0.3s ease-in-out;
`

export const Close = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: ${({ theme }) => theme.text};
`

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    gap: 10px;
    align-items: center;
`
