import { DBInventorySupplier } from '@InventoryControl/Supplier/Data/DataSource/API/Entity/Supplier'
import {
    InventorySupplierContainer,
    InventorySupplierRegister,
} from '@InventoryControl/Supplier/Data/DataSource/Container'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useInventorySupplierManager = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { id } = useParams<{ id: string }>()
    const [state, setState] = useState<DBInventorySupplier>(
        {} as DBInventorySupplier
    )
    const getSupplierUseCase = InventorySupplierContainer.get(
        InventorySupplierRegister.UseCaseGetSupplier
    )
    const updateSupplierUseCase = InventorySupplierContainer.get(
        InventorySupplierRegister.UseCaseUpdateSupplier
    )

    const handleEditSupplier = () => {
        const handleChange = (e: any) => {
            const { name, value } = e.target
            setState((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }

        const handleSubmit = async () => {
            setLoading(true)
            try {
                await updateSupplierUseCase.execute(state)
                toast.success('Fornecedor atualizado com sucesso')
            } catch (e) {
                console.log(e)
                toast.error('Erro ao atualizar fornecedor')
            }
            setLoading(false)
        }
        return { handleChange, handleSubmit }
    }

    const getSupplier = async () => {
        setLoading(true)
        try {
            const supplier = await getSupplierUseCase.execute(String(id))
            setState(supplier)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!id) return
        getSupplier()
    }, [id])

    return { state, getSupplier, loading, handleEditSupplier }
}
