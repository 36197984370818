import styled from 'styled-components'

export const Version = styled.span`
    font-size: 10px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
