import { InventoryExitsRepository } from '@InventoryControl/Exits/Data/Repository/ExitsRepository'

export interface DeleteExits {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteExitsUseCase implements DeleteExits {
    constructor(private repository: InventoryExitsRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
