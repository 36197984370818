import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { InventoryProductsRepository } from '@InventoryControl/Products/Data/Repository/ProductRepository'
import { Product } from '@InventoryControl/Products/entities/Product'

export interface CreateProduct {
    execute(client: Product): Promise<DBInventoryProduct>
    getSource(): string
}

export class CreateProductUseCase implements CreateProduct {
    constructor(private repository: InventoryProductsRepository) {}
    async execute(client: Product): Promise<DBInventoryProduct> {
        return this.repository.create(client)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
