import { useState } from 'react'
import {
    MovementContainer,
    MovementRegister,
} from '../Data/DataSource/Container'

export const usePreMovements = ({
    onCompleted = () => {},
}: {
    onCompleted?: () => void
}) => {
    const [movements, setMovements] = useState<any[]>([])
    const [loading, setLoading] = useState(false)

    const preMovementUseCase = MovementContainer.get(
        MovementRegister.UseCaseGetPreMovements
    )
    const ignorePreMovementUseCase = MovementContainer.get(
        MovementRegister.UseCaseIgnorePreMovement
    )

    const getPreMovements = async () => {
        setLoading(true)
        try {
            const data = await preMovementUseCase.execute()
            setMovements(data)
        } catch (e) {
            console.log(e)
        }
    }

    const ignorePreMovement = async (id: string) => {
        setLoading(true)
        try {
            await ignorePreMovementUseCase.execute(id)
        } catch (e) {
            console.log(e)
        }

        onCompleted?.()
        setLoading(false)
    }

    return {
        ignorePreMovement,
        movements,
        loading,
        getPreMovements,
    }
}
