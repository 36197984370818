import { InferType, date, number, object, string } from 'yup'

export const entrySchema = object().shape({
    observation: string(),
    quantity: number().required('Quantidade é obrigatório'),
    unitCost: number().required('Custo unitário obrigatório'),
    totalPurchaseValue: number(),
    created_at: date(),
})

export interface Entry extends InferType<typeof entrySchema> {}
