import { Table } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { formatCurrency } from '@Utils/Formatters/currency'
import { TablePagination } from '@material-ui/core'
import { format } from 'date-fns'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const EntryList = ({
    filteredEntries,
    loading,
}: {
    filteredEntries: DBInventoryEntry[]
    loading: boolean
}) => {
    const navigate = useNavigate()
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const gotoEntry = (id: string) => {
        navigate(RoutesPaths.InventoryEntry.replace(':id', id))
    }
    const getPaginate = (page: number, limit: number) => {
        return filteredEntries.slice((page - 1) * limit, page * limit)
    }

    if (loading) return <Loader />

    return (
        <Grid container>
            <Grid item lg={12} xs={12}>
                <Table>
                    <thead>
                        <tr>
                            <th>Produto</th>
                            <th>Fornecedor</th>
                            <th>Quantidade</th>
                            <th>Custo Unitário</th>
                            <th>Valor de Compra Total</th>
                            <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getPaginate(page, limit).map((entry) => (
                            <tr
                                key={entry._id}
                                data-clickable
                                onClick={() => gotoEntry(entry._id)}
                            >
                                <td>{entry.product?.name ?? 'N/D'}</td>
                                <td>{entry.supplier?.name ?? 'N/D'}</td>
                                <td>{entry.quantity ?? 'N/D'}</td>
                                <td>
                                    {formatCurrency(entry.unitCost) ?? 'N/D'}
                                </td>
                                <td>
                                    {formatCurrency(
                                        Number(entry.totalPurchaseValue || 0)
                                    ) ?? 'N/D'}
                                </td>
                                <td>
                                    {format(
                                        new Date(entry?.created_at),
                                        'dd/MM/yyyy'
                                    ) ?? 'N/D'}
                                </td>
                            </tr>
                        ))}
                        {filteredEntries.length === 0 && (
                            <tr>
                                <td colSpan={9}>Nenhum registro encontrado</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {Number(filteredEntries?.length) > 10 && (
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100, 10000, 100000]}
                        component="div"
                        count={filteredEntries.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}
