import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'

export const DeleteExitModal = ({
    isOpen,
    setIsOpen,
    callback,
}: {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    callback: () => void
}) => {
    const handleDelete = () => {
        setIsOpen(false)
        callback()
    }

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <h2>Tem certeza que deseja deletar esta saída</h2>
            <p>Você não poderá recuperar esta saída</p>
            <p>O saldo será revertido</p>

            <ModalFooter>
                <Button
                    variant={ButtonVariant.primary}
                    onClick={() => setIsOpen(false)}
                >
                    Cancelar
                </Button>
                <Button
                    variant={ButtonVariant.secondary}
                    onClick={handleDelete}
                >
                    Deletar
                </Button>
            </ModalFooter>
        </Modal>
    )
}
