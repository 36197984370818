import { RootState } from '@Core/Communication'
import { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import {
    SidebarFolderContainer,
    SidebarFolderContent,
} from './SidebarFolder.styles'
import { SidebarFolderProps } from './SidebarFolter.types'

export const SidebarFolder = ({ name, icon, children, active }: SidebarFolderProps) => {
    const [open, setOpen] = useState(false)
    const { sidebarCollapsed } = useSelector(
        (state: RootState) => state.appConfig
    )

    const toggle = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setOpen(!open);
        setTimeout(() => {
            const offsetTop = (event.target as HTMLDivElement)?.offsetTop;
            const indicatorEvent = new CustomEvent('update-indicator', {
                detail: { offsetTop },
            });
            window.dispatchEvent(indicatorEvent);
        }, 350)
    };


    return (
        <>
            <SidebarFolderContainer
                onClick={toggle}
                className={`sidebar-item ${active && 'activeFolder'}`}
                collapsed={sidebarCollapsed}
                opened={open}
            >
                {icon} <span className="sidebar-text">{name}</span>
                {!sidebarCollapsed && (
                    <h6>{open ? <BsChevronUp /> : <BsChevronDown />}</h6>
                )}
            </SidebarFolderContainer>
            <SidebarFolderContent>{open && children}</SidebarFolderContent>
        </>
    )
}
