export function memorizeResult<T extends (...args: any[]) => any>(func: T): T {
    const memory = new Map<any, any>()

    const wrapper = (...args: Parameters<T>): ReturnType<T> => {
        const key = JSON.stringify(args)
        if (memory.has(key)) {
            console.log('Resultado recuperado do cache.')
            return memory.get(key)
        } else {
            const result = func(...args)
            memory.set(key, result)
            return result
        }
    }

    return wrapper as T
}
