import { roles } from '@Commercial/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { useInventoryProducts } from '@InventoryControl/Products/Hooks/useProducts/useInventoryProducts'
import {
    formatAutoCompleteValue,
    formattedAutocomplete,
} from '@Utils/Formatters/formattedAutocomplete'
import { InputAdornment } from '@material-ui/core'
import { Form } from '@unform/web'
import { useEffect } from 'react'
import { Dynamic } from '../Custom/Dynamic/Dynamic'
import { Gift } from '../Custom/Gift/Gift'
import { Roller } from '../Custom/Roller/Roller'
import { useCommercialProductManager } from '../Hooks/useCommercialProductManager/useCommercialProductManager'

const productType = {
    dynamic: {
        Component: Dynamic,
        permission: roles.product.permissions.update.permissions.cord.key,
    },
    rollerClip: {
        Component: Roller,
        permission: roles.product.permissions.update.permissions.cord.key,
    },
    gift: {
        Component: Gift,
        permission: roles.product.permissions.update.permissions.cord.key,
    },
}

export const ProductDetails = () => {
    const { handleEditProduct, state, loading, getProduct } =
        useCommercialProductManager()
    const { handleChange, handleSubmit, handleInative } = handleEditProduct()
    const { hasPermission } = usePermissions()
    const { products, getProducts } = useInventoryProducts()

    const markupError =
        state?.markup < Number(state?.minMarkup) ||
        state?.markup > Number(state?.maxMarkup)

    const onSelectProduct = (_, product) => {
        handleChange({
            target: {
                name: 'inventoryProduct',
                value: product?.value || '',
            },
        })
    }

    const isDynamic = productType[state?.productType]?.Component

    const Component =
        productType[state?.productType] &&
        productType[state?.productType]?.Component

    useEffect(() => {
        getProducts()
    }, [])

    if (loading && !state?._id) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{state?.name}</h1>
                <div>
                    <Grid flex flexEnd>
                        <PermissionWrapper
                            permission={roles.product.permissions.update.key}
                        >
                            {!state.isInactive &&
                                hasPermission(
                                    roles.product.permissions.delete.key
                                ) && (
                                    <Button
                                        onClick={handleInative}
                                        variant={ButtonVariant.secondary}
                                    >
                                        Desativar
                                    </Button>
                                )}
                            <Button
                                onClick={handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </Grid>
                </div>
            </ContainerHeader>
            <Form onSubmit={() => {}} className="mt-2">
                <Grid container item lg={12}>
                    <Grid item lg={12}>
                        <Input
                            name={'name'}
                            onChange={handleChange}
                            label="Nome"
                            value={state?.name}
                        />
                    </Grid>
                    {!isDynamic && (
                        <PermissionWrapper
                            permission={
                                roles.product.permissions.update.permissions
                                    .cost.key
                            }
                        >
                            <Grid item lg={12}>
                                <Input
                                    name={'unitCost'}
                                    type="number"
                                    onChange={handleChange}
                                    label="Custo Unitário"
                                    disabled={
                                        !hasPermission(
                                            roles.product.permissions.update
                                                .permissions.cost.key
                                        )
                                    }
                                    value={state?.unitCost}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                R$
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <PermissionWrapper
                                permission={
                                    roles.product.permissions.update.permissions
                                        .markup.key
                                }
                            >
                                <Grid item lg={12}>
                                    <fieldset className="border rounded border-gray-500 p-2">
                                        <legend>Markup Mínimo</legend>
                                        <Grid container lg={12}>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'minMarkup'}
                                                    label="Markup"
                                                    onChange={handleChange}
                                                    value={state?.minMarkup}
                                                    type="number"
                                                    InputProps={{
                                                        min: 0,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'minUnitPrice'}
                                                    label="Venda unitária"
                                                    disabled
                                                    value={state.minUnitPrice}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'minProfit'}
                                                    label="Lucro"
                                                    disabled
                                                    value={state.minProfit}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'minMarkupFinal'}
                                                    label="Markup Final"
                                                    disabled
                                                    value={state.minMarkupFinal}
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item lg={12}>
                                    <fieldset className="border rounded border-gray-500 p-2">
                                        <legend>Markup Ideal</legend>
                                        <Grid container lg={12}>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'markup'}
                                                    label="Markup"
                                                    onChange={handleChange}
                                                    type="number"
                                                    value={state?.markup}
                                                    error={markupError}
                                                    supportMessages={[
                                                        {
                                                            message: markupError
                                                                ? `O Valor deve ser maior que ${Number(
                                                                      state?.minMarkup
                                                                  )} e menor que ${Number(
                                                                      state?.maxMarkup
                                                                  )}`
                                                                : '',
                                                        } as SupportMessages,
                                                    ]}
                                                    InputProps={{
                                                        min: state.minMarkup,
                                                        max: state.maxMarkup,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'unitPrice'}
                                                    label="Venda unitária"
                                                    disabled
                                                    value={state.unitPrice}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'profit'}
                                                    label="Lucro"
                                                    disabled
                                                    value={state.profit}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'markupFinal'}
                                                    label="Markup Final"
                                                    disabled
                                                    value={state.markupFinal}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                                <Grid item lg={12}>
                                    <fieldset className="border rounded border-gray-500 p-2">
                                        <legend>Markup Máximo</legend>
                                        <Grid container lg={12}>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'maxMarkup'}
                                                    label="Markup"
                                                    onChange={handleChange}
                                                    value={state?.maxMarkup}
                                                    type="number"
                                                    InputProps={{
                                                        min: 0,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'maxUnitPrice'}
                                                    label="Venda unitária"
                                                    disabled
                                                    value={state.maxUnitPrice}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'maxProfit'}
                                                    label="Lucro"
                                                    disabled
                                                    value={state.maxProfit}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                R$
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={3}>
                                                <Input
                                                    name={'maxMarkupFinal'}
                                                    label="Markup Final"
                                                    disabled
                                                    value={state.maxMarkupFinal}
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                %
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                </Grid>
                            </PermissionWrapper>

                            <Grid item lg={12}>
                                <InputAutoComplete
                                    results={formattedAutocomplete(
                                        products,
                                        'name'
                                    )}
                                    label={'Produto Vinculado'}
                                    onChange={onSelectProduct}
                                    name={'inventoryProduct'}
                                    value={formatAutoCompleteValue(
                                        state.inventoryProduct,
                                        'name'
                                    )}
                                    inputProps={{
                                        name: `inventoryProduct`,
                                    }}
                                />
                            </Grid>
                        </PermissionWrapper>
                    )}
                    <Grid item lg={12}>
                        {hasPermission(
                            productType[state?.productType]?.permission
                        ) && (
                            <Component
                                refresh={getProduct}
                                state={state}
                                onChange={handleChange}
                            />
                        )}
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
