import { remoteConfig } from '@/utils/remoteConfig'
import { CookiesStorage } from '@Core/Communication/GlobalStore/CookiesStorage/CookiesStorage'
import { useAuth } from '@Core/Hooks/useAuth'
import logo from '@assets/logo.png'
import odbLogo from '@assets/odb.png'
import { Divider } from '@material-ui/core'
import { OAuthProvider } from 'firebase/auth'
import { getValue } from 'firebase/remote-config'
import { Container, LoginCard, Logo, LogoContainer } from './Login.styles'
import { LoginForm } from './LoginForm'

export const Login = () => {
    const { authenticate, uiAuthenticate, loading, step, user } = useAuth(
        new CookiesStorage(),
        new OAuthProvider('microsoft.com')
    )

    const getFeatureFlat = (feature: string) =>
        getValue(remoteConfig, feature).asBoolean()

    return (
        <Container>
            <LoginCard>
                <LogoContainer>
                    <Logo src={logo}></Logo>
                    <Divider style={{ width: '50%' }} />
                    <Logo src={odbLogo} alt={'Oficina do Brinde'} />
                </LogoContainer>
                <LoginForm
                    uiAuthenticate={uiAuthenticate}
                    step={step}
                    user={user}
                    loading={loading}
                />
            </LoginCard>
        </Container>
    )
}
