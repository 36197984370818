import {
    GetUsersUseCase,
    GetUserUseCase,
    UpdateUserUseCase,
} from '@User/Domain/UseCases'
import { DisableUserUseCase } from '@User/Domain/UseCases/DisableUser'
import { EnableUserUseCase } from '@User/Domain/UseCases/EnableUser'
export const Register = {
    UseCaseGetUsers: GetUsersUseCase,
    UseCaseGetUser: GetUserUseCase,
    UseCaseUpdateUser: UpdateUserUseCase,
    UseCaseDisableUser: DisableUserUseCase,
    UseCaseEnableUser: EnableUserUseCase,
}
