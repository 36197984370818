import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import { useCallback, useMemo, useState } from 'react'

export const usePaginate = ({
    proposals,
}: {
    proposals: DBCommercialProposal[]
}) => {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [status, setStatus] = useState(['open', 'won', 'lost', 'N/D'])

    const handleStatusChange = (name: string) =>
        useCallback(
            (value: string) => {
                let newValues = new Set(status)

                if (newValues.has(name) && !value) {
                    newValues.delete(name)
                }
                if (!newValues.has(name) && value) {
                    newValues.add(name)
                }

                setStatus(Array.from(newValues))
            },
            [status]
        )

    const hasStatus = (name: string) => status.includes(name)

    const filteredProposal = useMemo(
        () =>
            proposals.filter((proposal) => {
                return (
                    ((proposal?.name || '')
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase()) ||
                        (proposal?.clientName || '')
                            ?.toLowerCase()
                            ?.includes(search?.toLowerCase())) &&
                    (!proposal?.status || status.includes(proposal?.status))
                )
            }),
        [proposals, search, status]
    )

    const getPaginate = (page: number, limit: number) => {
        return filteredProposal.slice((page - 1) * limit, page * limit)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    return {
        filteredProposal,
        getPaginate,
        handleSearch,
        search,
        page,
        limit,
        setPage,
        setLimit,
        handleStatusChange,
        hasStatus,
    }
}
