import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input, InputSize } from '@Core/Components/Input/Input'
import { MenuOptions } from '@Core/Components/MenuOptions/MenuOptions'
import { IMenuOption } from '@Core/Components/MenuOptions/MenuOptions.types'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { roles } from '@InventoryControl/roles'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { useEffect, useState } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { EntryList } from '../Common/Components/EntryList/EntryList'
import { useInventoryEntries } from '../Hooks/useInventoryEntries/useInventoryEntries'
import {
    exportEntryData,
    exportInventoryDictionary,
    exportInventoryEntryValueDictionary,
} from '../Utils/excelExport'

export const EntriesList = () => {
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('')
    const { entries, getEntries, loading } = useInventoryEntries()
    const filteredEntries = entries.filter(
        (entry) =>
            entry?.product?.name
                ?.toLowerCase()
                .includes(searchText.toLowerCase()) ||
            entry?.supplier?.name
                ?.toLowerCase()
                ?.includes(searchText.toLowerCase())
    )
    const handleCreate = () => {
        navigate(RoutesPaths.InventoryCreateEntry)
    }

    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }

    const exportMenuList: IMenuOption[] = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(exportEntryData(filteredEntries), `Entradas`, {
                    columnsDict: exportInventoryDictionary,
                    valuesDict: exportInventoryEntryValueDictionary,
                    sheetName: 'Entradas',
                })
            },
        },
    ]

    useEffect(() => {
        getEntries()
    }, [])

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Entradas</h1>
                <RightContent>
                    <Input
                        useUnform={false}
                        name="search"
                        size={InputSize.Small}
                        label="Buscar"
                        onChange={handleSearch}
                        value={searchText}
                    />
                    <PermissionWrapper
                        permission={roles.entries.permissions.create.key}
                    >
                        <Button
                            onClick={handleCreate}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Nova Entrada
                        </Button>
                    </PermissionWrapper>
                    <PermissionWrapper
                        permission={roles.entries.permissions.export.key}
                    >
                        <MenuOptions menuList={exportMenuList}>
                            <TfiExport />
                        </MenuOptions>
                    </PermissionWrapper>
                </RightContent>
            </ContainerHeader>
            <EntryList filteredEntries={filteredEntries} loading={loading} />
        </Container>
    )
}
