export class CookiesStorage {
    length: number = 0
    key: any = ''
    clear() {
        document.cookie.split(';').forEach(function (c) {
            document.cookie = c
                .replace(/^ +/, '')
                .replace(
                    /=.*/,
                    '=;expires=' + new Date().toUTCString() + ';path=/'
                )
        })
    }

    getItem(key: string) {
        let name = key + '='
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';')
        for (const element of ca) {
            let c = element
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }

    setItem(key: string, value: any) {
        if (typeof value === 'object') {
            value = JSON.stringify(value)
        }
        const d = new Date()
        d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000)
        let expires = 'expires=' + d.toUTCString()
        document.cookie = key + '=' + value + ';' + expires + ';path=/'
    }

    removeItem(key: string) {
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
}
