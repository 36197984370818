import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository'
import { DBCommercialProposal } from '../../Data/DataSource/API/Entity/Proposal'

export interface UpdateProposal {
    execute(entity: DBCommercialProposal): Promise<DBCommercialProposal>
    getSource(): string
}

export class UpdateProposalUseCase implements UpdateProposal {
    constructor(private repository: CommercialProposalRepository) {}
    async execute(entity: DBCommercialProposal): Promise<DBCommercialProposal> {
        return this.repository.update(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
