import { DefaultTheme } from 'styled-components'
import { ButtonVariant } from './Button.type'

export const getVariant = (variant: ButtonVariant, theme: DefaultTheme) =>
    ({
        [ButtonVariant.primary]: {
            backgroundColor: theme.colors.primary,
            color: theme.colors.button.primaryText,
            '&:hover': {
                backgroundColor: theme.colors.button.primaryHover,
            },
        },
        [ButtonVariant.secondary]: {
            backgroundColor: theme.colors.secondary,
            color: theme.colors.button.secondaryText,
            '&:hover': {
                backgroundColor: theme.colors.button.secondaryHover,
            },
        },
        [ButtonVariant.tertiary]: {
            backgroundColor: theme.colors.tertiary,
            color: theme.colors.button.tertiaryText,
            '&:hover': {
                backgroundColor: theme.colors.button.tertiaryHover,
            },
        },
    }[variant])

export const getSize = (size: 'small' | 'large' | 'medium') =>
    ({
        small: {
            padding: '5px 10px',
            fontSize: '12px',
        },
        large: {
            padding: '10px 20px',
            fontSize: '1rem',
        },
        medium: {
            padding: '8px 16px',
            fontSize: '14px',
        },
    }[size])
