import { BaseRepository } from '@/Domain/Repository/BaseRepository'
import { Product } from '@Commercial/Products/entities/Product'
import {
    CommercialAccessories,
    DBCommercialCordType,
    DBCommercialMarkup,
    DBCommercialPriceRange,
    DBCommercialProduct,
} from '../DataSource/API/Entity/Product'
import ProductsDataSource from '../DataSource/ProductsDataSource'

export class CommercialProductsRepository
    implements BaseRepository<DBCommercialProduct, Product>
{
    dataSource: ProductsDataSource
    constructor(_dataSource: ProductsDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(): Promise<DBCommercialProduct[]> {
        return this.dataSource.getProducts()
    }
    async findById(id: string): Promise<DBCommercialProduct> {
        return this.dataSource.getProductById(id)
    }
    async search(query: string): Promise<DBCommercialProduct[]> {
        return this.dataSource.search(query)
    }
    async create(entity: Product): Promise<DBCommercialProduct> {
        return this.dataSource.createProduct(entity)
    }

    async createAccessories(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        return this.dataSource.createAccessories(entity)
    }

    async createCordType(
        entity: DBCommercialCordType
    ): Promise<DBCommercialCordType> {
        return this.dataSource.createCordType(entity)
    }
    async update(entity: DBCommercialProduct): Promise<DBCommercialProduct> {
        return this.dataSource.updateProduct(entity)
    }
    async updateCordType(
        entity: DBCommercialCordType
    ): Promise<DBCommercialCordType> {
        return this.dataSource.updateCordType(entity)
    }

    async updateAccessories(
        entity: CommercialAccessories
    ): Promise<CommercialAccessories> {
        return this.dataSource.updateAccessories(entity)
    }

    async updateMarkup(
        entity: DBCommercialMarkup
    ): Promise<DBCommercialMarkup> {
        return this.dataSource.updateMarkup(entity)
    }

    async updatePriceRange(
        entity: DBCommercialPriceRange
    ): Promise<DBCommercialPriceRange> {
        return this.dataSource.updatePriceRange(entity)
    }

    async delete(id: string): Promise<void> {
        return this.dataSource.deleteProduct(id)
    }

    async deleteCordType(id: string): Promise<void> {
        return this.dataSource.deleteCordType(id)
    }

    async deleteAccessories(id: string): Promise<void> {
        return this.dataSource.deleteAccessories(id)
    }

    async deletePriceRange(id: string): Promise<void> {
        return this.dataSource.deletePriceRange(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
