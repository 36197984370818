import { combineReducers } from '@reduxjs/toolkit'
import appConfig from '../Reducers/appconfig/reducer'
import authentication from '../Reducers/authentication/reducer'
import clients from '../Reducers/clients/reducer'
import movements from '../Reducers/movements/reducer'
import supplier from '../Reducers/supplier/reducer'
import user from '../Reducers/user/reducer'

export default combineReducers({
    authentication,
    user,
    appConfig,
    clients,
    movements,
    supplier,
})
