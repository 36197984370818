import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { MenuOptions } from '@Core/Components/MenuOptions/MenuOptions'
import { IMenuOption } from '@Core/Components/MenuOptions/MenuOptions.types'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { roles } from '@InventoryControl/roles'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { Grid } from '@material-ui/core'
import { Key, useEffect, useState } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { NewDestinationModal } from '../Common/NewDestinationModal/NewDestinationModal'
import { useInventoryDestinations } from '../Hooks/useInventoryDestination/useInventoryDestination'
import {
    exportDestinationData,
    exportInventoryDestinationValueDictionary,
    exportInventoryDictionary,
} from '../Utils/excelExport'

export const DestinationsList = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { destinations, getDestinations, loading } =
        useInventoryDestinations()

    const [searchTest, setSearchTest] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTest(event.target.value)
    }
    const filteredSuppliers = destinations.filter((destination) =>
        destination.name.toLowerCase().includes(searchTest.toLowerCase())
    )

    const getPaginate = (page: number, limit: number) => {
        return filteredSuppliers.slice((page - 1) * limit, page * limit)
    }

    const openModal = () => {
        setIsModalOpen(true)
    }

    const exportMenuList: IMenuOption[] = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(
                    exportDestinationData(filteredSuppliers),
                    `Destinos`,
                    {
                        columnsDict: exportInventoryDictionary,
                        valuesDict: exportInventoryDestinationValueDictionary,
                        sheetName: 'Destinos',
                    }
                )
            },
        },
    ]

    useEffect(() => {
        getDestinations()
    }, [])
    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Destinos</h1>
                <RightContent>
                    <Input
                        useUnform={false}
                        name="search"
                        label="Buscar"
                        size="small"
                        onChange={handleSearch}
                        value={searchTest}
                    />
                    <PermissionWrapper
                        permission={roles.destinations.permissions.create.key}
                    >
                        <Button
                            onClick={openModal}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Novo destino
                        </Button>
                        <NewDestinationModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                            callback={getDestinations}
                        />
                    </PermissionWrapper>
                    <PermissionWrapper
                        permission={roles.destinations.permissions.export.key}
                    >
                        <MenuOptions menuList={exportMenuList}>
                            <TfiExport />
                        </MenuOptions>
                    </PermissionWrapper>
                </RightContent>
            </ContainerHeader>
            <Grid container item xs={12} lg={12}>
                <Grid item xs={12} lg={12}>
                    {loading && <Loader />}
                    {!loading && destinations && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPaginate(page, limit).map((destination) => (
                                    <tr
                                        key={destination._id as Key}
                                        data-clickable
                                        onClick={() => {
                                            navigate(
                                                formatRouteId(
                                                    RoutesPaths.InventoryDestination,
                                                    destination._id
                                                )
                                            )
                                        }}
                                    >
                                        <td>{destination.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {!loading && destinations.length > 10 && (
                        <TablePagination
                            labelRowsPerPage="Itens por página"
                            rowsPerPageOptions={[10, 25, 100, 10000, 100000]}
                            component="div"
                            count={destinations.length}
                            rowsPerPage={limit}
                            labelDisplayedRows={({ from, to, count }) =>
                                from
                                    ? `${from}-${to} de ${count}`
                                    : `1 de ${count}`
                            }
                            page={Number(page - 1)}
                            onPageChange={(_, page) => setPage(page + 1)}
                            onRowsPerPageChange={(e) => {
                                setLimit(Number(e.target.value))
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
