import { RoutesPaths } from '@Balance/RoutesPaths'
import { roles } from '@Balance/roles'
import { DeleteAdditionalEmailModal } from '@Clients/Common/DeleteAdditionalEmailModal/DeleteAdditionalEmailModal'
import { EmailDelete } from '@Clients/Common/DeleteAdditionalEmailModal/DeleteAdditionalEmailModal.types'
import { Clients } from '@Clients/Domain/Model/Clients'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { IconButton } from '@Core/Components/IconButton/IconButton'
import { Input, InputSize } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { MovementsTable } from '@Core/Components/MovementsTable/MovementsTable'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { SendMailWithStock } from '@Core/Components/SendMailWithStock/SendMailWithStock'
import { StockTable } from '@Core/Components/StockTable/StockTable'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { StockDocument } from '@Reports/Pdf/Pdf'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import DeleteIcon from '@assets/delete.png'
import { usePDF } from '@react-pdf/renderer'
import { Scope } from '@unform/core'
import { Form } from '@unform/web'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useClientManager } from '../Hooks/useClientManager'
import { DeleteModalProps } from './ClientDetails.types'
import {
    exportClientStockDictionary,
    exportClientStockValueDictionary,
    exportStockData,
} from './ClientDetails.utils'

export const ClientDetails = () => {
    const [isDeleteEmailModalShow, setIsDeleteEmailModalShow] = useState(false)
    const [selectedEmailID, setSelectedEmailID] = useState<EmailDelete>({
        email: '',
        index: 0,
    })
    const [sendStock, setSendStock] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { getClientByID, handleEditClient, state } = useClientManager()
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const stockDocument = StockDocument({
        title: `Saldo - ${state?.corporateName}`,
        // @ts-ignore
        stocks: state?.stocks,
    })
    const [instance, update] = usePDF({
        document: stockDocument,
    })
    const {
        handleChange,
        handleSubmit,
        handleNewAdditionalEmail,
        handleRemoveAdditionalMail,
    } = handleEditClient(id ?? '')
    const [client, setClient] = useState<Clients>()
    const [editMode, setEditMode] = useState(false)
    const [filteredMovements, setFilteredMovements] = useState<
        DBMovement[] | undefined
    >([])

    const getClient = async () => {
        if (!id) return
        const clientDetails = await getClientByID(id)
        setClient(clientDetails)
    }
    const closeSendStock = () => setSendStock(false)

    const toggleEditMode = () => {
        setEditMode(!editMode)
        editMode && getClient()
    }

    const _handleSubmit = async () => {
        await handleSubmit()
        toggleEditMode()
    }

    const handleShowDeleteModal = (email: string, index: number) => {
        setSelectedEmailID({ email, index })
        setIsDeleteEmailModalShow(true)
    }

    const handleDelete = (item: DeleteModalProps) => {
        handleRemoveAdditionalMail(item)
        setIsDeleteEmailModalShow(false)
    }

    const exportStockMenuList = [
        {
            label: 'Enviar por e-mail para o cliente',
            onClick: () => {
                setSendStock(true)
            },
        },
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(
                    exportStockData(client?.stocks, { client }),
                    `${client?.corporateName} - Saldo`,
                    {
                        columnsDict: exportClientStockDictionary,
                        valuesDict: exportClientStockValueDictionary,
                        sheetName: 'Saldo',
                    }
                )
            },
        },
        {
            label: 'Exportar para PDF',
            href: instance.url,
            attrs: {
                download: `${client?.corporateName} - Saldo.pdf`,
                target: '_blank',
            },
        },
    ]

    const getMovements = (page: number, limit: number) => {
        setPage(page)
        setLimit(limit)
        setFilteredMovements(
            client?.movements?.slice((page - 1) * limit, page * limit)
        )
    }

    const stockOnlyOutIn = () =>
        client?.stocks?.filter(
            (stock) =>
                stock.product[0].outTotal?.[0]?.total > 0 ||
                stock.product?.[0].inputTotal?.[0]?.total > 0
        )

    useEffect(() => {
        getClient()
    }, [])

    useEffect(() => {
        // @ts-ignore
        setClient(state)
        update(stockDocument)
        getMovements(page, limit)
    }, [state])

    if (!client) return <Loader />

    return (
        <Container>
            <SendMailWithStock
                onSuccess={() => {}}
                client={client || ({} as Clients)}
                isOpen={sendStock}
                onClose={closeSendStock}
            />
            <DeleteAdditionalEmailModal
                isOpen={isDeleteEmailModalShow}
                setIsOpen={setIsDeleteEmailModalShow}
                email={selectedEmailID}
                onConfirm={handleDelete}
            />
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{client?.corporateName}</h1>
                {!editMode && (
                    <RightContent>
                        <PermissionWrapper
                            permission={roles.clients.permissions.update.key}
                        >
                            <Button
                                onClick={toggleEditMode}
                                variant={ButtonVariant.primary}
                            >
                                Editar
                            </Button>
                        </PermissionWrapper>
                        <PermissionWrapper
                            permission={roles.movements.permissions.create.key}
                        >
                            <Button
                                onClick={() =>
                                    navigate(RoutesPaths.NewMovement, {
                                        state: { pathname, client },
                                    })
                                }
                                variant={ButtonVariant.primary}
                            >
                                Nova Movimentação
                            </Button>
                        </PermissionWrapper>
                    </RightContent>
                )}
                {editMode && (
                    <RightContent>
                        <PermissionWrapper
                            permission={roles.clients.permissions.update.key}
                        >
                            <Button
                                data-testid="cancel-button"
                                onClick={toggleEditMode}
                                variant={ButtonVariant.secondary}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={_handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </RightContent>
                )}
            </ContainerHeader>
            <Form onSubmit={() => {}} initialData={client}>
                <Grid container>
                    {editMode && (
                        <Grid item xs={12} lg={4} md={6}>
                            <Input
                                label="Nome"
                                name="corporateName"
                                onChange={handleChange}
                                value={state?.corporateName}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} lg={4} md={6}>
                        <Input
                            label="E-mail"
                            supportMessages={[
                                {
                                    message: 'Principal',
                                    variant: 'info',
                                },
                            ]}
                            name="mail"
                            disabled={!editMode}
                            onChange={handleChange}
                            type="mail"
                            value={state?.mail}
                        />
                    </Grid>
                    {editMode &&
                        state?.additionalEmails &&
                        state?.additionalEmails.map((item, index) => (
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                md={6}
                                key={`additionalEmails[${index}]`}
                                flex
                            >
                                <Grid item xs={10}>
                                    <Scope path={`additionalEmails[${index}]`}>
                                        <Input
                                            supportMessages={[
                                                {
                                                    message: 'Adicional',
                                                    variant: 'info',
                                                },
                                            ]}
                                            label="E-mail"
                                            name={`additionalEmails[${index}]`}
                                            onChange={handleChange}
                                            type="mail"
                                            value={item}
                                        />
                                    </Scope>
                                </Grid>
                                <IconButton
                                    size="medium"
                                    onClick={() =>
                                        handleShowDeleteModal(item, index)
                                    }
                                >
                                    <img
                                        src={DeleteIcon}
                                        alt="Remover e-mail"
                                    />
                                </IconButton>
                            </Grid>
                        ))}
                    {!editMode &&
                        client?.additionalEmails &&
                        client?.additionalEmails.map((item, index) => (
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                md={6}
                                key={`additionalEmails[${index}]`}
                            >
                                <Scope path={`additionalEmails[${index}]`}>
                                    <Input
                                        supportMessages={[
                                            {
                                                message: 'Adicional',
                                                variant: 'info',
                                            },
                                        ]}
                                        label="E-mail"
                                        disabled
                                        name={`additionalEmails[${index}]`}
                                        onChange={handleChange}
                                        type="mail"
                                        value={item}
                                    />
                                </Scope>
                            </Grid>
                        ))}
                    {editMode && (
                        <Grid item xs={12} lg={3} md={6} flex flexEnd>
                            <div>
                                <Button
                                    onClick={handleNewAdditionalEmail}
                                    size={InputSize.Large}
                                >
                                    Adicionar e-mail
                                </Button>
                            </div>
                        </Grid>
                    )}
                    <Grid item lg={12}>
                        <TextArea
                            disabled={!editMode}
                            name="observation"
                            label="Observações"
                            minRows={3}
                            value={state?.observation}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </Form>
            <div className="pt-2">
                <Grid container xs={12} lg={12}>
                    <Grid item lg={12}>
                        <StockTable
                            stocks={stockOnlyOutIn() || []}
                            exportOptions={exportStockMenuList}
                        />
                    </Grid>
                </Grid>
            </div>

            <Grid container xs={12} lg={12}>
                <PermissionWrapper
                    permission={roles.movements.permissions.read.key}
                >
                    <Grid container item lg={12}>
                        <Grid item lg={8}>
                            <h3 className="text-medium font-semibold mt-2">
                                Movimentações
                            </h3>
                        </Grid>
                    </Grid>
                </PermissionWrapper>
                <Grid item lg={12}>
                    <MovementsTable
                        allMovements={client?.movements as DBMovement[]}
                        movements={filteredMovements as DBMovement[]}
                        hideFilters={['client']}
                        options={{
                            count: Number(client?.movements?.length),
                            limit,
                            page,
                        }}
                        hideColumns={['client']}
                        getMovements={getMovements}
                        hasPagination
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
