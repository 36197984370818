import styled from 'styled-components'
export const HomeBackground = styled.div<{ url: string }>`
    background-image: ${({ url }) => `url(${url})`};
    background-size: cover;
    background-position: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
`

export const HomeTitle = styled.h1`
    color: ${({ color }) => color || 'white'};
    // font-size: 40px;
    font-weight: bold;
    text-align: center;
`

export const HomeDescription = styled.p`
    color: ${({ color }) => color || 'white'};
    // font-size: 20px;
    text-align: center;
`
