import {
    CommercialAccessories,
    DBCommercialPriceRange,
    DBCommercialProduct,
} from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Button } from '@Core/Components'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Modal } from '@Core/Components/Modal/Modal'
import { formattedAutocomplete } from '@Utils/Formatters/formattedAutocomplete'
import { Grid } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'

export const GiftOptions = ({
    isOpen,
    onClose,
    control,
    setValue,
    state,
    prefixName,
                                disabled = false
}: {
    isOpen: boolean
    onClose: () => void
    control: any
    setValue: any
    disabled?: boolean
}) => {
    const getProductContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseGetProduct
    )
    const [priceRange, setPriceRange] = useState<DBCommercialPriceRange[]>([])
    const [accessories, setAccessories] = useState<CommercialAccessories[]>([])

    const getPrices = useCallback(async () => {
        let product = state
        if (state?.productID !== undefined) {
            product = (await getProductContainer.execute(
                state?.productID
            )) as unknown as DBCommercialProduct[]
        }
        const range = [...(product?.priceTable || [])].sort((a, b) =>
            a.name.localeCompare(b.name)
        )
        setAccessories(product?.accessories)
        setPriceRange(range)

        getMarkups()

    }, [state])

    const getMarkups = useCallback(async () => {
        const markups = state.markups
        setValue(prefixName + '.markupTable', markups[0])
    }, [])

    const getUpdatedPrice = useCallback(() => {
        if(disabled) return
       if(!state.priceRange || !state.priceTable) return
        const range = state.priceTable.find(table => table._id === state.priceRange._id)
        if(!range) return
        setTimeout(() => {
        setValue(prefixName + '.priceRange', range)
            }, 500)
    },[state])

    useEffect(() => {
        getPrices()
        getUpdatedPrice()
    }, [])

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="grid grid-cols-1 gap-4">
                <h2>{state.name}</h2>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                        {Boolean(priceRange?.length) && (
                            <InputAutoComplete
                                control={control}
                                useUnform={false}
                                results={formattedAutocomplete<DBCommercialPriceRange>(
                                    priceRange || [],
                                    'name'
                                )}
                                label={'Especificação'}
                                labelKey={'name'}
                                onChange={() => {}}
                                name={`${prefixName}.priceRange`}
                            />
                        )}
                    </Grid>
                    {state.accessories && accessories && (
                        <Grid item xs={12} lg={12}>
                            <InputAutoComplete
                                control={control}
                                useUnform={false}
                                results={formattedAutocomplete<CommercialAccessories>(
                                    accessories || [],
                                    'name'
                                )}
                                multiple
                                filterSelectedOptions
                                label={'Acessórios'}
                                labelKey={'name'}
                                onChange={setValue}
                                name={`${prefixName}.giftAccessories`}
                            />
                        </Grid>
                    )}
                </Grid>
                <Button
                    disabled={!Boolean(state?.priceRange)}
                    type="button"
                    onClick={onClose}
                >
                    Fechar
                </Button>
            </div>
        </Modal>
    )
}
