import {
    ClientContainer,
    ClientRegister,
} from '@Balance/Clients/Data/DataSource/Container'
import { useEffect, useState } from 'react'
import { NegativeClientStock } from './useNegativeStocks.types'

export const useNegativeStocks = () => {
    const [negativeStocks, setNegativeStocks] = useState<NegativeClientStock[]>(
        []
    )
    const negativeStockUseCase = ClientContainer.get(
        ClientRegister.UseCaseNegativeStockClients
    )

    useEffect(() => {
        negativeStockUseCase.execute().then((rest) => setNegativeStocks(rest))
    }, [])

    return {
        negativeStocks,
    }
}
