import {
    InventoryProductContainer,
    InventoryProductRegister,
} from '@InventoryControl/Products/Data/DataSource/Container'
import {
    Product,
    productSchema,
} from '@InventoryControl/Products/entities/Product'
import getValidationErrors from '@Utils/Support/getValidationErrors'
import { FormHandles } from '@unform/core'
import { useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'

export const useInventoryCreateProduct = () => {
    const formRef = useRef<FormHandles>(null)
    const [loading, setLoading] = useState(false)
    const createProductUseCase = InventoryProductContainer.get(
        InventoryProductRegister.UseCaseCreateProduct
    )
    const [state, setState] = useState<Product>({} as Product)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }))
    }

    const handleCreateProduct = useCallback(
        async (callBack: () => void) => {
            setLoading(true)
            const productState = { ...state }
            productState.unitCost = Number(
                String(productState.unitCost).replace(',', '.')
            )
            try {
                if (
                    await productSchema.validate(productState, {
                        abortEarly: false,
                    })
                ) {
                    await createProductUseCase.execute(productState)
                    toast.success('Produto criado com sucesso')
                    formRef.current?.reset()
                    setState({} as Product)
                    callBack?.()
                }
            } catch (error) {
                const errors = getValidationErrors(error as any)
                formRef.current?.setErrors(errors)
            } finally {
                setLoading(false)
            }
        },
        [state]
    )

    return {
        handleCreateProduct,
        handleChange,
        formRef,
        state,
        loading,
    }
}
