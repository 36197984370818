import { RootState } from '@Core/Communication'
import { setSupplierAction } from '@Core/Communication/GlobalStore/Reducers/supplier/reducer'
import { ApiType } from '@Services/api'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useSupplier = (api: ApiType) => {
    const [loading, setLoading] = useState(false)
    const { supplier } = useSelector((state: RootState) => state.supplier)
    const dispatch = useDispatch()
    const getSupplier = async () => {
        setLoading(true)
        const { data } = await api.get('/supplier')
        dispatch(setSupplierAction({ source: api.getSource(), data }))
        setLoading(false)
    }

    const search = async (query: string) => {
        if (!query) return getSupplier()
        const { data } = await api.get(`/supplier/search/${query}`)
        dispatch(setSupplierAction({ data, source: api.getSource() }))
    }

    useEffect(() => {
        getSupplier()
    }, [])

    return {
        loading,
        supplier: supplier[api.getSource()] || [],
        search,
    }
}
