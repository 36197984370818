import {
    DatePicker,
    formattedValue,
} from '@InventoryControl/Entries/Common/Components/DatePicker/DatePicker'

import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from '@/components/ui/hover-card'
import { formatAutoCompleteValue } from '@Utils/Formatters/formattedAutocomplete'
import { Typography } from '@material-ui/core'
import { Grid } from '../Grid/Grid'
import { Input } from '../Input/Input'
import { InputAutoComplete } from '../InputAutoComplete/InputAutoComplete'
import { TextArea } from '../TextArea/TextArea'

export const AutoForm = ({ inputs }: { inputs: any[] }) => {
    return (
        <div className="grid grid-cols-12 gap-2 h-full">
            {inputs?.map(
                ({
                    type,
                    label,
                    name,
                    disabled,
                    key,
                    labelKey = '',
                    grid,
                    onChange,
                    value,
                    register,
                    hide = false,
                    className = '',
                    withHover,
                    ...props
                }) => {
                    if (hide) return <></>
                    if (type === 'label') {
                        return (
                            <Grid item lg={grid} xs={grid} key={key}>
                                <Typography align="center" variant="body1">
                                    {label}:
                                </Typography>
                                <Typography align="center" variant="body1">
                                    {value}
                                </Typography>
                            </Grid>
                        )
                    }
                    if (type === 'custom') {
                        return (
                            <Grid item lg={grid} xs={grid} key={key}>
                                {props?.Component?.()}
                            </Grid>
                        )
                    }
                    if (type === 'autocomplete')
                        return (
                            <Grid item lg={grid} xs={grid} key={key}>
                                <div className="grid w-full items-center">
                                    <InputAutoComplete
                                        className={` ${className}`}
                                        name={name}
                                        label={label}
                                        loading={!props.results}
                                        results={props.results || []}
                                        onChange={onChange}
                                        control={props?.control || undefined}
                                        labelKey={labelKey}
                                        value={formatAutoCompleteValue(
                                            value,
                                            labelKey
                                        )}
                                        InputProps={{
                                            className,
                                        }}
                                        inputProps={{
                                            className,
                                            disabled: Boolean(disabled),
                                            name,
                                            register,
                                            useUnform: false,
                                            ...props?.inputProps,
                                        }}
                                    />
                                </div>
                            </Grid>
                        )
                    if (type === 'date') {
                        return (
                            <Grid item lg={grid} xs={grid} key={key}>
                                {disabled && (
                                    <Input
                                        className={` ${className}`}
                                        useUnform={false}
                                        {...props}
                                        name={name}
                                        disabled
                                        value={formattedValue(value)}
                                    />
                                )}
                                {!disabled && (
                                    <DatePicker
                                        inputProps={{
                                            disabled: Boolean(!disabled),
                                            register,
                                            useUnform: false,
                                            label: label,
                                        }}
                                        onChange={onChange}
                                    />
                                )}
                            </Grid>
                        )
                    }
                    if (type === 'text') {
                        return (
                            <Grid item lg={grid} xs={grid} key={key}>
                                <div className="grid w-full items-center">
                                    <TextArea
                                        className={` ${className}`}
                                        maxRows={1}
                                        useUnform={false}
                                        label={label}
                                        register={register}
                                        name={name}
                                        disabled={Boolean(disabled)}
                                        {...props}
                                    />
                                </div>
                            </Grid>
                        )
                    }
                    return (
                        <Grid item lg={grid} xs={grid} key={key}>
                            <div className="grid w-full items-center">
                                {withHover && value ? (
                                    <HoverCard>
                                        <HoverCardTrigger>
                                            <Input
                                                className={` ${className}`}
                                                useUnform={false}
                                                label={label}
                                                name={name}
                                                type={type}
                                                value={value}
                                                disabled={Boolean(disabled)}
                                                {...props}
                                            />
                                        </HoverCardTrigger>
                                        <HoverCardContent className="w-80">
                                            {props?.helper || value}
                                        </HoverCardContent>
                                    </HoverCard>
                                ) : (
                                    <Input
                                        className={` ${className}`}
                                        useUnform={false}
                                        label={label}
                                        name={name}
                                        type={type}
                                        value={value}
                                        disabled={Boolean(disabled)}
                                        {...props}
                                    />
                                )}
                            </div>
                        </Grid>
                    )
                }
            )}
        </div>
    )
}
