import { initializeApp } from 'firebase/app'
import { getDatabase, ref } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: 'oficina-do-cracha.firebaseapp.com',
    projectId: 'oficina-do-cracha',
    databaseURL: 'https://oficina-do-cracha-default-rtdb.firebaseio.com',
    storageBucket: 'oficina-do-cracha.appspot.com',
    messagingSenderId: '522473829904',
    appId: '1:522473829904:web:19fc2da6f3bee4bbd0bc95',
    measurementId: 'G-QR76HZ07B6',
}

export const app = initializeApp(firebaseConfig)
export const perf = getPerformance(app)
export const database = getDatabase(app)
export const db = getFirestore(app)
export const presenceRef = ref(database, 'presence')
export const storage = getStorage(app);
