import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'

import { Grid } from '@Core/Components/Grid/Grid'
import { useInventoryCreateSupplier } from '@InventoryControl/Supplier/Hooks/useInventoryCreateSupplier/useInventoryCreateSupplier'
import { Form } from '@unform/web'
import { NewSupplierContent } from './NewSupplierModal.styles'
import { NewSupplierModalProps } from './NewSupplierModal.types'

export const NewSupplierModal = ({
    isOpen,
    setIsOpen,
    callback,
}: NewSupplierModalProps) => {
    const { formRef, handleCreateSupplier, handleChange, state } =
        useInventoryCreateSupplier()

    const toggleModal = () => {
        setIsOpen?.(!isOpen)
    }

    const handleSubmit = async () => {
        handleCreateSupplier(() => {
            toggleModal()
            callback?.()
        })
    }

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={toggleModal}>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h2>Novo Fornecedor</h2>
                <NewSupplierContent>
                    <Grid container>
                        <Grid item lg={12}>
                            <Input
                                name={'name'}
                                label="Nome"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item lg={6}>
                            <Input
                                name={'contact'}
                                label="Contato"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item lg={6}>
                            <Input
                                name={'phone'}
                                label="Telefone"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <Input
                                name={'email'}
                                label="E-mail"
                                type="email"
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </NewSupplierContent>
                <ModalFooter>
                    <Button
                        onClick={toggleModal}
                        variant={ButtonVariant.secondary}
                    >
                        Cancelar
                    </Button>
                    <Button type="submit" variant={ButtonVariant.primary}>
                        Cadastrar
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
