import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Button, ButtonVariant, Table } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Card, IconButton } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'

export const CordTypes = ({
    cordType,
    onDelete,
    refresh,
    productId,
}: {
    cordType: any
    onDelete: () => void
    refresh: () => void
    productId: string
}) => {
    const [editMode, setEditMode] = useState(cordType.isNew || false)
    const [state, setState] = useState(cordType)
    const { control, setValue, watch } = useForm({
        defaultValues: state,
    })

    const updateCordTypeContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseUpdateCordType
    )

    const createCordTypeContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseCreateCordType
    )

    const addRow = useCallback(() => {
        setState((prev) => {
            const newRows = [
                ...prev.table.rows,
                new Array(prev.table.columns.length)
                    .fill({
                        value: '',
                    })
                    .map((item) => ({
                        value: '',
                        key: crypto.randomUUID(),
                    })),
            ]
            setValue('table.rows', newRows)
            return {
                ...prev,
                table: {
                    ...prev.table,
                    rows: newRows,
                },
            }
        })
    }, [setState])

    const addColumn = useCallback(() => {
        setState((prev) => {
            const newColumns = [...prev.table.columns, '']
            setValue('table.columns', newColumns)
            const newRows = [...prev.table.rows]
            for (const element of newRows) {
                element.push('')
            }
            setValue('table.rows', newRows)
            return {
                ...prev,
                table: {
                    ...prev.table,
                    columns: newColumns,
                },
            }
        })
    }, [setState])

    const removeRow = useCallback(
        (index: number) => {
            setState((prev) => {
                const newRows = [...prev.table.rows]
                newRows.splice(index, 1)
                setValue('table.rows', newRows)
                return {
                    ...prev,
                    table: {
                        ...prev.table,
                        rows: newRows,
                    },
                }
            })
        },
        [setState]
    )

    const removeColumn = useCallback(
        (index: number) => {
            setState((prev) => {
                const newColumns = [...prev.table.columns]
                newColumns.splice(index, 1)
                setValue('table.columns', newColumns)
                const newRows = [...prev.table.rows]
                for (const element of newRows) {
                    element.splice(index, 1)
                }
                setValue('table.rows', newRows)
                return {
                    ...prev,
                    table: {
                        ...prev.table,
                        columns: newColumns,
                    },
                }
            })
        },
        [setState]
    )

    const handleSave = useCallback(async () => {
        if (state.isNew) {
            const { _id, ...rest } = state
            await createCordTypeContainer.execute({ ...rest, productId })
        } else {
            await updateCordTypeContainer.execute({ ...state, productId })
        }
        toast.success('Tipo atualizado com sucesso')
        setEditMode(false)
        refresh()
    }, [state, productId])

    useEffect(() => {
        watch((value) => {
            setState(value)
        })
    }, [])

    return (
        <Card>
            <Table key={state._id}>
                <thead>
                    <th
                        colSpan={
                            editMode
                                ? state.table.columns.length
                                : state.table.columns.length - 1
                        }
                    >
                        {editMode && (
                            <Input
                                name={'name'}
                                control={control}
                                fullWidth
                                useUnform={false}
                            />
                        )}
                        {!editMode && state.name}
                    </th>

                    {editMode ? (
                        <>
                            <th>
                                <Button onClick={handleSave}>Salvar</Button>
                            </th>
                            <th>
                                <Button
                                    type="button"
                                    variant={ButtonVariant.secondary}
                                    onClick={() => {
                                        setEditMode(false)
                                        refresh()
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </th>
                        </>
                    ) : (
                        <th>
                            <Button
                                type="button"
                                variant={ButtonVariant.secondary}
                                onClick={() => setEditMode(true)}
                            >
                                Editar
                            </Button>
                        </th>
                    )}
                </thead>
                <tbody>
                    {editMode && (
                        <tr className="head">
                            <th colSpan={2}></th>
                            {[...new Array(state.table.columns.length - 1)].map(
                                (_, i) => (
                                    <th key={_}>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => removeColumn(i + 1)}
                                        >
                                            <AiFillCloseCircle />
                                        </IconButton>
                                    </th>
                                )
                            )}
                        </tr>
                    )}
                    <tr className="head">
                        {editMode && <th></th>}
                        {state.table.columns.map(({ key, value }, i) => (
                            <th key={key}>
                                {editMode && (
                                    <Input
                                        name={'table.columns[' + i + '].value'}
                                        control={control}
                                        fullWidth
                                        useUnform={false}
                                    />
                                )}
                                {!editMode && value}
                            </th>
                        ))}
                        {editMode && (
                            <td
                                rowSpan={state.table.rows.length + 1}
                                className="head"
                            >
                                <IconButton color="primary" onClick={addColumn}>
                                    <AiOutlinePlusCircle />
                                </IconButton>
                            </td>
                        )}
                    </tr>
                    {state.table.rows.map((row, i) => (
                        <tr key={row[0]}>
                            {editMode && (
                                <td>
                                    <IconButton
                                        onClick={() => removeRow(i)}
                                        color="secondary"
                                    >
                                        <AiFillCloseCircle />
                                    </IconButton>
                                </td>
                            )}
                            {row.map(({ key, value }, j) => (
                                <td
                                    key={key}
                                    data-first-col={j === 1}
                                    data-first-row={i === 0 && j !== 0}
                                >
                                    {editMode && (
                                        <Input
                                            name={
                                                'table.rows[' +
                                                i +
                                                '][' +
                                                j +
                                                '].value'
                                            }
                                            control={control}
                                            fullWidth
                                            useUnform={false}
                                        />
                                    )}
                                    {!editMode && value}
                                </td>
                            ))}
                        </tr>
                    ))}
                    {editMode && (
                        <tr>
                            <td colSpan={state.table.columns.length + 1}>
                                <IconButton color="primary" onClick={addRow}>
                                    <AiOutlinePlusCircle />
                                </IconButton>
                            </td>
                            <td>
                                <Button
                                    variant={ButtonVariant.secondary}
                                    onClick={onDelete}
                                >
                                    Deletar
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
