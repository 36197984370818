import { DBCommercialProposal } from "../Data/DataSource/API/Entity/Proposal"

export const sumQuantities = (state: DBCommercialProposal) => {
  const products = [...state?.products]
  return products.reduce(
      (acc, cur) => Number(acc) + (cur.isActive && Number(cur.quantity) || 0),
      0
  )
}

export const sumActiveProducts = (state: DBCommercialProposal) => {
  const products = [...state?.products]
  return products.filter(
      (item) => item.isActive
  ).length
}