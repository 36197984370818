import { IgnorePreMovementUseCase } from '@Balance/Movements/Domain/UseCases/IgnorePreMovement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'
import { Container } from 'inversify'
import { MovementAPI } from '../API/MovementAPI'
import { PreMovementAPI } from '../API/PreMovementAPI'
import {
    CreateMovement,
    CreateMovementUseCase,
    DeleteMovement,
    DeleteMovementUseCase,
    GetMovement,
    GetMovements,
    GetMovementsUseCase,
    GetMovementUseCase,
    GetPreMovementsUseCase,
    UpdateMovements,
    UpdateMovementUseCase,
} from './../../../Domain/UseCases'
import { Register } from './MovementRegister'

const container = new Container()
container
    .bind<CreateMovement>(Register.UseCaseCreateMovement)
    .toDynamicValue(
        () =>
            new CreateMovementUseCase(new MovementRepository(new MovementAPI()))
    )
container
    .bind<GetMovement>(Register.UseCaseGetMovement)
    .toDynamicValue(
        () => new GetMovementUseCase(new MovementRepository(new MovementAPI()))
    )
container
    .bind<GetMovements>(Register.UseCaseGetMovements)
    .toDynamicValue(
        () => new GetMovementsUseCase(new MovementRepository(new MovementAPI()))
    )
container
    .bind<UpdateMovements>(Register.UseCaseUpdateMovement)
    .toDynamicValue(
        () =>
            new UpdateMovementUseCase(new MovementRepository(new MovementAPI()))
    )
container
    .bind<DeleteMovement>(Register.UseCaseDeleteMovement)
    .toDynamicValue(
        () =>
            new DeleteMovementUseCase(new MovementRepository(new MovementAPI()))
    )
container
    .bind<GetPreMovementsUseCase>(Register.UseCaseGetPreMovements)
    .toDynamicValue(
        () =>
            new GetPreMovementsUseCase(
                new MovementRepository(new PreMovementAPI())
            )
    )
container
    .bind<IgnorePreMovementUseCase>(Register.UseCaseIgnorePreMovement)
    .toDynamicValue(
        () =>
            new IgnorePreMovementUseCase(
                new MovementRepository(new PreMovementAPI())
            )
    )

export default container
