import { UserContainer, UserRegister } from '@User/Data/DataSource/Container'
import { DBUser } from '@User/Domain/Model/User'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const useUserManager = () => {
    const userUseCase = UserContainer.get(UserRegister.UseCaseGetUser)
    const userUpdateUseCase = UserContainer.get(UserRegister.UseCaseUpdateUser)
    const userDisableUseCase = UserContainer.get(
        UserRegister.UseCaseDisableUser
    )
    const userEnableUseCase = UserContainer.get(UserRegister.UseCaseEnableUser)
    const [user, setUser] = useState<DBUser | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const { id } = useParams()

    const handleChange = (e: React.ChangeEvent<any>) => {
        const key = e.target.name
        const value = e.target.value
        setUser(
            (prev: DBUser | null): DBUser | null =>
                ({
                    ...prev,
                    [key]: value,
                } as DBUser)
        )
    }

    const getUser = async (id: string) => {
        setLoading(true)
        try {
            const data = await userUseCase.execute(id)
            setUser(data)
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    const handleChangePermission = (e: React.ChangeEvent<any>) => {
        const roleKey = e.target.name
        const roleValue = e.target.checked
        if (roleValue) {
            setUser(
                (prev: DBUser | null): DBUser | null =>
                    ({
                        ...prev,
                        // @ts-ignore
                        roles: [...prev?.roles, roleKey],
                    } as DBUser)
            )
        }
        if (!roleValue) {
            setUser(
                (prev: DBUser | null): DBUser | null =>
                    ({
                        ...prev,
                        roles: [
                            // @ts-ignore
                            ...prev.roles.filter((role) => role !== roleKey),
                        ],
                    } as DBUser)
            )
        }
    }

    const handleSave = async () => {
        setLoading(true)
        try {
            await userUpdateUseCase.execute(user as DBUser)
            toast.success('Usuário atualizado com sucesso')
        } catch (error) {
            console.log(error)
            toast.error('Erro ao atualizar usuário')
        }
        setLoading(false)
    }

    const handleDisable = async () => {
        setLoading(true)
        try {
            await userDisableUseCase.execute(user?._id)
            toast.success('Usuário desativado com sucesso')
        } catch (error) {
            console.log(error)
            toast.error('Erro ao desativar usuário')
        }
        setLoading(false)
    }

    const handleEnable = async () => {
        setLoading(true)
        try {
            await userEnableUseCase.execute(user?._id)
            toast.success('Usuário ativado com sucesso')
        } catch (error) {
            toast.error('Erro ao ativar usuário')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (id) getUser(id)
    }, [id])

    return {
        user,
        loading,
        handleChangePermission,
        handleSave,
        handleChange,
        handleDisable,
        handleEnable,
    }
}
