import { NegativeStockClientsUseCase } from '@Balance/Clients/Domain/UseCases/NegativeStockClients'
import { DeleteClientsUseCase } from './../../../Domain/UseCases/DeleteClient'
export const Register = {
    UseCaseGetClients: Symbol.for('Clients'),
    UseCaseGetClient: Symbol.for('Client'),
    UseCaseSearchClient: Symbol.for('SearchClient'),
    UseCaseCreateClient: Symbol.for('CreateClient'),
    UseCaseUpdateClient: Symbol.for('UpdateClient'),
    UseCaseDeleteClient: DeleteClientsUseCase,
    UseCaseNegativeStockClients: NegativeStockClientsUseCase,
}
