import { Button, ButtonVariant } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { IconButton } from '@Core/Components/IconButton/IconButton'
import { Input } from '@Core/Components/Input/Input'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import DeleteIcon from '@assets/delete.png'
import { Card, CardContent, InputAdornment } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { BsCalculator } from 'react-icons/bs'
import { DatePicker } from '../DatePicker/DatePicker'
import { Status } from './ExitForm.styles'

export const ExitForm = ({
    state,
    errors,
    handleChange,
    handleAutoCompleteChange,
    products,
    register,
    handleDateChange,
    handleCalculate,
    onRemove,
    isFailed,
    isCreated,
    destinations,
    clients,
}: any) => {
    const [isClientDestination, setIsClientDestination] = useState(false)
    useEffect(() => {
        if (state?.[`destination-${state.id}`]) {
            setIsClientDestination(
                state?.[`destination-${state.id}`]?.name === 'Cliente'
            )
        }
    }, [state])

    return (
        <Card>
            <Status error={isFailed} success={isCreated}>
                <div className="success">
                    <span>Criado</span>
                </div>
                <Grid container flex flexEnd>
                    <RightContent
                        style={{
                            padding: '0.5rem 1rem',
                            paddingBottom: '0',
                        }}
                    >
                        <IconButton
                            size="small"
                            alignItems="center"
                            onClick={() => onRemove(state.id)}
                        >
                            <img src={DeleteIcon} alt="Remover" />
                        </IconButton>
                    </RightContent>
                </Grid>
                <CardContent>
                    <Grid container>
                        <Grid
                            item
                            xs={isClientDestination ? 6 : 12}
                            lg={isClientDestination ? 6 : 12}
                        >
                            {destinations.length > 0 && (
                                <InputAutoComplete
                                    label="Destino"
                                    errors={errors}
                                    name={`destination-${state.id}`}
                                    onChange={handleAutoCompleteChange(
                                        `destination-${state.id}`
                                    )}
                                    results={destinations}
                                    inputProps={{
                                        disabled: Boolean(isCreated),
                                        name: `destination-${state.id}`,
                                        register: register(
                                            `destination-${state.id}`
                                        ),
                                        useUnform: false,
                                    }}
                                />
                            )}
                        </Grid>
                        {isClientDestination && (
                            <Grid item xs={6} lg={6}>
                                {clients.length > 0 && (
                                    <InputAutoComplete
                                        label="Cliente"
                                        errors={errors}
                                        name={`client-${state.id}`}
                                        onChange={handleAutoCompleteChange(
                                            `client-${state.id}`
                                        )}
                                        results={clients}
                                        inputProps={{
                                            disabled: Boolean(isCreated),
                                            name: `client-${state.id}`,
                                            register: register(
                                                `client-${state.id}`
                                            ),
                                            useUnform: false,
                                        }}
                                    />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={8} lg={8}>
                            {products.length > 0 && (
                                <InputAutoComplete
                                    label="Produto"
                                    errors={errors}
                                    name={`product-${state.id}`}
                                    value={state?.[`product-${state.id}`]}
                                    onChange={handleAutoCompleteChange(
                                        `product-${state.id}`
                                    )}
                                    results={products}
                                    inputProps={{
                                        disabled: Boolean(isCreated),
                                        name: `product-${state.id}`,
                                        register: register(
                                            `product-${state.id}`
                                        ),
                                        useUnform: false,
                                    }}
                                />
                            )}
                        </Grid>
                        {state &&
                            state?.[`product-${state.id}`] &&
                            state?.[`unitCost-${state.id}`] !== undefined && (
                                <Grid item xs={4} lg={4}>
                                    <Input
                                        errors={errors}
                                        register={register(
                                            `unitCost-${state.id}`
                                        )}
                                        useUnform={false}
                                        type="number"
                                        disabled={Boolean(isCreated)}
                                        name={`unitCost-${state.id}`}
                                        label="Custo Unitário"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    R$
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={4} lg={4}>
                            <Input
                                register={register(`quantity-${state.id}`)}
                                useUnform={false}
                                errors={errors}
                                disabled={Boolean(isCreated)}
                                name={`quantity-${state.id}`}
                                label="Quantidade"
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <Input
                                register={register(
                                    `totalSalesValue-${state.id}`
                                )}
                                useUnform={false}
                                errors={errors}
                                disabled={Boolean(isCreated)}
                                name="totalSalesValue"
                                label="Valor Total"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            R$
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                onClick={handleCalculate}
                                                type="button"
                                                title="Calcular quantidade x valor unitário"
                                                variant={ButtonVariant.tertiary}
                                            >
                                                <BsCalculator />
                                            </Button>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} lg={4}>
                            <DatePicker
                                inputProps={{
                                    errors: errors,
                                    disabled: Boolean(isCreated),
                                    register: register(
                                        `created_at-${state.id}`
                                    ),
                                    useUnform: false,
                                    label: 'Data da Saída',
                                }}
                                onChange={handleDateChange(
                                    `created_at-${state.id}`
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <TextArea
                                register={register(`observation-${state.id}`)}
                                useUnform={false}
                                name={`observation-${state.id}`}
                                label="Observações"
                                rows={5}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Status>
        </Card>
    )
}
