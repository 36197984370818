import { Supplier } from '@InventoryControl/Supplier/entities/Supplier'
import { Api } from '@Services/api'
import SuppliersDataSource from '../SupplierDataSource'
import { DBInventorySupplier } from './Entity/Supplier'

export class SupplierAPI implements SuppliersDataSource {
    axios = new Api()

    async getSupplierById(id: string): Promise<DBInventorySupplier> {
        const { data } = await this.axios.get(`/inventory/suppliers/${id}`)
        return data
    }

    async getSuppliers(): Promise<DBInventorySupplier[]> {
        const { data } = await this.axios.get('/inventory/suppliers')
        return data
    }

    async search(query: string): Promise<DBInventorySupplier[]> {
        const { data } = await this.axios.get(
            `/inventory/suppliers/search/${query}`
        )
        return data
    }

    async createSupplier(destination: Supplier): Promise<DBInventorySupplier> {
        const { data } = await this.axios.post(
            '/inventory/suppliers',
            destination
        )
        return data
    }

    async updateSupplier(
        destination: DBInventorySupplier
    ): Promise<DBInventorySupplier> {
        const { data } = await this.axios.put(
            `/inventory/suppliers/${destination._id}`,
            destination
        )
        return data
    }

    async deleteSupplier(id: string): Promise<void> {
        await this.axios.delete(`/inventory/suppliers/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
