import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { CordDetails } from '../CordDetails/CordDetails'

export const Dynamic = ({
    state,
    onChange,
    refresh: _refresh,
}: {
    state: DBCommercialProduct
    onChange: () => void
    refresh: () => Promise<void>
}) => {
    return <CordDetails state={state} onChange={onChange} refresh={_refresh} />
}
