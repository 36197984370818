import { DBInventoryProduct } from '@InventoryControl/Products/Data/DataSource/API/Entity/Product'
import { InventoryProductsRepository } from '@InventoryControl/Products/Data/Repository/ProductRepository'

export interface GetProduct {
    execute(id: string): Promise<DBInventoryProduct>
    getSource(): string
}

export class GetProductUseCase implements GetProduct {
    constructor(private repository: InventoryProductsRepository) {}
    async execute(id: string): Promise<DBInventoryProduct> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
