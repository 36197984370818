import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import { InventoryExitsRepository } from '@InventoryControl/Exits/Data/Repository/ExitsRepository'

export interface GetExit {
    execute(id: string): Promise<DBInventoryExits>
    getSource(): string
}

export class GetExitUseCase implements GetExit {
    constructor(private repository: InventoryExitsRepository) {}
    async execute(id: string): Promise<DBInventoryExits> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
