// @ts-nocheck
import { TextField } from '@material-ui/core'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { forwardRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

const ExampleCustomInput = forwardRef<any>(
    ({ value, onClick, ...props }, ref) => (
        <TextField
            variant="outlined"
            onClick={onClick}
            {...props}
            fullWidth
            ref={ref}
            value={formattedValue(value)}
        />
    )
)

export const formattedValue = (value: string) => {
    const start = value

    if (start) {
        return `${format(new Date(start), 'dd/MM/yyyy')}`
    }
    return ''
}

export const DatePicker = ({
    onChange,
    range = [new Date(), new Date()],
    inputProps = {},
}: {
    onChange: (date: Date) => void
    range?: [Date, Date]
    inputProps?: any
}) => {
    const [startDate, setStartDate] = useState(range[0])
    const _onChange = (dates: any) => {
        const start = dates
        setStartDate(start)
        onChange(start)
    }

    return (
        <ReactDatePicker
            selected={startDate}
            onChange={_onChange}
            startDate={startDate}
            locale={ptBR}
            isClearable
            customInput={<ExampleCustomInput {...inputProps} />}
        />
    )
}
