import { DBInventoryEntry } from '@InventoryControl/Entries/Data/DataSource/API/Entries/Entry'
import { InventoryEntryRepository } from '@InventoryControl/Entries/Data/Repository/EntryRepository'

export interface UpdateEntry {
    execute(entity: DBInventoryEntry): Promise<DBInventoryEntry>
    getSource(): string
}

export class UpdateEntryUseCase implements UpdateEntry {
    constructor(private repository: InventoryEntryRepository) {}
    async execute(entity: DBInventoryEntry): Promise<DBInventoryEntry> {
        return this.repository.update(entity)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
