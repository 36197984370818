import { CommercialAccessories } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { Button, ButtonVariant } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { formatCurrency } from '@Utils/Formatters/currency'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export const Accessory = ({
    accessory,
    refresh,
    onDelete,
    handleSave,
}: {
    accessory: CommercialAccessories
    refresh: () => void
    onDelete: () => void
    handleSave: (entity: CommercialAccessories) => void
}) => {
    const [editMode, setEditMode] = useState(false)
    const { control, handleSubmit } = useForm({
        defaultValues: accessory,
    })

    const handleSaveAccessory = (data: CommercialAccessories) => {
        handleSave(data)
        setEditMode(false)
    }

    const renderButtons = () => {
        if (editMode) {
            return (
                <>
                    <Button
                        type="button"
                        variant={ButtonVariant.secondary}
                        onClick={() => onDelete()}
                    >
                        Deletar
                    </Button>
                    <Button
                        type="reset"
                        variant={ButtonVariant.secondary}
                        onClick={() => setEditMode(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant={ButtonVariant.primary}
                        onClick={handleSubmit(handleSaveAccessory)}
                    >
                        Salvar
                    </Button>
                </>
            )
        }
        return (
            <Button
                type="button"
                variant={ButtonVariant.primary}
                onClick={() => setEditMode(true)}
            >
                Editar
            </Button>
        )
    }

    return (
        <tr>
            <td>
                {editMode && (
                    <Input name="name" control={control} useUnform={false} />
                )}
                {!editMode && accessory.name}
            </td>
            <td>
                {editMode && (
                    <Input
                        name="value"
                        control={control}
                        useUnform={false}
                        type="number"
                        InputProps={{ inputProps: { step: 0.01, min: 0 } }}
                    />
                )}
                {!editMode && formatCurrency(accessory.value)}
            </td>
            <td>{renderButtons()}</td>
        </tr>
    )
}
