import { NegativeStockClientsUseCase } from '@Balance/Clients/Domain/UseCases/NegativeStockClients'
import { ClientAPI } from '@Clients/Data/DataSource/API/ClientAPI'
import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'
import {
    GetClientsUseCase,
    GetClientUseCase,
    SearchClientUseCase,
} from '@Clients/Domain/UseCases'
import { CreateClientUseCase } from '@Clients/Domain/UseCases/CreateClient'
import { DeleteClientsUseCase } from '@Clients/Domain/UseCases/DeleteClient'
import { Container } from 'inversify'
import { UpdateClientUseCase } from './../../../Domain/UseCases/UpdateClient'
import { Register } from './ClientRegister'

const container = new Container()
container
    .bind<GetClientUseCase>(Register.UseCaseGetClient)
    .toDynamicValue(
        () => new GetClientUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind<GetClientsUseCase>(Register.UseCaseGetClients)
    .toDynamicValue(
        () => new GetClientsUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind<SearchClientUseCase>(Register.UseCaseSearchClient)
    .toDynamicValue(
        () => new SearchClientUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind<CreateClientUseCase>(Register.UseCaseCreateClient)
    .toDynamicValue(
        () => new CreateClientUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind<UpdateClientUseCase>(Register.UseCaseUpdateClient)
    .toDynamicValue(
        () => new UpdateClientUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind(Register.UseCaseDeleteClient)
    .toDynamicValue(
        () => new DeleteClientsUseCase(new ClientsRepository(new ClientAPI()))
    )
container
    .bind<NegativeStockClientsUseCase>(Register.UseCaseNegativeStockClients)
    .toDynamicValue(
        () =>
            new NegativeStockClientsUseCase(
                new ClientsRepository(new ClientAPI())
            )
    )

export default container
