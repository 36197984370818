import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { createSlice } from '@reduxjs/toolkit'
import { ReducersName } from '../ReducersName'
import { SetMovementAction } from './reducer.type'

const initialState: { movements: { [key: string]: DBMovement[] } } = {
    movements: {},
}

const movementsReducer = createSlice({
    initialState,
    name: ReducersName.movements,
    reducers: {
        setMovementsAction(state, { payload }: SetMovementAction) {
            if (payload.incremental) {
                state.movements = {
                    ...state.movements,
                    [payload.source]: [
                        ...state.movements[payload.source],
                        ...payload.data,
                    ],
                }
                return
            }
            state.movements = {
                ...state.movements,
                [payload.source]: payload.data,
            }
        },
    },
})
const { actions } = movementsReducer

export const { setMovementsAction } = actions

export default movementsReducer.reducer
