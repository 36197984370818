import { InferType, boolean, number, object, string } from 'yup'

export const productSchema = object().shape({
    name: string().required('Nome obrigatório'),
    description: string(),
    unitMeasure: string().required('Unidade de medida obrigatória'),
    minimumStock: number().required('Estoque mínimo obrigatório'),
    unitCost: number().required('Custo unitário obrigatório'),
    isInactive: boolean(),
})

export interface Product extends InferType<typeof productSchema> {}
