import { useCommercialContext } from '@Commercial/Context/CommercialContextProvider'
import { DBCommercialProposal } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import { formCalculate } from '@Commercial/Proposal/Utils/formCalulate'
import { RoutesPaths } from '@Commercial/RoutesPaths'
import { roles } from '@Commercial/roles'
import { RootState } from '@Core/Communication'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { getUserName } from '@Utils/Formatters/getUserName'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IFormInputs } from './useNewProposal.types'

export const useNewProposal = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const pipeContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetProposalPipe
    )
    const { allProposals } = useCommercialContext()
    const { user } = useSelector((state: RootState) => state.user)
    const { hasPermission } = usePermissions()
    const noMarkupLimit = hasPermission(
        roles.proposal.permissions.update.permissions.noMarkupLimit.key
    )
    const [state, setState] = useState<IFormInputs>({
        name: '',
        clientName: '',
        products: [
            {
                isActive: true,
                markup: 100,
            },
        ],
        discount: 0,
        bvPercentage: 0,
        bvAmount: 0,
    })
    const { register, handleSubmit, watch, formState, ...methods } =
        useForm<IFormInputs>({
            defaultValues: {
                ...state,
            },
        })

    const proposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseCreateProposal
    )

    const getDuplicatedProposal = useCallback(
        (name: string) => {
            return allProposals.some((proposal) => proposal.name === name)
        },
        [allProposals]
    )

    useEffect(() => {
        const subscription = watch((value: any, { name }) => {
            formCalculate(setState)(value, { name })
            if (name === 'name') {
                if (value.name.length > 3) {
                    debounce(async () => {
                        const client = await pipeContainer.execute(value.name)
                        const hasProposal = getDuplicatedProposal(value.name)
                        if (hasProposal) {
                            methods.setError('name', {
                                type: 'manual',
                                message: 'Ja existe uma proposta com esse pipe',
                            })
                        } else {
                            methods.clearErrors('name')
                        }
                        if (!client) return
                        const { organization } = client
                        methods.setValue('clientName', organization?.name)
                        setState((old) => ({
                            ...old,
                            clientName: organization?.name,
                        }))
                    }, 500)()
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, state])

    const validate = (data: any) => {
        if (!data.name) {
            return {
                error: true,
                message: 'O pipe da proposta é obrigatório',
            }
        }
        if (!data.products.length) {
            return {
                error: true,
                message: 'A proposta deve ter pelo menos um produto',
            }
        }
        if (data.products.length) {
            for (const element of data.products) {
                if (!element.name) {
                    return {
                        error: true,
                        message: 'Um produto deve ser selecionado',
                    }
                }
                if (!element.quantity) {
                    return {
                        error: true,
                        message: 'Todos os produtos devem ter quantidade',
                    }
                }
                if (!element.freight) {
                    return {
                        error: true,
                        message: 'Todos os produtos devem ter frete',
                    }
                }
                if (element.productType === 'gift') {
                    if (element.quantity < element.minQuantity) {
                        return {
                            error: true,
                            message:
                                'Todos os produtos devem respeitar o limite mínimo de quantidade',
                        }
                    }
                    if (element.totalPriceFull < element.minPrice) {
                        return {
                            error: true,
                            message:
                                'Todos os produtos devem respeitar o limite mínimo de valor',
                        }
                    }
                }
                if (
                    !noMarkupLimit &&
                    (element.markup < element.minMarkup ||
                        element.markup > element.maxMarkup)
                ) {
                    return {
                        error: true,
                        message: `O markup do produto deve estar no intervalo de ${element.minMarkup} - ${element.maxMarkup}`,
                    }
                }
            }
        }
        return {
            error: false,
            message: '',
        }
    }

    const onSubmit = async (data: any) => {
        const { error, message } = validate(data)
        const userV = {
            mid: user?.id as string,
            name: getUserName(user),
        }
        if (error) {
            toast.error(message)
            return
        }
        setLoading(true)
        try {
            const response = (await proposalContainer.execute({
                ...data,
                user: userV,
            })) as unknown as
                | DBCommercialProposal
                | { message: string; status: number }
            if (response?.status === 409) {
                toast.error('Proposta já existe com esse PIPE')
                setLoading(false)
                return
            }
            toast.success('Proposta criada com sucesso')
            navigate(RoutesPaths.CommercialProposals)
        } catch (e) {
            toast.error(e.message)
            console.log(e)
        }
        setLoading(false)
    }

    const handleAddingNewProduct = useCallback(() => {
        methods.setValue('products', [
            ...state.products,
            { markup: 100, isActive: true },
        ])
    }, [state])

    return {
        loading,
        state,
        register,
        formState,
        handleSubmit: handleSubmit(onSubmit, (msg) => console.error(msg)),
        handleAddingNewProduct,
        methods,
    }
}
