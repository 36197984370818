import { roles } from '@Commercial/roles'
import { Input } from '@Core/Components/Input/Input'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { Grid, InputAdornment } from '@material-ui/core'
import { CordMarkup } from '../CordMarkup/CordMarkup'

export const Roller = ({ state, onChange, refresh }) => {
    return (
        <Grid item container lg={12} spacing={2}>
            <Grid item lg={3}>
                <PermissionWrapper
                    permission={
                        roles.product.permissions.update.permissions.cost.key
                    }
                >
                    <Input
                        name={'unitCost'}
                        label="Custo unitário"
                        fullWidth
                        type="number"
                        onChange={onChange}
                        value={state.unitCost}
                        InputProps={{
                            inputProps: {
                                step: 0.01,
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                            ),
                        }}
                    />
                </PermissionWrapper>
            </Grid>
            <Grid item lg={3}>
                <Input
                    name={'quantityPerPage'}
                    label="Quantidade por Folha"
                    fullWidth
                    type="number"
                    onChange={onChange}
                    value={state.quantityPerPage}
                    InputProps={{
                        inputProps: {
                            step: 1,
                        },
                    }}
                />
            </Grid>
            <Grid item lg={3}>
                <Input
                    name={'pagePrice'}
                    label="Custo por Folha"
                    fullWidth
                    type="number"
                    onChange={onChange}
                    value={state.pagePrice}
                    InputProps={{
                        inputProps: {
                            step: 0.01,
                        },
                        startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={3}>
                <Input
                    name={'freight'}
                    label="Frete"
                    fullWidth
                    type="number"
                    onChange={onChange}
                    value={state.freight}
                    InputProps={{
                        inputProps: {
                            step: 0.01,
                        },
                        startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item lg={12}>
                <CordMarkup markup={state.markups[0]} refresh={refresh} />
            </Grid>
        </Grid>
    )
}
