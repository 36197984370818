import { ICoreRepository } from '@Core/Domain/Repository/CoreRepository.type'
import CoreDataSource from '../DataSource/CoreDataSource'

export class CoreRepository implements ICoreRepository {
    dataSource: CoreDataSource
    constructor(_dataSource: CoreDataSource) {
        this.dataSource = _dataSource
    }

    async sendStock(id: string): Promise<void> {
        return this.dataSource.sendStock(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
