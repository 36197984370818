import { AutoMenu } from '@Core/Components/AutoMenu/AutoMenu'
import { IMenu } from '@Core/Components/AutoMenu/AutoMenu.types'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { SidebarFolder } from '@Entry/Common/Sidebar/components/SidebarFolder/SidebarFolder'
import { useMemo } from 'react'
import { RiGroupLine, RiUserSettingsLine } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { RoutesPaths } from './RoutesPaths'
import { moduleKey, roles } from './roles'

const MenuItems: IMenu[] = [
    {
        title: 'Usuários',
        to: RoutesPaths.UserList,
        icon: <RiGroupLine />,
        permission: roles.user.permissions.read.key,
    },
]

export const Menu = () => {
    const { pathname } = useLocation()
    const { hasModulePermission } = usePermissions()
    const hasSubMenuActive = useMemo(
        () => Boolean(MenuItems.find((item) => item.to === pathname)),
        [pathname]
    )

    if (!hasModulePermission(moduleKey)) return <></>
    return (
        <SidebarFolder name="Gestão de Usuários" icon={<RiUserSettingsLine />} active={hasSubMenuActive}>
            <AutoMenu menu={MenuItems} />
        </SidebarFolder>
    )
}
