import LogRocket from 'logrocket'
import { useRouteError } from 'react-router-dom'
import { Error } from './Error.styles'

export default function ErrorPage() {
    const error = useRouteError() as { message: string; statusText: string }

    LogRocket.captureException(error as unknown as Error)

    return (
        <Error>
            <h1 className="text-2xl font-bold">Oops!</h1>
            <p>Parece que aconteceu um erro!</p>
        </Error>
    )
}
