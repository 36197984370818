import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import styled from 'styled-components'

export const Stack = styled.div`
    padding: 10px;
    width: min(fit-content, 100%);
    border-radius: 8px;
    border: 1px solid ${({ theme }) => convertHexToRGBA('#00000', 0.1)};
    box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
`
