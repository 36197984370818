import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useInventoryEntriesManager } from '../Hooks/useInventoryEntriesManager/useInventoryEntriesManager'
import { DeleteEntryModal } from './Components/DeleteEntryModal/DeleteEntryModal'
import { EntryDetailsForm } from './Components/EntryDetailsForm/EntryDetailsForm'

export const EntriesDetails = () => {
    const { id } = useParams<{ id: string }>()
    const { getEntry, state, loading, handleChange, handleSave, deleteEntry } =
        useInventoryEntriesManager()

    const [editMode, setEditMode] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const handleDelete = () => {
        setShowDeleteModal(true)
    }

    const handleEdit = () => {
        setEditMode(!editMode)
        getEntry(id || '')
    }

    const onSaved = () => {
        setEditMode(false)
        getEntry(id || '')
        toast.success('Entrada salva com sucesso!')
    }

    useEffect(() => {
        if (id) {
            getEntry(id)
        }
    }, [id])

    if (!state?._id && loading) return <Loader />

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Detalhes da Entrada</h1>
                <RightContent>
                    {!editMode && (
                        <>
                            <Button
                                onClick={handleEdit}
                                variant={ButtonVariant.primary}
                            >
                                Editar
                            </Button>
                            <Button
                                onClick={handleDelete}
                                variant={ButtonVariant.secondary}
                            >
                                Deletar
                            </Button>
                            <DeleteEntryModal
                                isOpen={showDeleteModal}
                                setIsOpen={setShowDeleteModal}
                                callback={deleteEntry}
                            />
                        </>
                    )}
                    {editMode && (
                        <>
                            <Button
                                onClick={handleEdit}
                                variant={ButtonVariant.secondary}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => handleSave(onSaved)}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </>
                    )}
                </RightContent>
            </ContainerHeader>
            <Grid container>
                <Grid item lg={12} xs={12}>
                    <EntryDetailsForm
                        state={state}
                        editMode={editMode}
                        handleChange={handleChange}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
