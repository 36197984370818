import { Client } from '@Clients/Data/DataSource/API/Entity/Client'
import {
    ClientContainer,
    ClientRegister,
} from '@Clients/Data/DataSource/Container'
import { Clients } from '@Clients/Domain/Model/Clients'
import { CreateClient, GetClient, UpdateClient } from '@Clients/Domain/UseCases'
import { setClientsAction } from '@Core/Communication/GlobalStore/Reducers/clients/reducer'
import { isValidEmail } from '@brazilian-utils/brazilian-utils'
import { FormHandles } from '@unform/core'
import LogRocket from 'logrocket'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { validateClient } from './../Utils/validateClient'
import { useClients } from './useClients'

export const useClientManager = () => {
    const [state, setState] = useState<Partial<Clients>>()
    const formRef = useRef<FormHandles>(null)
    const { clients } = useClients()
    const dispatch = useDispatch()

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = target
        if (name.includes('additionalEmails')) {
            const index = Number(
                name
                    .split('additionalEmails')[1]
                    .replace('[', '')
                    .replace(']', '')
            )
            const additionalEmails = state?.additionalEmails || []
            additionalEmails[index] = value
            setState({ ...state, additionalEmails })
            return
        }
        setState({ ...state, [name]: value })
    }

    const handleAddClient = useCallback(
        (onClose?: () => void) => {
            const handleSubmit = async () => {
                try {
                    validateClient(state as Client, clients)
                } catch (e) {
                    const { message } = e as { message: string }
                    if (Boolean(message)) {
                        toast.error(message)
                    }
                    LogRocket.captureException(e as Error)
                    return
                }
                if (!state?.corporateName) {
                    toast.error('Nome do cliente é obrigatório')
                    return
                }

                if (!state?.mail) {
                    toast.error('E-mail do cliente é obrigatório')
                    return
                }
                if (!isValidEmail(state.mail))
                    return toast.error('E-mail inválido')
                const additionalMails = state?.additionalEmails?.filter(
                    (item) => item !== ''
                )
                for (let i in additionalMails) {
                    if (!isValidEmail(additionalMails[Number(i)])) {
                        toast.error('E-mail adicional inválido')
                        return
                    }
                }

                try {
                    const createClientContainer =
                        ClientContainer.get<CreateClient>(
                            ClientRegister.UseCaseCreateClient
                        )
                    const data = await createClientContainer.execute({
                        ...state,
                        additionalEmails: additionalMails,
                    } as Client)
                    toast.success('Cliente adicionado com sucesso!')

                    dispatch(
                        setClientsAction({
                            source: createClientContainer.getSource(),
                            data: [data],
                            incremental: true,
                        })
                    )
                    onClose?.()
                } catch (e) {
                    toast.error('Erro ao adicionar cliente')
                }
            }
            const handleAddingAdditionalEmail = () => {
                if (!state?.additionalEmails) {
                    setState({ ...state, additionalEmails: [''] })
                    return
                }
                setState({
                    ...state,
                    additionalEmails: [...state.additionalEmails, ''],
                })
            }
            return {
                state,
                handleChange,
                handleSubmit,
                handleAddingAdditionalEmail,
            }
        },
        [state]
    )

    const handleEditClient = useCallback(
        (id: number | string) => {
            const handleSubmit = async () => {
                try {
                    const updateClientUseCase =
                        ClientContainer.get<UpdateClient>(
                            ClientRegister.UseCaseUpdateClient
                        )
                    const newClientState = { ...state }
                    delete newClientState?.movements
                    delete newClientState?.stocks
                    const data = await updateClientUseCase.execute(
                        newClientState as Clients
                    )
                    setState(data)
                    toast.success('Cliente atualizado com sucesso!')
                } catch (e) {
                    toast.error('Erro ao atualizar cliente')
                    LogRocket.captureException(e as Error)
                    throw e
                }
            }
            const handleNewAdditionalEmail = () => {
                if (!state?.additionalEmails) {
                    setState({ ...state, additionalEmails: [''] })
                    return
                }
                setState({
                    ...state,
                    additionalEmails: [...state.additionalEmails, ''],
                })
            }
            const handleRemoveAdditionalMail = ({
                index,
            }: {
                index: number
            }) => {
                const additionalEmails = state?.additionalEmails || []
                additionalEmails.splice(index, 1)
                setState({ ...state, additionalEmails })
            }
            return {
                handleChange,
                handleSubmit,
                handleNewAdditionalEmail,
                handleRemoveAdditionalMail,
            }
        },
        [state]
    )

    const getClientByID = async (id: string) => {
        const getClientUseCase = ClientContainer.get<GetClient>(
            ClientRegister.UseCaseGetClient
        )
        const data = await getClientUseCase.execute(id)
        setState(data)
        return data
    }

    return {
        state,
        formRef,
        handleAddClient,
        handleEditClient,
        getClientByID,
    }
}
