import { ApiType } from '@Utils/Services/api'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DBProduct, Product } from '../entities/Product'

export const useProductManager = (api: ApiType) => {
    const navigate = useNavigate()
    const [state, setState] = useState<Product | DBProduct>()

    const handleAddingProduct = (callback?: () => void) => {
        const handleChange = ({ target }: ChangeEvent) => {
            // @ts-ignore
            const { value } = target
            setState({
                name: value,
            })
        }
        const handleSubmit = async () => {
            await api.post('/products', state)
            toast.success('Produto cadastrado com sucesso!')
            setState(undefined)
            callback && callback()
        }
        return {
            handleChange,
            handleSubmit,
        }
    }

    const handleEditProduct = () => {
        const handleChange = ({ target }: ChangeEvent) => {
            // @ts-ignore
            const { value } = target
            setState((prev) => ({
                ...prev,
                name: value,
            }))
        }
        const handleSubmit = async () => {
            // @ts-ignore
            await api.patch(`/products/${state?._id}`, state)
            toast.success('Produto editado com sucesso!')
            // @ts-ignore
            getById(state?._id as string)
            setState(undefined)
        }
        const getById = async (id: string) => {
            const { data } = await api.get(`/products/${id}`)
            setState(data)
        }

        const handleDelete = async () => {
            // @ts-ignore
            await api.delete(`/products/${state?._id}`)
            toast.success('Produto deletado com sucesso!')
            setState(undefined)
            navigate('/products/list')
        }
        return {
            getById,
            handleChange,
            handleSubmit,
            handleDelete,
        }
    }

    return {
        state,
        handleAddingProduct,
        handleEditProduct,
    }
}
