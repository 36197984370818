// @ts-nocheck
import { dateFormatter } from '@Movements/Utils/Movements.utils'
import { formatCurrency } from '@Utils/Formatters/currency'
import { Exits } from '../entities/Exits'

export const exportInventoryDictionary = (item: any) =>
    ({
        _id: 'ID',
        destination: 'Destino',
        product: 'Produto',
        quantity: 'Quantidade',
        unitCost: 'Custo Unitário',
        totalSalesValue: 'Valor de Venda Total',
        created_at: 'Data',
        __v: '',
    }[item] || item)

export const exportInventoryValueDictionary = (
    key: Exits,
    item: Exits[key],
    exit: Exits
) => {
    return (
        {
            _id: { value: item, type: 'string' },
            destination: {
                value: exit?.client
                    ? `Cliente: ${exit?.client?.corporateName}`
                    : item?.name ?? 'N/D',
                type: 'string',
            },
            product: { value: item?.name, type: 'string' },
            quantity: { value: item, type: 'number' },
            unitCost: {
                value: formatCurrency(item) ?? 'N/D',
                type: 'string',
            },
            totalSalesValue: {
                value: formatCurrency(Number(item || 0)) ?? 'N/D',
                type: 'string',
            },
            created_at: {
                value: dateFormatter(new Date(item)),
                type: 'string',
            },
            __v: { value: item, type: 'number' },
        }[key] || { value: item, type: 'string' }
    )
}

export const exportInventoryExitValueDictionary = (
    key: Exits,
    item: Exits[key]
) => item

export const exportExitData = (data: Exits[]) => {
    const columns = [
        '_id',
        'destination',
        'product',
        'quantity',
        'unitCost',
        'totalSalesValue',
        'created_at',
    ]
    const rows = data.map((item: Exits) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportInventoryValueDictionary(
                column,
                item[column],
                item
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportInventoryData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
    }))
