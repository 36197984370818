import { logoutAction } from '@Core/Communication/GlobalStore/Reducers/authentication/reducer'
import { setUserFeaturesFlagsAction } from '@Core/Communication/GlobalStore/Reducers/user/reducer'
import { RoutesPaths } from '@Entry/Common/RoutesPaths'
import { API } from '@Services/api'
import { createSlug } from '@Utils/Formatters/slug'
import { useEffect, useMemo, useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FEATURE_ICONS, FEATURE_ROUTES } from './useUserProfileMenu.constants'
import { Feature, MenuItem } from './useUserProfileMenu.type'

export const useUserProfileMenu = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [menuItems, setMenuItems] = useState<MenuItem[]>([])

    const defaultMenuItems = useMemo(
        () => [
            {
                label: 'Sair',
                icon: MdLogout,
                id: 'logout',
                testID: 'logout-button',
                onClick: () => {
                    dispatch(logoutAction())
                    setTimeout(() => {
                        navigate(RoutesPaths.Login)
                    }, 350)
                },
            },
        ],
        []
    )

    const getUserFeatures = async () => {
        const { data } = await API.get('/user/features')
        const { menus = [], flags = {} } = data as {
            menus: Feature[]
            flags: any
        }
        setMenus(menus)
        setUserFeaturesFlags(flags)
    }

    const setUserFeaturesFlags = (flags: { [key: string]: any }) => {
        if (!flags) return
        dispatch(
            setUserFeaturesFlagsAction({ source: API.getSource(), data: flags })
        )
    }

    const setMenus = (menus: Feature[]) => {
        setMenuItems([
            ...menus.map((feature) => ({
                label: feature.name,
                id: feature.name,
                icon: FEATURE_ICONS[feature.icon],
                testID: `feature-${createSlug(feature.name)}`,
                onClick: () =>
                    navigate(FEATURE_ROUTES[createSlug(feature.route)]),
            })),
            ...defaultMenuItems,
        ])
    }

    useEffect(() => {
        setMenuItems(defaultMenuItems)
        getUserFeatures()
    }, [])

    return useMemo(
        () => ({
            menu: menuItems,
        }),
        [menuItems]
    )
}
