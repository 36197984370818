import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { useCordTypes } from '@Commercial/Products/Hooks/useCordTypes/useCordTypes'
import { roles } from '@Commercial/roles'
import { Button, Container } from '@Core/Components'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { Grid, Tab, Tabs } from '@material-ui/core'
import { TabContext, TabPanel } from '@material-ui/lab'
import { useState } from 'react'
import { Accessories } from '../Accessories/Accessories'
import { CordMarkup } from '../CordMarkup/CordMarkup'
import { CordTypes } from '../CordTypes/CordTypes'

export const CordDetails = ({
    state,
    onChange,
    refresh: _refresh,
}: {
    state: DBCommercialProduct
    onChange: () => void
    refresh: () => Promise<void>
}) => {
    const [currentTab, setCurrentTab] = useState('0')
    const { cordTypes, handleAddCordType, handleRemoveCordType, refresh } =
        useCordTypes({ product: state, refresh: _refresh })
    const { hasPermission } = usePermissions()

    const att = async () => {
        await _refresh()
        refresh()
    }
    const handleChange = async (_: any, newValue: string) => {
        await att()
        setCurrentTab(newValue)
    }

    if (!state) {
        return <Loader />
    }

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
                <TabContext value={currentTab}>
                    <Tabs value={currentTab} onChange={handleChange}>
                        <Tab label="Tipos" value="0" />
                        <Tab label="Acessórios" value="1" />
                        {hasPermission(
                            roles.product.permissions.update.permissions.markup
                                .key
                        ) && <Tab label="Markup" value="2" />}
                    </Tabs>
                    <TabPanel value="0">
                        <Container>
                            <Grid container spacing={2}>
                                {Boolean(cordTypes.length) &&
                                    cordTypes.map((cordType, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            key={cordType._id}
                                        >
                                            <CordTypes
                                                productId={state._id}
                                                refresh={att}
                                                cordType={cordType}
                                                onDelete={handleRemoveCordType(
                                                    index
                                                )}
                                            />
                                        </Grid>
                                    ))}
                                {!cordTypes.length && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={12}
                                        alignContent="center"
                                        justifyContent="center"
                                    >
                                        Nenhum Tipo adicionado
                                    </Grid>
                                )}
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    md={12}
                                    alignContent="center"
                                    justifyContent="center"
                                >
                                    <Button
                                        type="button"
                                        onClick={handleAddCordType}
                                    >
                                        Adicionar novo Tipo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                    <TabPanel value="1">
                        <Accessories
                            productId={state._id}
                            accessories={state.accessories}
                            refresh={_refresh}
                        />
                    </TabPanel>
                    <TabPanel value="2">
                        <PermissionWrapper
                            permission={
                                roles.product.permissions.update.permissions
                                    .markup.key
                            }
                        >
                            <CordMarkup
                                markup={state.markups[0]}
                                refresh={_refresh}
                            />
                        </PermissionWrapper>
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
