import { roles } from '@Balance/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input } from '@Core/Components/Input/Input'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { API } from '@Services/api'
import { Form } from '@unform/web'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useProductManager } from '../Hooks/useProductManager'

export const ProductDetails = () => {
    const { id } = useParams<{ id: string }>()
    const { handleEditProduct, state } = useProductManager(API)
    const { getById, handleChange, handleSubmit } = handleEditProduct()

    useEffect(() => {
        getById(String(id) || '')
    }, [])

    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">{state?.name}</h1>
                <div>
                    <Grid flex flexEnd>
                        <PermissionWrapper
                            permission={roles.products.permissions.update.key}
                        >
                            <Button
                                onClick={handleSubmit}
                                variant={ButtonVariant.primary}
                            >
                                Salvar
                            </Button>
                        </PermissionWrapper>
                    </Grid>
                </div>
            </ContainerHeader>
            <Form onSubmit={() => {}} className="mt-2">
                <Grid container item lg={12}>
                    <Grid item lg={12}>
                        {state && (
                            <Input
                                name={'name'}
                                onChange={handleChange}
                                label="Nome"
                                value={state?.name}
                            />
                        )}
                    </Grid>
                </Grid>
            </Form>
        </Container>
    )
}
