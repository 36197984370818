import {
    ClientContainer,
    ClientRegister,
} from '@Clients/Data/DataSource/Container'
import { GetClient } from '@Clients/Domain/UseCases'
import { Stock } from '@Core/Entities/Stock'
import { useEffect, useState } from 'react'

export const useClientStock = (clientId: string) => {
    const [loading, setLoading] = useState(false)
    const getClientUseCase = ClientContainer.get<GetClient>(
        ClientRegister.UseCaseGetClient
    )
    const [clientStock, setClientStock] = useState<Stock[] | undefined>(
        undefined
    )

    const getStock = async () => {
        setLoading(true)
        const { stocks } = await getClientUseCase.execute(clientId)
        setClientStock(stocks)
        setLoading(false)
    }

    const getStockByProductId = (productId: string) => {
        return clientStock?.find((stock) => stock.product[0]._id === productId)
    }

    useEffect(() => {
        getStock()
    }, [clientId])

    return {
        loading,
        stock: clientStock,
        getStockByProductId,
    }
}
