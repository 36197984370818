import { RolesItem } from './../Core/Entities/RolesItem'

export const moduleKey = 'user'
export const roles: RolesItem = {
    user: {
        name: 'Usuários',
        key: 'user',
        permissions: {
            read: {
                name: 'Visualizar',
                description: 'Permite visualizar usuários',
                key: 'user:read',
            },
            edit: {
                name: 'Editar',
                description: 'Permite editar usuários',
                key: 'user:edit',
            },
        },
    },
}
