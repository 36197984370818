import { useAccessories } from '@Commercial/Products/Hooks/useAccessories/useAccessories'
import { Button, Container, Table } from '@Core/Components'
import { Loader } from '@Core/Components/Loader/Loader'
import { Grid } from '@material-ui/core'
import { Accessory } from '../Accessory/Accessory'

export const Accessories = ({ accessories, refresh, productId }) => {
    const {
        state,
        handleAddAccessory,
        handleRemoveAccessory,
        refreshAccessory,
        handleSaveAccessory,
    } = useAccessories({ accessories, refresh, productId })

    if (!productId) {
        return <Loader />
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.map((accessory, index) => (
                            <Accessory
                                key={accessory.id || accessory._id}
                                handleSave={handleSaveAccessory}
                                refresh={refreshAccessory}
                                accessory={accessory}
                                onDelete={handleRemoveAccessory(index)}
                            />
                        ))}
                        {!state.length && (
                            <tr>
                                <td colSpan={2}>Nenhum Acessório adicionado</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    alignContent="center"
                    justifyContent="center"
                >
                    <Button type="button" onClick={handleAddAccessory}>
                        Adicionar novo Acessório
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
