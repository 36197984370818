import { BoxProps } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Box = styled.div<BoxProps>`
    ${({ m, theme }) =>
        m &&
        css`
            margin: ${theme.getResponsiveSize(theme.spacing(m))};
        `};
    ${({ mt, theme }) =>
        mt &&
        css`
            margin-top: ${theme.getResponsiveSize(theme.spacing(mt))};
        `};
    ${({ mr, theme }) =>
        mr &&
        css`
            margin-right: ${theme.getResponsiveSize(theme.spacing(mr))};
        `};
    ${({ mb, theme }) =>
        mb &&
        css`
            margin-bottom: ${theme.getResponsiveSize(theme.spacing(mb))};
        `};
    ${({ ml, theme }) =>
        ml &&
        css`
            margin-left: ${theme.getResponsiveSize(theme.spacing(ml))};
        `};
    ${({ mx, theme }) =>
        mx &&
        css`
            margin-left: ${theme.getResponsiveSize(theme.spacing(mx))};
            margin-right: ${theme.getResponsiveSize(theme.spacing(mx))};
        `};
    ${({ my, theme }) =>
        my &&
        css`
            margin-top: ${theme.getResponsiveSize(theme.spacing(my))};
            margin-bottom: ${theme.getResponsiveSize(theme.spacing(my))};
        `};
    ${({ p, theme }) =>
        p &&
        css`
            padding: ${theme.getResponsiveSize(theme.spacing(p))};
        `};
    ${({ pt, theme }) =>
        pt &&
        css`
            padding-top: ${theme.getResponsiveSize(theme.spacing(pt))};
        `};
    ${({ pr, theme }) =>
        pr &&
        css`
            padding-right: ${theme.getResponsiveSize(theme.spacing(pr))};
        `};
    ${({ pb, theme }) =>
        pb &&
        css`
            padding-bottom: ${theme.getResponsiveSize(theme.spacing(pb))};
        `};
    ${({ pl, theme }) =>
        pl &&
        css`
            padding-left: ${theme.getResponsiveSize(theme.spacing(pl))};
        `};
    ${({ px, theme }) =>
        px &&
        css`
            padding-left: ${theme.getResponsiveSize(theme.spacing(px))};
            padding-right: ${theme.getResponsiveSize(theme.spacing(px))};
        `};
    ${({ py, theme }) =>
        py &&
        css`
            padding-top: ${theme.getResponsiveSize(theme.spacing(py))};
            padding-bottom: ${theme.getResponsiveSize(theme.spacing(py))};
        `};
`
