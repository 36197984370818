import { LoaderContainer, Spinner } from './Loader.styles'

export const Loader = () => {
    return (
        <LoaderContainer data-testid="loader">
            <Spinner>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Spinner>
        </LoaderContainer>
    )
}
