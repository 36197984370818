import { BalanceCard } from '@Balance/Common/BalanceCard/BalanceCard'
import { NegativeBalance } from '@Balance/Common/NegativeBalance/NegativeBalance'
import { Grid } from '@material-ui/core'

export const Summary = () => (
    <Grid container>
        <Grid item md={12} lg={12}>
            <BalanceCard />
        </Grid>
        <Grid item md={12} lg={12}>
            <NegativeBalance />
        </Grid>
    </Grid>
)
