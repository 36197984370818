export enum ButtonVariant {
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
}

export interface ButtonProps {
    variant?: ButtonVariant
    children: React.ReactNode
    onClick?: () => void
    disabled?: boolean
    className?: string
    size?: 'small' | 'large' | 'medium'
}
