export const getMovementType = (type: number) =>
    ({
        1: 'ENTRADA',
        2: 'SAÍDA',
    }[type])

export const dateFormatter = (date: Date) => {
    if (isNaN(date.getTime())) return 'N/A'
    if (date instanceof Date) {
        date = new Date(date)
    }
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    const hour = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month}/${year} ${hour}:${minutes}`
}
