import { DBCommercialMarkup } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Button, ButtonVariant, Table } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { Card, Grid, IconButton } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai'

export const CordMarkup = ({ markup, refresh }) => {
    const [editMode, setEditMode] = useState(false)
    const [state, setState] = useState<DBCommercialMarkup>(markup)

    const { control, setValue, watch } = useForm({
        defaultValues: state,
    })

    const updateMarkupContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseUpdateMarkup
    )

    const addColumn = useCallback(() => {
        setState((prev) => {
            const newColumns = [...prev.columns, '']
            setValue('columns', newColumns)
            const newRows = [...prev.rows]
            for (const element of newRows) {
                element.push('')
            }
            setValue('rows', newRows)
            return {
                ...prev,
                columns: newColumns,
            }
        })
    }, [setState])

    const removeColumn = useCallback(
        (index: number) => {
            setState((prev) => {
                const newColumns = [...prev.columns]
                newColumns.splice(index, 1)
                setValue('columns', newColumns)
                const newRows = [...prev.rows]
                for (const element of newRows) {
                    element.splice(index, 1)
                }
                setValue('rows', newRows)
                return {
                    ...prev,
                    columns: newColumns,
                }
            })
        },
        [setState]
    )

    const handleSave = () => {
        setEditMode(false)
        updateMarkupContainer.execute(state)
    }

    useEffect(() => {
        watch((value) => {
            setState(value as DBCommercialMarkup)
        })
    }, [])

    useEffect(() => {
        refresh()
    }, [])

    if (!state) {
        return <Loader />
    }

    return (
        <Card>
            <form>
                <Table key={state._id}>
                    <thead>
                        <th colSpan={state.columns.length - 1}>Markup</th>
                        {editMode ? (
                            <>
                                <th>
                                    <Button onClick={handleSave}>Salvar</Button>
                                </th>
                                <th>
                                    <Button
                                        type="button"
                                        variant={ButtonVariant.secondary}
                                        onClick={() => {
                                            setEditMode(false)
                                            refresh()
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </th>
                            </>
                        ) : (
                            <th>
                                <Button
                                    variant={ButtonVariant.secondary}
                                    onClick={() => setEditMode(true)}
                                >
                                    Editar
                                </Button>
                            </th>
                        )}
                    </thead>
                    <tbody>
                        {editMode && (
                            <tr className="head">
                                {[...new Array(state.columns.length)].map(
                                    (_, i) => (
                                        <th key={_}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => removeColumn(i)}
                                            >
                                                <AiFillCloseCircle />
                                            </IconButton>
                                        </th>
                                    )
                                )}
                            </tr>
                        )}
                        <tr className="head">
                            {state.columns.map(({ key, value }, i) => (
                                <th key={key}>
                                    {editMode && (
                                        <Input
                                            name={'columns[' + i + '].value'}
                                            control={control}
                                            fullWidth
                                            useUnform={false}
                                        />
                                    )}
                                    {!editMode && value}
                                </th>
                            ))}
                            {editMode && (
                                <td
                                    rowSpan={state.rows.length + 1}
                                    className="head"
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={addColumn}
                                    >
                                        <AiOutlinePlusCircle />
                                    </IconButton>
                                </td>
                            )}
                        </tr>
                        {state.rows.map((row, i) => (
                            <tr key={row[0]}>
                                {row.map(
                                    (
                                        { key, markup, minMarkup, maxMarkup },
                                        j
                                    ) => (
                                        <td key={key}>
                                            {editMode && (
                                                <Grid container spacing={1}>
                                                    <Grid item lg={12}>
                                                        <Input
                                                            name={
                                                                'rows[' +
                                                                i +
                                                                '][' +
                                                                j +
                                                                '].minMarkup'
                                                            }
                                                            label="Markup Mínimo"
                                                            control={control}
                                                            fullWidth
                                                            useUnform={false}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <Input
                                                            name={
                                                                'rows[' +
                                                                i +
                                                                '][' +
                                                                j +
                                                                '].markup'
                                                            }
                                                            label="Markup Ideal"
                                                            control={control}
                                                            fullWidth
                                                            useUnform={false}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12}>
                                                        <Input
                                                            name={
                                                                'rows[' +
                                                                i +
                                                                '][' +
                                                                j +
                                                                '].maxMarkup'
                                                            }
                                                            label="Markup Máximo"
                                                            control={control}
                                                            fullWidth
                                                            useUnform={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {!editMode && (
                                                <>
                                                    <span>
                                                        Min: {minMarkup}
                                                    </span>
                                                    <span>Ideal: {markup}</span>
                                                    <span>
                                                        Max: {maxMarkup}
                                                    </span>
                                                </>
                                            )}
                                        </td>
                                    )
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </form>
        </Card>
    )
}
