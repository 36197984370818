import { Button, ButtonVariant } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { IconButton } from '@Core/Components/IconButton/IconButton'
import { Input } from '@Core/Components/Input/Input'
import { InputAutoComplete } from '@Core/Components/InputAutoComplete/InputAutoComplete'
import { Loader } from '@Core/Components/Loader/Loader'
import { Select } from '@Core/Components/Select/Select'
import { Switch } from '@Core/Components/Switch/Switch.styles'
import { TextArea } from '@Core/Components/TextArea/TextArea'
import { Stack } from '@Movements/Common/Stack/Stack'
import { useProducts } from '@Products/Hooks/useProducts'
import { DBProduct } from '@Products/entities/Product'
import { API } from '@Services/api'
import { useSupplier } from '@Supplier/Hooks/useSupplier'
import { normalize } from '@Utils/Formatters/normalize'
import { handleEnter } from '@Utils/Support/keydown'
import DeleteIcon from '@assets/delete.png'
import { Alert } from '@material-ui/lab'
import { Scope } from '@unform/core'
import {
    ChangeEvent,
    InputHTMLAttributes,
    useCallback,
    useEffect,
    useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { typeOptions } from '../../constants/Movement.constants'
import { MovementStackProps } from './MovementStack.types'

export const MovementStack = ({
    item,
    onChange,
    onRemove,
    index,
    key,
    formRef,
    onlyInput,
}: MovementStackProps) => {
    const { getProducts, products } = useProducts(API)
    const { supplier } = useSupplier(API)
    const [filteredProducts, setFilteredProducts] = useState<DBProduct[]>([])
    const [filteredSupplier, setFilteredSuppliers] = useState<any[]>([])
    const [typeSelected, setTypeSelected] = useState<number | string>()
    const [inSupplierChecked, setInSupplierChecked] = useState(false)
    const { state: locationState } = useLocation()
    const [loading, setLoading] = useState(true)
    const [productSelected, setProductSelected] = useState<{
        label: string
    }>()
    const [supplierSelected, setSupplierSelected] = useState<any>()
    const [amount, setAmount] = useState(0)
    const [ticketNumber, setTicketNumber] = useState('')
    const [observation, setObservation] = useState('')

    const handleProductFormatter = (product: DBProduct) => ({
        label: product.name,
        value: product._id,
        ...product,
    })

    const handleSupplierFormatter = (supplier: any) => ({
        label: supplier.corporateName,
        value: supplier._id,
        ...supplier,
    })

    const handleChange = useCallback(
        (e: {
            target:
                | { name: string; value: any }
                | { name: string; value: boolean }
        }) => {
            const { name, value } = e.target
            if (name === 'amount') {
                setAmount(value)
            } else if (name === 'ticketNumber') {
                setTicketNumber(value)
            } else if (name === 'observation') {
                setObservation(value)
            }
            onChange({
                target: { name: `movements[${index}].${name}`, value },
            } as unknown as ChangeEvent)
        },
        [onChange, index, setAmount, setTicketNumber, setObservation]
    )

    const handleProductSearch = (
        e: ChangeEvent<InputHTMLAttributes<HTMLInputElement>>
    ) => {
        const { target } = e || { target: null }
        if (!target?.value) return
        const { value } = target

        const filtered = products
            .filter((product) =>
                product?.name
                    ?.toLowerCase()
                    ?.includes(String(value)?.toLowerCase())
            )
            .map(handleProductFormatter)
        setFilteredProducts(filtered)
    }

    const handleSupplierSearch = (
        e: ChangeEvent<InputHTMLAttributes<HTMLInputElement>>
    ) => {
        const { target } = e || { target: null }
        if (!target?.value) return
        const { value } = target
        const filtered = supplier
            .filter((item) =>
                item.corporateName
                    ?.toLowerCase()
                    ?.includes(String(value)?.toLowerCase())
            )
            .map(handleSupplierFormatter)
        setFilteredSuppliers(filtered)
    }
    // @ts-ignore
    const handleSupplierSelected = (_, value) => {
        setSupplierSelected({ label: value?.label })
        handleChange({
            target: {
                name: `supplier`,
                value,
            },
        })
    }

    // @ts-ignore
    const handleProductSelect = (_, value) => {
        setProductSelected({ label: value?.label })
        formRef?.current?.setFieldValue(`movements[${index}].product`, value)
        formRef?.current?.setFieldError(`movements[${index}].product`, '')
        handleChange({
            target: {
                name: `product`,
                value: value,
            },
        })
    }

    const handleSupplerToggle = () => {
        handleChange({
            target: {
                name: `inSupplier`,
                value: !inSupplierChecked,
            },
        })
    }

    const handlePreMovement = useCallback(() => {
        const { preMovement } = locationState
        const fillProduct = () =>
            new Promise((resolve) => {
                if (preMovement?.product !== '') {
                    const preProduct = products.find(
                        (product) =>
                            normalize(product.name?.toLocaleLowerCase()) ===
                            normalize(preMovement?.product?.toLocaleLowerCase())
                    )
                    if (preProduct) {
                        handleProductSelect(
                            null,
                            handleProductFormatter(preProduct)
                        )
                    }
                }
                setTimeout(() => resolve(true), 1)
            })
        const fillQuantity = () =>
            new Promise((resolve) => {
                if (preMovement?.quantity !== '') {
                    handleChange({
                        target: {
                            name: `amount`,
                            value: Number(preMovement?.quantity),
                        },
                    })
                }
                setTimeout(() => resolve(true), 1)
            })
        const fillSupplier = () =>
            new Promise((resolve) => {
                if (
                    preMovement?.supplier !== '' &&
                    preMovement?.supplier !== 'ODC'
                ) {
                    const preSupplier = supplier.find(
                        (supplier) =>
                            normalize(
                                supplier.corporateName?.toLocaleLowerCase()
                            ) ===
                            normalize(
                                preMovement?.supplier?.toLocaleLowerCase()
                            )
                    )
                    if (preSupplier) {
                        handleChange({
                            target: {
                                name: `inSupplier`,
                                value: true,
                            },
                        })
                        handleSupplierSelected(
                            null,
                            handleSupplierFormatter(preSupplier)
                        )
                    }
                }
                setTimeout(() => resolve(true), 1)
            })
        const fillTicket = () =>
            new Promise((resolve) => {
                handleChange({
                    target: {
                        name: `ticketNumber`,
                        value: preMovement?.ticket?.ticketNumber,
                    },
                })
                setTimeout(() => resolve(true), 1)
            })

        const fillType = () =>
            new Promise((resolve) => {
                setTypeSelected(typeOptions[1].value)
                handleChange({
                    target: {
                        name: `type`,
                        value: typeOptions[1].value,
                    },
                })
                setTimeout(() => resolve(true), 1)
            })
        const fillObs = () =>
            new Promise((resolve) => {
                handleChange({
                    target: {
                        name: `observation`,
                        value:
                            'Via Pre-movimentação \nCliente: ' +
                            preMovement?.client +
                            '\nProduto: ' +
                            preMovement?.product +
                            '\nQuantidade: ' +
                            preMovement?.quantity +
                            '\nTicket: ' +
                            preMovement?.ticket?.ticketNumber +
                            '\nFornecedor: ' +
                            preMovement?.supplier,
                    },
                })

                setTimeout(() => resolve(true), 1)
            })

        return Promise.all([
            fillProduct(),
            fillQuantity(),
            fillType(),
            fillSupplier(),
            fillTicket(),
            fillObs(),
        ])
    }, [
        locationState,
        products,
        supplier,
        handleChange,
        handleProductSelect,
        handleSupplierSelected,
    ])
    const preMovement = useCallback(async () => {
        if (!products.length) return
        if (!supplier.length) return
        if (locationState?.preMovement) {
            await handlePreMovement()
            setLoading(false)
            await handlePreMovement()
            await handlePreMovement()
        } else {
            setLoading(false)
        }
    }, [products, supplier, handlePreMovement])

    useEffect(() => {
        getProducts()
    }, [])
    useEffect(() => {
        preMovement()
    }, [products, supplier])

    useEffect(() => {
        setTypeSelected(
            formRef.current?.getFieldValue(`movements[${index}].type`)
        )
        setInSupplierChecked(
            formRef.current?.getFieldValue(`movements[${index}].inSupplier`)
        )
    }, [item])

    useEffect(() => {
        setFilteredProducts(products.map(handleProductFormatter))
    }, [products])
    useEffect(() => {
        setFilteredSuppliers(supplier.map(handleSupplierFormatter))
    }, [supplier])

    useEffect(() => {
        window.addEventListener('keydown', handleEnter)
        return () => window.removeEventListener('keydown', handleEnter)
    }, [])

    if (loading) return <Loader />

    return (
        <Stack key={key}>
            <Scope path={`movements[${index}]`}>
                <Grid container item lg={12}>
                    {locationState?.preMovement && (
                        <Grid container item lg={12} xs={12} md={12}>
                            <Grid item lg={12} xs={12} md={12}>
                                <Alert
                                    severity="info"
                                    action={
                                        <Button
                                            variant={ButtonVariant.secondary}
                                            onClick={handlePreMovement}
                                        >
                                            Recarregar
                                        </Button>
                                    }
                                >
                                    Essa movimentação foi preenchida
                                    automaticamente com uma pré-movimentação.
                                    Utilize o botão de recarregar ao lado, caso
                                    precise preencher novamente ou algum dado
                                    não tenha sido preenchido corretamente.
                                </Alert>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        lg={locationState?.preMovement ? 12 : 11}
                    >
                        <Grid item lg={4}>
                            <InputAutoComplete
                                results={filteredProducts}
                                autoFocus
                                inputProps={{
                                    autofocus: true,
                                    name: `product`,
                                    required: true,
                                }}
                                name={`product`}
                                // @ts-ignore
                                onInputChange={handleProductSearch}
                                onChange={handleProductSelect}
                                value={productSelected}
                                label="Produto"
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <Input
                                label="Quantidade"
                                required
                                onChange={handleChange}
                                name="amount"
                                value={amount}
                                type="number"
                                min="0"
                            />
                        </Grid>
                        <Grid item lg={4}>
                            <Select
                                label="Tipo"
                                required
                                options={
                                    onlyInput ? [typeOptions[0]] : typeOptions
                                }
                                onChange={handleChange}
                                value={typeSelected}
                                name={`type`}
                            />
                        </Grid>
                        <Grid container item lg={8}>
                            {String(typeSelected) === '2' && (
                                <>
                                    <Grid flex item lg={6}>
                                        <Input
                                            name="inSupplier"
                                            style={{ display: 'none' }}
                                        />
                                        <Switch onClick={handleSupplerToggle}>
                                            <input
                                                type="checkbox"
                                                checked={inSupplierChecked}
                                            />
                                            <div className="slider round"></div>
                                        </Switch>
                                        Produção em Terceiro
                                    </Grid>
                                    {inSupplierChecked && (
                                        <Grid item lg={6}>
                                            <InputAutoComplete
                                                onChange={
                                                    handleSupplierSelected
                                                }
                                                inputProps={{
                                                    name: `supplier`,
                                                    required: true,
                                                }}
                                                results={filteredSupplier}
                                                value={supplierSelected}
                                                autoFocus
                                                name={`supplier`}
                                                // @ts-ignore
                                                onInputChange={
                                                    handleSupplierSearch
                                                }
                                                label="Fornecedor"
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item lg={4}>
                            {!onlyInput && String(typeSelected) === '1' && (
                                <Input
                                    label="Venda no CA"
                                    onChange={handleChange}
                                    required
                                    autoFocus
                                    name={`caSales`}
                                />
                            )}
                            {String(typeSelected) === '2' && (
                                <Input
                                    label="Ticket Produção"
                                    required
                                    autoFocus
                                    onChange={handleChange}
                                    name={`ticketNumber`}
                                    value={ticketNumber}
                                />
                            )}
                        </Grid>
                        <Grid item lg={12}>
                            {locationState?.preMovement && (
                                <Alert severity="info">
                                    Confira as informações antes de continuar.
                                    Alguns dados podem não ter sido preenchidos
                                    corretamente automaticamente devido a
                                    possíveis divergências entre os sistemas.
                                    Verifique os dados brutos na seção de
                                    'Observações' abaixo para compará-los com os
                                    dados preenchidos acima.
                                </Alert>
                            )}
                            <TextArea
                                value={observation}
                                onChange={handleChange}
                                name={`observation`}
                                label="Observações"
                                minRows={5}
                            />
                        </Grid>
                    </Grid>
                    {!locationState?.preMovement && (
                        <Grid item lg={1}>
                            <IconButton
                                size="medium"
                                alignItems="center"
                                onClick={() => onRemove(item.id)}
                            >
                                <img
                                    src={DeleteIcon}
                                    alt="Remover movimentação"
                                />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Scope>
        </Stack>
    )
}
