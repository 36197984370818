import { roles } from '@Commercial/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { useEffect, useState } from 'react'
import { NewProductModal } from '../Common/NewProductModal/NewProductModal'
import { ProductsListComponent } from '../Common/ProductsList/ProductsList'
import { usePaginate } from '../Hooks/usePaginate/usePaginate'
import { useCommercialProducts } from '../Hooks/useProducts/useCommercialProducts'

export const ProductsList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { products, getProducts, loading } = useCommercialProducts()
    const {
        handleSearch,
        search,
        page,
        limit,
        setPage,
        setLimit,
        filteredProducts,
        getPaginate,
    } = usePaginate({
        products: products.filter((item) => item.name !== 'Outros'),
    })

    const openModal = () => {
        setIsModalOpen(true)
    }

    useEffect(() => {
        getProducts()
    }, [])
    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Produtos</h1>
                <RightContent>
                    <Input
                        size="small"
                        useUnform={false}
                        label="Buscar"
                        name="search"
                        onChange={handleSearch}
                        value={search}
                    />
                    <PermissionWrapper
                        permission={roles.product.permissions.create.key}
                    >
                        <Button
                            onClick={openModal}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Novo Produto
                        </Button>
                        <NewProductModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                            callback={getProducts}
                        />
                    </PermissionWrapper>
                </RightContent>
            </ContainerHeader>
            <ProductsListComponent
                products={products}
                filteredProducts={filteredProducts}
                loading={loading}
                getPaginate={getPaginate}
                page={page}
                limit={limit}
                setLimit={setLimit}
                setPage={setPage}
            />
        </Container>
    )
}
