import styled, { css } from 'styled-components'
import { SidebarFolderContainerProps } from './SidebarFolter.types'

export const SidebarFolderContainer = styled.div<SidebarFolderContainerProps>`
    display: flex;
    position: relative;
    gap: 10px;
    align-items: center;
    padding: 10px;
    min-height: 24px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s;
    background-color: ${({ active }) =>
        (active && `rgba(0, 0, 0, 0.1)`) || `transparent`};
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    list-style: none;

    ${({ collapsed, opened }) =>collapsed && css`
        ::after{
            content: '';
            display: block;
            position: absolute;
            top: 25%;
            // left: -1px;
            right: -1px;
            width: 4px;
            height: 50%;
            z-index: 0;
            background-color: ${({ theme }) => opened ? theme.colors.primary : theme.colors.secondary};
            border-radius: 10px 0 0 10px;
            // border-radius: 0 10px 10px 0;
            transition: all 0.3s
        }
    `}

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
    h6 {
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
    }
`

export const SidebarFolderContent = styled.div`
    > a {
        padding-left: 20px;
    }
`
