import { UserContainer, UserRegister } from '@User/Data/DataSource/Container'
import { DBUser } from '@User/Domain/Model/User'
import { useEffect, useState } from 'react'

export const useUsers = () => {
    const usersUseCase = UserContainer.get(UserRegister.UseCaseGetUsers)
    const [users, setUsers] = useState<DBUser[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const getAllUsers = async () => {
        setLoading(true)
        try {
            const data = await usersUseCase.execute()
            setUsers(data)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    return {
        users,
        loading,
    }
}
