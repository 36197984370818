import styled from 'styled-components'
import { GridProps } from './Grid.types'

export const GridContainer = styled.div<GridProps>`
    display: grid;
    ${({ flex }) => flex && 'display: flex;'}
    ${({ flexEnd }) => flexEnd && 'justify-content: flex-end;'}
    ${({ container }) => container && 'grid-template-columns: repeat(12, 1fr);'}
    ${({ lg }) => lg && `grid-column: span ${lg};`}
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
        ${({ md }) => md && `grid-column: span ${md};`}
    }
    @media (max-width: 768px) {
        ${({ xs }) => xs && `grid-column: span ${xs};`}
    }
`
