import { database } from '@/utils/firebase'
import { remoteConfig } from '@/utils/remoteConfig'
import { RootState } from '@Core/Communication'
import {
    setIsMobileAction,
    setSidebarCollapsedAction,
    toggleSidebarCollapsedAction,
    toggleThemeAction,
} from '@Core/Communication/GlobalStore/Reducers/appconfig/reducer'
import { CommandMenu } from '@Core/Components/Command/CommandMenu'
import ofcLogoWhite from '@assets/logo-white.png'
import ofcLogo from '@assets/logo.png'
import odbLogoWhite from '@assets/odb-white.png'
import odbLogo from '@assets/odb.png'
import { onDisconnect, onValue, ref, set } from 'firebase/database'
import { getValue } from 'firebase/remote-config'
import { useEffect, useMemo } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UserProfile } from '../UserProfile/UserProfile'
import {
    HamburgerMenu,
    HeaderContainer,
    LeftContent,
    Logo,
    RightContent,
    ThemeSwitcher,
    VerticalDivider,
} from './Header.styles'
import { HeaderProps } from './Header.type'

export const Header = ({ user }: HeaderProps) => {
    const { pathname } = useLocation()
    const { theme } = useSelector((state: RootState) => state.appConfig)
    const dispatch = useDispatch()

    const getFeatureFlat = (feature: string) =>
        getValue(remoteConfig, feature).asBoolean()

    const COMMAND_FEATURE_FLAG =
        Boolean(import.meta.env.DEV) ||
        getFeatureFlat('header_command') ||
        false

    const isDarkTheme = useMemo(() => theme === 'dark', [theme])

    const toggleMenu = () => dispatch(toggleSidebarCollapsedAction())

    const onlineUsersRef = ref(database, '.info/connected')
    // @ts-ignore
    const myPresenceRef = ref(database, `presence/${user?._id ?? ''}`)
    onValue(onlineUsersRef, (snapshot) => {
        if (snapshot.val() === true) {
            set(myPresenceRef, true)
            onDisconnect(myPresenceRef).remove()
        }
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1080) {
                dispatch(setSidebarCollapsedAction(false))
            }
            if (window.innerWidth > 768) {
                dispatch(setIsMobileAction(false))
            } else {
                dispatch(setIsMobileAction(true))
            }
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <HeaderContainer>
            <LeftContent>
                <HamburgerMenu onClick={toggleMenu}>
                    <RxHamburgerMenu
                        size={20}
                        color={isDarkTheme ? 'white' : '#333'}
                    />
                </HamburgerMenu>
                <Logo
                    src={isDarkTheme ? ofcLogoWhite : ofcLogo}
                    alt={'Oficina do Crachá'}
                />
                <VerticalDivider.Container>
                    <VerticalDivider.Divider />
                </VerticalDivider.Container>
                <Logo
                    src={isDarkTheme ? odbLogoWhite : odbLogo}
                    alt={'Oficina do Brinde'}
                />
            </LeftContent>
            {COMMAND_FEATURE_FLAG && <CommandMenu />}
            <RightContent>
                <ThemeSwitcher onClick={() => dispatch(toggleThemeAction())}>
                    <input type="checkbox" checked={isDarkTheme} />
                    <div className="slider round"></div>
                </ThemeSwitcher>
                <UserProfile user={user} />
            </RightContent>
        </HeaderContainer>
    )
}
