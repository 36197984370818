import { Pagination } from '@Core/Data/DataSource/API/Entity/Pagination'
import { CreateMovementModel } from '@Movements/Domain/Model/CreateMovement'
import { DBMovement } from '../DataSource/API/Entity/Movement'
import MovementDataSource from '../DataSource/MovementDataSource'
import { IMovementRepository } from './../../Domain/Repository/MovementRepository.type'

export class MovementRepository implements IMovementRepository {
    dataSource: MovementDataSource
    constructor(_dataSource: MovementDataSource) {
        this.dataSource = _dataSource
    }

    async findAll(
        page: number,
        limit: number
    ): Promise<Pagination<DBMovement>> {
        return this.dataSource.getMovements(page, limit)
    }

    async preMovements(): Promise<DBMovement[]> {
        return this.dataSource.getPreMovements()
    }
    async updatePreMovement(preMovementData: {
        id: string
        is_ignored?: boolean
        is_closed?: boolean
    }): Promise<DBMovement> {
        return this.dataSource.updatePreMovement(preMovementData)
    }
    async findById(id: string): Promise<DBMovement> {
        return this.dataSource.getMovementById(id)
    }
    async search(query: string): Promise<DBMovement[]> {
        return this.dataSource.search(query)
    }
    async create(entity: CreateMovementModel): Promise<DBMovement> {
        return this.dataSource.createMovement(entity)
    }
    async update(entity: DBMovement): Promise<DBMovement> {
        return this.dataSource.updateMovement(entity)
    }
    async delete(id: string): Promise<void> {
        return this.dataSource.deleteMovement(id)
    }

    getSource(): string {
        return this.dataSource.getSource()
    }
}
