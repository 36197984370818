import { store } from '@Core/Communication'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider as ReduxProvider } from 'react-redux'
import App from './App'
import './globals.css'
import './index.css'

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://0ea66d2403d84b9d98ce765d491d52eb@o594431.ingest.sentry.io/4504833860304896',
        tracesSampleRate: 1.0,
        integrations: [new BrowserTracing()],
    })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <App />
        </ReduxProvider>
    </React.StrictMode>
)
