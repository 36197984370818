// @ts-nocheck
import { Stock } from '@Core/Entities/Stock'
import { dateFormatter } from '@Movements/Utils/Movements.utils'

export const exportClientStockDictionary = (item: any) =>
    ({
        _id: 'ID',
        client: 'Cliente',
        supplier: 'Fornecedor',
        product: 'Produto',
        amount: 'Quantidade',
        type: 'Tipo',
        date: 'Data',
        lastInput: 'Última entrada',
        lastOut: 'Última saída',
        user: 'Responsável',
        __v: '',
    }[item] || item)

export const exportClientStockValueDictionary = (
    key: Stock,
    item: Stock[key]
) =>
    ({
        _id: { value: item, type: 'string' },
        client: { value: item?.corporateName, type: 'string' },
        supplier: {
            value: item[0]?.corporateName || item.corporateName,
            type: 'string',
        },
        product: { value: item[0]?.name, type: 'string' },
        amount: { value: item, type: 'number' },
        type: { value: item === 2 ? 'Saída' : 'Entrada', type: 'string' },
        date: { value: dateFormatter(new Date(item)), type: 'string' },
        lastInput: {
            value: dateFormatter(new Date(item?.[0]?.date)) || 'N/A',
            type: 'string',
        },
        lastOut: {
            value: dateFormatter(new Date(item?.[0]?.date)) || 'N/A',
            type: 'string',
        },
        user: { value: item?.name, type: 'string' },
        __v: { value: item, type: 'number' },
    }[key] || { value: item, type: 'string' })

export const exportClientMovementValueDictionary = (
    key: Stock,
    item: Stock[key]
) => item

export const exportMovementData = (data: any) => {
    const columns = [
        '_id',
        'client',
        'supplier',
        'product',
        'amount',
        'type',
        'date',
    ]
    const rows = data.map((item: any) => {
        const row: any = {}
        columns.forEach((column) => {
            const { value, type } = exportClientStockValueDictionary(
                column,
                item[column]
            )
            row[column] = { value, type }
        })
        return row
    })
    return rows
}

export const exportStockData = (data: any, inject?: any) =>
    data.map((item: any) => ({
        ...item,
        ...inject,
        lastInput: item.product[0].lastInput,
        lastOut: item.product[0].lastOut,
    }))
