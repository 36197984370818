import { UsersRepository } from '@User/Data/Repository/UsersRepository'
import { DBUser } from '../Model/User'

export interface DisableUser {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DisableUserUseCase implements DisableUser {
    constructor(private repository: UsersRepository) {}
    async execute(id: string): Promise<void> {
        return this.repository.disable(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
