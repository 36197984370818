import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { Product, productSchema } from '@Commercial/Products/entities/Product'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import getValidationErrors from '@Utils/Support/getValidationErrors'
import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import { FormHandles } from '@unform/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'

export const useCommercialCreateProduct = () => {
    const formRef = useRef<FormHandles>(null)
    const [loading, setLoading] = useState(false)
    const getVariablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )
    const createProductUseCase = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseCreateProduct
    )
    const [taxValue, setTaxValue] = useState(10)
    const [state, setState] = useState<Product>({
        unitCost: 0,
        productType: 'default',
        name: '',
        markup: 0,
        minMarkup: 0,
        maxMarkup: 0,
        minUnitPrice: 0,
        minProfit: 0,
        minMarkupFinal: 0,
        unitPrice: 0,
        profit: 0,
        markupFinal: 0,
        maxUnitPrice: 0,
        maxProfit: 0,
        maxMarkupFinal: 0,
    } as Product)

    const getTax = async () => {
        const variables = await getVariablesContainer.execute()
        const { variableValue } = variables.find(
            (item) => item.variableType === 'tax'
        ) || {
            variableValue: 0,
        }
        setTaxValue(Number(variableValue))
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => {
            const newState = {
                ...prev,
                [event.target.name]: event.target.value,
            }
            return calculateMarkups(newState)
        })
    }

    const handleCreateProduct = useCallback(
        async (callBack: () => void) => {
            setLoading(true)
            const productState = { ...state }
            productState.unitCost = Number(
                String(productState.unitCost).replace(',', '.')
            )
            try {
                if (
                    await productSchema.validate(productState, {
                        abortEarly: false,
                    })
                ) {
                    await createProductUseCase.execute(productState)
                    toast.success('Produto criado com sucesso')
                    formRef.current?.reset()
                    setState({} as Product)
                    callBack?.()
                }
            } catch (error) {
                const errors = getValidationErrors(error as any)
                console.log(errors, error)
                formRef.current?.setErrors(errors)
            } finally {
                setLoading(false)
            }
        },
        [state]
    )

    const calculateMarkups = (state: Product) => {
        const calculateUnitPrice = (markup: number, unitCost: number) => {
            return ((Number(markup) / 100 + 1) * unitCost * 100) / 100
        }
        const calculateProfit = (unitPrice: number, unitCost: number) => {
            const tax = ((unitPrice || 0) * (taxValue || 0)) / 100
            return Number(unitPrice) - Number(unitCost) - tax
        }
        const calculateMarkup = (profit: number, unitCost: number) => {
            return (profit / unitCost) * 100
        }
        const minUnitPrice = calculateUnitPrice(
            Number(state.minMarkup),
            Number(state.unitCost)
        )
        const unitPrice = calculateUnitPrice(
            Number(state.markup),
            Number(state.unitCost)
        )
        const maxUnitPrice = calculateUnitPrice(
            Number(state.maxMarkup),
            Number(state.unitCost)
        )

        const minProfit = calculateProfit(
            Number(minUnitPrice),
            Number(state.unitCost)
        )
        const profit = calculateProfit(
            Number(unitPrice),
            Number(state.unitCost)
        )
        const maxProfit = calculateProfit(
            Number(maxUnitPrice),
            Number(state.unitCost)
        )

        const minMarkupFinal = calculateMarkup(
            Number(minProfit),
            Number(state.unitCost)
        )
        const markupFinal = calculateMarkup(
            Number(profit),
            Number(state.unitCost)
        )
        const maxMarkupFinal = calculateMarkup(
            Number(maxProfit),
            Number(state.unitCost)
        )
        return {
            ...state,
            unitPrice: formatCurrency(unitPrice),
            profit: formatCurrency(profit),
            markupFinal,
            minProfit: formatCurrency(minProfit),
            maxProfit: formatCurrency(maxProfit),
            minMarkupFinal: Number(minMarkupFinal).toFixed(0),
            maxMarkupFinal: Number(maxMarkupFinal).toFixed(0),
            minUnitPrice: formatCurrency(minUnitPrice),
            maxUnitPrice: formatCurrency(maxUnitPrice),
        }
    }

    useEffect(() => {
        getTax()
    }, [])

    return {
        handleCreateProduct,
        handleChange,
        formRef,
        state,
        loading,
    }
}
