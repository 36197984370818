import { DBInventoryDestination } from '@InventoryControl/Destinations/Data/DataSource/API/Entity/Destination'
import {
    InventoryDestinationContainer,
    InventoryDestinationRegister,
} from '@InventoryControl/Destinations/Data/DataSource/Container'
import { useState } from 'react'

export const useInventoryDestinations = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [destinations, setDestinations] = useState<DBInventoryDestination[]>(
        []
    )
    const getDestinationsUseCase = InventoryDestinationContainer.get(
        InventoryDestinationRegister.UseCaseGetDestinations
    )

    const formattedAutocomplete = (entity: DBInventoryDestination[]) => {
        return entity.map((destination) => ({
            value: destination,
            label: destination.name,
        }))
    }

    const getDestinations = async () => {
        setLoading(true)
        try {
            const destinations = await getDestinationsUseCase.execute()
            setDestinations(destinations)
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return { destinations, getDestinations, loading, formattedAutocomplete }
}
