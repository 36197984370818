import { CommercialAccessories } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { useCallback, useEffect, useState } from 'react'

export const useAccessories = ({ accessories, refresh, productId }) => {
    const [state, setAccessories] =
        useState<CommercialAccessories[]>(accessories)

    const createAccessoriesContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseCreateAccessories
    )
    const updateAccessoriesContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseUpdateAccessories
    )
    const deleteAccessoriesContainer = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseDeleteAccessories
    )

    const handleAddAccessory = useCallback(() => {
        setAccessories((prev) => [
            ...prev,
            {
                id: crypto.randomUUID(),
                name: '',
                value: 0,
                productId,
                isNew: true,
            },
        ])
    }, [setAccessories])

    const handleRemoveAccessory = useCallback(
        (index: number) => async () => {
            if (accessories[index]?._id) {
                await deleteAccessoriesContainer.execute(accessories[index]._id)
                refreshAccessory()
            }
            setAccessories((prev) => [
                ...prev.slice(0, index),
                ...prev.slice(index + 1),
            ])
        },
        [accessories]
    )

    const handleSaveAccessory = async (entity: CommercialAccessories) => {
        if (entity.id) {
            await createAccessoriesContainer.execute({ ...entity, productId })
            refreshAccessory()
        } else {
            await updateAccessoriesContainer.execute({ ...entity, productId })
        }
        refreshAccessory()
    }

    const refreshAccessory = async () => {
        refresh()
    }

    useEffect(() => {
        setAccessories(accessories)
    }, [accessories])

    useEffect(() => {
        refreshAccessory()
    }, [])

    return {
        state,
        handleAddAccessory,
        handleRemoveAccessory,
        refreshAccessory,
        handleSaveAccessory,
    }
}
