import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import { MovementRepository } from '@Movements/Data/Repository/MovementRepository'

export interface GetMovement {
    execute(id: DBMovement['_id']): Promise<DBMovement>
    getSource(): string
}

export class GetMovementUseCase implements GetMovement {
    constructor(private repository: MovementRepository) {}
    async execute(id: DBMovement['_id']): Promise<DBMovement> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
