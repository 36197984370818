import { InventoryProductsRepository } from '@InventoryControl/Products/Data/Repository/ProductRepository'
import { DBInventoryProduct } from './../../Data/DataSource/API/Entity/Product'

export interface UpdateProduct {
    execute(product: DBInventoryProduct): Promise<DBInventoryProduct>
    getSource(): string
}

export class UpdateProductUseCase implements UpdateProduct {
    constructor(private repository: InventoryProductsRepository) {}
    async execute(product: DBInventoryProduct): Promise<DBInventoryProduct> {
        return this.repository.update(product)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
