import {
    DeleteProductsUseCase,
    GetProductsUseCase,
    GetProductUseCase,
} from '@InventoryControl/Products/Domain/UseCases'
import { CreateProductUseCase } from '@InventoryControl/Products/Domain/UseCases/CreateProduct'
import { Container } from 'inversify'
import { UpdateProductUseCase } from '../../../Domain/UseCases/UpdateProduct'
import { InventoryProductsRepository } from '../../Repository/ProductRepository'
import { ProductAPI } from '../API/ProductAPI'
import { Register } from './ProductRegister'

const container = new Container()
container
    .bind<GetProductUseCase>(Register.UseCaseGetProduct)
    .toDynamicValue(
        () =>
            new GetProductUseCase(
                new InventoryProductsRepository(new ProductAPI())
            )
    )
container
    .bind<GetProductsUseCase>(Register.UseCaseGetProducts)
    .toDynamicValue(
        () =>
            new GetProductsUseCase(
                new InventoryProductsRepository(new ProductAPI())
            )
    )

container
    .bind<CreateProductUseCase>(Register.UseCaseCreateProduct)
    .toDynamicValue(
        () =>
            new CreateProductUseCase(
                new InventoryProductsRepository(new ProductAPI())
            )
    )
container
    .bind<UpdateProductUseCase>(Register.UseCaseUpdateProduct)
    .toDynamicValue(
        () =>
            new UpdateProductUseCase(
                new InventoryProductsRepository(new ProductAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteProduct)
    .toDynamicValue(
        () =>
            new DeleteProductsUseCase(
                new InventoryProductsRepository(new ProductAPI())
            )
    )

export default container
