import { convertHexToRGBA } from '@Utils/Formatters/opacityColor'
import styled from 'styled-components'

export const Switch = styled.div`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0 10px;
    @media (max-width: 768px) {
        margin: 0 5px;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${({ theme }) => theme.colors.primary};
        -webkit-transition: 0.4s;
        transition: all 0.4s;
    }
    .slider:before {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    input + .slider {
        background: ${({ theme }) => convertHexToRGBA(theme.borderColor, 0.5)};
    }
    input:checked + .slider {
        background: ${({ theme }) => theme.colors.primary};
    }
    input + .slider:before {
        background-color: #f5f5f5;
    }
    input:checked + .slider:before {
        background-color: #f5f5f5;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #111;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
    .slider.round {
        border-radius: 34px;
    }
    .slider.round:before {
        border-radius: 50%;
    }
`
