import { setIsSessionExpiredAction } from '@Core/Communication/GlobalStore/Reducers/authentication/reducer'
import { getAuth } from 'firebase/auth'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useSession = (storage: Storage) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const refreshSession = async () => {
        const token = storage.getItem('@ofc:accessToken')
        if (token === '' || token === null || token === undefined) {
            dispatch(setIsSessionExpiredAction(true))
            return
        }
        if (getAuth().currentUser?.email) {
            dispatch(setIsSessionExpiredAction(false))
        } else {
            storage.removeItem('@ofc:accessToken')
        }
    }

    getAuth().onAuthStateChanged((user) => {
        if (!user) {
            dispatch(setIsSessionExpiredAction(true))
        }
    })

    useEffect(() => {
        const interval = setInterval(() => {
            refreshSession()
        }, 1000 * 10 * 1)
        return () => {
            clearInterval(interval)
        }
    }, [])
}
