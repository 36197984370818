import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import {
    calculateBV,
    calculateSaleValue,
} from '@Commercial/Proposal/Utils/calculateBv'
import { roles } from '@Commercial/roles'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { formatCurrency } from '@Utils/Formatters/currency'
import { useCallback, useEffect, useState } from 'react'
import { FooterContainer } from './ProposalFooter.styles'

export const ProposalFooter = ({
    state,
    setFormValue,
}: {
    state: {
        products: any[]
        bvPercentage: number
        bvAmount: number
    }
    setFormValue: (key: string, value: any) => void
}) => {
    const variablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )
    const { hasPermission } = usePermissions()
    const [taxValue, setTaxValue] = useState(0)
    const [values, setValues] = useState({
        costTotal: 0,
        totalPrice: 0,
        markup: 100,
        tax: taxValue,
        taxAmount: 0,
        profit: 0,
        bvPercentage: 0,
        bvAmount: 0,
    })

    const readCost = hasPermission(
        roles.proposal.permissions.read.permissions.readCost.key
    )
    const readFooter = hasPermission(
        roles.proposal.permissions.read.permissions.readFooter.key
    )

    const readSimpleFooter =
        hasPermission(
            roles.proposal.permissions.read.permissions.readSimpleFooter.key
        ) || readFooter

    const calculate = useCallback(() => {
        const tax = taxValue || 0
        const costTotal = state.products.reduce(
            (acc, cur) => acc + ((cur.isActive && Number(cur.totalCost)) || 0),
            0
        )
        setFormValue('costTotal', costTotal)
        const freightTotal = state.products.reduce(
            (acc, cur) => acc + ((cur.isActive && Number(cur.freight)) || 0),
            0
        )
        const freightTaxTotal = (Number(freightTotal) * Number(tax)) / 100
        setFormValue('freightTotal', freightTotal)
        setFormValue('freightTaxTotal', freightTaxTotal)
        const totalDiscount = state.products.reduce(
            (acc, cur) =>
                acc +
                ((cur.isActive &&
                    Number(cur.unitDiscount) * Number(cur.quantity)) ||
                    0),
            0
        )
        setFormValue('totalDiscount', formatCurrency(totalDiscount))
        const totalPrice = state.products.reduce((acc, cur) => {
            return acc + ((cur.isActive && Number(cur.totalPriceFull)) || 0)
        }, 0)
        const saleValue = calculateSaleValue(totalPrice, 0)
        setFormValue('totalPrice', totalPrice)
        setFormValue('saleValue', saleValue)
        const bvValue = calculateBV(saleValue, Number(state.bvPercentage) / 100)
        const taxAmount = ((saleValue || 0) * (tax || 0)) / 100
        setFormValue('taxAmount', taxAmount)
        setFormValue('bvAmount', bvValue.toFixed(2))
        setFormValue('bvAmountToShow', formatCurrency(bvValue))
        const profit =
            (saleValue || 0) -
            (costTotal || 0) -
            (taxAmount || 0) -
            (bvValue || 0)
        setFormValue('profit', profit)
        const markup = (profit / costTotal) * 100 || 0
        setFormValue('markup', markup)
        setValues((prev) => ({
            ...prev,
            costTotal,
            totalPrice,
            taxAmount,
            profit: profit,
            markup,
            saleValue,
            bvAmount: Number(bvValue?.toFixed(2)),
            bvPercentage: Number(state.bvPercentage),
            totalDiscount,
        }))
    }, [state, taxValue])

    const handleCalculate = () => {
        calculate()
    }

    const getTax = async () => {
        const variables = await variablesContainer.execute()
        const { variableValue } = variables.find(
            (item) => item.variableType === 'tax'
        ) || {
            variableValue: 0,
        }
        if(state.status === 'won' || state.status === 'lost'){
            setTaxValue(state.tax || Number(variableValue))
            setFormValue('tax', Number(variableValue))
            return
        }else{
            setTaxValue(Number(variableValue))
            setFormValue('tax', Number(variableValue))
        }
    }

    useEffect(handleCalculate, [state, taxValue])
    useEffect(() => {
        getTax()
    }, [])

    if (!readFooter && !readSimpleFooter) return <></>

    return (
        <FooterContainer>
            <div className="saleValue">
                <b>Receita Total</b>
                <span>{formatCurrency(values.saleValue)}</span>
            </div>
            {readCost && readFooter && (
                <div className="costTotal">
                    <b>Custo Total</b>
                    <span>{formatCurrency(values.costTotal)}</span>
                </div>
            )}
            {readFooter && (
                <div className="tax">
                    <b>Imposto {taxValue}%</b>
                    <span>{formatCurrency(values.taxAmount)}</span>
                </div>
            )}
            <div className="bv">
                <b>BV / Cartão {values.bvPercentage}%</b>
                <span>{formatCurrency(values.bvAmount)}</span>
            </div>
            {readCost && readFooter && (
                <div className="profit">
                    <b>Lucro</b>
                    <span>{formatCurrency(values.profit)}</span>
                </div>
            )}
            <div className="markup">
                <b>Markup</b>
                <span>{Math.round(values.markup)}%</span>
            </div>
        </FooterContainer>
    )
}
