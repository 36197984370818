import { Button, Menu, MenuItem } from '@material-ui/core'
import { FC, useState } from 'react'
import { IMenuOptions } from './MenuOptions.types'

export const MenuOptions: FC<IMenuOptions> = ({ children, menuList }) => {
    const [anchorEl, setAnchorEl] = useState<any>(null)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpenHref = (item: any) => () => {
        const a = document.createElement('a')
        a.href = item.href
        a.target = item.attrs?.target
        a['download'] = item.attrs?.download
        a.click()
        handleClose()
    }

    return (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                {children}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuList.map((item) =>
                    item?.href ? (
                        <MenuItem
                            key={item.label}
                            onClick={handleOpenHref(item)}
                        >
                            {item.label}
                        </MenuItem>
                    ) : (
                        <MenuItem key={item.label} onClick={item?.onClick}>
                            {item.label}
                        </MenuItem>
                    )
                )}
            </Menu>
        </>
    )
}
