import { Pagination } from '@Core/Data/DataSource/API/Entity/Pagination'
import { CreateMovementModel } from '@Movements/Domain/Model/CreateMovement'
import { Api } from '@Services/api'
import MovementDataSource from '../MovementDataSource'
import { DBMovement } from './Entity/Movement'

export class MovementAPI implements MovementDataSource {
    axios = new Api()

    async getPreMovements(): Promise<DBMovement[]> {
        const { data } = await this.axios.get('/movements/pre-movements')
        return data
    }

    async updatePreMovement(preMovementData: {
        id: string
        is_ignored?: boolean
        is_closed?: boolean
    }): Promise<DBMovement> {
        const { data } = await this.axios.put(
            `/movements/pre-movements/${preMovementData.id}`,
            preMovementData
        )
        return data
    }

    async getMovementById(id: string): Promise<DBMovement> {
        const { data } = await this.axios.get(`/movements/${id}`)
        return data[0]
    }

    async getMovements(
        page: number = 1,
        limit: number = 10
    ): Promise<Pagination<DBMovement>> {
        const { data } = await this.axios.get(
            `/movements?page=${page}&limit=${limit}`
        )
        return data
    }

    async search(query: string): Promise<DBMovement[]> {
        const { data } = await this.axios.get(`/movements/search/${query}`)
        return data
    }

    async createMovement(movement: CreateMovementModel): Promise<DBMovement> {
        const { data } = await this.axios.post('/movements', movement)
        return data
    }

    async updateMovement(movement: DBMovement): Promise<DBMovement> {
        const { data } = await this.axios.put(
            `/movements/${movement._id}`,
            movement
        )
        return data
    }

    async deleteMovement(id: string): Promise<void> {
        await this.axios.delete(`/movements/${id}`)
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
