import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import { Toggle } from '@/components/ui/toggle'
import { useCommercialContext } from '@Commercial/Context/CommercialContextProvider'
import { RootState } from '@Core/Communication'
import { UserContainer, UserRegister } from '@User/Data/DataSource/Container'
import { DBUser } from '@User/Domain/Model/User'
import { makeStyles } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

const ALL = {
    firebase: {
        uid: 'all',
    },
    name: 'Todos',
}

export const UserSelection = () => {
    const [users, setUser] = useState<DBUser[]>([])
    const getUserContainer = UserContainer.get(UserRegister.UseCaseGetUsers)
    const { selectedUser, setSelectedUser } = useCommercialContext()
    const { user } = useSelector((state: RootState) => state.user)

    const vendorsToSelect = useMemo(
        () =>
            [ALL, ...users].map((user) => ({
                label: user?.name || user?.displayName || user?.mail,
                value: user?.firebase?.uid,
            })),
        [users]
    )

    const activeFlag = Boolean(users.length)

    const getUsers = async () => {
        const all = await getUserContainer.execute()
        setUser(all)
    }

    const handleChange = useCallback(
        (value: string) => {
            let newValues = new Set(selectedUser)

            if (newValues.has(value)) {
                newValues.delete(value)
                if (newValues.size === 0 || newValues.size === users.length) {
                    newValues = new Set(['all'])
                }
            } else {
                if (value === 'all') {
                    newValues = new Set(['all'])
                } else if (newValues.has('all')) {
                    newValues = new Set(
                        [...newValues].filter((v) => v !== 'all')
                    )
                    newValues.add(value)
                } else {
                    newValues.add(value)
                }
            }

            setSelectedUser(Array.from(newValues))
        },
        [users, selectedUser]
    )

    const isSelected = (value: string) =>
        selectedUser.includes('all') || selectedUser.includes(value)

    useEffect(() => {
        getUsers()
    }, [])

    if (activeFlag) {
        return (
            <div className="flex flex-col max-h-fit">
                <ScrollArea className="h-[50vh] max-h-fit flex flex-col items-center">
                    {vendorsToSelect.map(({ label, value }) => (
                        <div className="flex w-[100%] items-center mx-2">
                            <div className="w-2/12">
                                <Avatar className="w-9 h-9">
                                    <AvatarImage
                                        src={`https://ui-avatars.com/api/?name=${label}&background=0D8ABC&color=fff`}
                                    />
                                </Avatar>
                            </div>
                            <div className="flex items-center justify-between p-1 w-9/12">
                                <div className="w-8/12  truncate text-ellipsis overflow-hidden">
                                    <span className="text-xs">
                                        {label} {user?.id === value && '(Você)'}
                                    </span>
                                </div>
                                <Toggle
                                    onClick={() => handleChange(value)}
                                    pressed={isSelected(value)}
                                    className="dark:bg-transparent"
                                >
                                    {isSelected(value) ? (
                                        <AiFillStar
                                            size={20}
                                            className="text-od-primary-500"
                                        />
                                    ) : (
                                        <AiOutlineStar size={20} />
                                    )}
                                </Toggle>
                            </div>
                        </div>
                    ))}
                </ScrollArea>
            </div>
        )
    }

    return (
        <div className="flex flex-col max-w-[90%] max-h-[50vh]">
            <ScrollArea className="w-[100%] max-h-[50vh]">
                {Array(10)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            className="flex items-center space-x-4 mb-2"
                            key={index}
                        >
                            <Skeleton className="w-9 h-9 rounded-full" />
                            <div className="flex items-center justify-between p-1 w-10/12">
                                <Skeleton className="h-4 w-9/12" />
                                <Skeleton className="h-4 w-4" />
                            </div>
                        </div>
                    ))}
            </ScrollArea>
        </div>
    )
}
