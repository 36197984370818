import { usePreMovements } from '@Balance/Movements/Hooks/usePreMovements'
import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter } from '@Core/Components/Modal/Modal'
import { Grid } from '@material-ui/core'
import { BsLink45Deg } from 'react-icons/bs'
import { toast } from 'react-toastify'

export const IgnorePreMovementModal = ({
    isOpen,
    onClose,
    item,
}: {
    isOpen: boolean
    onClose?: () => void
    item: any
}) => {
    const { id, product, ticket, quantity, client, supplier } = item || {
        id: '',
        product: '',
        ticket: { ticketNumber: '' },
        quantity: '',
        client: '',
        supplier: '',
    }
    const { ignorePreMovement, loading } = usePreMovements({
        onCompleted: () => {
            onClose?.()
            toast.success('Pre-movimentação foi ignorada!')
        },
    })
    if (id === '' || id === undefined) return null

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h3>
                Você tem certeza que gostaria de ignorar esta pre-movimentação?
            </h3>
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={6}>
                    <b>Ticket:</b>{' '}
                    <a
                        title="Abrir ticket no Tiflux"
                        target="_blank"
                        href={`https://app.tiflux.com/v/tickets/${ticket.ticketNumber}/basic_info`}
                    >
                        <span>{ticket.ticketNumber} </span>
                        <i>
                            <BsLink45Deg />
                        </i>
                    </a>
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                    <b>Produto:</b> {product}
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                    <b>Quantidade:</b> {quantity}
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                    <b>Cliente:</b> {client}
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                    <b>Fornecedor:</b> {supplier}
                </Grid>
            </Grid>

            <ul>
                <li>Está ação não pode ser desfeita.</li>
            </ul>
            <ModalFooter>
                <Button onClick={onClose} variant={ButtonVariant.primary}>
                    Cancelar
                </Button>
                <Button
                    onClick={() => ignorePreMovement(id)}
                    variant={ButtonVariant.secondary}
                    disabled={loading}
                >
                    {loading ? 'Aguarde...' : 'Ignorar'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
