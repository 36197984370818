import { ClientsRepository } from '@Clients/Data/Repository/ClientsRepository'
import { Clients } from '../Model/Clients'

export interface GetClient {
    execute(id: string): Promise<Clients>
    getSource(): string
}

export class GetClientUseCase implements GetClient {
    constructor(private repository: ClientsRepository) {}
    async execute(id: string): Promise<Clients> {
        return this.repository.findById(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
