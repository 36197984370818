import styled from 'styled-components'

export const IconButtonContainer = styled.button<{
    justifyContent: string
    alignItems: string
}>`
    display: flex;
    align-items: ${({ alignItems }) => alignItems};
    justify-content: ${({ justifyContent }) => justifyContent};
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    padding: 12px 0;
    & > img {
        width: 24px;
    }
`
