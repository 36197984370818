import { InventoryExitsRepository } from '@InventoryControl/Exits/Data/Repository/ExitsRepository'
import { DBInventoryExits } from '../../Data/DataSource/API/Entity/Exits'

export interface UpdateExit {
    execute(exit: DBInventoryExits): Promise<DBInventoryExits>
    getSource(): string
}

export class UpdateExitUseCase implements UpdateExit {
    constructor(private repository: InventoryExitsRepository) {}
    async execute(exit: DBInventoryExits): Promise<DBInventoryExits> {
        return this.repository.update(exit)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
