import { FC } from 'react'
import { GridContainer } from './Grid.styles'
import { GridProps } from './Grid.types'

export const Grid: FC<GridProps> = ({
    children,
    item = false,
    container = false,
    xs,
    lg,
    md,
    flex,
    flexEnd,
}) => {
    return (
        <GridContainer
            xs={xs}
            lg={lg}
            md={md}
            item={item}
            container={container}
            flex={flex}
            flexEnd={flexEnd}
        >
            {children}
        </GridContainer>
    )
}
