import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import { formCalculate } from '@Commercial/Proposal/Utils/formCalulate'
import { roles } from '@Commercial/roles'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IFormInputs } from './useManagerProposal.types'

export const useManagerProposal = ({ callback }:  {callback: () => void}) => {
    const [loading, setLoading] = useState(false)
    const pipeContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetProposalPipe
    )
    const { hasPermission } = usePermissions()
    const noMarkupLimit = hasPermission(
        roles.proposal.permissions.update.permissions.noMarkupLimit.key
    )
    const [state, setState] = useState<IFormInputs>({
        products: [
            {
                isActive: true,
                markup: 100,
            },
        ],
        bvPercentage: 0,
        bvAmount: 0,
        discount: 0,
    })
    const { register, handleSubmit, watch, formState, ...methods } =
        useForm<IFormInputs>({
            defaultValues: {
                ...state,
            },
        })

    const proposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseUpdateProposal
    )

    useEffect(() => {
        const subscription = watch((value: any, { name }) => {
            formCalculate(setState)(value, { name })
            if (name === 'name') {
                if (value.name.length > 3) {
                    debounce(async () => {
                        const client = await pipeContainer.execute(value.name)
                        if (!client) return
                        const { organization } = client
                        methods.setValue('clientName', organization?.name)
                        setState((old) => ({
                            ...old,
                            clientName: organization?.name,
                        }))
                    }, 500)()
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, state])

    const validate = (data: any) => {
        if (!data.name) {
            return {
                error: true,
                message: 'O pipe da proposta é obrigatório',
            }
        }
        if (!data.products.length) {
            return {
                error: true,
                message: 'A proposta deve ter pelo menos um produto',
            }
        }
        if (data.products.length) {
            for (const element of data.products) {
                if (!element.name) {
                    return {
                        error: true,
                        message: 'Um produto deve ser selecionado',
                    }
                }
                if (element.isActive) {
                    if (!element.quantity) {
                        return {
                            error: true,
                            message: 'Todos os produtos devem ter quantidade',
                        }
                    }
                    if (!element.freight) {
                        return {
                            error: true,
                            message: 'Todos os produtos devem ter frete',
                        }
                    }
                    if (element.productType === 'gift') {
                        if (element.quantity < element.minQuantity) {
                            return {
                                error: true,
                                message:
                                    'Todos os produtos devem respeitar o limite mínimo de quantidade',
                            }
                        }
                        if (element.totalPriceFull < element.minPrice) {
                            return {
                                error: true,
                                message:
                                    'Todos os produtos devem respeitar o limite mínimo de valor',
                            }
                        }
                    }
                    if (
                        !noMarkupLimit &&
                        (element.markup < element.minMarkup ||
                            element.markup > element.maxMarkup)
                    ) {
                        return {
                            error: true,
                            message: `O markup do produto deve estar no intervalo de ${element.minMarkup} - ${element.maxMarkup}`,
                        }
                    }
                }
            }
        }
        return {
            error: false,
            message: '',
        }
    }

    const onSubmit = async (data: any) => {
        setLoading(true)
        const { error, message } = validate(data)
        if (error) {
            toast.error(message)
            setLoading(false)
            return
        }
        try {
            await proposalContainer.execute(data)
            toast.success('Proposta Atualizada com sucesso')
            callback?.()
        } catch (e) {
            toast.error(e.message)
            console.log(e)
        }
        setLoading(false)
    }

    const handleAddingNewProduct = useCallback(() => {
        methods.setValue('products', [
            ...state.products,
            { markup: 100, isActive: true },
        ])
    }, [state])

    return {
        loading,
        state,
        register,
        handleSubmit: handleSubmit(onSubmit),
        handleAddingNewProduct,
        methods,
    }
}
