import { InventorySuppliersRepository } from '@InventoryControl/Supplier/Data/Repository/SupplierRepository'

export interface DeleteSupplier {
    execute(id: string): Promise<void>
    getSource(): string
}

export class DeleteSupplierUseCase implements DeleteSupplier {
    constructor(private repository: InventorySuppliersRepository) {}
    async execute(id: string) {
        return this.repository.delete(id)
    }
    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
