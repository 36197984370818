import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { useCommercialContext } from '@Commercial/Context/CommercialContextProvider'
import { memo, useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { BiUser } from 'react-icons/bi'
import { BsFilter } from 'react-icons/bs'
import { DatePickerWithRange } from '../DatePickerWithRange/DatePickerWithRange'
import { UserSelection } from '../UserSelection/UserSelection'

const ProposalFilterComponent = ({
    handleSearch,
    search,
    viewAll,
    handleStatusChange,
    handleDateChange,
    hasStatus,
}) => {
    const [activeTab, setActiveTab] = useState('filters')
    const { selectedUser } = useCommercialContext()
    const [date, setDate] = useState<DateRange | undefined>(undefined)

    const StatusFilter = () => {
        return (
            <AccordionItem value="status">
                <AccordionTrigger>Status</AccordionTrigger>
                <AccordionContent>
                    <div className="w-[100%] space-y-1">
                        <ul className="flex flex-col gap-2">
                            <li className="items-center space-x-3 space-y-0 border border-blue-200 shadow rounded px-2 pt-1">
                                <Checkbox
                                    id="open"
                                    onCheckedChange={handleStatusChange('open')}
                                    checked={hasStatus('open')}
                                    className="border-blue-500 data-[state=checked]:bg-blue-500"
                                />
                                <label
                                    htmlFor="open"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Abertos
                                </label>
                            </li>
                            <li className="items-center space-x-3 space-y-0 border border-green-200 shadow rounded px-2 pt-1">
                                <Checkbox
                                    id="won"
                                    onCheckedChange={handleStatusChange('won')}
                                    checked={hasStatus('won')}
                                    className="border-green-500 data-[state=checked]:bg-green-500"
                                />
                                <label
                                    htmlFor="won"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Ganhos
                                </label>
                            </li>
                            <li className="items-center space-x-3 space-y-0 border border-red-200 shadow rounded px-2 pt-1">
                                <Checkbox
                                    id="lost"
                                    checked={hasStatus('lost')}
                                    onCheckedChange={handleStatusChange('lost')}
                                    className="border-red-500 data-[state=checked]:bg-red-500"
                                />
                                <label
                                    htmlFor="lost"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    Perdidos
                                </label>
                            </li>
                            <li className="items-center space-x-3 space-y-0 border border-gray-200 shadow rounded px-2 pt-1">
                                <Checkbox
                                    id="N/D"
                                    checked={hasStatus('N/D')}
                                    onCheckedChange={handleStatusChange('N/D')}
                                    className="border-black data-[state=checked]:bg-black"
                                />
                                <label
                                    htmlFor="N/D"
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    N/D
                                </label>
                            </li>
                        </ul>
                    </div>
                </AccordionContent>
            </AccordionItem>
        )
    }

    const tabs = useMemo(
        () => ({
            filters: (
                <Accordion type="multiple" className="w-full">
                    <AccordionItem value="byDate">
                        <AccordionTrigger>Período</AccordionTrigger>
                        <AccordionContent>
                            <DatePickerWithRange
                                date={date}
                                setDate={setDate}
                            />
                        </AccordionContent>
                    </AccordionItem>
                    <StatusFilter />
                </Accordion>
            ),
            vendors: viewAll ? <UserSelection /> : undefined,
        }),
        [viewAll, date, setDate, hasStatus]
    )

    useEffect(() => {
        handleDateChange(date)
    }, [date])

    const activeFiltersCount = useMemo(() => {
        let activeFilters = 0
        if (search.length) {
            activeFilters += 1
        }
        if (selectedUser.filter((i) => i !== 'all').length) {
            activeFilters += 1
        }
        if (date?.from || date?.to) {
            activeFilters += 1
        }
        if (
            hasStatus('open') ||
            hasStatus('won') ||
            hasStatus('lost') ||
            hasStatus('N/D')
        ) {
            activeFilters += 1
        }
        const isPlural = activeFilters > 1
        if (!activeFilters) return 'Filtros'
        return isPlural
            ? `${activeFilters} filtros ativos`
            : `${activeFilters} filtro ativo`
    }, [search, selectedUser, date, hasStatus])

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button className="text-xs min-w-fit">
                    {activeFiltersCount}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="min-w-[350px]">
                <Input
                    placeholder="Buscar por pipe"
                    onChange={handleSearch}
                    value={search}
                />
                <div className="shadow w-[100%] h-0.5 mt-2" />
                <div className="flex flex-row">
                    <div
                        onClick={() => setActiveTab('filters')}
                        className={
                            'flex flex-col w-[100%] h-[80] min-h-fit items-center justify-center p-3  cursor-pointer ' +
                            (activeTab === 'filters'
                                ? ' shadow rounded bg-slate-50 border border-slate-100 my-2 dark:text-black'
                                : '')
                        }
                    >
                        <BsFilter size={24} />
                        Filtros
                    </div>
                    {viewAll && (
                        <div
                            onClick={() => setActiveTab('vendors')}
                            className={
                                'flex flex-col w-[100%] h-[80] min-h-fit items-center justify-center p-3 cursor-pointer' +
                                (activeTab === 'vendors'
                                    ? ' shadow rounded bg-slate-50 border border-slate-100 my-2 dark:text-black'
                                    : '')
                            }
                        >
                            <BiUser size={24} />
                            Vendedores
                        </div>
                    )}
                </div>
                <div className="w-[100%]">{tabs[activeTab]}</div>
            </PopoverContent>
        </Popover>
    )
}

export const ProposalFilter = memo(ProposalFilterComponent)
