import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import {
    CommercialProductContainer,
    CommercialProductRegister,
} from '@Commercial/Products/Data/DataSource/Container'
import { roles } from '@Commercial/roles'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { useState } from 'react'

export const useCommercialProducts = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<DBCommercialProduct[]>([])
    const { hasPermission } = usePermissions()
    const hasJoker = hasPermission(roles.proposal.permissions.joker.key)
    const getProductsUseCase = CommercialProductContainer.get(
        CommercialProductRegister.UseCaseGetProducts
    )

    const formattedAutocomplete = (products: DBCommercialProduct[]) => {
        return products.map((product) => ({
            value: product,
            label: product.name,
        }))
    }

    const getProducts = async () => {
        setLoading(true)
        try {
            const products = await getProductsUseCase.execute()
            setProducts(
                [...products].filter((product) =>
                    hasJoker ? true : product.name !== 'Outros'
                )
            )
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    return { products, getProducts, loading, formattedAutocomplete }
}
