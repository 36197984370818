import { VariableTable } from '@Commercial/Products/Custom/VariableTable/VariableTable'
import { CommercialVariables } from '@Commercial/Proposal/Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '@Commercial/Proposal/Data/DataSource/Container'
import { roles } from '@Commercial/roles'
import { Button, ButtonVariant, Container } from '@Core/Components'
import { Input } from '@Core/Components/Input/Input'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { useUser } from '@Core/Hooks/useUser'
import { CardActions, CardContent, CardHeader } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

export const VariablesDetails = () => {
    const [requestTaxUpdate, setRequestTaxUpdate] = useState(false)
    const { user } = useUser()
    const { control, getValues, setValue, watch } = useForm({
        defaultValues: {
            variables: [] as CommercialVariables[],
        },
    })
    const watchVariables = watch('variables')
    const getVariablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseGetVariablesProposal
    )
    const updateVariablesContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseUpdateVariablesProposal
    )
    const updateTaxValueContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseUpdateTaxValueProposal
    )

    const save = (id: string) => async () => {
        const variable = getValues('variables').find((item) => item._id === id)
        await updateVariablesContainer.execute(variable as CommercialVariables)
        toast.success('Variável atualizada com sucesso')
    }

    const handleGetVariables = async () => {
        const variables = await getVariablesContainer.execute()
        setValue('variables', variables)
    }

    const updateTaxValue = async () => {
        setRequestTaxUpdate(true)
        await updateTaxValueContainer.execute(user?.mid as string || '')
        toast.success(
            'Sua solicitação de atualização de imposto foi enviada com sucesso. Você será notificado por e-mail assim que a atualização for concluída.'
        )
    }

    const variablesDict = useMemo(
        () => ({
            creditCard: (index: number, item: CommercialVariables) => (
                <>
                    <VariableTable
                        variables={item}
                        onSave={save(item._id)}
                        options={{
                            nameLabel: 'Parcelas',
                            valueLabel: 'Taxa',
                        }}
                    />
                </>
            ),
            tax: (index: number, item: CommercialVariables) => (
                <Input
                    name={`variables[${index}].variableValue`}
                    label={item.variableName}
                    useUnform={false}
                    control={control}
                />
            ),
        }),
        [control]
    )

    useEffect(() => {
        handleGetVariables()
    }, [])

    return (
        <Container>
            <div className="w-full flex justify-between p-3">
                <h1 className="text-3xl font-bold">Variáveis</h1>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {watchVariables.map((item, index) => (
                    <div
                        className="mt-1 shadow-sm shadow-border rounded-sm border-card h-fit"
                        key={item._id}
                    >
                        <div className="flex justify-center text-center">
                            <form>
                                <CardHeader title={item.variableName} />
                                <CardContent>
                                    {variablesDict[item.variableType](
                                        index,
                                        item
                                    )}
                                </CardContent>
                            </form>
                        </div>
                        <PermissionWrapper
                            permission={roles.variables.permissions.update.key}
                        >
                            <CardActions>
                                <div className="flex justify-between w-full">
                                    <Button
                                        type="button"
                                        onClick={save(item._id)}
                                    >
                                        Salvar
                                    </Button>
                                    {item.variableType === 'tax' && (
                                        <div className="flex flex-col">
                                            <Button
                                                disabled={requestTaxUpdate}
                                                onClick={updateTaxValue}
                                                type="button"
                                                variant={
                                                    ButtonVariant.secondary
                                                }
                                                className="bg-gray-500 hover:bg-gray-600"
                                            >
                                                {requestTaxUpdate
                                                    ? 'Atualização solicitada'
                                                    : 'Solicitar atualização de imposto das propostas'}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </CardActions>
                            {item.variableType === 'tax' &&
                                requestTaxUpdate && (
                                    <div className="text-sm text-black m-2 w-full p-2 bg-green-200 border rounded-sm">
                                        Sua solicitação de atualização de
                                        imposto foi enviada com sucesso. Você
                                        será notificado por e-mail assim que a
                                        atualização for concluída.
                                    </div>
                                )}
                        </PermissionWrapper>
                    </div>
                ))}
            </div>
        </Container>
    )
}
